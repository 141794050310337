<template>


    <div class="time-bar-new" v-bind:class="{shift: eventProp.isShift, fixed: !eventProp.relative}">

        <div v-if="mode == 'v2'" style="margin-top: -15px; background-color: rgba(100, 116, 175, 0.0); padding: 20px;">
            <div style="padding-bottom: 5px; font-size: 24px; color: #6d8d23; font-family: DistrictProBook; font-weight: 700; border-bottom:1px solid #ccc;">
                {{ storeName }}
            </div>

            <div style="padding: 10px 0; border-bottom:1px solid #ccc; ">
                <div style="background-color: rgba(240, 202, 36, 0.0); padding:0;margin:0;">

                    <div style="padding: 0 5px; ">
                        <div class="time-bar-labels" v-for="hour in 25" :key=hour
                             style="display:inline-block; height: 12px; overflow:hidden;
                             border:0px solid blue; text-align:center;">
                            <div
                                    style="height: 12px; line-height:12px;  font-size: 10px; color: #333;"
                                    v-if="(hour-1) % 6 == 0">{{ ((hour-1) < 11) ? '0' : '' }}{{ (hour-1) }}</div>
                        </div>
                    </div>


                    <!-- Time bars -->
                    <div style="padding: 0 14px;" class="background-v2">

                        <div style="height: 50px; overflow:hidden; position:relative;">
                            <div class="time-bars" v-for="hour in 24" :key=hour
                                 v-bind:style="{ 'border-left': [(hour==1) ? '1px solid #ccc' : 'none'] }"
                                 style="display:inline-block; border-right:1px solid #ccc; height: 50px; z-index:-2;  position:relative;"></div>
                        </div>
                        <div style="height:0px; margin-top: -50px; position:relative;"></div>




                        <div v-for="(timeFrame, index) in timeFrames">
                            <div style="height: 15px; margin-top:0px; z-index: 100; position:relative; font-size:10px; line-height:15px; text-align:center; overflow:hidden;" v-bind:style="GetOffset(timeFrame)">
                                <span v-if="timeFrame.classes == 'desired'">Bevorzugt</span>
                                <span v-if="timeFrame.classes == 'undesired'">Ungewollt</span>
                            </div>
                            <div style="height:0px; margin-top: -5px; position:relative;"></div>
                        </div>


                        <!--
                        <div v-if="showSicknessBar" style="height: 10px; margin-top:5px; border-radius:20px; width:100px; background-color:#e5154f; z-index: 100; position:relative;"></div>
                        <div v-if="showSicknessBar" style="height:0px; margin-top: -15px; position:relative;"></div>

                        <div v-if="showHolidayBar" style="height: 10px; margin-top:15px; border-radius:20px; width:200px; background-color:#dec92f; z-index: 100; position:relative;"></div>
                        <div v-if="showHolidayBar" style="height:0px; margin-top: -25px; position:relative;"></div>
                        -->

                        <div style="width: 100%; height:62px; position:relative;"></div>
                    </div>
                    <!-- Time bars end -->



                </div>
            </div>

            <div style="padding: 15px 0; border-bottom:1px solid #ccc;">
                <div style="font-size:9px;" v-for="(timeFrame, index) in timeFrames">
                    {{ timeFrame }}
                </div>
                <div style="width: 50%; display:inline-block;">
                    <div style="width: 20%; display:inline-block;">
                        Skill
                    </div>
                    <div style="width: 80%; display:inline-block;">
                        <div v-for="(entry, i) in skills" :key="i" style="display: inline-block; border: 1px solid #aaa; padding: 0 10px; border-radius:20px; margin: 0 5px; color: #aaa;">
                            {{entry}}
                        </div>
                    </div>
                </div>
                <div style="width: 50%; display:inline-block;">
                    <div style="width: 20%; display:inline-block;">
                        Spot
                    </div>
                    <div style="width: 80%; display:inline-block;">
                        <div v-for="(entry, i) in spots" :key="i" style="display: inline-block; border: 1px solid #aaa; padding: 0 10px; border-radius:20px; margin: 0 5px; color: #aaa;">
                            {{entry}}
                        </div>
                    </div>
                </div>
            </div>



            <div style="padding: 15px 0; border-bottom:1px solid #ccc;">
                <div style="width: 15%; display:inline-block;">
                    KV Fehler
                </div>
                <div style="width: 85%; display:inline-block;">
                    <div style="display: inline-block; border: 1px solid #aaa; font-size: 10px;
                        padding: 0 10px; border-radius:20px; margin: 0 3px; color: #aaa;">
                        Wochenruhe nicht eingehalten
                    </div>
                    <div style="display: inline-block; border: 1px solid #aaa; font-size: 10px;
                        padding: 0 10px; border-radius:20px; margin: 0 3px; color: #aaa;">
                        Ruhepause nicht eingehalten
                    </div>
                </div>
            </div>


            <div style="padding-top: 15px;">
                <div style="width: 75%; display:inline-block; font-size: 14px;">
                    <div style="display: inline-block; margin-right: 10px;">
                        Krankenstände einblenden
                    </div>
                    <div style="display: inline-block; margin-right: 25px; color: #e5154f;"
                         v-bind:style="{ 'color': [(showSicknessBar) ? '#e5154f' : '#ccc'] }">
                        <i v-if="showSicknessBar" class="fas fa-check-circle" v-on:click="showSicknessBar = !showSicknessBar"></i>
                        <i v-if="!showSicknessBar" class="fas fa-circle" v-on:click="showSicknessBar = !showSicknessBar"></i>
                    </div>
                    <div style="display: inline-block; margin-right: 10px;">
                        Urlaube einblenden
                    </div>
                    <div style="display: inline-block; color: #ccc;"
                         v-bind:style="{ 'color': [(showHolidayBar) ? '#dec92f' : '#ccc'] }">
                        <i v-if="showHolidayBar" class="fas fa-check-circle" v-on:click="showHolidayBar = !showHolidayBar"></i>
                        <i v-if="!showHolidayBar" class="fas fa-circle" v-on:click="showHolidayBar = !showHolidayBar"></i>
                    </div>
                </div>
                <div style="width: 25%; display:inline-block; text-align: right;">
                    <div class="button" style="background-color: var(--ml); width: 100px; text-align:center;" v-on:click="$parent.Toggle()">
                        <div class="label" style="position: relative; color:white;">Fertig</div>
                    </div>
                </div>
            </div>

        </div>



        <div v-if="mode == 'v1' || mode == ''">
            <div class="hour-grid" v-bind:class="{full: parseInt(timeDisplayLengthInMilliseconds / (1000 * 60 * 60)) === 24, 'full-half': parseInt(timeDisplayLengthInMilliseconds / (1000 * 60 * 60)) === 36, half: parseInt(timeDisplayLengthInMilliseconds / (1000 * 60 * 60)) === 12 ,quarter: parseInt(timeDisplayLengthInMilliseconds / (1000 * 60 * 60)) === 6}">
                <div class="time-marks">
                    <div class="mark" v-for="indexHour in parseInt(timeDisplayLengthInMilliseconds / (1000 * 60 * 60))" v-bind:class="{am: parseInt(day.clone().add(indexHour, 'hours').format('HH')) < 13}" :key="indexHour">
                        <div class="label">{{indexHour + day.hour() < 11 ? '0' + (indexHour + day.hour()  - 1).toString() : indexHour + day.hour()  === 25 ? '00' : (indexHour + day.hour()  - 1)}}</div>
                    </div>
                </div>
            </div>
            <div class="background tomorrow" v-bind:style="GetOffsetTomorrow()"></div>
            <div class="background yesterday" v-bind:style="GetOffsetYesterday()"></div>
            <div class="background" v-if="!eventProp.hideBar" v-bind:class="{'overlap-drag': TimeFrameDragOverlapCheck()}">
                <div v-for="(timeFrame, index) in timeFrames" v-bind:id="index"
                     v-show="((timeFrame.approved || timeFrame.approved === undefined) && timeFrame.show)"
                     v-on:click="ToggleDropDownShift($event, GetShiftOptions(timeFrame), 0, 10, timeFrame)"
                     v-bind:style="[GetOffset(timeFrame), GetSpotColor(timeFrame)]"
                     v-bind:class="[timeFrame.classes,
                    {selected: shiftsSelected.find(s => s === timeFrame.shift)},
                    {locked: (timeFrame.classes.indexOf('historic') !== -1 && timeFrame.shift.historicFromDate < currentDate),
                    'locked-start': timeFrame.startDate.format('YYYY-MM-DD') !== day.format('YYYY-MM-DD')},
                    {'template' : timeFrame.shift && timeFrame.shift.weekDays},
                    {'published': timeFrame.shift && timeFrame.shift.published},
                    {'hide-until-hover': IsHiddenUntilHover(timeFrame)}]"
                     class="timeframe" :key="'frame' + index" v-on:mousedown="DragStartShift($event, timeFrame)" drag v-tooltip="GetTimeFrameTooltip(index)">
                    <div class="overtime start"></div>
                    <div class="overtime end"></div>
                    <div class="timeframe break" v-if="HasBreak(timeFrame)" v-bind:style="GetBreakOffset(timeFrame)"></div>
                    <div class="label start" v-bind:style="GetOffsetLabel(timeFrame, true)" v-if="!missingShiftStart && TimeFrameVisible(timeFrame)">{{timeFrame.startDate.format('HH') + ':' + timeFrame.startDate.format('mm')}}</div>
                    <div class="label end" v-bind:style="GetOffsetLabel(timeFrame)" v-if="!missingShiftEnd && TimeFrameVisible(timeFrame)">{{timeFrame.endDate.format('HH') + ':' + timeFrame.endDate.format('mm')}}</div>
                    <div class="handle start" v-on:mousedown="StartDrag($event, timeFrame, index, 'start')" v-if="timeFrame.editable">&vert;</div>
                    <div class="handle end" v-on:mousedown="StartDrag($event, timeFrame, index, 'end')" v-if="timeFrame.editable">&vert;</div>
                    <div class="label-preference" v-if="timeFrame.preference" v-bind:style="GetPreferenceOffset(timeFrame, 'middle')">{{GetPreferenceLabel(timeFrame)}}</div>
                </div>

            </div>
            <div class="background shift-dragged" v-if="timeFrameDrag">
                <div
                        v-bind:style="GetOffset(timeFrameDrag)"
                        v-bind:class="[timeFrameDrag.classes,
                    {locked: (timeFrameDrag.classes.indexOf('historic') !== -1 && timeFrameDrag.shift.historicFromDate < currentDate),
                    'locked-start': timeFrameDrag.startDate.format('YYYY-MM-DD') !== day.format('YYYY-MM-DD')},
                    {'template' : timeFrameDrag.shift && timeFrameDrag.shift.weekDays},
                    {'published': timeFrameDrag.shift && timeFrameDrag.shift.published}]"
                        class="timeframe">
                    <div class="overtime start"></div>
                    <div class="overtime end"></div>
                    <div class="label start" v-if="!missingShiftStart && timeFrameDrag.startDate > day">{{timeFrameDrag.startDate.format('HH') + ':' + timeFrameDrag.startDate.format('mm')}}</div>
                    <div class="label end" v-if="!missingShiftEnd && timeFrameDrag.endDate > day">{{timeFrameDrag.endDate.format('HH') + ':' + timeFrameDrag.endDate.format('mm')}}</div>
                    <div class="handle start" v-if="timeFrameDrag.editable">&vert;</div>
                    <div class="handle end" v-if="timeFrameDrag.editable">&vert;</div>
                </div>
            </div>
            <div class="info">
                <div class="entry" v-for="(entry, i) in info" :key="'entry-'+i" v-bind:class="{half: entry.half}">
                    <div class="label">{{entry.label}}</div>
                    <div class="data">{{entry.data}}</div>
                </div>
                <div class="entry warning" v-for="(entry, iw) in warnings" :key="'warning-'+iw" v-bind:class="{half: entry.half}">
                    <div class="label">{{entry.label}}</div>
                    <div class="data">{{entry.data}}</div>
                </div>
                <div class="entry error" v-for="(entry, ie) in errors" :key="'error-'+ie" v-bind:class="{half: entry.half}">
                    <div class="label">{{entry.label}}</div>
                    <div class="data">{{entry.data}}</div>
                </div>
                <div class="employee" v-if="false">
                    <div class="picture" v-bind:style="{ background: (employee !== null ? employee.picture : 'url(../img/default.jpg)')}"></div>
                    <div class="namefirst">{{employee.firstName}}</div>
                    <div class="namelast">{{employee.lastName}}</div>

                    <div class="fa-stack" v-on:click="RemoveShift()">
                        <i class="fas fa-circle fa-stack-2x"></i>
                        <i class="fas fa-times fa-stack-2x"></i>
                    </div>
                </div>
            </div>
        </div>

        <drag ref="drag"/>
        <timebaruserselect ref="overlay"/>
        <dropdown v-for="(dropdown, id) in dropdowns" :dropdown="dropdown" :key="id"/>
    </div>
</template>

<script>
    import drag from './drag.vue'
    import timebaruserselect from './TimeBarUserSelect.vue'
    import dropdown from '../uicomponents/dropdown.vue'
    import { mapState, mapGetters } from 'vuex';
    export default {
        name: 'timebar',
        components: {
            drag,
            dropdown,
            timebaruserselect,
        },
        data(){
            return{
                id: '',
                mode: 'v1',
                showSicknessBar: true,
                showHolidayBar: true,


                dropdowns: [],

                event: {},
                startDrag: {x: 0, y: 0},
                dragEvent: '',
                dragTime: {},
                dragTimeCap: {},
                dragTimeInit: {},
                dragPauseId: -1,
                dragInterval: -1,
                dragPixelTimeRatio: 0,
                day: null,
                missingShiftStart: false,
                missingShiftEnd: false,
                dragging: false,
                timeDisplayLengthInMilliseconds: null,
                timeFrames: [],
                info: [],
                errors: [],
                warnings: [],
                employee: null,
                relative: null,
                timeFramesCopy: [],
                selectedTimeFrame: {},
                draggingTime: false,
                currentDate: null,
                timeFrameDrag: null,
                employeeAvailabilityStateMap: {},
                employeeAvailabilityTypeMap: {},
                totalWidth: 0,
            }
        },
        computed: {
            ...mapGetters(['planShiftMap', 'actualShiftMax', 'employeePreferenceMap', 'rosterVisibilityState']),
            shiftsSelected: function () { return window.roster.shiftsSelected; },
            skills: function () {
                let val = [];
                for(let key in this.info){
                    if (this.info[key].label.toLowerCase().indexOf("skill") !== -1) {
                        val.push(this.info[key].data);
                    }
                }
                return val;
            },
            spots: function () {
                let val = [];
                for(let key in this.info){
                    if (this.info[key].label.toLowerCase().indexOf("spot") !== -1) {
                        val.push(this.info[key].data);
                    }
                }
                return val;
            },
            storeName: function () {
                return $('.menu-bar input').val();
            }
        },
        watch: {
            planShiftMap(newVal, oldVal){
                this.Init();
            },
            employeePreferenceMap(newVal, oldVal){
                this.Init();
            },
            visibilityState(newVal, oldVal){
                this.Init();
            }
        },
        props:{
            eventProp: Object,
            visibilityState: Object,
            employeeId: String,
        },
        created(){
            this.currentDate = this.$helpers.getMomentTZ();
            var view = this;
            window.addEventListener('resize', function(){requestAnimationFrame(function(){
                view.RefreshWidth();
                view.$forceUpdate();
            })});
            view.Init();

            this.$helpers.GetUiDataMap().then(uiData=> {
                view.updateUIData(uiData);
            });
        },
        destroyed(){
            var view = this;

            window.removeEventListener('resize', this.$forceUpdate);
            window.clearTimeout(this.dragInterval);
        },
        beforeDestroy(){
            var view = this;
            if(this.$refs.overlay && this.$refs.overlay.$el) $(this.$refs.overlay.$el).remove();
            if(this.$refs.drag && this.$refs.drag.$el) $(this.$refs.drag.$el).remove();
            window.timebars = window.timebars.filter(t => t !== view);
        },
        methods: {
            updateUIData: function(data){
                this.uiData = data;
                let stateMap =  {};
                let typeMap = {};
                for(let i = 0; i < data.employeeAvailabilityStates.length; i++){
                    let employeeAvailabilityState = data.employeeAvailabilityStates[i];
                    stateMap[employeeAvailabilityState.id] = employeeAvailabilityState;

                }
                for(let i = 0; i < data.employeeAvailabilityTypes.length; i++){
                    let employeeAvailabilityType = data.employeeAvailabilityTypes[i];
                    typeMap[employeeAvailabilityType.id] = employeeAvailabilityType;

                }

                this.employeeAvailabilityStateMap = stateMap;
                this.employeeAvailabilityTypeMap = typeMap;
            },
            IsHiddenUntilHover(timeFrame){
                if(timeFrame.classes.indexOf('desired') !== -1 ||
                    timeFrame.classes.indexOf('undesired') !== -1
                ) return true;
                return false;
            },
            GetPreferenceLabel(timeFrame){

                /*let label = "";
                let preference = timeFrame.preference;
                if(!preference) return '';
                if(preference.state != 1 || (preference.state == 1 && preference.type == 4)){
                    label = this.employeeAvailabilityStateMap[preference.state] ? this.employeeAvailabilityStateMap[preference.state].label_de : '';
                } else {
                    label = this.employeeAvailabilityTypeMap[preference.type] ? this.employeeAvailabilityTypeMap[preference.type].label_de : '';
                }*/
                return timeFrame.tooltip;
            },
            TimeFrameDragOverlapCheck(){
                if(!this.timeFrameDrag) return false;
                let view = this;
                return this.timeFrames.find(t => t.shift && view.$helpers.CheckTimeFrameIntersect(t.startDate, t.endDate, view.timeFrameDrag.startDate, view.timeFrameDrag.endDate));
            },
            AddTimeFrameDrag(shift){
                if(!shift) return;
                window.timebars.filter(t => t).forEach(t => t.timeFrameDrag = null);
                this.timeFrameDrag = {
                    classes: 'shift ' + (shift.confirmed ? 'confirmed' : '') + (shift.approved ? 'approved' : '') + (shift.published ? 'published' : ''),
                    show: true,
                    type: "shift",
                    id: shift.id,
                    startDate: this.$helpers.getMomentFromStringTZ(shift.from),
                    endDate: this.$helpers.getMomentFromStringTZ(shift.to),
                };
            },
            GetTimeFrameTooltip(index) {
                let view = this;
                return () => {return view.timeFrames[index].tooltip}
            },
            ToggleOverlay($event, selection){
                var view = this;
                if(selection[0].shift && window.roster.shiftsSelected.find(s => s === selection[0].shift)){
                    window.roster.shiftsSelected = window.roster.shiftsSelected.filter(s => s !== selection[0].shift);
                    return;
                }
                if(selection[0].shift && !window.roster.shiftsSelected.find(s => s === selection[0].shift)) {
                    window.roster.shiftsSelected.push(selection[0].shift);
                }
                //if(selection[0].shift && selection[0].shift.published) return;



                window.roster.$forceUpdate();
                this.$refs.overlay.Init({
                    elRelative: $event.currentTarget,
                    shift: selection[0].shift,
                    callbackEnd: function(newUserForShift){
                        var timebarComponent = window.timebars.find(t => t && t.eventProp && t.eventProp.employee && t.eventProp.employee.id === newUserForShift.id);
                        if(timebarComponent){
                            var shift = selection[0].shift;
                            var employee = timebarComponent.eventProp.employee;
                            if(employee){
                                var employeePrev = window.roster.employeeMap[shift.employeeId];
                                if(employeePrev)
                                {
                                    employeePrev.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = employeePrev.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].filter(s => s !== shift);
                                    employeePrev.shifts = employeePrev.shifts.filter(s => s !== shift);
                                }
                                shift.employeeId = employee.id;
                                if(!employee.shiftsDate) employee.shiftsDate = {};
                                if(!employee.shifts) employee.shifts = [];
                                if(!employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')]) employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = [];
                                employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].push(shift);
                                employee.shifts.push(shift);

                                view.$emit('timebar-update-shift', { shift: shift, fields: {employeeId: employee.id}, clearEmployeeFilter: false});
                                window.roster.$forceUpdate();
                                timebarComponent.Init(timebarComponent.day);
                                view.Init(view.day);
                            }
                        }
                        view.$refs.overlay.toggled = false;
                    }
                });
            },
            extractDate: function(str){
                if(!this.$helpers.isBlank(str)) {
                    let split = str.split("T");
                    return split[0];
                } else {
                    return null;
                }
            },
            extractTime: function(str){
                if(!this.$helpers.isBlank(str)) {
                    let split1 = str.split("T");
                    let split2 = split1[1].split(":")
                    return split2[0]+":"+split2[1];
                } else {
                    return null;
                }

            },
            UpdateShift(shift){
                var view = this;

                var fields = {};

                var fromNew = view.dragTime.format();
                var toNew = view.dragTimeCap.format();
                if(view.dragTimeInit > view.dragTimeCap){
                    var temp = toNew;
                    toNew = fromNew;
                    fromNew = temp;
                }
                fields['from'] = fromNew;
                fields['to'] = toNew;
                view.$emit("timebar-update-shift", {id: shift.id, fields: fields});

            },

            DragWatch(){
                var view = this;
                if(!window.mouse.click){
                    requestAnimationFrame(function(){view.draggingTime = false});
                    if(view.selectedTimeFrame.classes.indexOf('shift') !== -1) view.UpdateShift(view.selectedTimeFrame.shift);

                    return;
                }
                var delta = window.mouse.x - this.startDrag.x;
                var dragPixelRatio = this.timeDisplayLengthInMilliseconds / $(this.$el).find('.background').width();
                dragPixelRatio = (1000 * 60);
                var millisecondDelta = (delta * dragPixelRatio);
                millisecondDelta = Math.floor(millisecondDelta / (1000 * 60 * 5)) * (1000 * 60 * 5);
                let timeCap = this.dragTimeCap;
                if(this.HasBreak(view.selectedTimeFrame)){
                    
                    let breakFromMoment = this.$helpers.getMomentFromStringTZ(view.selectedTimeFrame.shift.breakFrom);
                    if(breakFromMoment._d.getTime() < view.selectedTimeFrame.startDate._d.getTime()) breakFromMoment.add(1, 'days');
                    if(this.dragTimeCap._d.getTime() === this.selectedTimeFrame.startDate._d.getTime()){
                        let breakToMoment = breakFromMoment.clone().add(view.selectedTimeFrame.shift.breakValue, 'minutes');
                        timeCap = breakToMoment;
                    } else {
                        timeCap = breakFromMoment;
                    }
                }
                var timeDelta = this.dragTimeInit.clone().add(millisecondDelta, 'milliseconds');
                /*if((isStartTime && (parseInt(timeEnd.format('x')) > parseInt(timeDelta.format('x'))))
                || (!isStartTime && (parseInt(timeStart.format('x')) < parseInt(timeDelta.format('x'))))) {*/

                if(this.dragTimeCap._d.getTime() === this.selectedTimeFrame.startDate._d.getTime()) {
                    if(timeDelta._d.getTime() - timeCap._d.getTime() < 5 * 60 * 1000){
                        timeDelta = timeCap.clone().add(5, 'minutes');
                    }
                } else {
                    if(timeCap._d.getTime() - timeDelta._d.getTime() < 5 * 60 * 1000){
                        timeDelta = timeCap.clone().add(-5, 'minutes');
                    }
                }
                if(this.dragTimeInit < timeCap) {

                    if(timeDelta.date() < this.day.date()) timeDelta = this.day.clone().hours(0).minutes(5);
                    if(timeDelta.date() > this.day.date()) timeDelta = this.day.clone().hours(23).minutes(55);
                }
                this.dragTime.year(timeDelta.year());
                this.dragTime.month(timeDelta.month());
                this.dragTime.date(timeDelta.date());
                this.dragTime.hours(timeDelta.hours());
                this.dragTime.minutes(timeDelta.minutes());
                this.$forceUpdate();
                requestAnimationFrame(function(){view.DragWatch()});
            },

            StartDrag($event, timeFrame, index, direction){
                if(timeFrame.shift && timeFrame.shift.published) return;
                var event = $event;
                var el = event.currentTarget;
                this.draggingTime = true;
                this.selectedTimeFrame = timeFrame;
                if(el.classList.contains('start')) this.dragTime = timeFrame.startDate;
                else this.dragTime = timeFrame.endDate;
                if(el.classList.contains('start')) this.dragTimeCap = timeFrame.endDate;
                else this.dragTimeCap = timeFrame.startDate;
                this.dragTimeInit = this.dragTime.clone();
                var posx = $(this.$el).find('.timeframe#' + index.toString() + ' .handle.' + direction);
                if(posx.length && posx.length > 0) posx = posx[0].getBoundingClientRect().left + ((posx[0].getBoundingClientRect().right - posx[0].getBoundingClientRect().left) * .5);
                else posx = window.mouse.x;
                this.startDrag = {x: posx, y: window.mouse.y};
                var view = this;
                view.$refs.drag.DragStop();
                view.dragging = false;
                requestAnimationFrame(function(){
                    view.$refs.drag.DragStop();
                    view.dragging = false;
                    view.DragWatch();
                });
            },
            GetShiftOptions(timeFrame){
                if(!timeFrame.shift) return [];
                var temp = [];
                temp.push({label: 'Unnassign', shift: timeFrame.shift});
                return temp;
            },
            ToggleDropDownShift: function ($event, selection, offsetx, offsety, timeFrame) {
                if(timeFrame.type != "shift"){
                    return;
                }
                if(true) {
                    if(this.draggingTime) return;
                    this.ToggleOverlay($event, selection);
                    return;
                }
                if(this.draggingTime) return;
                if(selection.length === 0) return;
                var view = this;
                var event = $event;
                var box = event.currentTarget.getBoundingClientRect();
                var dropdowns = this.dropdowns;
                this.dropdowns.push({
                    items: selection,
                    el: event.currentTarget,
                    remainAfterSelect: true,
                    toggled: true,
                    offsetx: offsetx,
                    offsety: offsety,
                    posx: box.left + offsetx,
                    posy: box.bottom + offsety,
                    onceSelected: function ($eventInner, item, dropdown) {
                        view.RemoveShift(item.shift);
                        view.dropdowns = view.dropdowns.filter(d => d !== dropdown);
                    }
                });
            },
            RemoveShift(shift){
                if(shift.published) return;
                var employee = this.eventProp.employee;
                if(employee){
                    this.eventProp.employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = this.eventProp.employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].filter(s => s !== shift);
                    this.eventProp.employee.shifts = this.eventProp.employee.shifts.filter(s => s !== shift);
                    delete shift.employeeId;
                    if(!employee.shiftsDate) employee.shiftsDate = {};
                    if(!employee.shifts) employee.shifts = [];
                    this.timeFramesCopy = this.timeFramesCopy.filter(t => !t.shift || (t.shift && t.shift !== shift));
                    this.$forceUpdate();

                    this.$emit('timebar-update-shift', { shift: shift, fields: {employeeId: null}, clearEmployeeFilter: true});

                }
            },
            PopulateByDay(day){
                this.timeFrames = [];
                this.timeFramesCopy = [];
                this.info = [];
                this.errors = [];
                this.warnings = [];
                this.day = day.clone();

                var view = this;
                if(this.eventProp.isShift){
                    var shift = this.eventProp.timeFrames[0].shift;
                    this.info.push({label: 'Shift', data: shift.fromDate.format('HH:mm') + ' - ' +  shift.toDate.format('HH:mm')});
                    this.info.push({label: 'Skill:', data: shift.spotId && window.roster.spotMap[shift.spotId].requiredSkill &&window.roster.spotMap[shift.spotId].requiredSkill !== '' ? window.roster.skillMap[window.roster.spotMap[shift.spotId].requiredSkill].name : '', half: true});
                    this.info.push({label: 'Spot:', data: shift.spotId ? window.roster.spotMap[shift.spotId].name : '', half: true});
                    return;
                }


                var employeeMapped = this.eventProp.employee;


                this.AddEmployeePreferenceBars(employeeMapped);
                this.AddEmployeeAvailabilityBars(employeeMapped);
                this.AddShiftBars(employeeMapped, this.day.clone().add(-1, 'days'));
                this.AddShiftBars(employeeMapped, this.day);
                this.AddShiftBars(employeeMapped, this.day.clone().add(1, 'days'));
                this.info.push({label: 'Store:', data: $('.menu-bar input').val()});

                /*if(this.day.hours() + (this.timeDisplayLengthInMilliseconds / (1000 * 3600)) > 24) this.AddDay(day.clone().add(1, 'day'));
                var shiftsBefore = employeeMapped.shifts ? employeeMapped.shifts.filter(s => s.fromDate.format('YYYY-MM-DD') === this.day.clone().add(-1, 'day').format('YYYY-MM-DD')) : [];
                if(shiftsBefore.find(s => s.toDate.date() === day.date())) this.AddDay(day.clone().add(-1, 'day')); */
                this.$forceUpdate();
            },
            AddDay(day){
                var view = this;
                day = day.clone();

                if(this.eventProp.isShift){
                    var shift = this.eventProp.timeFrames[0].shift;
                    this.info.push({label: 'Shift', data: shift.fromDate.format('HH:mm') + ' - ' +  shift.toDate.format('HH:mm')});
                    this.info.push({label: 'Skill:', data: shift.spotId ? window.roster.spotMap[shift.spotId].unitName : '', half: true});
                    this.info.push({label: 'Spot:', data: shift.spotId ? window.roster.spotMap[shift.spotId].name : '', half: true});
                    return;
                }
                var employeeMapped = this.eventProp.employee;

                this.AddEmployeePreferenceBars(employeeMapped);
                this.AddEmployeeAvailabilityBars(employeeMapped);
                this.AddShiftBars(employeeMapped, day);
                this.info.push({label: 'Store:', data: $('.menu-bar input').val()});

                this.$forceUpdate();
            },
            AddShiftBars(employee, day){
                let dateKey = day.format('YYYY-MM-DD');
                let actualShiftMap = this.$parent.employeeDateActualShiftMap;
                let plannedShiftMap = this.$parent.employeeDatePlannedShiftMap;

                let mode = this.$parent.showRosterMode;

                let actualShiftsAdded = false;
                if(actualShiftMap[employee.id] && actualShiftMap[employee.id][dateKey] && (this.visibilityState ? this.visibilityState.visibilityActualShifts : true)){
                    actualShiftMap[employee.id][dateKey].forEach(actualShift => {
                        let from = this.$parent.actualShiftMomentMap[actualShift.id].fromDate;
                        let to = this.$parent.actualShiftMomentMap[actualShift.id].toDate;
                        if(this.timeFrames.filter(t => t.id == actualShift.id) == 0){
                            this.timeFrames.push({editable: false,
                                classes: 'actualshift ' + (actualShift.confirmed ? 'confirmed' : ''),
                                show: true,
                                type: "actualshift",
                                id: actualShift.id,
                                startDate: from,
                                endDate: to,
                                tooltip: "Stempelzeit: "+(actualShift.confirmed ?'bestätigt':'unbestätigt')});
                            actualShiftsAdded = true;
                        }

                    });
                }
                if(plannedShiftMap[employee.id] && plannedShiftMap[employee.id][dateKey] && !actualShiftsAdded && (this.visibilityState ? this.visibilityState.visibilityPlanShifts : true)){
                    plannedShiftMap[employee.id][dateKey].forEach(shift => {
                        let from = this.$parent.planShiftMomentMap[shift.id].fromDate;
                        let to = this.$parent.planShiftMomentMap[shift.id].toDate;
                        if(this.timeFrames.filter(t => t.id == shift.id) == 0 && (from.unix() > this.actualShiftMax || mode == "plan")){

                            if(shift.historicTo && !shift.historicToDate) shift.historicToDate = this.$helpers.getMomentFromStringTZ(shift.historicTo);
                            if(shift.historicFrom && !shift.historicFromDate) shift.historicFromDate = this.$helpers.getMomentFromStringTZ(shift.historicFrom);
                            this.timeFrames.push({editable: true, classes: 'shift ' + (shift.published ? 'published' : ''), show: true, type: "shift", id: shift.id, start: from.format('x'), startDate: from, end: to.format('x'), endDate: to, shift: shift});
                            if(shift.historicFrom) this.timeFrames.push({editable: true, classes: 'historic', show: shift, type: "historic", id: 'template-'+shift.id, start: shift.historicFromDate.format('x'), startDate: shift.historicFromDate, end: shift.historicToDate.format('x'), endDate: shift.historicToDate, shift: shift});
                            this.info.push({label: 'Skill:', data: shift.spotId ? window.roster.spotMap[shift.spotId].unitName : shift.spotId, half: true});
                            this.info.push({label: 'Spot:', data: shift.spotId ? window.roster.spotMap[shift.spotId].name: shift.spotId, half: true});

                            let legalChecks = window.roster.legalErrorMap[shift.employeeId] ? (window.roster.legalErrorMap[shift.employeeId][dateKey] ? window.roster.legalErrorMap[shift.employeeId][dateKey] : {}) : {};
                            if(legalChecks.restError){
                                this.errors.push({label: 'KV verletzt:', data: '11 Stunden Ruhezeit nicht eingehalten'});
                            }
                            if(legalChecks.restWeekError){
                                this.errors.push({label: 'KV verletzt:', data: 'Kein durchgehend freier Tag / 36 Stunden frei'});
                            }
                            if(legalChecks.shiftError){
                                this.errors.push({label: 'KV verletzt:', data: 'Über 12 Stunden Arbeitszeit am Tag'});
                            }
                            if(legalChecks.weekError){
                                this.errors.push({label: 'KV verletzt:', data: 'Über 60 Stunden Arbeitszeit in dieser Woche'});
                            }

                            if(legalChecks.plan_restError){
                                this.errors.push({label: 'Plantyp verletzt:', data: '11 Stunden Ruhezeit nicht eingehalten'});
                            }
                            if(legalChecks.plan_restWeekError){
                                this.errors.push({label: 'Plantyp verletzt:', data: 'Kein durchgehend freier Tag / 36 Stunden frei'});
                            }
                            if(legalChecks.plan_dayError){
                                this.errors.push({label: 'Plantyp verletzt:', data: 'Über 12 Stunden Arbeitszeit am Tag'});
                            }
                            if(legalChecks.plan_weekError){
                                this.errors.push({label: 'Plantyp verletzt:', data: 'Über 60 Stunden Arbeitszeit in dieser Woche'});
                            }

                        }

                    });
                }

            },
            AddEmployeePreferenceBars(employee){
                if(this.$parent.employeePreferenceAvailabilityMap[employee.id]){
                    if(this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[this.day.format('YYYY-MM-DD')]){
                        for(var x in this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[this.day.format('YYYY-MM-DD')]){
                            var preference = this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[this.day.format('YYYY-MM-DD')][x].preference;
                            this.AddEmployeePreferenceBar(preference);
                        }
                    }
                    let prevDay = this.day.clone().add(-1,'days');
                    if(this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[prevDay.format('YYYY-MM-DD')]){
                        for(var x in this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[prevDay.format('YYYY-MM-DD')]){
                            var preference = this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[prevDay.format('YYYY-MM-DD')][x].preference;
                            this.AddEmployeePreferenceBar(preference);
                        }
                    }
                    let nextDay = this.day.clone().add(1,'days');
                    if(this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[nextDay.format('YYYY-MM-DD')]){
                        for(var x in this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[nextDay.format('YYYY-MM-DD')]){
                            var preference = this.$parent.employeePreferenceAvailabilityMap[employee.id].preferencesDate[nextDay.format('YYYY-MM-DD')][x].preference;
                            this.AddEmployeePreferenceBar(preference);
                        }
                    }

                }
            },
            AddEmployeeAvailabilityBars(employee){
                if(this.$parent.employeePreferenceAvailabilityMap[employee.id] && this.$parent.employeePreferenceAvailabilityMap[employee.id].availabilities){

                    for(var x in this.$parent.employeePreferenceAvailabilityMap[employee.id].availabilities){
                        let availabilities = this.$parent.employeePreferenceAvailabilityMap[employee.id].availabilities[x];
                        availabilities.forEach(a => {

                            let date = this.day;
                            let dayOfWeek = date.isoWeekday();
                            if(a.weekDays.filter(w => w === dayOfWeek).length > 0){
                                this.AddEmployeeAvailabilityBar(a, date);
                            }

                            date = this.day.clone().add(-1,'days');
                            dayOfWeek = date.isoWeekday();
                            if(a.weekDays.filter(w => w === dayOfWeek).length > 0){
                                this.AddEmployeeAvailabilityBar(a, date);
                            }

                            date = this.day.clone().add(1,'days');
                            dayOfWeek = date.isoWeekday();
                            if(a.weekDays.filter(w => w === dayOfWeek).length > 0){
                                this.AddEmployeeAvailabilityBar(a, date);
                            }
                        });

                    }
                }
            },
            AddEmployeePreferenceBar(preference){
                let preferenceClass = 'notshift preference';
                let tooltip = "";
                let isVisible = true;

                if(preference.state === 1) {
                    if(preference.type == 1) {
                        preferenceClass += ' vacation'
                        tooltip = "Urlaub";
                        isVisible = this.visibilityState ? this.visibilityState.visibilityVacation : true;
                    } else if(preference.type == 2) {
                        preferenceClass += ' sickness';
                        tooltip = "Krankenstand";
                        isVisible = this.visibilityState ? this.visibilityState.visibilitySick : true;
                    } else if(preference.type == 3) {
                        preferenceClass += ' holidayadjustment';
                        tooltip = "Feiertagsausgleich";
                        isVisible = this.visibilityState ? this.visibilityState.visibilityHolidayAdjustment : true;
                    } else if(preference.type == 6) {
                        preferenceClass += ' nursingvacation';
                        tooltip = "Pflegeurlaub";
                        isVisible = this.visibilityState ? this.visibilityState.visibilityVacation : true;
                    } else if(preference.type == 7) {
                        preferenceClass += ' education';
                        tooltip = "Ausbildung";
                        isVisible = this.visibilityState ? this.visibilityState.visibilityEducation : true;
                    } else if(preference.type == 8) {
                        preferenceClass += ' wagecontinuation';
                        tooltip = "Lohnfortzahlung";
                        isVisible = this.visibilityState ? this.visibilityState.visibilityWageContinuation : true;
                    } else {
                        preferenceClass += ' unavailable';
                        tooltip = "Nicht Verfügbar";
                        isVisible = this.visibilityState ? this.visibilityState.visibilityNotAvailable : true;
                    }

                };
                if(preference.state === 2){
                    preferenceClass += ' onlyavailable';
                    tooltip = "Nur verfügbar";
                    isVisible = this.visibilityState ? this.visibilityState.visibilityOnlyAvailable : true;
                }
                if(preference.state === 3){
                    preferenceClass += ' undesired';
                    tooltip = "Ungewünscht";
                    isVisible = this.visibilityState ? this.visibilityState.visibilityUndesired : true;
                }
                if(preference.state === 4){
                    preferenceClass += ' desired';
                    tooltip = "Wunsch";
                    isVisible = this.visibilityState ? this.visibilityState.visibilityDesired : true;
                }


                if(this.timeFrames.filter(t => t.id == preference.id).length == 0 && preference.approved){
                    this.timeFrames.push({approved: preference.approved,
                        classes: preferenceClass,
                        type: "preference",
                        overrideContract: preference.overrideContract,
                        id: preference.id,
                        show: isVisible,
                        startDate: this.$parent.employeePreferenceMomentMap[preference.id].fromDate.clone(),
                        endDate: this.$parent.employeePreferenceMomentMap[preference.id].toDate.clone(),
                        preference: preference,
                        tooltip: tooltip});
                    if(this.timeFrames[this.timeFrames.length - 1].startDate >= this.timeFrames[this.timeFrames.length - 1].endDate) this.timeFrames[this.timeFrames.length - 1].endDate.add(1, 'day');
                }
            },
            AddEmployeeAvailabilityBar(a, date){
                let isVisible = true;
                var availabilityClass = 'notshift';
                let availabilityId = date.format('YYYY-MM-DD')+"-"+a.id;
                let availabilityFrom = this.$helpers.getMomentFromStringTZ(date.format('YYYY-MM-DD')+'T'+a.from);
                let availabilityTo = this.$helpers.getMomentFromStringTZ(date.format('YYYY-MM-DD')+'T'+a.to);
                if(availabilityTo<=availabilityFrom){
                    availabilityTo = availabilityTo.add(1,'days');
                }
                let tooltip ="";
                if(a.state === 1){
                    availabilityClass += ' unavailable';
                    tooltip = "Nicht verfügbar";
                    isVisible = this.visibilityState ? this.visibilityState.visibilityNotAvailable : true;
                }
                if(a.state === 2) {
                    availabilityClass += ' onlyavailable';
                    tooltip = "Nur verfügbar";
                    isVisible = this.visibilityState ? this.visibilityState.visibilityOnlyAvailable : true;
                }
                if(a.state === 3) {
                    availabilityClass += ' undesired';
                    tooltip = "Ungewünscht";
                    isVisible = this.visibilityState ? this.visibilityState.visibilityUndesired : true;
                }
                if(a.state === 4) {
                    availabilityClass += ' desired';
                    tooltip = "Wunsch";
                    isVisible = this.visibilityState ? this.visibilityState.visibilityDesired : true;
                }

                if(this.timeFrames.filter(t => t.id == availabilityId).length == 0 ){
                    this.timeFrames.push({classes: availabilityClass,
                        type: "availability",
                        id: availabilityId,
                        availability: availabilityClass,
                        show: isVisible,
                        startDate: availabilityFrom,
                        endDate: availabilityTo,
                        tooltip: tooltip,
                        preference: tooltip,
                    });
                    if(this.timeFrames[this.timeFrames.length - 1].startDate > this.timeFrames[this.timeFrames.length - 1].endDate) this.timeFrames[this.timeFrames.length - 1].startDate.add(-1, 'day');
                }
            },
            Init(day){

                (this.$parent.mode != undefined && this.$parent.mode != null) ? this.mode = this.$parent.mode : 'v1';

                var view = this;
                if(this.employeeId) this.eventProp.employee = window.roster.employeeMap[this.employeeId];
                if(!window.timebars) window.timebars = [];
                //window.timebars = window.timebars.filter(t => t && $(t.$el).closest('html').length > 0);
                if(!window.timebars.find(t => t === this)) window.timebars.push(view);
                view.event = view.eventProp;
                if(view.$refs.overlay) view.$refs.overlay.toggled = false;
                view.eventProp.timebar = view;
                if(day) view.PopulateByDay(day);
                else view.PopulateByDay(view.eventProp.day);

                if(!view.day) view.day = view.startTime ? view.day.clone().hour(view.startTime) : view.day.clone();
                view.timeFramesCopy = [];
                var shifts = view.timeFramesCopy.filter(s => s.shift);
                for(var x in shifts)
                {
                    var shift = shifts[x];
                    if(!shift.timebars) shift.timebars = [];
                    shift.timebars = shift.timebars.filter(t => t);
                    shift.timebars.push(view);
                }

                if(!view.timeDisplayLengthInMilliseconds)
                {
                    if(view.eventProp.timeDisplayLengthInMilliseconds) view.timeDisplayLengthInMilliseconds = view.eventProp.timeDisplayLengthInMilliseconds;
                    else view.timeDisplayLengthInMilliseconds = 86400000; //milliseconds in one day
                }

                if(view.eventProp.hideBar) return;
                requestAnimationFrame(function(){
                    view.RefreshWidth();
                    //view.$forceUpdate();
                });
            },
            RefreshWidth(){
                let view = this;
                if(view.$el) view.totalWidth = view.$el.getBoundingClientRect().width;
            },
            DragStartShift: function ($event, timeFrame) {
                if(timeFrame.type != "shift" || timeFrame.shift == null) return;
                let shift = timeFrame.shift;
                if(shift.published) return;
                if($(window.mouse.element).closest('.handle').length > 0) return;
                this.$helpers.DragShift(shift, window.roster);
                return;
                var view = this;
                /*var mousetemp = {x: window.mouse.x, y: window.mouse.y};
            || Math.abs(mousetemp.x - window.mouse.x) < 10 || Math.abs(mousetemp.y - window.mouse.y) < 10*/
                setTimeout(function(){
                    if(!window.mouse.click || view.draggingTime) return;
                    $($(view.$parent.$el).find('.background')[0]).css('display', 'none');



                    view.$parent.dragShift = shift;
                    /*
                    view.$parent.sollIstDragging.employeeIdOld = shift.employeeId;
                    view.$parent.sollIstDragging.shiftTimeinMinutes = shift.helperHolidayValue + Math.floor((shift.toDate.unix() - shift.fromDate.unix())/60);
                    */

                    view.dragging = true;
                    view.$parent.dragging = true;
                    view.$parent.FilterSpot(shift.spotId,true);
                    var event = $event;
                    view.$refs.drag.info = [];
                    view.$refs.drag.info.push({label: 'Shift', data: shift.fromDate.format('HH:mm') + ' - ' +  shift.toDate.format('HH:mm')});
                    view.$refs.drag.info.push({label: 'Skill:', data: shift.spotId ? window.roster.spotMap[shift.spotId].unitName : '', half: true});
                    view.$refs.drag.info.push({label: 'Spot:', data: shift.spotId ? window.roster.spotMap[shift.spotId].name : '', half: true});
                    view.$refs.drag.DragStart(event.pageX, event.pageY, shift);
                    view.$refs.drag.callbackEnd = view.DragEndShift;
                }, 200);
            },
            DragEndShift: function (shift) {
                this.$parent.sollIstDragging.employeeIdOld = null;

                $($(this.$parent.$el).find('.background')[0]).css('display', '');
                if(!this.dragging) return;

                var view = this;


                requestAnimationFrame(function(){view.dragging = false;view.$parent.dragging = false});
                var unassign = $(window.mouse.element).closest('.shifts');

                if(unassign.length > 0){
                    this.RemoveShift(shift);
                    requestAnimationFrame(function(){view.Init(view.day)});
                    return;
                }

                var timebar = $(window.mouse.element).closest('.time-bar-new');
                if(timebar){
                    var timebarComponent = window.timebars.find(t => t.$el === timebar[0]);
                    if(timebarComponent){
                        var employee = timebarComponent.eventProp.employee;
                        if(employee){
                            var employeePrev = window.roster.employeeMap[shift.employeeId];
                            if(employeePrev)
                            {
                                employeePrev.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = employeePrev.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].filter(s => s !== shift);
                                employeePrev.shifts = employeePrev.shifts.filter(s => s !== shift);

                            }
                            shift.employeeId = employee.id;
                            if(!employee.shiftsDate) employee.shiftsDate = {};
                            if(!employee.shifts) employee.shifts = [];
                            if(!employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')]) employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = [];
                            employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].push(shift);
                            employee.shifts.push(shift);
                            view.$parent.UpdateSollIstMapEntry(employee,shift);

                            this.$emit('timebar-update-shift', { shift: shift, fields: {employeeId: employee.id}, clearEmployeeFilter: true});
                            window.roster.$forceUpdate();
                            timebarComponent.Init(timebarComponent.day);

                        }
                    }
                }
                var day = $(window.mouse.element).closest('.day');
                if(day && day.length > 0){
                    var el = day[0];
                    var dayDate = this.$helpers.getMomentFromStringTZ(el.attributes['year'].value + '-' +el.attributes['month'].value + '-' +el.attributes['day'].value, 'YYYY-MM-DD');
                    var entry = day.closest('.entry');
                    var employeeId = day.attr('employeeId');

                    var employee = window.roster.employees.find(e => e.id === employeeId);
                    if(employee){
                        var employeePrev = window.roster.employeeMap[shift.employeeId];
                        if(employeePrev)
                        {
                            employeePrev.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = employeePrev.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].filter(s => s !== shift);
                            employeePrev.shifts = employeePrev.shifts.filter(s => s !== shift);
                        }
                        shift.employeeId = employee.id;
                        if(!employee.shiftsDate) employee.shiftsDate = {};
                        if(!employee.shifts) employee.shifts = [];
                        if(!employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')]) employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = [];
                        employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].push(shift);
                        employee.shifts.push(shift);
                        this.$emit('timebar-update-shift', { shift: shift, fields: {employeeId: employee.id}, clearEmployeeFilter: true});


                    }
                }


                requestAnimationFrame(function(){
                    view.$forceUpdate();
                    view.$parent.toggled = false;
                });
                this.Init(this.day);
            },

            GetSwitchedSpotIconColor(color){

                    color = this.$helpers.hexToHSLObject(color);
                    if(color.s !== 0) color.s += (100 - color.s) * .9; //color.s === 0 means it's monochrome, adding more saturation would make it red
                    if(color.s !== 0) color.l += (100 - color.l) * .6;
                    else color.l += (100 - color.l) * .7;

                    color.l *= .85;
                    color.s *= .6;
                    return 'hsl(' + color.h + 'deg,' + color.s + '%,' + color.l + '%)';

            },

            GetSpotColor(timeFrame){
                if(this.rosterVisibilityState.daySpotColorSwitch && timeFrame.shift && timeFrame.shift.spotId && timeFrame.classes.indexOf('historic') === -1){
                    return {background: this.GetSwitchedSpotIconColor(window.roster.spotMap[timeFrame.shift.spotId].color)};
                }
                return {};
            },
            GetOffset(timeFrame){

                let styles = timeFrame.styles ? timeFrame.styles : {};
                if(this.mode == 'v2'){
                    if(timeFrame.classes == 'desired'){
                        styles["top"] = '5px';
                        styles["background-color"] = '#aace27';
                    }
                    if(timeFrame.classes == 'undesired'){
                        styles["top"] = '5px';
                        styles["background-color"] = '#ff1655';
                    }
                }
                let daytime = this.timeDisplayLengthInMilliseconds ? this.timeDisplayLengthInMilliseconds : (24 * 60 * 60 * 1000);
                let timeFrameWidth = timeFrame.endDate._d.getTime() - timeFrame.startDate._d.getTime();

                let frameWidth = (timeFrameWidth / daytime) * 100;
                styles.width = frameWidth + '%';
                let frameLeft = ((timeFrame.startDate._d.getTime() - this.day._d.getTime()) / daytime) * 100;
                styles.left = frameLeft + '%';
                /*if(frameLeft < 0) styles["padding-left"] = (-1 * (frameWidth - frameLeft)) + '%';*/
                /*if(frameLeft < 0) {
                    styles["padding-left"] = ('calc(' + (-frameLeft) + '% + 40px)');
                }
                if(frameLeft > 0 && frameLeft + frameWidth > 100) {
                    styles["padding-right"] = ('calc(' + (frameWidth - (100 - frameLeft)) + '% + 40px)');
                }*/

                return styles;
            },

            GetOffsetLabel(timeFrame, start){

                let styles = timeFrame.styles ? timeFrame.styles : {};
                if(this.mode == 'v2'){
                    if(timeFrame.classes == 'desired'){
                        styles["top"] = '5px';
                        styles["background-color"] = '#aace27';
                    }
                    if(timeFrame.classes == 'undesired'){
                        styles["top"] = '5px';
                        styles["background-color"] = '#ff1655';
                    }
                }
                let daytime = this.timeDisplayLengthInMilliseconds ? this.timeDisplayLengthInMilliseconds : (24 * 60 * 60 * 1000);
                let timeFrameWidth = timeFrame.endDate._d.getTime() - timeFrame.startDate._d.getTime();

                let frameWidth = (timeFrameWidth / daytime) * this.totalWidth;
                let frameLeft = ((timeFrame.startDate._d.getTime() - this.day._d.getTime()) / daytime) * this.totalWidth;
                if(!start) {
                    if(frameWidth < 90 || this.totalWidth - frameLeft < 40) styles['margin-top'] = '10px';
                    if (frameLeft + frameWidth > this.totalWidth) {
                        styles['right'] = ((frameLeft + frameWidth) - this.totalWidth) + 'px';
                    }
                    if (frameLeft + frameWidth < 90) {
                        styles['margin-top'] = '10px';
                        if(frameLeft + frameWidth < 40){
                            styles['right'] = ((-40 + (frameLeft + frameWidth + 4))) + 'px';

                        }
                    }
                } else {
                    if (frameLeft < 0) {
                        styles['left'] = ((-frameLeft)) + 'px';
                    } else if(this.totalWidth - frameLeft < 40) styles['left'] = ((-40 + (this.totalWidth - frameLeft + 4))) + 'px';
                }

                return styles;
            },


            GetPreferenceOffset(timeFrame, position){
                let styles = timeFrame.styles ? timeFrame.styles : {};
                let daytime = this.timeDisplayLengthInMilliseconds ? this.timeDisplayLengthInMilliseconds : (24 * 60 * 60 * 1000);
                let timeFrameWidth = timeFrame.endDate._d.getTime() - timeFrame.startDate._d.getTime();

                let frameWidth = (timeFrameWidth / daytime) * this.totalWidth;
                let frameWidthFromStart = ((timeFrame.endDate._d.getTime() - this.day._d.getTime()) / daytime) * this.totalWidth;
                let frameLeft = ((timeFrame.startDate._d.getTime() - this.day._d.getTime()) / daytime) * this.totalWidth;
                /*styles.left = frameLeft + '%';*/


                let letterLengthInPixels = 7.2;
                let labelWidth = this.GetPreferenceLabel(timeFrame).length * letterLengthInPixels;
                //styles.left = 'calc(50% - ' + (labelWidth * .5) + 'px)';
                styles.width = labelWidth + 'px';

                if(position === 'middle') {
                    /*let frameWidthVisible = ((100 / frameWidth) * 100);// - (frameWidth - (frameLeft > 0 ? frameLeft : 0))) * 100;
                    if(frameWidthVisible > 100) frameWidthVisible = 100;
                    if(frameLeft > 0){
                        frameWidthVisible = frameWidthVisible - (frameLeft * (100 / frameWidth));
                    }
                    let labelOffset = (frameWidthVisible * .5);// + (100 * (frameLeft / frameWidth));
                    if(frameLeft < 0) {
                        labelOffset -= (frameLeft * (100 / frameWidth));
                    }
                    if(frameLeft + frameWidth < 100){
                        labelOffset -= (frameWidthVisible - ((frameLeft + frameWidth) * (100 / frameWidth))) * .5;
                    }*/
                    if(frameLeft < 0) {
                        if(frameLeft + frameWidth < this.totalWidth) {
                            if(frameWidthFromStart > labelWidth) styles.left = (-frameLeft + (((frameWidthFromStart) - labelWidth) * .5)) + 'px';
                            else styles.left = (-frameLeft + (((frameWidthFromStart) - labelWidth))) + 'px';
                        }
                        else styles.left = (-frameLeft + (((this.totalWidth) - labelWidth) * .5)) + 'px';
                    }
                    else if(frameWidth + frameLeft > this.totalWidth) styles.left = (((this.totalWidth - frameLeft) - labelWidth) * .5) + 'px';
                    else styles.left = (((frameWidth) - labelWidth) * .5) + 'px';
                }

                return styles;
            },
            TimeFrameVisible(timeFrame){
                if(timeFrame.startDate._d.getTime() > this.day._d.getTime() && timeFrame.endDate._d.getTime() < this.day._d.getTime() + this.timeDisplayLengthInMilliseconds) return true;
                if(timeFrame.startDate._d.getTime() < this.day._d.getTime() && timeFrame.endDate._d.getTime() > this.day._d.getTime()) return true;
                if(timeFrame.startDate._d.getTime() < this.day._d.getTime() + this.timeDisplayLengthInMilliseconds && timeFrame.endDate._d.getTime() > this.day._d.getTime() + this.timeDisplayLengthInMilliseconds) return true;
                return false;
            },
            HasBreak(timeFrame){
                if(timeFrame && timeFrame.shift){
                    if(timeFrame.classes.indexOf('historic') === -1){
                        return timeFrame.shift.breakFrom;
                    } else {
                        return timeFrame.shift.historicBreakFrom;
                    }
                } else {
                    return false;
                }
                
                
            },
            GetBreakOffset(timeFrame){
                let styles = {};
                let timeFrameWidth = timeFrame.endDate._d.getTime() - timeFrame.startDate._d.getTime();
                
                let breakFromMoment;
                let breakToMoment;
                if(timeFrame.classes.indexOf('historic') === -1){
                    breakFromMoment = this.$helpers.getMomentFromStringTZ(timeFrame.shift.breakFrom);
                    if(breakFromMoment._d.getTime() < timeFrame.startDate._d.getTime()) breakFromMoment.add(1, 'days');
                    breakToMoment = breakFromMoment.clone().add(timeFrame.shift.breakValue, 'minutes');
                } else {
                    breakFromMoment = this.$helpers.getMomentFromStringTZ(timeFrame.shift.historicBreakFrom);
                    if(breakFromMoment._d.getTime() < timeFrame.startDate._d.getTime()) breakFromMoment.add(1, 'days');
                    breakToMoment = breakFromMoment.clone().add(timeFrame.shift.historicBreakValue, 'minutes');
                }
                
                let templateFrameWidth = breakToMoment._d.getTime() - breakFromMoment._d.getTime();

                let frameWidth = (templateFrameWidth / timeFrameWidth) * 100;
                styles.width = frameWidth + '%';
                let frameLeft = ((breakFromMoment._d.getTime() - timeFrame.startDate._d.getTime()) / timeFrameWidth) * 100;
                styles.left = frameLeft + '%';

                return styles;
            },
            GetOffsetTomorrow(){
                let styles = {};
                let daytime = this.timeDisplayLengthInMilliseconds ? this.timeDisplayLengthInMilliseconds : (24 * 60 * 60 * 1000);
                let dayWidth = ((24 * 60 * 60 * 1000) / daytime) * 100;
                styles.width = dayWidth + '%';
                styles.left = dayWidth + '%';
                return styles;
            },
            GetOffsetYesterday(){
                let styles = {};
                let daytime = this.timeDisplayLengthInMilliseconds ? this.timeDisplayLengthInMilliseconds : (24 * 60 * 60 * 1000);
                let dayWidth = ((24 * 60 * 60 * 1000) / daytime) * 100;
                styles.width = dayWidth + '%';
                styles.left = (-1 * dayWidth) + '%';
                return styles;
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .label{
        position: relative;
        top: 0px;
        font-size: 10px;
        border-radius: 100px;
        padding: 0 5px;
        width: fit-content;
    }

    .timeframe .label{

        color: black;
    }

    .hour-grid{
        pointer-events: none;
        position: absolute;
        left: 0;
        top: -5px;
        width: 100%;
        height: 90px;
        z-index: -1;
        padding: 30px 10px 0;
    }
    .fixed .hour-grid,.shift .hour-grid{

        display: none;
    }


    .time-marks{
        width: 100%;
        height: 100%;
        display: inline-block;
    }

    .time-marks .label{
        position: absolute;
        top: -20px;
        left: -15px;
        width: 30px;
        text-align: center;
    }

    .time-bar-labels{
        width: calc(100% / 25);
    }
    .time-bars{
        width: calc(100% / 24);
    }

    .time-marks .mark{
        position: relative;
        float: left;
        width: calc(100% / 24);
        height: 100%;
        display: inline-block;
        box-shadow: inset 2px 0px 0 -1px var(--contrast-1);
        background: none;
    }
    .hour-grid.full-half .time-marks .mark{
        width: calc(100% / 36);
    }
    .hour-grid.full .time-marks .mark{
        width: calc(100% / 24);
    }
    .hour-grid.half .time-marks .mark{
        width: calc(100% / 12);
    }
    .hour-grid.quarter .time-marks .mark{
        width: calc(100% / 6);
    }
    .time-marks .mark.am{
    }
    .label.start{
        position: absolute;
        left: 0;
    }

    .label.end{
        position: absolute;
        right: 0;
    }

    .notshift .label{
        color: var(--contrast-4);
    }

    .time-bar-new{
        width: 550px;
        position: relative;
        z-index: 2;
        padding-top: 15px;
    }

    .time-bar-new.fixed{
        padding-top: initial;
        width: 100%;
    }
    .time-bar-new.shift{
        background: white;
        box-shadow: 4px 4px 5px 3px rgba(0,0,0,0.2);
    }

    .background{
        background: none;
        position: relative;
        height: 60px;
        min-width: 300px;
        width: calc(100% - 20px);
        left: 0;
        right: 0;
        bottom: 0;
        margin:auto;
        border-radius: 0;
        overflow: hidden;
        margin: 10px;
    }


    .fixed .background{
        height: 44px;
        top: 3px;
        background: none;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }


    .rota,.work-hours{
        background: #cccccc;
        position: absolute;
        height: 40px;
        bottom: 0;
        border-radius: 10px;
    }

    .timeframe{
        position: absolute;
        height: 100%;
        bottom: 0;
        padding: 0 40px;
    }

    .timeframe.locked,.timeframe.published,.timeframe.historic{
        /*pointer-events: none;*/
    }
    .timeframe.published .handle,.timeframe.historic .handle{
        display: none;
    }

    .timeframe.locked-start .handle.start{
        pointer-events: none;
        opacity: 0;
    }

    .timeframe.break{
        height: 100%;
        z-index: -1;
        background: #e3c423;
        bottom: 0;
    }



    .shift-only .shift{
        background: #cccccc;
    }

    .off-hours{
        background: rgba(0,0,0,.5);
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .off-hours.left{
        right: 100%;
    }

    .off-hours.right{
        left: 100%;
    }

    .overtime{
        background: #b22212;
        position: absolute;
        height: 100%;
        width: 0px;
    }
    .overtime.end{

        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .overtime.start{

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .pause{
        background: #ddbd1d;
        position: absolute;
        height: 110%;
        bottom: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .dragbox{
        height: 100%;
        position: absolute;
        top: 0;
        width: 10px;
        cursor: pointer;
    }

    .dragbox.start{
        left: -5px;
    }
    .dragbox.end{
        right: -5px;
    }

    .fa-map-marker{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        cursor: pointer;
        overflow: hidden;
    }

    .rota .fa-map-marker{
        color: #cccccc;
        top: -17px;
    }
    .shift .fa-map-marker{
        transform: scaleY(-1);
        color: #b2b2b2;
        bottom: -17px;
        z-index: 1;
    }
    .pause .fa-map-marker{
        transform: scaleY(-1);
        color: #ddbd1d;
        bottom: -17px;
        z-index: 1;
    }
    .fa-map-marker.add-pause{
        transform: scaleY(-1);
        color: #ddbd1d;
        bottom: -35px;
        height: 15px;
        top: initial;
        width: 20px;
    }
    .fa-map-marker .fa-plus{
        position: absolute;
        color: white;
        top: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 10px;
        pointer-events: none;
    }

    .start .fa-map-marker{
        width: 50%;
        left: 0;
        right: initial;
    }
    .end .fa-map-marker{
        width: 50%;
        right: 0;
        left: initial;
    }
    .end .fa-map-marker:before{
        margin-left: -7px;
    }

    .pause-box{
        display: none;
        position: absolute;
        bottom: 100%;
        border-radius: 10px;
        background: #ddbd1d;
        text-align: center;
        transform: translateY(-10px);
        left:-50000%;
        right:-50000%;
        margin: auto;
        width: 80px;
    }
    .pause-box .label{
        position: relative;
        color: white;
        padding: 0 5px;
        float: left;
        width: 100%;
        text-align: center;
        left: 0;
        right: 0;
    }
    .pause-box .label.start{
        width: 50%;
    }
    .pause-box .label.end{
        width: 50%;
    }
    .pause:hover .pause-box{
        display: inline-block;
    }

    .label.missing{
        margin: auto;
        top: 0;
        left: 0;
        right: 0;

    }

    .dragbox#rota{
        display: none;
    }

    .info{
        width: 100%;
        position: relative;
        padding: 10px;
        padding-top: 0;
    }

    .entry {
        width: 100%;
        float: left;
        height: 23px;
        display: flex;
    }
    .entry.half {
        width: 50%;
    }
    .entry > .label{
        width: initial;
        height: 20px;
        position: relative;
        margin-right: 5px;
        font-size: 14px;
        margin: 0;
        text-align: right;
    }
    .entry > .data{
        flex: auto;
        height: 20px;
        position: relative;
        font-size: 14px;
        margin-left: 5px;
        box-shadow: 0 2px 0 -1px var(--contrast-1);
        color: var(--contrast-4);
    }

    .fixed .info,
    .fixed .error,
    .fixed .warning{
        display: none;
    }
    .shift .info{
        top: -6px;
    }


    .info .entry:last-child{
        margin-bottom: 10px;
    }

    .employee {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-transition: .2s;
        transition: .2s;
        padding: 10px;
        padding-bottom: 30px;
        padding-top: 6px;
        padding-left: 40px;
        border-radius: 5px;
        height: 45px;
        opacity: 1;
        width: 90%;
        margin-top: 10px;
        margin-bottom: 0px;
        box-shadow: inset 0 0 0 1px #a9a9a9;
    }

    .employee:hover {
        background: rgba(0, 0, 0, .1);
    }

    .employee * {
        cursor: pointer;
    }

    .employee .picture {
        background-size: 100%;
        position: absolute;
        height: 30px;
        width: 30px;
        left: 5px;
        top: 7px;
        border-radius: 3px;
    }

    .employee .namefirst {
        font-size: 10px;
        color: dimgray;
        text-align: left;
        height: 15px;
        white-space: nowrap;
    }

    .employee .namelast {
        font-size: 11px;
        color: dimgray;
        text-align: left;
        height: 15px;
        white-space: nowrap;
    }

    .employee .progressbar {
        position: absolute;
        width: calc(100% - 10px);
        right: 0;
        margin: auto;
        bottom: 5px;
        height: 6px;
        border-radius: 100px;
        background: #c1c1c1;
        left: 0;
        overflow: hidden;
    }

    .employee .progressbar .progressfill {
        position: absolute;
        width: 50%;
        bottom: 00px;
        height: 100%;
        border-radius: 100px;
        background: gray;
        left: 0;
    }

    .fa-stack{
        position: absolute;
        right: -8px;
        top: -8px;
        font-size: 10px;
    }

    .fa-stack .fa-circle {
        color: #808080;
    }

    .fa-stack .fa-times{
        transform: scale(.7) translateX(0px);
    }

    .shift{
        z-index: 7;
        /*border-radius: 5px; */
        background: #b2b2b2;
        cursor: pointer;
        position: absolute;
        height: 25px;
        bottom: 0;
    }

    .actualshift{
        z-index: 8;
        /*border-radius: 5px; */
        background: #b2b2b2;
        position: absolute;
        height: 25px;
        bottom: 0;
    }

    .confirmed {
        background: #eba767 !important;
    }

    .notshift{
        z-index: 5;
        opacity: 0.7;
    }

    .preference{
        z-index: 6 !important;
        height: 25px;
    }

    .shift.published{
        background: #6d8d22;
    }
    .historic,.template{
        z-index: 6;
        background: #e5e5e5;
        /*border-radius: 5px; */
        height: 45px;
    }

    .fixed .historic{
        height: 100%;
    }





    .vacation{
        background: #e5c420;
        /*border-radius: 5px; */
    }
    .sickness{
        background: #e51650;
        /*border-radius: 5px; */
    }
    .holidayadjustment{
        background: #40eded;
        /*border-radius: 5px; */
    }
    .nursingvacation{
        background: #f99a86;
        /*border-radius: 5px; */
    }
    .education{
        background: #348feb;
        /*border-radius: 5px; */
    }
    .wagecontinuation{
        background: #eb7d34;
        /*border-radius: 5px; */
    }
    .onlyavailable{
        /*border-radius: 5px; */
        background: #294285;
    }
    .unavailable{
        /*border-radius: 5px; */
        background: #e51650;
    }
    .undesired{
        background: #ff1655;
        /*border-radius: 5px; */
    }
    .desired{
        background: #aace27;
        /*border-radius: 5px; */
    }

    .error .data{
        box-shadow: 0 3px 0 -1px rgba(125,0,0,.5);
    }
    .warning .data{
        box-shadow: 0 3px 0 -1px rgba(125,0,0,.5);
    }

    .override-contract .desired,.override-contract .undesired,.override-contract .available,.override-contract .unavailable{
        opacity: .5;
    }

    .handle{
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 15px;
        margin: auto;
        cursor: col-resize;
        color: #9f9f9f;
        text-align: center;
        transition: .2s;
        opacity: 0;
    }

    .time-bar-new:hover .handle{
        opacity: 1;
    }
    .locked .handle{
        display:none;
    }

    .handle.start{
        left: -10px;

    }

    .handle.end{
        right: -10px;
    }

    .timeframe.selected{
        box-shadow: 0 0 2px 1px rgba(0,170,0,.9);
    }
    .shift .background.tomorrow,.shift .background.yesterday{
        display: none;
    }

    .background.tomorrow,.background.yesterday{
        background: rgba(0,0,0,.1);
        position: absolute;
        height: 100%;
        top: 0;
        bottom: initial;
        margin: 0;
        display: none;
    }

    .fixed .background.tomorrow, .fixed .background.yesterday{
        display: inline-block;
    }

    .background.shift-dragged{

        position: absolute;
        top: -7px;
    }

    .background.overlap-drag{
        background: rgba(229, 21, 79, 0.50);
    }


    .time-bar-new.component{
        top: 0;
        overflow: hidden;
        width: 280px;
        border-radius: 10px;
        height: 60px;
        padding-top: 10px;
        background: var(--bg);
        position: relative;
        border-radius: 0;
        transition: .2s;
    }

    .time-bar-new.component *{
        pointer-events: none;
    }



    .time-bar-new.component:hover{
        background: var(--contrast-3);
    }

    .time-bar-new.component.published{
        pointer-events: none;
        background: #c1e077;
    }


    .timeframe .label-preference{
        color: white;
        white-space: nowrap;
        position: absolute;
        /* left: 5px; */
        /* right: 5px; */
        margin: auto;
        text-align: center;
        pointer-events: none;
        width: fit-content;
        overflow: hidden;
        top: 0;
        bottom: 0;
        height: fit-content;
    }


    .hide-until-hover{
        opacity: 0;
        transition: .1s;
    }

    .group > .entry:hover .hide-until-hover{
        opacity: .7;
    }

</style>
