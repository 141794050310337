<template>
    <div class="timebar-overlay" v-bind:style="{width: width + 'px', height: height + 'px', top: posy + 'px', left: posx + 'px', display: toggled ? 'inline-block' : 'none'}">
        <div class="background" v-on:click="Toggle(false)"></div>
        <div class="fa-stack" v-on:click="ShiftInfo($event)">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-info fa-stack-2x"></i>
        </div>
        <!--<userselect ref="userselect"/>-->
    </div>
</template>

<script>
    import userselect from './UserSelect.vue'
    import { mapState, mapGetters } from 'vuex';
    export default {
        name: 'timebaruserselect',
        components: {
            userselect,
        },
        data(){
            return{
                selectedItem: {},
                items: [],
                toggled: false,
                posxstart: 0,
                posystart: 0,
                posx: 0,
                posy: 0,
                height: 50,
                width: 50,
                callbackEnd: function(){},
                elRelative: null,
                shift: null,
            }
        },
        created () {
            var view = this;
            requestAnimationFrame(function(){
                $(view.$el).appendTo('body');
            });
            window.addEventListener('resize', this.Reposition);
        },
        destroyed(){
            window.removeEventListener('resize', this.Reposition);
        },
        beforeDestroy(){
            if(this.$refs.userselect && this.$refs.userselect.$el) $(this.$refs.userselect.$el).remove();
        },
        computed: {
            ...mapGetters([
                'employeeMap',
                'planShiftMap',
                'rosterFromTo', 
                'shiftSkillMap',
                'skillMap', 
                'spotMap', 
                'storeId', 
            ]),
        },
        methods: {
            ChangeUser($event){
                var view = this;
                this.$refs.userselect.Init({
                    elRelative: $event.currentTarget,
                    items: Object.values(window.roster.employeeMap),
                    callbackEnd: view.callbackEnd,
                    filterItems:  Object.values(window.roster.spotMap),
                    shift: view.shift,
                    filterMap: function(items, item)
                    {
                        return items.filter(e => (e.skills && e.skills.find(s => s.skillId === item.requiredSkill)) || (e.shifts && e.shifts.find(s => s.spotId && s.spotId === item.id)));
                    },
                    displayMap: function(item){ return item.firstName + ' ' + item.lastName },
                    overlay: view,
                });
            },
            ShiftInfo($event){
                let view = this;
                let from = view.$helpers.getMomentFromStringTZ(view.shift.from).format("DD.MM.YYYY HH:mm");
                let to = view.$helpers.getMomentFromStringTZ(view.shift.to).format("DD.MM.YYYY HH:mm");
                let breakFrom = "";
                if(view.shift.breakFrom){
                    breakFrom = view.$helpers.getMomentFromStringTZ(view.shift.breakFrom).format("DD.MM.YYYY HH:mm");
                }
                let dataEntries = [
                  {label: 'Von', key: 'from', hidden: false, type: 'text', disabled: true, value: from},
                  {label: 'Bis', key: 'to', hidden: false, type: 'text', disabled: true, value: to},
                  {label: 'Position', key: 'spotId', hidden: false, disabled: true, type: 'text', value: view.spotMap[view.shift.spotId].name},
                 

                  {label: 'Pausenzeit', key: 'breakValue', disabled: true, hidden: false, type: 'text', value: view.shift.breakValue+' Minuten'},
                  {label: 'Pause Von', key: 'breakFrom', disabled: true, hidden: view.shift.breakFrom == null, type: 'text', value: breakFrom },
                  
                ];
                if(view.shift.publishDate != null){
                    let publishDate = view.$helpers.getMomentFromStringTZ(view.shift.publishDate).format("DD.MM.YYYY HH:mm");
                    dataEntries.push( {label: 'Veröffentlicht am', key: 'publishDate', disabled: true, hidden: false, type: 'text', value: publishDate })
                }
                dataEntries.push( {label: 'Fixiert', key: 'pinned', disabled: true, hidden: false, type: 'bool', value: view.shift.pinned })
                
                let target = $event.currentTarget;

                this.$helpers.OpenPopup({
                    elRelative: target,
                    type: 'datafill',
                    componentState: {
                        entries: dataEntries,
                        selectCallback: function (shift) {
                            
                        }
                    },
                    header: 'Schicht Info',
                    pointerDirection: 1,
                    expanded: true,
                    centered: true,
                });
            },
            Toggle(val){
                var view = this;
                if(val !== undefined || val !== null) {
                    this.toggled = val;
                    if(!val) window.roster.shiftsSelected = window.roster.shiftsSelected.filter(s => s !== view.shift);
                    return;
                }
                this.toggled = !this.toggled;
                if(!this.toggled) window.roster.shiftsSelected = window.roster.shiftsSelected.filter(s => s !== view.shift);
            },
            Reposition(){
                var view = this;
                requestAnimationFrame(function(){
                    if(view.elRelative && view.elRelative.parentNode) {
                        var box = view.elRelative.getBoundingClientRect();
                        var boxParent = view.elRelative.parentNode.getBoundingClientRect();
                        var posx = box.left - 5;
                        if(posx < boxParent.left) posx = boxParent.left - 5;
                        view.posx = posx;
                        var posy = box.top - 5;
                        if(posy < boxParent.top) posy = boxParent.top - 5;
                        view.posy = posy;
                        view.width = box.width + 10;
                        view.height = box.height + 10;
                    }
                });
            },
            Init(options){
                var view = this;
                if(options.elRelative) {
                    this.elRelative = options.elRelative;
                    this.Reposition();
                }
                if(options.callbackEnd) this.callbackEnd = options.callbackEnd;
                if(options.shift) this.shift = options.shift;
                this.toggled = true;
            }
        },
        mounted() {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .timebar-overlay{
        position:fixed;
        display:inline-block;
        background: none;
        box-shadow: inset 0 0 0 1px #e6154c;
        border-radius: 5px;
        display:none;
        z-index: 1000;
    }

     .background {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        z-index: -1;
        top: 0;
    }

    .fa-stack{
        box-shadow: 0 0 0 1px #e6154c, inset 0 0 0 1px #e6154c;
        border-radius: 50px;
        position: absolute;
        top: -15px;
        height: 32px;
        width: 32px;
        right: 45px;
        background: #d9d9d9;
        transition: .2s;
        cursor: pointer;
    }
    .fa-stack:hover{
        background: #404040;
    }

    .fa-stack .fa-circle{
        opacity: 0;
    }

    .fa-stack .fa-user{
        color: #808080;
        transform: scale(.7);
    }
    .fa-stack .fa-info{
        color: #808080;
        transform: scale(.7);
    }
</style>
