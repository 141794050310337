<template>
  <div class="date-container">
    <template v-if="displayMode === 'header'">
      <div style="position: relative;">
        <dateselectionheader :label="headerLabel"
                             @PrevDate="prevMonth"
                             @NextDate="nextMonth"
                             @SetToday="setToday"
                             @CalendarOpen="toggleDatePicker"
                             style="display: flex; position: static; align-items: baseline;"/>
        <div class="header-picker-container" v-if="showDatePicker">
          <datepicker :state="popupDatepickerState"
                      @Cancelled="closeDatepicker"
                      @OnMonthSelect="onDPMonthSelect"
                      @OnDaySelect="onDPDaySelect"/>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="header-row">
        <h6 class="date-title">Zeitraum</h6>
        <div class="mode-selector-container" v-if="mode === 'both'">
          <buttonc :label="currentMode === 'monthlyRange'? 'indiv. Datumwahl' : 'Montatsauswahl'" @click="changeMode"/>
        </div>
      </div>
      <div class="date-selector">
        <template v-if="currentMode === 'monthlyRange'">
          <buttonc type="no-background" icon="fa-angle-left" :disabled="disabled" @click="prevMonth"/>
          <span class="month-text">{{ monthString }}</span>
          <buttonc type="no-background" icon="fa-angle-right" :disabled="disabled" @click="nextMonth"/>
        </template>
        <template v-else>
          <input class="date-picker"
                 type="date"
                 :value="start.format('YYYY-MM-DD')"
                 :max="end.format('YYYY-MM-DD')"
                 @change="startChanged">
          <span>–</span>
          <input class="date-picker"
                 type="date"
                 :value="end.format('YYYY-MM-DD')"
                 :min="start.format('YYYY-MM-DD')"
                 @change="endChanged">
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'md-date-select',
  props: {
    disabled: Boolean,
    mode: { type: String, default: () => 'both' }, // monthlyRange, customRange, both
    initStart: [ Object, Date ],
    initEnd: [ Object, Date ],
    displayMode: { type: String, default: () => 'standalone' }, // standalone, header
  },
  data() {
    const start = this.initStart ? moment(this.initStart) : moment().startOf('month');
    const end = this.initEnd ? moment(this.initEnd) : moment().subtract(1, 'd').endOf('month');
    return {
      currentMode: this.mode === 'both' ? 'monthlyRange' : this.mode,
      start,
      end,
      monthString: start.format('MMMM YYYY'),
      showDatePicker: false,
      datePickerStartDate: null,
    };
  },
  computed: {
    headerLabel() {
      if (this.currentMode === 'monthlyRange') {
        return this.monthString;
      }
      return `${ this.start.format('DD.MM.YYYY') } – ${ this.end.format('DD.MM.YYYY') }`;
    },
    popupDatepickerState() {
      return {
        viewsEnabled: this.datePickerStartDate
            ? [ 'D' ]
            : this.mode === 'both'
                ? [ 'M', 'D' ]
                : this.mode === 'monthlyRange'
                    ? [ 'M' ]
                    : [ 'D' ],
        selectedTab: (this.currentMode === 'monthlyRange' && !this.datePickerStartDate) ? 'M' : 'D',
        date: this.start,
        fromToMode: this.datePickerStartDate ? 2 : null,
      };
    },
  },
  methods: {
    hasListener(eventName) {
      return !!this.$listeners[eventName];
    },
    prevMonth() {
      this.start = this.start.startOf('month').subtract(1, 'M').startOf('month');
      this.end = moment(this.start).endOf('month');
      this.dateChanged();
    },
    nextMonth() {
      this.start = this.start.startOf('month').add(1, 'M').startOf('month');
      this.end = moment(this.start).endOf('month');
      this.dateChanged();
    },
    setToday() {
      this.currentMode = 'monthlyRange';
      this.start = this.$helpers.getCurrentMonthMomentTZ();
      this.end = moment(this.start).endOf('month');
      this.dateChanged();
    },

    startChanged(event) {
      this.start = moment(event.target.value);
      this.dateChanged();
    },
    endChanged(event) {
      this.end = moment(event.target.value);
      this.dateChanged();
    },
    dateChanged() {
      const range = { from: this.start.toDate(), to: this.end.toDate(), mode: this.currentMode };
      this.monthString = this.start.format('MMMM YYYY');
      this.$emit('change', range);
      this.$emit('dateChanged', range);
    },
    changeMode() {
      if (this.currentMode === 'customRange') {
        this.currentMode = 'monthlyRange';
        this.start = this.start.startOf('month');
        this.end = moment(this.start).endOf('month');
      } else {
        this.currentMode = 'customRange';
      }
      this.$emit('modeChanged', this.currentMode);
      this.dateChanged();
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    closeDatepicker() {
      this.showDatePicker = false;
      this.datePickerStartDate = null;
    },
    onDPMonthSelect(date) {
      this.currentMode = 'monthlyRange';
      this.start = moment(date);
      this.end = moment(this.start).endOf('month');
      this.closeDatepicker();
      this.dateChanged();
    },
    onDPDaySelect(date) {
      if (!this.datePickerStartDate) {
        this.datePickerStartDate = moment(date); // sets a temporary start date which also changes the mode to customRange
      } else {
        this.currentMode = 'customRange';
        this.start = this.datePickerStartDate;
        this.end = moment(date);
        if (this.end.isBefore(this.start)) {
          this.end = moment(this.start);
          this.start = moment(date);
        }
        this.closeDatepicker();
        this.dateChanged();
      }
    },
  },
}
</script>

<style scoped>
.header-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.date-title {
  color: var(--contrast-4);
  font-weight: bold;
  margin: auto;
}

.mode-selector-container {
  width: 0;
  overflow-x: visible;
}

.mode-selector-container > .button {
  width: max-content;
  margin: 0;
  transform: translateX(-100%);
}

.date-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2rem;
}

.date-selector > .month-text {
  width: 10rem;
  font-weight: bold;
}

.date-picker {
  border: 1px solid var(--contrast-1);
  border-radius: 0;
  background: none;
  color: var(--contrast-4);
  margin: 0 1rem;
}

.date-picker::after {
  color: var(--contrast-4);
}

.header-picker-container {
  position: absolute;
  top: calc(100%);
  left: 50%;
  background-color: var(--contrast-1);
  padding: 2rem 1.5rem 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
}
</style>
