/* eslint-disable */
<template>

<div class="login-panel">
  <dropdowncontainer @hook:mounted="AttachContents" ref="container" :state="state"/>
	        <div class="loginwidth">
	            <img id="profile-img" class="profile-img-card" src="../assets/serviceimg/logo.png" />
	            <p id="profile-name" class="profile-name-card"></p>
	            <div class="form-signin">
                <form>
	              <span id="reauth-email" class="reauth-email"></span>
                <div class="entry">
                  <input type="email" autocomplete="off" placeholder="Email" v-model="email"/>
                  <labelc type="input-error" v-if="errors['email']" :text="errors['email'].text"/>
                  <buttonc type="input-error" icon="fa-exclamation-circle" v-bind:class="[errors['email'].type]" v-if="errors['email']"  v-tooltip="errors['email'].tooltip"/>
                </div>
                <div class="entry">
                  <input type="password" placeholder="Passwort" v-model="password" autocomplete="new-password"/>
                  <labelc type="input-error" v-if="errors['password']" :text="errors['password'].text"/>
                  <buttonc type="input-error" icon="fa-exclamation-circle" v-bind:class="[errors['password'].type]" v-if="errors['password']"  v-tooltip="errors['password'].tooltip"/>
                </div>
                <div class="entry">
                  <input type="password" placeholder="Passwort bestätigen" v-model="passwordconfirm" autocomplete="new-password"/>
                  <labelc type="input-error" v-if="errors['passwordconfirm']" :text="errors['passwordconfirm'].text"/>
                  <buttonc type="input-error" icon="fa-exclamation-circle" v-bind:class="[errors['passwordconfirm'].type]" v-if="errors['passwordconfirm']"  v-tooltip="errors['passwordconfirm'].tooltip"/>
                </div>
                </form>
                <div class="footer no-divider">

                  <div class="align-right" style="flex: initial">
                    <buttonc v-if="!loading" type="rectangle green footer" label="Passwort neu setzen"  @click="submitPasswordReset" style="width: 180px;"/>
                    <buttonc v-if="loading" type="rectangle green footer" icon="fa-spinner" style="width: 180px;"/>
                  </div>
                </div>
	            </div><!-- /form -->
	        </div><!-- /card-container -->
    	</div><!-- /container -->

</template>

<script>
import axios from 'axios'
import dropdowncontainer from "../components/uicomponents/dropdowncontainer";
import buttonc from "../components/uicomponents/button";
export default {
  name: 'PwReset',
    components: {
        dropdowncontainer,
        buttonc,
    },
  data () {
    return {
		email:"",
		password:"",
		passwordconfirm:"",
		token:"",
        state:{
            show: true,
            toggled: true,
            centered: true,
            locked: true,
            expanded: true,
        },
        errors: {},
        loading: false,
    }
  },
  created: function () {
        this.email = this.$route.params.email;
        this.token = this.$route.params.token;
        this.password = "";
        this.passwordconfirm ="";
    },
beforeDestroy(){
    this.$refs.container.$destroy();
},
	// methods that implement data logic.
	methods : {
      AttachContents(){
          let view = this;
          requestAnimationFrame(function(){
              view.$refs.container.$el.querySelector('.tab-body').appendChild(view.$el);
              view.$refs.container.toggled = true;
              view.$refs.container.show = true;
          });
      },
		isBlank: function (str) {
		    return (!str || /^\s*$/.test(str));
		},
		getQueryStringValue: function (key) {
			  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
		},
		submitPasswordReset : function() {

        let view = this;
        this.errors = {};

			if(!this.isBlank(this.password) && !this.isBlank(this.passwordconfirm) && this.password === this.passwordconfirm && this.password.length >=8){
				let payload = {
						command: "resetPassword",
						email: this.email,
						token: this.token,
						password: this.password
                }
                let view = this;
                axios({
                    method: 'put',
                    url: '/api/user',
                    data: payload
                }).then(response => {
					view.toggleModal('Erfolg', 'Ihr Passwort wurde erfolgreich gesetzt. Sie können sich nun einloggen.', '');
                    window.setTimeout(view.returnToIndex, 3000);
                }).catch(function (error) {
                    if(error.response != undefined && error.response.data.message != undefined){
                        view.toggleModal('Fehler', 'Ihr Passwort konnte nicht erneuert werden. Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator', '');
                    }
                });
			}else {
          if(this.isBlank(this.password) || this.password.length <8){
              view.errors.password = {type: 'error', key: 'password', text: 'Passwort Fehler', tooltip: 'Bitte stellen Sie sicher dass ihr Passwort mindestens 8 zeichen lang ist'};
          } else {
              view.errors.password = {type: 'error', key: 'password', text: 'Passwort Fehler', tooltip: 'Das Bestätigungs Feld nicht mit dem Passwort Feld übereinstimmt'};
              view.errors.passwordconfirm = {type: 'error', key: 'passwordconfirm', text: 'Passwort Fehler', tooltip: 'Das Bestätigungs Feld nicht mit dem Passwort Feld übereinstimmt'};
          }
      }



		},
		returnToIndex: function(){
			this.$router.replace('/');
		},
      toggleModal(title, text, text2) {
          this.$helpers.OpenInfoPopup(title, [text, text2]);
      },
    },
	computed: {
		modalStyle() {
		    return this.showModal ?
		    { 'padding-left': '0px;', display: 'block' } : {};
		}
	},
	watch: {
		'$route' (to, from) {
			this.email = to.params.email;
        	this.token = to.params.token;
		}
  	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login-panel {
    width: 600px !important;
  }

  .footer .align-left > *{
    color: var(--contrast-6);
    font-size: 14px;
  }

  .entry .label{
    width: 100px;
  }
</style>
