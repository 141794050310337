<template>
  <div>
    <div style="text-align:left; font-weight:bold; font-size: 20px;">
      Report Liste
    </div>

    <div>
      <b-table class="report-table"
               striped
               sticky-header
               hover
               :items="reportItems"
               :fields="reportFields"
               @row-clicked="onReportClick"
               ref="reportTable">
        <template v-slot:cell(actions)="row">
          <!--
          <b-button :disabled="!row.item.configSettings"
                    class="mx-1"
                    size="sm"
                    variant="outline-dark"
                    @click="showConfigOptions(row.item)">
            <i class="fas fa-wrench"></i>
          </b-button>
          -->
          <b-button v-if="showOpenButton"
                    class="mx-1"
                    size="sm"
                    :disabled="!row.item.url"
                    v-on:click="openReport(row.item)">
            <i class="fas fa-eye"></i>
          </b-button>
          <b-button v-if="hasOnDownloadListener"
                    class="mx-1"
                    size="sm"
                    variant="success"
                    :hidden="!row.item.url"
                    v-on:click="onReportDownload(row.item)">
            <i class="fas fa-download"></i>
          </b-button>
          <b-button v-if="hasOnDeleteListener"
                    class="mx-1"
                    size="sm"
                    variant="danger"
                    v-on:click="onDeleteReport(row.item)">
            <i class="fas fa-trash"></i>
          </b-button>
        </template>
      </b-table>
    </div>

    <div v-if="loading" style="width: 100%;" class="text-center">
      <i class="fas fa-spinner"></i> Reports werden geladen
    </div>

    <div v-else-if="reportItems.length === 0">
      Es wurden noch keine Reports erstellt
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ml-report-list',
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    showOpenButton: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    reportItems() {
      return this.reports || [];
    },
    reportFields() {
      return [
        {
          key: 'name',
          label: 'Dokumentname',
          sortable: true,
        },
        {
          key: 'format',
          label: 'Dateiformat',
          sortable: true,
        },
        {
          key: 'creationDate',
          label: 'Erstellungsdatum',
          sortable: true,
        },
        {
          key: 'comment',
          label: 'Kommentar',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ];
    },
    hasOnClickListener() {
      return !!this.$listeners?.reportClicked;
    },
    hasOnDownloadListener() {
      return !!this.$listeners?.downloadReport;
    },
    hasOnDeleteListener() {
      return !!this.$listeners?.deleteReport;
    },
  },
  mounted() {
    // this.selectReports();
  },
  watch: {
    /*reports: function (newReports: ReportData[]) {
      this.selectReports(newReports);
    },*/
  },
  methods: {
    onReportClick(report) {
      if (this.hasOnClickListener)
        this.$emit('reportClicked', report);
      else if (report.url)
        this.openReport(report);
    },
    onReportDownload(report) {
      this.$emit('downloadReport', report);
    },
    onDeleteReport(report) {
      this.$emit('deleteReport', report);
    },

    /*selectReports(reports: ReportData[] = undefined) {
      if (reports === undefined)
        reports = this.reports;
      reports.forEach((report, i) => {
        if (report.selected)
          this.$refs.reportTable.selectRow(i);
        else
          this.$refs.reportTable.unselectRow(i);
      });
    },*/

    openReport(report) {
      window.open(report.url, 'blank')
    },
    /*showConfigOptions(report: ReportData) {
      console.log(report.configSettings);
    },*/
  },
});
</script>

<style scoped>
.fas.fa-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(315deg);
  }
}
</style>
