<template>
  <div class="tab-menu">
    <div class="header">
      <menutabs
        :state="{ tabs: tabs }"
        :tab-selected="tabSelected"
        @tab-select="UpdateSelectedView"
      ></menutabs>
    </div>
    <div class="component-container">
      <component ref="component" :is="tabSelected.view" />
    </div>
  </div>
</template>

<script>
import documenttemplates from "./DocumentTemplates.vue";
import employeedocuments from "./EmployeeDocuments.vue";

export default {
  components: {
    documenttemplates,
    employeedocuments,
  },
  name: "documentsmain",
  data() {
    return {
      viewMode: 1,
      loading: true,
      loadingText: "",
      tabs: [
        { label: "Personalakt", view: "employeedocuments", selected: true },
        { label: "Vorlagen", view: "documenttemplates", selected: false },
      ],
      tabSelected: null,
    };
  },
  props: {
    view: String,
  },
  created() {
    let tab = this.tabs.find((t) => t.selected);
    if (tab) this.tabSelected = tab;
    else this.tabSelected = { view: this.view ? this.view : "employeedocuments" };
  },
  mounted() {},
  updated() {},
  destroyed() {},
  methods: {
    UpdateSelectedView(tab) {
      this.tabs.forEach((t) => (t.selected = false));
      tab.selected = true;
      this.tabSelected = tab;
    },
  },
};
</script>
