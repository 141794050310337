<template>
    <div class="about-container">
        <div class="mdicon" ></div>
        <div class="entry divider">
            <div class="line-through left"></div>
                <labelc v-if="mdrelease" type="no-break" :text="'ML Release ' + mdrelease + ' (Build ' + mdversion + ')'"/>
            <div class="line-through right"></div>
        </div>
        <div class="entry divider">
            <labelc type="no-break" :text="'Impressum:'"/>
        </div>
        <div class="entry divider">
            <labelc type="no-break" :text="'MD MeinDienstplan GmbH'"/>
        </div>
        <div class="entry divider">
            <labelc type="no-break" :text="'Gschmeidlerstraße 45'"/>
        </div>
        <div class="entry divider">
            <labelc type="no-break" :text="'2020 Hollabrunn'"/>
        </div>
        <div class="entry divider">
            <labelc type="no-break" :text="'FN 483121y, DVR 4019122'"/>
        </div>
        <div class="entry divider">
            <labelc type="no-break" :text="'Firmenbuchgericht: LG Korneuburg'"/>
        </div>
        <div class="entry divider">
            <labelc type="no-break" :text="'UID: ATU72868916'"/>
        </div>

        <div class="entry divider" style="margin-top: 10px">
            <div class="line-through"></div>
        </div>
        <div class="entry divider" style="margin-top: 10px">

        </div>
        <div class="data-container">
            <buttonc type="no-border" label="Changelog" @click="openChangelog()"/>
            <buttonc type="no-border" label="Hilfecenter" @click="openHelpcenter()"/>
            <buttonc type="no-border" label="AGB" @click="openAGB()"/>
            <buttonc type="no-border" label="Datenschutz" @click="openPrivacy()"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aboutpopup",
        components: {
        },
        data(){
            return {
                mdrelease: null,
                mdversion: null,
                view: this,
            }
        },
        computed: {
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        props:{
            state: Object,
        },
        methods:{
            openPrivacy(){
                window.open("http://www.meindienstplan.at/at/datenschutz", "_blank");
            },
            openAGB(){
                window.open("http://www.meindienstplan.at/at/agb", "_blank");
            },
            openChangelog(){
                window.open("https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/article/2113339393", "_blank");
            },
            openHelpcenter(){
                window.open("http://hilfecenter.meindienstplan.at", "_blank");
            }
        },
    }
</script>

<style scoped>
    .about-container{
        width: 500px;
        text-align: center;
    }

    .mdicon{
        display: inline-block;
        height: 50px;
        width: 50px;
        background: url('/img/ml-logo.png');
        background-size: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }

    .divider .line-through{
        width: 100%;
        height: 1px;
        background: #c1c1c1;
        position: relative;
        display: flex;
        z-index: 999;
        align-self: center;
    }

    .data-container{
        display: flex;
        justify-content: space-evenly;
    }

    .data-container > *{
        display: inline-flex;
    }
</style>
