<template>
<div class="tab-menu">
    <div class="header">
        <menutabs :state="{tabs: tabs}" :tab-selected="tabSelected" @tab-select="UpdateSelectedView"></menutabs>
    </div>
    <div class="component-container">
        <component ref="component" :is="tabSelected.view"/>
    </div>
</div>
</template>

<script>
import companysettings from '../companysettingcontext/CompanySettings.vue'
import userroles from '../companysettingcontext/UserRoles.vue'
import financialaccountinggroups from '../companysettingcontext/FinancialAccountingGroups.vue'


export default {
    components: {
        companysettings,
        userroles,
        financialaccountinggroups,
    },
    name: 'CompanySettingsMain',
    data() {
        return {
            viewMode: 1,
            loading: true,
            loadingText: '',
            tabs:[
                {label: 'Einstellungen', view: 'companysettings', selected: true},
                {label: 'Benutzerrollen', view: 'userroles', selected: false},
                {label: 'FIBU Kreise', view: 'financialaccountinggroups', selected: false},
            ],
            tabSelected: null,
        }
    },
    props: {
        view: String,
    },
    created() {
        let tab = this.tabs.find(t => t.selected);
        if(tab) this.tabSelected = tab;
        else this.tabSelected = {view: this.view ? this.view : 'companysettings'};
    },
    mounted(){

    },
    updated() {},
    destroyed() {},
    methods: {
        UpdateSelectedView(tab){
            this.tabs.forEach(t => t.selected = false);
            tab.selected = true;
            this.tabSelected = tab;
        }
    }
}
</script>
