<template>
<div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
        <div class="align-left">
            <dateselectionheader :label="dateDisplay" @PrevDate="modDate('sub')" @NextDate="modDate('add')" />
        </div>
        <div class="align-middle">
            <headerbuttonstack :state="headerButtons" />
        </div>
        <div class="align-right">
            <buttonc v-if="storeData != null" type="square" icon="fa-info" v-tooltip="'Gewählte Software anzeigen'" @click="showChosenSoftwarePopup"/>
        </div>
    </div>
    <!--
    <div class="header main">
      <dateselectionheader :label="date.format('MMMM')"></dateselectionheader>
    </div>
      <div class="employee" v-for="employee in employees" :key="employee.id">
        <employeeentry :state="employee" />
      </div>

      -->

    

    <div v-if="selectedStore != null" class="scrollbar-container">
        <div class="full split-bottom">
            <div class="header">
                <div class="label">Betrieb auswählen</div>
            </div>
            <div style="float: left !important; margin: 4px 0px 4px -5px;">
                <buttonc :disabled="loading" v-for="store in storeArray" v-bind:key="store.id" :label="store.name" :active="selectedStore != null && selectedStore == store.id" @click="selectedStore = store.id"/>
            </div>

        </div>
        <br /><br />
        <div v-if="loading" class="loading-md bg">
            <i class="fas fa-spinner"></i>
            <br/>
            <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">
                {{ loadingText }}
            </div>
        </div>
        <div v-else>
          <div class="full" style="margin-top: -16px; margin-bottom: 16px;">
            <div class="header">
              <div v-if="storeData != null && softwareId == 0" class="label">
                Bitte stattdessen den "Tages Export" für die Abwesenheiten verwenden.
              </div>
            </div>
          </div>


          <div v-if="storeData != null && softwareId == 1">

            <table class="table table-striped table-borderless">
                <tr>
                    <th class="documentsTh" scope="col">ID</th>
                    <th class="documentsTh" scope="col" v-if="softwareId == 0">Store BMD ID</th>
                    <th class="documentsTh" scope="col" v-if="softwareId == 1">ID Kostenstelle</th>
                    <th class="documentsTh" style="width: 200px;" scope="col" v-if="softwareId == 0">Mitarbeiter BMD ID</th>
                    <th class="documentsTh" style="width: 200px;" scope="col" v-if="softwareId == 1">Mitarbeiter DPW ID</th>
                    <th class="documentsTh" scope="col">Name</th>
                    <th class="documentsTh" scope="col">SV-Nummer</th>
                    <th class="documentsTh" scope="col">Art</th>
                    <th class="documentsTh" scope="col" v-if="softwareId == 0">Art BMD Nummer</th>
                    <th class="documentsTh" scope="col">Von</th>
                    <th class="documentsTh" scope="col">Bis</th>
                    <th class="documentsTh" scope="col">Tage</th>
                </tr>
                <tbody>
                    <tr v-for="absence in exportableTableAbsences" v-bind:key="absence.id">
                        <td class="documentsTd" scope="col">{{ absence.id }}</td>
                        <td class="documentsTd" scope="col" v-if="softwareId == 0">
                            <div v-if="absence.storeExternalId == null">BMD ID fehlt<div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                            </div>
                            <div v-if="absence.storeExternalId != null">{{ absence.storeExternalId }}</div>
                        </td>
                        <td class="documentsTd" scope="col" v-if="softwareId == 1">
                            <div v-if="absence.storeExternalId == null">DPW ID fehlt<div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                            </div>
                            <div v-if="absence.storeExternalId != null">{{ absence.storeExternalId }}</div>
                        </td>

                        <td class="documentsTd" scope="col" v-if="softwareId == 0">
                            <div v-if="!absence.editing">
                                <div v-if="absence.bmdId == null">
                                    <div class="button small right mright8" v-on:click="toggleEmployeeBmd(absence)">
                                        <div class="label">BMD ID eingeben</div>
                                    </div>
                                </div>
                                <div v-if="absence.bmdId != null">{{ absence.bmdId }}
                                    <div class="button small right mright8" v-on:click="toggleEmployeeBmd(absence)">
                                        <div class="label">BMD ID bearbeiten</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="absence.editing">
                                <input style="width: 50%;" v-model="absence.bmdId" />
                                <div class="button small right mright8" v-on:click="saveEmployeeBmd(absence)">
                                    <div class="label">Speichern</div>
                                </div>
                            </div>
                        </td>
                        <td class="documentsTd" scope="col" v-if="softwareId == 1">
                            <div v-if="!absence.editing">
                                <div v-if="absence.dpwId != null">{{ absence.dpwId }}
                                </div>
                            </div>
                        </td>

                        <td class="documentsTd" scope="col">{{ absence.lastName }} {{ absence.firstName }}</td>
                        <td class="documentsTd" scope="col">{{ absence.socialsecurity }}</td>
                        <td class="documentsTd" scope="col">{{ typeMap[absence.absence.mdType] }}</td>
                        <td class="documentsTd" scope="col" v-if="softwareId == 0">{{ typeMapBmd[absence.absence.mdType] }}</td>
                        <td class="documentsTd" scope="col">{{ absence.absence.fromMoment.format('DD.MM.') }}</td>
                        <td class="documentsTd" scope="col">{{ absence.absence.toMoment.format('DD.MM.') }}</td>
                        <td class="documentsTd" scope="col">{{ absence.absence.totalDays }}</td>
                    </tr>
                </tbody>
            </table>

            <hr />
            <br />
            <strong>Die nachfolgenden Werte werden nicht exportiert</strong>
            <br />
            <br />

            <table class="table table-striped table-borderless">
                <tr>
                    <th class="documentsTh" scope="col">ID</th>
                    <th class="documentsTh" scope="col" v-if="softwareId == 0">Store BMD ID</th>
                    <th class="documentsTh" scope="col" v-if="softwareId == 1">ID Kostenstelle</th>
                    <th class="documentsTh" scope="col" v-if="softwareId == 0">Mitarbeiter BMD ID</th>
                    <th class="documentsTh" scope="col" v-if="softwareId == 1">Mitarbeiter DPW ID</th>
                    <th class="documentsTh" scope="col">Name</th>
                    <th class="documentsTh" scope="col">SV-Nummer</th>
                    <th class="documentsTh" scope="col">Art</th>
                    <th class="documentsTh" scope="col">Von</th>
                    <th class="documentsTh" scope="col">Bis</th>
                    <th class="documentsTh" scope="col">Tage</th>
                    <th class="documentsTh" scope="col">Status</th>
                </tr>
                <tbody>
                    <tr v-for="absence in hiddenTableAbsences">
                        <td class="documentsTd" scope="col">{{ absence.id }}</td>
                        <td class="documentsTd" scope="col" v-if="softwareId == 0">
                            <div v-if="absence.storeExternalId == null">BMD ID fehlt<div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                            </div>
                            <div v-if="absence.storeExternalId != null">{{ absence.storeExternalId }}</div>
                        </td>
                        <td class="documentsTd" scope="col" v-if="softwareId == 1">
                            <div v-if="absence.storeExternalId == null">DPW ID fehlt<div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                            </div>
                            <div v-if="absence.storeExternalId != null">{{ absence.storeExternalId }}</div>
                        </td>

                        <td class="documentsTd" scope="col" v-if="softwareId == 0">
                            <div v-if="absence.bmdId == null">BMD ID fehlt</div>
                            <div v-if="absence.bmdId != null">{{ absence.bmdId }}</div>
                        </td>
                        <td class="documentsTd" scope="col" v-if="softwareId == 1">
                            <div v-if="absence.dpwId == null">DPW ID fehlt</div>
                            <div v-if="absence.dpwId != null">{{ absence.dpwId }}</div>
                        </td>

                        <td class="documentsTd" scope="col">{{ absence.lastName }} {{ absence.firstName }}</td>
                        <td class="documentsTd" scope="col">{{ absence.socialsecurity }}</td>
                        <td class="documentsTd" scope="col">{{ typeMap[absence.absence.mdType] }}</td>
                        <td class="documentsTd" scope="col"></td>
                        <td class="documentsTd" scope="col"></td>
                        <td class="documentsTd" scope="col">{{ absence.absence.totalDays }}</td>
                        <td class="documentsTd" scope="col">
                            <span v-if="absence.absence.mdApproved"></span>
                            <span v-if="!absence.absence.mdApproved">nicht genehmigt</span>
                            <span v-if="absence.absence.softDelete">gelöscht</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>

</div>
</template>

<script>
// @ is an alias to /src
import {
    mapState,
    mapGetters
} from 'vuex';
export default {
    name: 'AbsenceExport',
    components: {},
    data() {
        return {
            selectedStore: null,
            stores: [],




            loading: true,
            loadingText: 'Daten werden geladen...',
            selectedDate: this.$helpers.getMomentTZ().clone(),

            companyData: null,
            storeData: null,
            isBalanceConfirmationRequired: true,
            storeExternalId: '',

            timeframeStart: this.$helpers.getMomentTZ().clone().startOf('month'),
            timeframeEnd: this.$helpers.getMomentTZ().clone().endOf('month'),

            absenceExports: [],
            absences: [],

            absenceEmployeeMap: {},

            exportableTableAbsences: [],
            hiddenTableAbsences: [],
            softwareId: 0,

            stateMap: {
                1: 'Nicht verfügbar',
                2: 'Nur Verfügbar',
                3: 'Wunsch',
                4: 'Abneigung'
            },
            typeMap: {
                1: 'Urlaub',
                2: 'Krankenstand',
                3: 'FTA',
                4: 'Nicht verfügbar / Sonstiges',
                5: 'Kein Vertrag',
                6: 'Pflegeurlaub',
                7: 'Ausbildung',
                8: 'LFZ'
            },
            typeMapBmd: {
                1: '1',
                2: '2',
                6: '5'
            },

            monthNames: {
                0: 'Jänner',
                1: 'Februar',
                2: 'März',
                3: 'April',
                4: 'Mai',
                5: 'Juni',
                6: 'Juli',
                7: 'August',
                8: 'September',
                9: 'Oktober',
                10: 'November',
                11: 'Dezember',
            },

        }
    },
    mounted() {
        let view = this;
        view.loading = true;
        this.refresh(true);
    },
    watch: {
        companyId: function (val, oldVal) {
            this.selectedStore = null;
            this.stores = [];
            this.refresh(true);
        },
        selectedStore: function (val, oldVal) {
            if(val != null)
            this.refresh(true);
        },
    },
    computed: {
        ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings', 'employeeMap', 'storeArray', 'selectedStores']),
        dateDisplay: function () {
            let view = this;
            if (view.storeData != null) {
                if (view.softwareId == 0) {
                    return view.monthNames[view.timeframeStart.months()] + " " + view.timeframeStart.format('YYYY');
                } else if (view.softwareId == 1) {
                    return view.timeframeStart.format("DD") + '. - ' + view.timeframeEnd.format("DD") + '. ' + view.monthNames[view.timeframeStart.months()] + " " + view.timeframeStart.format('YYYY');
                }
            }
            return "-";
        },
        headerButtons() {
            let view = this;

            let state = {
                buttons: []
            };
            state.buttons.push({
                type: "block",
                icon: "fa-refresh",
                click: () => {
                    view.refresh();
                },
                tooltip: 'Refresh'
            });
            if (view.storeData != null && (view.softwareId == 0)) {
                state.buttons.push({
                    type: "block",
                    icon: "fa-file-download",
                    click: () => {
                        view.export();
                    },
                    tooltip: 'Datei herunterladen'
                });
            }
            if (view.storeData != null && view.softwareId == 1) {
                state.buttons.push({
                    type: "block",
                    icon: "fa-server",
                    click: () => {
                        view.saveOnSFTP();
                    },
                    tooltip: 'Datei am Server ablegen'
                });
            }
            return state;
        },
    },
    methods: {
        reset: function () {
            let view = this;
            view.companyData = null;
            view.absences = [];
            view.exportableTableAbsences = [];
            view.hiddenTableAbsences = [];
        },

        showChosenSoftwarePopup($event){
            let info = 'Für den Betrieb werden Daten verarbeitet für: ' + ((this.storeData != null && this.softwareId == 0)? 'BMD' : (this.storeData != null && this.softwareId == 1)? 'DPW' : 'nicht ausgewählt!');
            let dataEntries = [];
            dataEntries.push({key: 'info', hidden: false, type: 'output', value: info});
            this.$helpers.OpenPopup({
                elRelative: $event.currentTarget,
                type: 'datafill',
                componentState: {
                    entries: dataEntries,
                    selectCallback: function (){
                        console.log('Bye bye...');
                    },
                    positiveButtonLabel: 'Schließen'
                },
                header: 'Gewählte Software',
                remainAfterSelect: false,
                expanded: true,
                locked: false
            });
        },

        refresh: function (changeTimeframe = false, force = false) {
            let view = this;
            view.reset();


            view.$helpers.GetCompanySettings(force).then(response => {
                view.$helpers.success('Betriebseinstellungen', 'Betriebseinstellungen wurden geladen');
                view.updateCompanySettings(response);
                view.$helpers.GetStoresArray().then((stores) => {
                    view.$helpers.success('Standorte', 'Standorte wurden geladen');
                    view.storesData = stores;
                    console.log(stores);
                    if(view.selectedStore == null){
                        if(stores.length > 0){
                            view.selectedStore = stores[0].id;
                            console.log('Auto selected first store:');
                            console.log(view.selectedStore);
                            view.$forceUpdate();
                        }
                    }
                    view.loading = true;
                    if (view.storesData.length > 0) {
                        view.storesData = view.storeArray;
                        let selectedStoreIndex = 0;
                        for (let i = 0; i < view.storesData.length; i++) {
                            if(view.storesData[i].id == view.selectedStore){
                                selectedStoreIndex = i;
                            }
                        }

                        view.storeData = view.storesData[selectedStoreIndex];
                        //view.isBalanceConfirmationRequired = (view.storeData == null || view.storeData.isBalanceConfirmationRequired == null || view.storeData.isBalanceConfirmationRequired == undefined || view.storeData.isBalanceConfirmationRequired);
                        if(view.companyData != null){
                            if(view.companyData.individualStoreIds == 1){
                                if (view.storeData != null) {
                                    if (view.softwareId == 0) {
                                        view.storeExternalId = view.storeData.bmdId;
                                    } else if (view.softwareId == 1) {
                                        view.storeExternalId = view.storeData.dpwId;
                                    }
                                }
                            }else{
                                if (view.softwareId == 0) {
                                    view.storeExternalId = view.companyData.bmdId;
                                } else if (view.softwareId == 1) {
                                    view.storeExternalId = view.companyData.dpwId;
                                }
                            }
                                if (changeTimeframe) {
                                    if (view.companyData != null && view.softwareId == 0) {
                                        //BMD PFAD MONATLICH
                                        view.selectedDate.subtract(1, 'months');
                                        view.timeframeStart = view.selectedDate.clone().startOf('month');
                                        view.timeframeEnd = view.selectedDate.clone().endOf('month');
                                        view.$forceUpdate();
                                    } else if (view.companyData != null && view.softwareId == 1) {
                                        //DPW PFAD ZWEI WÖCHENTLICH
                                        if (view.selectedDate.date() > 16) {
                                            view.timeframeStart = view.selectedDate.clone().startOf('month');
                                            view.timeframeEnd = view.timeframeStart.clone().add('days', 14);
                                            view.$forceUpdate();
                                        } else {
                                        this.selectedDate.startOf('month');
                                        view.timeframeStart = view.selectedDate.clone().subtract(1, 'months').startOf('month').add('days',15);
                                        view.timeframeEnd = view.timeframeStart.clone().endOf('month');
                                        view.$forceUpdate();
                                        }
                                    }
                                }
                        }

                        view.createAbsenceExport();
                    }
                })
            }).catch(error => {
                console.log("REFRESH ERROR: "+error)
            });
        },
        updateCompanySettings: function(response){
            let view = this;      
            if(response.data.length == 1){
                view.companyData = response.data[0];
                view.softwareId = view.companyData.softwareId;
            }
        },

        loadEmployees: function () {
            let view = this;
            view.$helpers.GetEmployeeMapOLD(true,false,view.selectedStore)
                .then(response => {
                    view.$helpers.success('Mitarbeiter', 'Mitarbeiter wurden geladen');
                    let map = {};

                    Object.values(view.employeeMap).forEach(e => {
                        map[e.id] = {
                            mdId: e.mdId,
                            absences: []
                        };
                    });
                    view.absenceEmployeeMap = map;

                    view.loadAbsences();

                }).catch(function (error) {
                    console.log(error.response);
                });
        },

        /*
              loadWorktimeBalance: function(){
                let view = this;
                let url = '/api/workbalance';

                url = this.$helpers.appendParam("store_id", this.$store.state.storeId, url, '/api/workbalance');
                //url = this.$helpers.appendParam("storeId", this.$store.state.storeId, url, '/api/workbalance');

                this.axios({
                  method: 'get',
                  headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                  },
                  url: url,
                }).then(response => {
                }).catch(function (error) {
                  view.saves = false;
                })
              },*/
        loadAbsences: function () {
            console.log("LOAD ABSENCES");
            let view = this;
            let url = '/api/absence';

            url = this.$helpers.appendParam("storeId", view.selectedStore, url, '/api/absence');
            //url = this.$helpers.appendParam("storeId", this.$store.state.storeId, url, '/api/workbalance');
            url = this.$helpers.appendParam("from", view.timeframeStart.format('DD.MM.YYYY'), url, '/api/absence');
            url = this.$helpers.appendParam("to", view.timeframeEnd.format('DD.MM.YYYY'), url, '/api/absence');

            this.axios({
                method: 'get',
                headers: {
                    'AUTHORIZATION': "Bearer " + this.$store.state.jwt
                },
                url: url,
            }).then(response => {
                view.$helpers.success('Mitarbeiter', 'Mitarbeiter wurden geladen');
                view.absences = response.data.data;

                
                Object.values(view.absences).forEach(absence => {


                    let fromMoment = view.$helpers.getMomentFromStringUTC(absence.from, 'YYYY-MM-DD HH:mm:ss').tz("Europe/Vienna");
                    let toMoment = view.$helpers.getMomentFromStringUTC(absence.to, 'YYYY-MM-DD HH:mm:ss').tz("Europe/Vienna");

                    if(view.softwareId == 1 && fromMoment.clone().startOf('day').format('x') == toMoment.clone().startOf('day').format('x')){
                        if (fromMoment >= view.timeframeStart && toMoment <= view.timeframeEnd) {
                            
                            absence.fromTimestamp = fromMoment.clone().format('x');
                            absence.fromMoment = fromMoment.clone();
                            absence.toMoment = toMoment.clone();
                            absence.totalDays = Math.round(toMoment.diff(fromMoment, 'minutes') / (24*60) * 100) / 100;
                            view.absenceEmployeeMap[absence.employeeId].absences.push(absence);

                            

                        if(view.absenceEmployeeMap[absence.employeeId]){
                        //view.actualShiftEmployeeMap[actualShift.employeeId].actualShifts.push(actualShift);
                            view.absenceEmployeeMap[absence.employeeId].absences.push(absence);
                        }else{
                        view.$helpers.error('FEHLER', 'Abwesenheit konnte nicht einem Mitarbeiter zugewiesen werden');
                        console.log("Employee with the ID: " + absence.employeeId + " is missing in shift: " + absence.id);
                        }
/*
                        if(typeof view.absenceEmployeeMap[absence.employeeId] === 'undefined'){
                            console.log("employee not found - Maybe run the sync command");
                        }else{
                            view.absenceEmployeeMap[absence.employeeId].absences.push(absence);
                        }*/
                        }
                    }else{
                        if (fromMoment < view.timeframeStart) {
                            fromMoment = view.timeframeStart.clone();
                        }

                        let midnightStart = fromMoment.clone().startOf('day');
                        if (midnightStart.format('x') != fromMoment.format('x')) {
                            fromMoment = fromMoment.clone().add(1, 'days').startOf('day');
                        }

                        let midnightEnd = toMoment.clone().startOf('day');
                        if (midnightEnd.format('x') != toMoment.format('x')) {
                            toMoment = toMoment.clone().startOf('day');
                        }
                        toMoment.subtract(1, 'days');
                        if (toMoment > view.timeframeEnd) {
                            toMoment = view.timeframeEnd.clone().startOf('day');
                        }

                        absence.fromTimestamp = fromMoment.clone().format('x');
                        absence.fromMoment = fromMoment.clone();
                        absence.toMoment = toMoment.clone();
                        absence.totalDays = toMoment.diff(fromMoment, 'days') + 1;
/*
                        if(typeof view.absenceEmployeeMap[absence.employeeId] === 'undefined'){
                            console.log("employee not found - Maybe run the sync command");
                        }else{
                            view.absenceEmployeeMap[absence.employeeId].absences.push(absence);
                        }*/
                        if(view.absenceEmployeeMap[absence.employeeId]){
                        //view.actualShiftEmployeeMap[actualShift.employeeId].actualShifts.push(actualShift);
                            view.absenceEmployeeMap[absence.employeeId].absences.push(absence);
                        }else{
                        view.$helpers.error('FEHLER', 'Abwesenheit konnte nicht einem Mitarbeiter zugewiesen werden');
                        console.log("Employee with the ID: " + absence.employeeId + " is missing in shift: " + absence.id);
                        }

                    }

                });

                console.log("REFRESH VISIBLE MAPS");
                view.refreshVisibleMaps();

            }).catch(function (error) {
                view.saves = false;
            })
        },

        refreshVisibleMaps: function () {
            console.log("refreshVisibleMaps");
            let view = this;

            Object.values(view.absences).forEach(absence => {
                let add = false;
                let addHidden = false;
                if (absence.mdState == 1) { // Only absences (other values than 1 are whishes,and other stuff)
                    addHidden = true;

                    if(!absence.softDelete){
                        if (view.softwareId == 0) {
                            if (absence.mdType == 1 || absence.mdType == 2 || absence.mdType == 6) {
                                if (!absence.mdDeleted && absence.mdApproved) {
                                    if (absence.totalDays > 0) {
                                        add = true;
                                    }
                                }
                            }
                        } else if (view.softwareId == 1) {
                            if (absence.mdType == 1 || absence.mdType == 2 || absence.mdType == 3 || absence.mdType == 6 || absence.mdType == 7 || absence.mdType == 8) {
                                if (!absence.mdDeleted && absence.mdApproved) {
                                    if (absence.totalDays > 0) {
                                        add = true;
                                    }
                                }
                            }
                        }
                    }

                }

                if (add) {
                    view.exportableTableAbsences.push({
                        id: absence.id,
                        editing: false,
                        storeExternalId: view.storeExternalId,
                        bmdId: view.employeeMap[absence.employeeId].bmdId,
                        dpwId: view.employeeMap[absence.employeeId].dpwId,
                        socialsecurity: view.employeeMap[absence.employeeId].socialsecurity,
                        firstName: view.employeeMap[absence.employeeId].firstName,
                        lastName: view.employeeMap[absence.employeeId].lastName,
                        employeeId: absence.employeeId,
                        absence: absence
                    });
                } else if (addHidden) {
                    view.hiddenTableAbsences.push({
                        id: absence.id,
                        editing: false,
                        storeExternalId: view.storeExternalId,
                        bmdId: view.employeeMap[absence.employeeId].bmdId,
                        dpwId: view.employeeMap[absence.employeeId].dpwId,
                        socialsecurity: view.employeeMap[absence.employeeId].socialsecurity,
                        firstName: view.employeeMap[absence.employeeId].firstName,
                        lastName: view.employeeMap[absence.employeeId].lastName,
                        employeeId: absence.employeeId,
                        absence: absence
                    });

                }
            });

            view.exportableTableAbsences.sort(view.sortAbsenceArray);
            view.hiddenTableAbsences.sort(view.sortAbsenceArray);
            view.loading = false;
        },

        sortAbsenceArray: function (a, b) {
            let x = a.lastName + " " + a.firstName;
            let y = b.lastName + " " + b.firstName;
            if (x.toUpperCase() < y.toUpperCase()) return -1;
            if (x.toUpperCase() > y.toUpperCase()) return 1;

            if (a.absence.fromTimestamp < b.absence.fromTimestamp) return -1;
            if (a.absence.fromTimestamp > b.absence.fromTimestamp) return 1;
            return 0;
        },

        createAbsenceExport: function () {
            //If the export for the timeframe is not existing it gets created and returned immediately
            //Otherwise if it already exists, it just gets returned with no side effects
            let view = this;
            let url = '/api/absenceexport';

            let payload = {
                storeId: view.selectedStore,
                from: view.timeframeStart.format('DD.MM.YYYY'),
                to: view.timeframeEnd.format('DD.MM.YYYY'),
            };

            this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer " + this.$store.state.jwt
                },
                url: url,
                data: payload
            }).then(response => {
                view.$helpers.success('Abwesenheiten', 'Abwesenheiten Struktur wurde angelegt');
                view.absenceExports = response.data.data;
                view.loadEmployees();
            }).catch(function (error) {
                console.log(error);
            })
        },

        modDate: function(method){
          let view = this;
          if(view.storeData != null && view.softwareId == 0){
            //BMD PFAD MONATLICH
            if(method == 'add'){
              this.selectedDate.add(1, 'months');
              this.timeframeStart = this.selectedDate.clone().startOf('month');
              this.timeframeEnd = this.selectedDate.clone().endOf('month');
              this.$forceUpdate();
            }else if(method == 'sub'){
              this.selectedDate.subtract(1, 'months');
              this.timeframeStart = this.selectedDate.clone().startOf('month');
              this.timeframeEnd = this.selectedDate.clone().endOf('month');
              this.$forceUpdate();
            }
          }else if(view.storeData != null && view.softwareId == 1){
            //DPW PFAD ZWEI WÖCHENTLICH
            if(method == 'add'){
              if(view.selectedDate.date() > 16){
                this.selectedDate.add(1, 'months').startOf('month');
                view.timeframeStart = view.selectedDate.clone().subtract(1, 'months').startOf('month').add('days',15);
                view.timeframeEnd = view.timeframeStart.clone().endOf('month');
                view.$forceUpdate();
              }else{
                this.selectedDate.endOf('month');
                view.timeframeStart = view.selectedDate.clone().startOf('month');
                view.timeframeEnd = view.timeframeStart.clone().add('days',14);
                view.$forceUpdate();
              }
            }else if(method == 'sub'){
              if(view.selectedDate.date() > 16){
                this.selectedDate.startOf('month');
                view.timeframeStart = view.selectedDate.clone().subtract(1, 'months').startOf('month').add('days',15);
                view.timeframeEnd = view.timeframeStart.clone().endOf('month');
                view.$forceUpdate();
              }else{
                this.selectedDate.subtract(1, 'months').endOf('month');
                view.timeframeStart = view.selectedDate.clone().startOf('month');
                view.timeframeEnd = view.timeframeStart.clone().add('days',14);
                view.$forceUpdate();
              }
            }
          }
          this.refresh();
        },
        toggleEmployeeBmd: function (absence) {
            let view = this;

            Object.values(view.exportableTableAbsences).forEach(absence => {
                absence.editing = false;
            });

            absence.editing = true;
            view.$forceUpdate();
        },
        saveEmployeeBmd: function (absence) {
            let view = this;

            view.saves = true;

            let payload = {
                storeId: view.selectedStore,
                bmdId: absence.bmdId,
                employeeId: absence.employeeId,
            };
            let url = '/api/employee';

            this.axios({
                method: 'put',
                headers: {
                    'AUTHORIZATION': "Bearer " + this.$store.state.jwt
                },
                url: url,
                data: payload
            }).then(response => {
                view.saves = false;
                view.$helpers.success('Speichern erfolgreich', 'Daten wurde erfolgreich gespeichert');

                view.employeeMap[absence.employeeId].bmdId = absence.bmdId;
                Object.values(view.exportableTableAbsences).forEach(absence => {
                    absence.editing = false;
                    absence.bmdId = view.employeeMap[absence.employeeId].bmdId;
                });
                Object.values(view.hiddenTableAbsences).forEach(absence => {
                    absence.editing = false;
                    absence.bmdId = view.employeeMap[absence.employeeId].bmdId;
                });

            }).catch(function (error) {
                view.saves = false;
                view.$helpers.error('Speichern fehlgeschlagen', 'Fehler beim Speichern der Daten');
            })

            view.$forceUpdate();
        },
        toggleEmployeeDpw: function (absence) {
            let view = this;
            Object.values(view.exportableTableAbsences).forEach(absence => {
                absence.editing = false;
            });
            absence.editing = true;
            view.$forceUpdate();
        },
        saveEmployeeDpw: function (absence) {
            let view = this;

            if (absence.dpwId.length > 8) {
                view.$helpers.error('Speichern fehlgeschlagen', 'Die Nummer darf höchstens 8 stellig sein');
                return;
            }

            view.saves = true;

            let payload = {
                storeId: view.selectedStore,
                dpwId: absence.dpwId,
                employeeId: absence.employeeId,
            };
            let url = '/api/employee';

            this.axios({
                method: 'put',
                headers: {
                    'AUTHORIZATION': "Bearer " + this.$store.state.jwt
                },
                url: url,
                data: payload
            }).then(response => {
                view.saves = false;
                view.$helpers.success('Speichern erfolgreich', 'Daten wurde erfolgreich gespeichert');

                view.employeeMap[absence.employeeId].dpwId = absence.dpwId;
                Object.values(view.exportableTableAbsences).forEach(absence => {
                    absence.editing = false;
                    absence.dpwId = view.employeeMap[absence.employeeId].dpwId;
                });
                Object.values(view.hiddenTableAbsences).forEach(absence => {
                    absence.editing = false;
                    absence.dpwId = view.employeeMap[absence.employeeId].dpwId;
                });

            }).catch(function (error) {
                view.saves = false;
                view.$helpers.error('Speichern fehlgeschlagen', 'Fehler beim Speichern der Daten');
            })

            view.$forceUpdate();
        },

        export: function () {
            let view = this;
            let canExport = true;
            Object.values(view.exportableTableAbsences).forEach(absence => {
                if (absence.bmdId == null) {
                    canExport = false;
                }
            });
            if (canExport) {
                let view = this;
                let url = '/api/absenceexport/export';

                url = this.$helpers.appendParam("storeId", view.selectedStore, url, '/api/absenceexport/export');
                url = this.$helpers.appendParam("from", view.timeframeStart.format('DD.MM.YYYY'), url, '/api/absenceexport/export');
                url = this.$helpers.appendParam("to", view.timeframeEnd.format('DD.MM.YYYY'), url, '/api/absenceexport/export');

                this.axios({
                    method: 'get',
                    headers: {
                        'AUTHORIZATION': "Bearer " + this.$store.state.jwt
                    },
                    url: url,
                }).then(response => {
                    view.forceFileDownload(response, view.storeData.storeNumber + "_export_abwesenheiten.csv");
                }).catch(function (error) {
                    console.log(error);
                })
            } else {
                view.$helpers.error('Export fehlgeschlagen', 'BMD Id fehlt bei einem Mitarbeiter');
            }
        },

        saveOnSFTP: function () {
            let view = this;
            let canExport = true;
            Object.values(view.exportableTableAbsences).forEach(absence => {
                if (absence.dpwId == null) {
                    canExport = false;
                }
            });
            if (canExport) {
                let view = this;
                let url = '/api/absenceexport/saveOnSFTP';

                url = this.$helpers.appendParam("storeId", view.selectedStore, url, '/api/absenceexport/saveOnSFTP');
                url = this.$helpers.appendParam("from", view.timeframeStart.format('DD.MM.YYYY'), url, '/api/absenceexport/saveOnSFTP');
                url = this.$helpers.appendParam("to", view.timeframeEnd.format('DD.MM.YYYY'), url, '/api/absenceexport/saveOnSFTP');

                this.axios({
                    method: 'get',
                    headers: {
                        'AUTHORIZATION': "Bearer " + this.$store.state.jwt
                    },
                    url: url,
                }).then(response => {
                    view.$helpers.success('Export erfolgreich', 'Die Datei wurde erstellt und gespeichert');
                }).catch(function (error) {
                    console.log(error);
                })
            } else {
                view.$helpers.error('Export fehlgeschlagen', 'DPW Id fehlt bei einem Mitarbeiter');
            }
        },

        // TODO: use $helpers function
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },

    },
}
</script>

<style scoped>
.employee {
    height: 40px;
    width: 150px;
    margin: 5px;

    position: relative;
    box-shadow: 0 0 1px 1px var(--contrast-3);
    padding: 5px;
    float: left;
}

/* th {
    font-family: DistrictProBold;
    font-size: 13px;
    color: white;
    background: var(--ml);
  }

  td {
    font-family: DistrictProBook;
    font-size: 13px;
  } */
</style>
