<template>
  <div class="dropdown-search" v-bind:class="{toggled: false &&  toggled && displayedItems.length > 0, light: styleLight, 'no-border': noBorder, 'bold-input' : boldInput}">
    <inputc v-if="!disabledInput" class="entry" :placeholder="placeholder" v-on:click="toggled = true" :maxItems="maxItems"
            :inputProp="input" :items="items" :remainAfterSelect="remainAfterSelect" :selectMultiple="selectMultiple" :selectionInit="selectIf ? selectIf : selectIndex" @item-selected="EmitSelected" :noNull="noNull"/>

  </div>
</template>

<script>
export default {
  name: "dropdownsearch",
  data(){
    return{
      el: null,
      defaultSettings: {
        pointerDirection: 0,
        pointerOffset: 15,
      },
      selectedItem: {},
      selectedItems: [],
      selectMultiple: false,
      items: [],
      filterOff: false,
      displayedItems: [],
      entries: [],
      toggled: true,
      posx: 0,
      remainAfterSelect: false,
      posy: 0,
      pointerDirection: 0,
      pointerOffset: 0,
      onceSelected: function($event,item){},
      parentCallback: function(){},
      input: '',
      inputPrevious: '',
      disabledInput: false,
      OnToggle: function(el, val){},
      styleLight: false,
      noBorder: false,
      boldInput: false,
      placeholder: null,
      selectIndex: null,
      selectIf: null,
      noNull: true,
      itemsWidth: 0,
      itemsPosY: 0,
      maxItems: 0,
    }
  },
  props:{
    state: Object,
  },
  watch: {
    state: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal){
        this.state = newVal;
        this.Init();
      }

    },
    input: function (newVal, oldVal){
      if(this.filterOff) this.UpdateDisplayedItems(newVal);
    },
    toggled: function(newVal, oldVal){
      this.RefreshItemsWidthPos();
    }
  },
  created(){
  },
  mounted(){
    if(!this.selectedItems && this.selectMultiple) this.selectedItems = [];
  },
  methods: {
    EmitSelected(selected){
      if(this.selectMultiple) this.selectedItems = this.items.filter(i => selected.find(it => i.value === it) != null)
      else if(selected.length) this.selectedItems = this.items.filter(i => selected === i.value);
      else this.selectedItem = selected;

      if(this.onceSelected) this.onceSelected(null, selected, this);
      this.$emit("item-selected", selected);
    },
    RefreshItemsWidthPos(){
      let view = this;
      requestAnimationFrame(function(){
        requestAnimationFrame(function(){
          let box = view.$el.querySelector('input').getBoundingClientRect();
          view.itemsWidth = box.width;
          view.itemsPosY = box.bottom;
          if(!view.noBorder) view.itemsWidth += 2; //2 to account for 1 pixel border on both sides
        });
      });
    },
    ClearSelection($event){
      this.selectedItem = null;
      this.selectedItems = [];
      this.input = '';
      if(this.onceSelected) this.onceSelected($event, {value: null}, this);
      if(this.parentCallback) this.parentCallback();
      this.toggled = false;
      if(this.OnToggle) this.OnToggle(this.$el, this.toggled);
    },
    BlurToggle($event){
      if(window.mouse.click && $(window.mouse.element).closest('.dropdown-search').length > 0) {
        if(this.selectMultiple) this.$el.querySelector('input').focus();
        return;
      }
      this.Toggle(false);
    },
    OnUpdate(){
      if(this && this.OnUpdate){this.OnUpdate(this)};
    },
    Toggle: function (val){
      var view = this;
      this.toggled = val;
      if(!this.toggled) {
        this.$el.querySelector('input').blur();
        this.ReapplySelection();
      }
      if(this.OnToggle) this.OnToggle(this.$el, val);
    },
    ReapplySelection(){
      var view = this;
      if (view.selectedItem && view.selectedItem.label) {
        view.input = view.selectedItem.label;
      }
      if (view.selectMultiple) {
        view.selectedItems.sort(function (a, b) {
          return view.items.indexOf(a) < view.items.indexOf(b)
        });
        if (view.selectedItems.length > 0) {
          var input = '';
          view.selectedItems.forEach(i => input += ' ' + i.label);
          view.input = input;
        } else view.input = '';
      }
    },
    ChangeValue(val){
      this.input = val.label;
      this.toggled =  false;
    },
    UpdateDisplayedItems(val){
      if(this.items){
        this.displayedItems = this.items.filter(i => i.label && i.label.toLowerCase().indexOf(val.toLowerCase()) > -1);
      }
    },
    Init(){
      let view = this;
      if(this.state){
        for(var x in this.state) this[x] = this.state[x];
        if(this.selectMultiple) this.remainAfterSelect = true;
      }
      this.toggled = false;
      this.displayedItems = this.items.slice();
      if(this.selectMultiple) {
        this.selectedItems = this.items.filter(i => i.selected);
        this.selectedItems.forEach(i => this.input += i.label + ' ');
      }else{
        if(this.selectedItem) this.input = this.selectedItem.label;
      }
      if((this.selectFirst || this.selectLast || !isNaN(parseInt(this.selectIndex)) || this.selectIf) && this.items.length > 0) {
        if(this.selectIf) {
          let found = this.items.find(i => view.selectIf(i));
          if(found) this.selectIndex = this.items.indexOf(found);
        }
        let index = this.selectIndex ? this.selectIndex : this.selectFirst ? 0 : this.selectLast ? this.items.length - 1 : -1;
        if(index !== null && index !== undefined && index > -1) {
          if (index >= this.items.length) index = this.items.length - 1;
          if (this.selectMultiple) {

            if(this.selectIf) {
              this.selectedItems = this.items.filter(i => view.selectIf(i));
              this.selectedItems.forEach(i => view.input += i.label + ' ');
            }
            else {
              var first = [];
              first.push(this.items[index]);
              this.selectedItems = first;
              this.input = first[index].label;
            }
          } else {
            this.state.selectedItem = this.selectedItem = this.items[index];
            this.input = this.selectedItem.label;
          }
        } else {
          this.selectedItem = null;
          this.input = '';
        }
      }
      if (this.placeholder == null) {
        this.placeholder = "Auswählen"
      }
      this.$forceUpdate();
    },
    selectItem(item){
      var view = this;

      if(view.selectMultiple){
        if(this.selectedItems.find(i => i === item)) this.selectedItems = this.selectedItems.filter(i => i !== item);
        else this.selectedItems.push(item);
        this.selectedItems.sort(function(a,b){ return view.items.indexOf(a) < view.items.indexOf(b)});
        if(this.selectedItems.length > 0) {
          var input = '';
          this.selectedItems.forEach(i => input += ' ' + i.label);
          this.input = input;
        } else this.input = '';

        this.selectedItems = this.selectedItems.slice();
        this.state.selectedItems = this.selectedItems;

      } else {
        this.selectedItem = item;
        this.state.selectedItem = this.selectedItem;
        this.input = item.label;
        this.toggled = this.remainAfterSelect || false;
        if(this.onceSelected) this.onceSelected(null, item, view);
        if(item.onceSelected) item.onceSelected(null, item, view);
        if(this.parentCallback) this.parentCallback();
        if(this.OnToggle) this.OnToggle(this.$el, this.toggled);
      }
      this.$emit("item-selected", item);
      this.$forceUpdate();
    },
    loadItems(itemsNew){
      this.items = itemsNew;
    },
    toggle(){
      this.dropdown.toggled = !this.dropdown.toggled;
      if(this.dropdown.parentCallback) this.dropdown.parentCallback();
    },
    EnterSelect($event){
      this.toggled = true;
      var view = this;
      if(!view.input) view.input = $event.currentTarget.value;
      var temp = this.displayedItems.filter(i => i.label.toLowerCase().indexOf(view.input.toLowerCase()) > -1);
      var event = $event;
      var keyName = event.key;
      if(keyName === "ArrowUp")
      {
        temp = this.displayedItems.filter(i => i.label.toLocaleLowerCase() === view.input.toLocaleLowerCase());
        if(temp.length > 0)
        {
          let index = this.displayedItems.indexOf(temp[0]);
          var newSelection = this.displayedItems[((index - 1) + this.displayedItems.length) % this.displayedItems.length];
          this.input = newSelection.label;
        } else if(this.displayedItems.length > 0) this.input = this.displayedItems[this.displayedItems.length - 1].label;
      }
      if(keyName === "ArrowDown")
      {
        temp = this.displayedItems.filter(i => i.label.toLocaleLowerCase() === view.input.toLocaleLowerCase());
        if(temp.length > 0)
        {
          let index = this.displayedItems.indexOf(temp[0]);
          var newSelection = this.displayedItems[((index + 1) + this.displayedItems.length) % this.displayedItems.length];
          this.input = newSelection.label;
        } else if(this.displayedItems.length > 0) this.input = this.displayedItems[0].label;
      }
      if(keyName !== "Enter") {
        if(keyName !== "Tab" &&  keyName !== "Shift" && keyName !== "ArrowDown" && keyName !== "ArrowUp") this.UpdateDisplayedItems(this.input);
        return;
      }
      if(temp.length > 0)
      {
        this.selectItem($event, temp[0]);
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropdown-search{
  position: relative;
  display: inline-block;
  width: inherit;
}
.dropdown-search .background{
  display: none;
  position: fixed;
  height: 2000px;
  width: 2000px;
  left: 0;
  right: 0;
  z-index: 1;
  top: 0;
}
.dropdown-search.toggled .background{
  display: inline-block;
}
.dropdown-search .entry{
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 10px;
  height: 25px;
}
.dropdown-search > .fa-stack{

  position: absolute;
  right: 5px;
  top: 6px;
  border-radius: 100px;
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin: 0;
  z-index: 10;
}
.dropdown-search > .fa-stack.clear{
  z-index: 10;
  right: 22px;
}
.dropdown-search > .fa-stack.clear .fa-times{
  transform: scale(.8);
  color: var(--contrast-3);
}
.dropdown-search.light > .fa-stack.clear .fa-times{
  color: var(--bg);
}
.dropdown-search > .fa-stack .fas{

  color: var(--contrast-3);
  font-size: 15px;
}
.dropdown-search > .fa-stack .fas.fa-circle{

  color: var(--contrast-4);
}

.dropdown-search.toggled{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.dropdown-search.toggled .entry{

  box-shadow: -1px 0 0px 0px var(--contrast-5),
  1px 0 0px 0px var(--contrast-5),
  0px -1px 0px 0px var(--contrast-5);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown-search .items{
  display:none;
  padding-bottom: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: var(--contrast-2);
  box-shadow: -1px 0 0px 0px var(--contrast-5), 1px 0 0px 0px var(--contrast-5), 0px 1px 0px 0px var(--contrast-5);
  z-index: 1000;
  position: fixed;
}
.dropdown-search.no-border .items{
  display:none;
  padding-bottom: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: var(--contrast-2);
  box-shadow: none !important;
  z-index: 20;
}
.dropdown-search.toggled .items{
  display: block;
  max-height: 170px;
  overflow-y: auto;

}





.dropdown-search .items > .item-with-desc:first-child{
  /*margin-top: -5px;*/
}
.dropdown-search .items > .item-with-desc{
  background: var(--contrast-3);
  box-shadow: -1px 0 0px 0px var(--contrast-5),
  1px 0 0px 0px var(--contrast-5);
  font-weight: 600;
  text-align: left;
  padding: 4px 0;
  padding-left: 10px;
  height: 35px;
  cursor: pointer;
  color:var(--contrast-4);
}
.dropdown-search .items > .item-with-desc *{
  pointer-events: none;
  float: left;
  min-height: 15px;
  margin-top: -3px;
  overflow: hidden;
}
.dropdown-search .items > .item-with-desc:nth-child(odd){
  background: var(--contrast-2);
}

.dropdown-search .items > .item-with-desc.current{
        background: var(--ml);
}
.dropdown-search .items > .item-with-desc:hover{
        background: var(--ml) !important;
}
.dropdown-search .items > .item-with-desc.selected{
        background: var(--ml) !important;
}
.dropdown-search .items > .item-with-desc:hover .label{
  color: var(--bg) !important;
}
.dropdown-search .items > .item-with-desc.current .label{
  color: var(--bg) !important;
}

.dropdown-search .items > *:last-child{
}
.dropdown-search .items > .item-with-desc .fas{
  font-size: 10px;
  margin-top: 4px;
  margin-left: 1px;
  overflow: visible;
}
.dropdown-search .items > .item-with-desc .label{
  white-space: nowrap;
  max-width:220px;
  line-height: 18px;
}
.dropdown-search .items > .item-with-desc .label > .description{
  float: none;
  font-size: 10px;
  color: var(--contrast-5) !important;
}
.dropdown-search .items > .item-with-desc:hover .label > .description{
  color: var(--bg) !important;
}
.dropdown-search .items > .item-with-desc.current .label > .description{
  color: var(--bg) !important;
}






.dropdown-search .items > .item:first-child{
  /*margin-top: -5px;*/
}
.dropdown-search .items > .item{
  background: var(--contrast-3);
  box-shadow: -1px 0 0px 0px var(--contrast-5),
  1px 0 0px 0px var(--contrast-5);
  font-weight: 600;
  text-align: left;
  padding: 4px 0;
  padding-left: 10px;
  height: 20px;
  cursor: pointer;
  color:var(--contrast-4);
}
.dropdown-search .items > .item *{
  pointer-events: none;
  float: left;
  min-height: 15px;
  margin-top: -3px;
  overflow: hidden;
}
.dropdown-search .items > .item:nth-child(odd){
  background: var(--contrast-2);
}

.dropdown-search .items > .item.current{
        background: var(--ml);
}
.dropdown-search .items > .item:hover{
        background: var(--ml) !important;
}
.dropdown-search .items > .item.selected{
        background: var(--ml) !important;
}
.dropdown-search .items > .item:hover .label{
  color: var(--bg) !important;
}
.dropdown-search .items > .item.selected .label{
  color: var(--bg) !important;
}

.dropdown-search .items > *:last-child{
}
.dropdown-search .items > .item .fas{
  font-size: 10px;
  margin-top: 4px;
  margin-left: 1px;
  overflow: visible;
}
.dropdown-search .items > .item .label{
  white-space: nowrap;
}




.dropdown-search .entry{
  text-align: left;
}

.light input{
  box-shadow: 0 0px 0 1px var(--contrast-3) !important;
  background: none !important;
  border-radius: 12px;
  font-size: 12px !important;
  font-family: DistrictProLight !important;
  font-weight: 400 !important;
}

.row:hover .light input{
  /* box-shadow: 0 0px 0 1px white !important; */
}

.bold-input input{
        color: var(--ml);
  font-weight: 800;
  font-family: DistrictProBold !important;
  font-size: 15px !important;
}

.light.toggled input{
  box-shadow: -1px 0 0px 0px var(--contrast-3), 1px 0 0px 0px var(--contrast-3), 0px -1px 0px 0px var(--contrast-3) !important;
  background: none !important;
}

.light .fa-angle-down{
  color: var(--bg) !important;
}
.light .fa-circle{
  color: var(--contrast-2) !important;
}
.light .items{
  position: fixed !important;
  margin-left: -1px;
  /*top: 25px !important;
  left: -1px !important;
  width: calc(100% + 2px) !important;*/
  box-shadow: inset 0 0 0 1px var(--contrast-3) !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.light .items{
  background: var(--bg) !important;
}
.light .items .item{
  background: var(--bg) !important;
  box-shadow: inset -2px 0 0 -1px var(--contrast-3), inset 2px 0 0 -1px var(--contrast-3) !important;
}
.light .items .item:hover{
        background: var(--ml) !important;
}



.hide{
  display: none;
}

.no-border input{
  border: none;
  box-shadow: none !important;
}

.center input{
  text-align: center;
}

.right input{
  text-align: right;
}

.menu-bar input{
  border-radius: 0;
}

.dropdown-search .input-component{
}
</style>
