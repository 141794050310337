<template>
  <div class="label" v-bind:class="[typeComputed, {disabled: disabledComputed, 'focus-highlight': hover}]" @click="Click($event)" v-tooltip="tooltip" v-bind:style="styleLabel">
    <checkbox v-if="checkbox" v-bind="checkbox" @Update="Update($event)"/>
    <input type="button" v-if="CheckListenerExists('click')" class="hidden-input" v-bind:tabindex="disabledComputed ? -1 : ''" v-on:change="Click($event)" v-on:blur="Blur" v-on:focus="Focus"/>
    <buttonc class="icon-left" v-if="iconLeftComputed" v-bind="iconLeftComputed"/>
    <div class="text" v-bind:style="[styleText]" v-if="textComputed !== undefined">{{textDisplay}}</div>
    <div class="text shadow" v-if="textComputed !== undefined" v-bind:style="[{'box-shadow': styleShadow}]"></div>
    <div class="title" v-bind:style="[styleText]" v-if="titleComputed !== undefined">{{titleComputed + ':'}}</div>
    <div class="info" v-bind:style="[styleText]" v-bind:class="InfoClasses()" v-if="infoComputed !== null && infoComputed !== undefined">{{infoDisplay}}</div>
    <buttonc class="icon-right" v-if="iconRightComputed" v-show="iconRight.hasOwnProperty('show') ? iconRight.show : true" v-bind="iconRightComputed" @click="(e) => { if(iconRightComputed && iconRightComputed.click) iconRightComputed.click(e)}" v-tooltip="iconRightComputed.tooltip"/>
    <component v-for="(component, indexc) in components" :key="indexc" :is="component.type" v-bind="component.propContainer" v-on="component.customEvents"
               @click.native="ClickComponent(component)" v-bind:style="[component.styles]"/>
  </div>
</template>

<script>
export default {
  name: "label",
  data(){
    return {
      styleShadow: '',
      hover: false,
    }
  },
  props: {
    disabled: [String, Boolean],
    iconLeft: Object,
    iconRight: Object,
    text: [String, Number],
    type: String,
    title: [String, Number],
    info: [String, Number],
    state: Object,
    styleText: [Object, String],
    styleLabel: [Object, String],
    tooltip: [Object, String, Function],
    checkbox: [Object],
    components: Array
  },
  computed: {
    disabledComputed(){
      if(this.state) return this.state.disabled;
      else return this.disabled;
    },
    iconLeftComputed(){
      if(this.state) return this.state.iconLeft;
      else return this.iconLeft;
    },
    iconRightComputed(){
      if(this.state) return this.state.iconRight;
      else return this.iconRight;
    },
    textComputed(){
      if(this.state) return this.state.text;
      else return this.text;
    },
    typeComputed(){
      if((!this.state || (this.state && !this.state.type)) && !this.type) return '';
      if(this.state) return this.state.type;
      else return this.type;
    },
    titleComputed(){
      if(this.state) return this.state.title;
      else return this.title;
    },
    infoComputed(){
      if(this.state) return this.state.info;
      else return this.info;
    },
    textDisplay(){
      let label = this.textComputed;
      if(this.typeComputed && this.typeComputed.indexOf('number') !== -1) {
        label = this.$helpers.ProcessNumericLabel(label);
      }
      return label;
    },
    infoDisplay(){
      let label = this.infoComputed;
      if(this.typeComputed && this.typeComputed.indexOf('number') !== -1) {
        label = this.$helpers.ProcessNumericLabel(label);
      }
      return label;
    },
  },
  mounted(){
    this.GetShadowStyle();
  },
  methods:{
    ClickComponent(component){

      if(component.click) {
        component.click(this, this.$children[this.components.indexOf(component)]);
      }
    },
    Focus($event){
      this.hover = true;
      this.$emit('Focus');
    },
    Blur($event){
      this.hover = false;
      this.$emit('Blur');
    },
    CheckListenerExists(eventName){
      return this.$listeners[eventName];
    },
    InfoClasses(){
      if(this.typeComputed.indexOf('number') === -1) return {};
      let number = parseInt(this.infoComputed);
      let classes = {green: number > 0, red: number < 0, gray: number === 0};
      return classes;
    },
    Click($event){
      if($event.target.closest('.button')) return;
      let input = this.$el.querySelector('input');
      if(input) input.focus();
      this.$emit('click', $event);
      if(this.checkbox) this.Update($event);
    },
    Update($event){
      this.$emit('Update', $event);
    },
    GetShadowStyle(color){
      if(!this.$el) return '';

      let background = getComputedStyle(this.$el).background;
      if(color) background = color;
      else {
        if (background === '') return '';
        background = background.split(' none')[0];
      }
      this.styleShadow = 'inset -12px 0px 2px -5px ' + background + ' !important';
    },
  },
}
</script>

<style scoped>

.label{
  margin-left: 5px;
  margin-right: 5px;
  transition: .2s;
  display: inline-flex;
}

.label *{
  align-self: center;
  transition: .2s;
}

.label.no-break{
  white-space: nowrap;
  /* overflow: hidden; */
}


.label.header{
  font-family: DistrictProBold;
        color: var(--ml);
  font-size: 16px;
}

.label.smallheader{
  font-family: DistrictProBold;
        color: var(--ml);
  font-size: 13px;
}

.label.header.title{
  font-size: 30px;
  float: left;
}

.label.large{
  font-size: 35px;
}
.label.huge{
  font-size: 90px;
}


.label .text{
  margin-right: 5px;
  display: inline-block;
}

.centered .text{
  margin-right: auto;
  margin-left: auto;
  display: inline-block;
}

.label.dark .text{
  color: var(--contrast-4);
}

.label .title{
  margin-right: 5px;
  display: inline-block;
}

.label .info{
  font-family: DistrictProBold;
  display: inline-block;
}

.label .green{
  font-family: DistrictProBold;
  color: #6c8d25;
}

.label .red{
  color: #e5154f;
  font-weight: 200;
  font-family: DistrictProLight;
}
.label .gray{
  /*color: var(--contrast-3);*/
  font-weight: 200;
  font-family: DistrictProLight;
}

.label .icon-left{
  border: none;
  box-shadow: none;
  background: none;
}

.label .icon-right{

  border: none;
  box-shadow: none;
  background: none;
  float: right;
  /*color: var(--contrast-2);*/
}

.label.groupheader{
  width: 100%;
  height: 20px;
  margin: 8px 8px 0px 8px;
  padding: 2px 4px;
  font-size: 12px;
  cursor: default;
  pointer-events: initial;
}
.label.groupheader .text{
  pointer-events: none;
        color: var(--ml);
}
.label.block{
  width: 100%;
  height: 40px;
  margin: 0;
  cursor: pointer;
  pointer-events: initial;
  position: relative;
}
.label.block .text{
  pointer-events: none;
}
.label.block .button{
  height: 100%;

}
.label.block .button:not(.allow-hover){
  pointer-events: none;
}
.label.block:hover, .label.block.focus-highlight{
  background: var(--contrast-3);
  color: var(--contrast-4);
  filter: none;
}
.label.block:hover > .text{
  text-shadow: 0px 0px 0px #404040;
}

.label.border-bottom{
  box-shadow: 0 1px 0 -1px var(--contrast-2);
}

.label.border-top{
  box-shadow: 0 -1px 0 -1px var(--contrast-2);
}
.label.border-bottom.border-top{
  box-shadow: 0 -1px 0 -1px var(--contrast-2), 0 1px 0 -1px var(--contrast-2);
}

.label.bold .text{
  font-family: DistrictProBold;
}

.label.thin .text{
  font-family: DistrictProLight;
  font-weight: 200;
}

.label.ticker .text{
  font-size: 18px;
}

.text.shadow{
  position: absolute;
  right: 0;
  width: 10px;
  height: 100%;
  top: 0;
  margin: 0;
  display: none;
}

.label.shadow .shadow{
  display: initial;
}

.label.shadow{
  box-shadow: none !important; /*gee I sure love bootstrap using common words as class names*/
}

.label.selectable .text{
  user-select: text;
}

.label.center-absolute .text{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}

.label.tiny .text{
  font-size: 8px;
}

.label.tab > *{
  pointer-events: none;
}

.label.tab.focus-highlight{
  background: var(--contrast-1);
  filter: brightness(120%);
  color: var(--contrast-4);
}

.label.center{
  justify-content: center;
}

.label.day {
  justify-content: center;
  margin-right: 0;
}

.datepicker .text{
  margin: 0;
}

.label.day *,.label.month * {
  pointer-events: none;
}

.options .label{
  margin: 0;
}

.options .label .text{
  margin: 0;

}

.label.underline{
  box-shadow: inset 0 -2px 0 -1px var(--contrast-3);
  padding-bottom: 5px;
}

.label.space-content{
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.label.thick {
  font-size: 14px;
  font-weight: 1600;
}



.label.input-error {
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  margin: auto;
  z-index: 10;
  height: fit-content;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  pointer-events: none;
}


.label.input-error .text{
  color: var(--contrast-6);
  word-break: keep-all;
  white-space: nowrap;
  font-family: DistrictProLight;
  font-weight: 200;
  font-size: 11px;
  user-select: none;
}


.input-component *{
  transition: .0s !important;
}

.label.block.small{
  height: 30px;
  position: relative;
}
.label.block.small .button{
  height: 100%;
  padding: 0;
}

.list-label .label{
  user-select: none;
}

.checkbox-label {
  width: 50% !important;
  height: 30px !important;
  padding-left: 5px;
}
.checkbox-label .text {
  margin: -2px 0 0 5px;
}

.checkbox-component{
  pointer-events: none;
}

.data-table .row:not(.collapsed) .entry > .label > .text {
  color: white;
}

.datetime .label .text{
  margin: 0;
}

.label.occupy-container{
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  justify-content: center;
  align-items: center;
  display: flex !important;
}

</style>
