<template>
    <div class="imagecrop modal">
        <div class="background"></div>
        <div class="content">
            <div class="crop-container"></div>

            <div class="button accept" v-on:click="UploadImage()">
                <div class="label">Upload</div>
            </div>
            <div class="button cancel" v-on:click="Remove()">
                <div class="label">Cancel</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "imagecrop",
        created(){
            var imagecrop = this;
            requestAnimationFrame(function(){
                var el = imagecrop.$el.querySelector('.crop-container');
                imagecrop.imagecrop.croppie = new Croppie(el, {
                    viewport: { width: 200, height: 200 },
                    boundary: { width: 300, height: 300 },
                    showZoomer: false,
                    enableOrientation: false
                });

                var fileReader = new FileReader();
                fileReader.onload = function (e) {
                    imagecrop.imagecrop.croppie.bind({
                        url: e.target.result,
                        zoom: .01
                    }).then(function () {
                        console.log('jQuery bind complete');
                    });
                };
                fileReader.readAsDataURL(imagecrop.imagecrop.imageData);

                //imagecrop.imagecrop.inputEl.addEventListener('change', function(){readFile(this);});
                /*imagecrop.imagecrop.croppie.result({type: 'base64', format: 'png'}).then(function(blob) {
                    var file = new File([blob], "image");
                    //window.navigator.msSaveOrOpenBlob(file, "image");
                });*/
            });
        },
        destroyed () {
            //this.imagecrop.inputEl.removeEventListener('change', function(){readFile(this);});
        },
        data(){
            return{
                croppie: Object,
                inputEl: Object,
                imageData: Object,
                OnCancel(){},
                OnCrop(){},
                OnAccept(){},
            }

        },
        methods: {
            callCancel(){
                this.Remove();
            },
            Remove(){
                var crop = this;
                this.$parent.imagecrops = this.$parent.imagecrops.filter( i => i !== crop.imagecrop);
                this.$emit('cancelCrop');
            },
            UploadImage(){
                var imagecrop = this;
                this.imagecrop.croppie.result({type: 'base64', format: 'png'}).then(function(base64) {
                    imagecrop.imagecrop.OnAccept(base64.split(',')[1]);
                });
            }
        },
        props:{
            imagecrop: Object
        }
    }
</script>

<style scoped>
    .imagecrop{

    }

    .imagecrop .content{

    }

    .imagecrop .content .crop-container{

    }

    .imagecrop .button {

    }
    .imagecrop .button.accept {

    }
    .imagecrop .button.cancel {

    }
</style>
