<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right"></div>
    </div>
    <div
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Betriebseinstellungen
    </div>

    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
      Die Betriebseinstellungen werden gerade geladen<br />
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && !hasAccessRights">
      <strong
        >Sie haben nicht die Berechtigung um die Betriebseinstellungen in diesem Store
        anzusehen.</strong
      >
      <br />
      Bitte wenden Sie sich an Ihren Store Administrator, er kann Ihnen die erforderlichen
      Rechte zuweisen.
      <br style="clear: both" />
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && hasAccessRights && otherError">
      <strong>Bei der Datenabfrage ist ein Fehler aufgetreten.</strong>
      <br />
      Bitte versuchen Sie die Seite neu zu laden.<br />
      <br />
      Sollte der Fehler danach immer noch auftreten wenden Sie sich bitte an den
      Support.<br />
      <small class="text-muted">&lt; {{ otherErrorMessage }} &gt;</small>
      <br style="clear: both" />
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && hasAccessRights && !otherError && companyData == null">
      Die Storedaten wurden noch nicht geladen
      <br style="clear: both" />

      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && hasAccessRights && !otherError && companyData != null">
      <!-- Einstellungen -->
      <div class="xColumn3">
        <div class="header">Einstellungen</div>

        <div class="entryBox">
          <div class="entryHeader">Interne ID</div>
          <div class="entryData">
            {{ internalId }}
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Firmenname</div>
          <div class="entryData">
            {{ storeName }}
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Zusatztext</div>
          <div class="entryData">
            {{ storeDescription }}
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">
            Firmen Nummer
            <small><em class="text-secondary">(Freitext)</em></small>
          </div>
          <div v-if="saves || !editieren" class="entryData">
            {{ companyNumber }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputCompanyNumber" />
          </div>
        </div>
        <br />



        <div class="entryBox">
          <div class="entryHeader">LV - Software</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ softwareId[companyData.softwareId] }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <select v-model="inputSoftwareId" style="width: 100%">
              <option
                v-for="(entry, key) in softwareId"
                :value="key"
                :key="key"
              >
                {{ entry }}
              </option>
            </select>
          </div>
        </div>


        <div
          v-if="!companyData.individualStoreIds && companyData.softwareId == 0"
          class="entryBox"
        >
          <div class="entryHeader">LV - BMD ID</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ bmdId }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputBmdId" />
          </div>
        </div>
        <div
          v-if="!companyData.individualStoreIds && companyData.softwareId == 1"
          class="entryBox"
        >
          <div class="entryHeader">LV - DPW ID</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ dpwId }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputDpwId" />
          </div>
        </div>

        <!-- TODO -- TAKE THIS KEY-VALUES FROM THE ENUM /api/enum/socialinsuranceregistrationmode -->
        <div class="entryBox">
          <div class="entryHeader">Mitarbeiter SV Einstellung</div>
          <div v-if="saves || !editieren" class="entryData">
            <span v-if="socialInsuranceRegistrationMode == 0"
              >Keine Sozialversicherungs Anmeldung erforderlich</span
            >
            <span v-if="socialInsuranceRegistrationMode == 1"
              >Sozialversicherungs Anmeldung erforderlich</span
            >
            <span v-if="socialInsuranceRegistrationMode == 2"
              >Sozialversicherungs Anmeldung und Anmeldedokument Bestätigung
              erforderlich</span
            >
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <select v-model="inputSocialInsuranceRegistrationMode" style="width: 100%">
              <option
                v-for="(entry, key) in enumSocialInsuranceRegistrationModes"
                :value="key"
                :key="key"
              >
                {{ entry }}
              </option>
            </select>
          </div>
        </div>

        <div class="entryBox">
          <div class="entryHeader">MD Mitarbeiterdaten Änderungsmodus</div>
          <div v-if="saves || !editieren" class="entryData">
            <span v-if="true">{{ enumEmployeeChangedFromMDModes[employeeChangedFromMDMode] }}</span>
            <!--<span v-if="socialInsuranceRegistrationMode == 1">Mitarbeiteränderungen benötigen eine Bestätigung</span>-->
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <select v-model="inputEmployeeChangedFromMDMode" style="width: 100%">
              <option
                v-for="(entry, key) in enumEmployeeChangedFromMDModes"
                :value="key"
                :key="key"
              >
                {{ entry }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Standorte -->
      <div class="xColumn3">
        <div class="header">
          Standorte
          <small class="text-secondary"><em>(Kostenstellen)</em></small>
        </div>

        <div
          style="font-size: 13px; text-align: left; padding: 7px 7px"
          v-for="store in storesDataFiltered"
          v-bind:key="store.id + 'store'"
        >
          <div>
            <div
              style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              Standort Name
            </div>
            <div
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              {{ store.name }}
            </div>
          </div>
          <br style="clear: both" />
          <div>
            <div
              style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              Zusatztext
            </div>
            <div
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              {{ store.description }}
            </div>
          </div>
          <br style="clear: both" />

          <div>
            <div
              style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              Standort Nummer
              <small class="text-secondary"><em>(Freitext)</em></small>
            </div>
            <div
              v-if="saves || !editieren"
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              {{ store.storeNumber }}
            </div>
            <div
              v-if="!saves && editieren"
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              <input v-model="store.inputStoreNumber" />
            </div>
          </div>
          <br style="clear: both" />

          <div v-if="companyData.softwareId == 0">
            <div
              style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              Standort BMD ID
            </div>
            <div
              v-if="saves || !editieren"
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              {{ store.bmdId }}
            </div>
            <div
              v-if="!saves && editieren"
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              <input v-model="store.inputBmdId" />
            </div>
          </div>

          <div v-if="companyData.softwareId == 1">
            <div
              style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              Standort DPW ID
            </div>
            <div
              v-if="saves || !editieren"
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              {{ store.dpwId }}
            </div>
            <div
              v-if="!saves && editieren"
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              <input v-model="store.inputDpwId" />
            </div>
          </div>



          <br v-if="companyData.softwareId == 0 || companyData.softwareId == 1" style="clear: both" />


          <div>
            <div
                style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              Standort Kostenstelle ID
            </div>
            <div
                v-if="saves || !editieren"
                style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              {{ store.costCenter }}
            </div>
            <div
                v-if="!saves && editieren"
                style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              <input v-model="store.inputCostCenter"/>
            </div>
          </div>
          <br style="clear: both"/>

          <div>
            <div
              style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              PLZ &amp; Stadt
            </div>
            <div
              style="
                display: inline-block;
                width: 50%;
                float: right;
                color: #aaa;
                font-weight: 200;
              "
            >
              {{ store.postalCode }} {{ store.city }}
            </div>
          </div>
          <br style="clear: both"/>

          
          <div v-if="companyData.softwareId == 2">
            <div
              style="
                display: inline-block;
                width: 50%;
                float: left;
                color: #555;
                font-weight: 700;
              "
            >
              Export in Webcockpit ist aktiviert?
            </div>
            <div v-if="saves || !editieren" 
                  v-bind:style="{ width: '50%', float: 'right', color: store.isWebcockpitExportEnabled ? 'green' : 'red', fontWeight: 200 }">
              <strong>{{ store.isWebcockpitExportEnabled ? 'Ja' : 'Nein' }}</strong>
            </div>
            <div v-if="!saves && editieren" style=" width: 50%; float: right; color: #aaa; font-weight: 200;" >
              <label>
                <input type="radio" v-model="store.isWebcockpitExportEnabled" :value="1" />
                On
              </label>
              &nbsp;&nbsp;&nbsp;
              <label>
                <input type="radio" v-model="store.isWebcockpitExportEnabled" :value="0" />
                Off
              </label>
            </div>
          </div>
          <hr style="clear: both" />


        </div>
      </div>

      <!-- Elda Dienstgeber -->
      <div class="xColumn3">
        <div class="header">Elda Dienstgeber Einstellungen</div>

        <div class="entryBox">
          <div class="entryHeader">Dienstgeber Name</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ eldaEmployerName }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputEldaEmployerName" style="width: 100%" />
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Dienstgeber Telefon</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ eldaEmployerPhoneNumber }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputEldaEmployerPhoneNumber" style="width: 100%" />
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Dienstgeber E-Mail</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ eldaEmployerEmail }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputEldaEmployerEmail" style="width: 100%" />
          </div>
        </div>

        <div class="entryBox">
          <div
            style="
              display: inline-block;
              width: 95%;
              float: left;
              color: #555;
              font-weight: 700;
            "
          >
            Zuständige Versicherungsträger / Beitragskontonummer
          </div>

          <div
            v-if="!saves && editieren"
            v-tooltip="'Versicherungsträger hinzufügen'"
            class="button small fas fa-plus"
            v-on:click="addInsurer()"
            style="display: inline-block; width: 5%; float: right; font-weight: 700"
          ></div>
        </div>

        <div
          v-if="saves || !editieren"
          v-for="eldaInsurer in eldaEmployerInsurer"
          class="entryBox"
        >
          <div
            style="
              display: inline-block;
              width: 50%;
              float: left;
              color: #555;
              font-weight: 700;
            "
          >
            {{ getInsurerLabelByKey(eldaInsurer.insurer) }}
          </div>
          <div class="entryData">
            {{ eldaInsurer.contribution_accountnumber }}
          </div>
        </div>
        <div
          v-if="!saves && editieren"
          v-for="(eldaInsurer, index) in inputEldaEmployerInsurer"
          class="entryBox"
        >
          <div
            style="
              display: inline-block;
              width: 50%;
              float: left;
              color: #555;
              font-weight: 700;
            "
          >
            <select v-model="eldaInsurer.insurer" style="width: 98%">
              <option v-for="item in insurer" :value="item.value" :key="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
          <div
            style="
              display: inline-block;
              width: 45%;
              float: left;
              color: #aaa;
              font-weight: 200;
            "
          >
            <input
              v-model="eldaInsurer.contribution_accountnumber"
              :key="index"
              style="width: 95%"
            />
          </div>
          <div
            v-tooltip="'Versicherungsträger löschen'"
            class="button small fas fa-trash"
            v-on:click="deleteInsurer(eldaInsurer.id)"
            style="display: inline-block; width: 5%; float: right; font-weight: 700"
          ></div>
        </div>
        <br />
      </div>

      <!-- Elda Hersteller -->
      <div class="xColumn3">
        <div v-if="saves || !editieren" class="header">Elda Hersteller Einstellungen</div>
        <div v-if="!saves && editieren" class="header" style="width: 93%">
          Elda Hersteller Einstellungen
        </div>
        <div
          v-if="!saves && editieren"
          v-tooltip="'Hersteller hinzufügen'"
          class="header fas fa-plus"
          v-on:click="addCreator()"
          style="width: 7%; float: right; padding-top: 14px"
        ></div>

        <div v-if="saves || !editieren" v-for="creator in eldaCreator">
          <div class="entryBox">
            <div class="entryHeader" style="color: #e51550">
              {{ creator.name }}
            </div>
          </div>

          <div class="entryBox">
            <div class="entryHeader">Elda Seriennummer</div>
            <div class="entryData">
              {{ creator.elda_serial_number }}
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Elda Passwort</div>
            <div class="entryData">
              &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Periodisch Clearing prüfen</div>
            <div class="entryData">
              <i
                class="fas fa-check-circle checkbox"
                v-bind:class="{ false: !creator.periodic_clearing_check }"
              ></i>
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Name</div>
            <div class="entryData">
              {{ creator.name }}
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Land</div>
            <div class="entryData">
              {{ getCountryLabelByKey(creator.country) }}
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller PLZ</div>
            <div class="entryData">
              {{ creator.postal }}
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Stadt</div>
            <div class="entryData">
              {{ creator.city }}
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Straße</div>
            <div class="entryData">
              {{ creator.street }}
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Telefonnummer</div>
            <div class="entryData">
              {{ creator.phone }}
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller E-Mail Adresse</div>
            <div class="entryData">
              {{ creator.email }}
            </div>
          </div>

          <hr style="clear: both" />
        </div>
        <div v-if="!saves && editieren" v-for="(creator, index) in inputEldaCreator">
          <div class="entryBox">
            <div v-if="creator.name !== ''" class="entryHeader" style="color: #e51550">
              {{ creator.name }}
            </div>
            <div v-else class="entryHeader" style="color: #e51550">Neuer Hersteller</div>
            <div
              v-tooltip="'Hersteller löschen'"
              class="button small fas fa-trash"
              v-on:click="deleteCreator(creator.id)"
              style="display: inline-block; width: 5%; float: right; font-weight: 700"
            ></div>
          </div>

          <div class="entryBox">
            <div class="entryHeader">Elda Seriennummer</div>
            <div class="entryData">
              <input
                v-model="creator.elda_serial_number"
                :key="'serialnumber' + index"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Elda Passwort</div>
            <div class="entryData">
              <input
                v-model="creator.elda_password"
                :key="'password' + index"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Periodisch Clearing prüfen</div>
            <div class="entryData">
              <i
                class="fas fa-check-circle checkbox"
                :key="'pcc' + index"
                v-bind:class="{ false: !creator.periodic_clearing_check }"
                v-on:click="
                  creator.periodic_clearing_check = !creator.periodic_clearing_check
                "
              ></i>
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Name</div>
            <div class="entryData">
              <input v-model="creator.name" :key="'name' + index" style="width: 100%" />
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Land</div>
            <div class="entryData">
              <select
                v-model="creator.country"
                :key="'country' + index"
                style="width: 100%"
              >
                <option v-for="item in enumCountry" :value="item.kfz" :key="item.kfz">
                  {{ item.valueGER }}
                </option>
              </select>
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller PLZ</div>
            <div class="entryData">
              <input
                v-model="creator.postal"
                :key="'postal' + index"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Stadt</div>
            <div class="entryData">
              <input v-model="creator.city" :key="'city' + index" style="width: 100%" />
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Straße</div>
            <div class="entryData">
              <input
                v-model="creator.street"
                :key="'street' + index"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller Telefonnummer</div>
            <div class="entryData">
              <input v-model="creator.phone" :key="'phone' + index" style="width: 100%" />
            </div>
          </div>
          <div class="entryBox">
            <div class="entryHeader">Hersteller E-Mail Adresse</div>
            <div class="entryData">
              <input v-model="creator.email" :key="'email' + index" style="width: 100%" />
            </div>
          </div>

          <hr style="clear: both" />
        </div>
      </div>

      <br style="clear: both" />

      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 5px"
      ></div>

      <!--
      <div v-if="companyData.softwareId == 0">
        <strong>BMD-ID</strong> <input v-model="bmdId"/> <button v-if="!saves" type="button" v-on:click="save('bmdId')">Speichern</button> <span v-if="saves">wird gespeichert</span><br/>
      </div>

      <div v-if="companyData.softwareId == 1">
        <strong>DPW-ID</strong> <input v-model="dpwId"/> <button v-if="!saves" type="button" v-on:click="save('dpwId')">Speichern</button> <span v-if="saves">wird gespeichert</span><br/>
      </div>
      -->
    </div>

    <b-modal
      size="lg"
      ref="modal-eldanumber-exists"
      @close="closeEldaNumberExistsModal()"
      centered
    >
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Achtung</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <h5>Die angegebene Elda Seriennummer existiert bereits in unserem System.</h5>
          <h5>Möchten Sie diese mit diesem Store verknüpfen?</h5>

          <br />

          <div style="padding: 1px" class="col-lg-12">
            <button
              style="width: 49.5%; margin-right: 1%"
              type="button"
              class="btn btn-sm btn-ml"
              v-on:click="
                linkTempCreatorToStore();
                closeEldaNumberExistsModal();
              "
            >
              Ja
            </button>
            <button
              style="width: 49.5%"
              type="button"
              class="btn btn-sm btn-secondary"
              v-on:click="closeEldaNumberExistsModal()"
            >
              Nein
            </button>
          </div>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeEldaNumberExistsModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "companysettings",
  data() {
    return {
      softwareId: {
        0: "BMD",
        1: "DPW",
        2: "LODAS/Lohn und Gehalt/Agenda/Gesper/ über Sotec Webcockpit",
      },
      insurer: [
        {
          value: "03",
          label: "Sozialversicherungsanstalt der Selbständigen",
        },
        {
          value: "05",
          label: "BVAEB",
        },
        {
          value: "08",
          label: "Allgemeine Unfallversicherungsanstalt",
        },
        {
          value: "11",
          label: "ÖGK Wien",
        },
        {
          value: "12",
          label: "ÖGK Niederösterreich",
        },
        {
          value: "13",
          label: "ÖGK Burgenland",
        },
        {
          value: "14",
          label: "ÖGK Oberösterreich",
        },
        {
          value: "15",
          label: "ÖGK Steiermark",
        },
        {
          value: "16",
          label: "ÖGK Kärnten",
        },
        {
          value: "17",
          label: "ÖGK Salzburg",
        },
        {
          value: "18",
          label: "ÖGK Tirol",
        },
        {
          value: "19",
          label: "ÖGK Vorarlberg",
        },
        {
          value: "24",
          label: "Betriebskrankenkasse Mondi",
        },
        {
          value: "25",
          label: "Betriebskrankenkasse voestalpine Bahnsysteme",
        },
        {
          value: "26",
          label: "Betriebskrankenkasse Zeltweg",
        },
        {
          value: "28",
          label: "Betriebskrankenkasse Kapfenberg",
        },
        {
          value: "1A",
          label: "KFA der Bediensteten der Stadt Wien",
        },
        {
          value: "4A",
          label: "KFA der Landeshauptstadt Linz",
        },
        {
          value: "4B",
          label: "Kranken- und Unfallfürsorgeträger der OÖ Gemeinden",
        },
        {
          value: "4C",
          label: "Kranken- und Unfallfürsorgeträger für OÖ Landesbedienstete",
        },
        {
          value: "4D",
          label: "OÖ Lehrer-Kranken- und Unfallfürsorge",
        },
        {
          value: "4E",
          label: "KFA der Beamten der Stadt Steyr",
        },
        {
          value: "4F",
          label: "KFA der Stadt Wels",
        },
        {
          value: "5A",
          label: "KFA der Landeshauptstadt Graz",
        },
        {
          value: "6A",
          label: "KFA der Stadt Villach",
        },
        {
          value: "7A",
          label: "KFA der MagistratsbeamtInnen der Stadt Salzburg",
        },
        {
          value: "7C",
          label: "KF d. pragmatisierten Bediensteten der Stadt Hallein",
        },
        {
          value: "94",
          label: "Bundesrechenzentrum GmbH",
        },
        {
          value: "99",
          label: "Dachverband der österreichischen Sozialversicherungen",
        },
        {
          value: "ST",
          label: "Statistik Austria",
        },
        {
          value: "MO",
          label: "Tabak – Monopolverwaltung GmbH",
        },
        {
          value: "A0",
          label: "Bundesarbeitskammer",
        },
      ],

      loading: true,
      longLoading: false,

      editieren: false,

      companyData: null,
      storesData: null,

      internalId: "",
      storeName: "",
      storeDescription: "",
      bmdId: "",
      dpwId: "",
      companyNumber: "",
      socialInsuranceRegistrationMode: 0,
      employeeChangedFromMDMode: 0,

      inputBmdId: "",
      inputDpwId: "",
      inputCompanyNumber: "",
      inputSoftwareId: 0,
      inputSocialInsuranceRegistrationMode: 0,
      inputEmployeeChangedFromMDMode: 0,

      enumSocialInsuranceRegistrationModes: {},
      enumEmployeeChangedFromMDModes: {},
      enumCountry: {},

      newEmailForNotification: "",
      emailsForNewEmployeeNotifications: [],

      eldaCreatorList: [],
      eldaCreatorTempSerialnumber: "",

      eldaEmployerName: "",
      eldaEmployerPhoneNumber: "",
      eldaEmployerEmail: "",
      eldaEmployerInsurer: [],
      eldaCreator: [],

      inputEldaEmployerName: "",
      inputEldaEmployerPhoneNumber: "",
      inputEldaEmployerEmail: "",
      inputEldaEmployerInsurer: [],
      inputEldaCreator: [],

      saves: false,
      hasAccessRights: true,
      otherError: false,
      otherErrorMessage: "",
    };
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.enumSocialInsuranceRegistrationModes[0] =
        "Keine Sozialversicherungs Anmeldung erforderlich";
      this.enumSocialInsuranceRegistrationModes[1] =
        "Sozialversicherungs Anmeldung erforderlich";
      this.enumSocialInsuranceRegistrationModes[2] =
        "Sozialversicherungs Anmeldung und Anmeldedokument Bestätigung erforderlich";

      this.enumEmployeeChangedFromMDModes[0] = "Mitarbeiteränderungen sofort übernehmen";
      this.enumEmployeeChangedFromMDModes[1] = "Kerndaten bestätigen";
      //this.enumEmployeeChangedFromMDModes[2] = "Sozialversicherungs Anmeldung und Anmeldedokument Bestätigung erforderlich";

      this.refresh();
    }
  },
  computed: {
    ...mapGetters([
      "companyId",
      "isSuperUser",
      "externalServicesMap",
      "companySettings",
      "selectedStores",
    ]),
    dateDisplay: function () {
      return this.$helpers.getMomentTZ().format("YYYY-MM-DD");
    },
    headerButtons() {
      let view = this;

      let state = {
        buttons: [
          {
            type: "block",
            icon: "fa-edit",
            show: !view.saves && !view.editieren,
            click: () => {
              view.editieren = !view.editieren;
            },
            tooltip: "Bearbeiten",
            title: "Bearbeiten",
          },
          {
            type: "block red",
            icon: "fa-ban",
            show: !view.saves && view.editieren,
            click: () => {
              view.editieren = !view.editieren;
              view.resetEldaInputs();
            },
            tooltip: "Abbrechen",
          },
          {
            type: "block green",
            icon: "fa-save",
            show: !view.saves && view.editieren,
            click: () => {
              view.save();
            },
            tooltip: "Änderung speichern",
          },
          {
            show: view.saves,
            type: "block",
            icon: "fa-spinner fa-spin",
            click: () => {},
            tooltip: "wird gespeichert",
          },
        ],
      };
      return state;
    },
    timeZoneList() {
      let result = [];
      /*
            this.timeZones.forEach(i => {
                let item = {label: i, value: i};
                result.push(item);
            })
            */
      return result;
    },
    storesDataFiltered() {
      let filtered = [];
      console.log(this.storesData);
      console.log(this.selectedStores);
      this.storesData.forEach((s) => {
        /*if (
          this.selectedStores.filter((elem) => {
            return s.id == elem.id;
          }).length > 0
        )*/
        filtered.push(s);
      });
      return filtered;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
    selectedStores: function (val, oldVal) {
      console.log("Selected stores changed in CompanySettings.vue");
      console.log(oldVal);
      console.log(val);
    },
    eldaCreator: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.eldaCreator.sort((a, b) => {
            if (a.id < b.id) return -1;
            else if (a.id > b.id) return 1;
            else return 0;
          });
        }
      },
    },
    inputEldaCreator: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.inputEldaCreator.sort((a, b) => {
            if (a.id < b.id) return -1;
            else if (a.id > b.id) return 1;
            else return 0;
          });
        }
      },
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;
      view.initEldaFields();
      view.loadListForNewEmployeeNotification();
      view.loadEldaEmployerSettings();
      view.loadEldaCreatorSettings();
      view.loadEldaCreatorList();
      view.loadCountryEnum();
      view.$helpers
        .GetCompanySettings(force)
        .then((response) => {
          view.updateCompanySettings(response);
          return view.$helpers.GetStoresSettings(force);
        })
        .then((response) => {
          view.storesData = response.data;
          for (let i = 0; i < view.storesData.length; i++) {
            view.storesData[i].inputBmdId = view.storesData[i].bmdId;
            view.storesData[i].inputDpwId = view.storesData[i].dpwId;
            view.storesData[i].inputStoreNumber = view.storesData[i].storeNumber;
            view.storesData[i].inputCostCenter = view.storesData[i].costCenter;
          }
          setTimeout(function () {
            view.loading = false;
          }, 200);
        })
        .catch((error) => {
          console.log("REFRESH ERROR: " + error);
          view.$helpers.error("Fehler", "Fehler beim Laden der Daten");

          if (error && error.response && error.response.status == 403) {
            view.hasAccessRights = false;
          }
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            view.otherErrorMessage = error.response.data.message;
          }
          view.otherError = true;
          //view.$helpers.error('Fehler', 'Fehler beim Laden der Daten');
          setTimeout(function () {
            view.loading = false;
          }, 200);
        });
    },
    resetEldaInputs() {
      let view = this;
      view.inputEldaEmployerName = view.eldaEmployerName;
      view.inputEldaEmployerPhoneNumber = view.eldaEmployerPhoneNumber;
      view.inputEldaEmployerEmail = view.eldaEmployerEmail;
      view.inputEldaEmployerInsurer = JSON.parse(
        JSON.stringify(view.eldaEmployerInsurer)
      );
      view.inputEldaCreator = JSON.parse(JSON.stringify(view.eldaCreator));
    },
    initEldaFields() {
      let view = this;
      view.eldaEmployerName = "";
      view.eldaEmployerPhoneNumber = "";
      view.eldaEmployerEmail = "";
      view.eldaEmployerInsurer = [];
      view.eldaCreator = [];
    },
    getInsurerLabelByKey: function (key) {
      let label = "";
      this.insurer.forEach(function (item, index) {
        if (item.value === key) {
          label = item.label;
        }
      });
      return label;
    },
    getCountryLabelByKey: function (key) {
      let label = "";
      Object.values(this.enumCountry).forEach((item) => {
        if (item.kfz === key) {
          label = item.valueGER;
        }
      });
      return label;
    },
    sha512(str) {
      if (str !== undefined && str !== null && str !== "") {
        return crypto.subtle
          .digest("SHA-512", new TextEncoder().encode(str))
          .then((buf) => {
            return Array.prototype.map
              .call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2))
              .join("");
          });
      } else {
        return Promise.resolve("");
      }
    },

    loadEldaEmployerSettings: function () {
      let view = this;
      let url = "/api/sec/elda/settings/employer";
      const params = {
        companyId: this.$store.state.companyId,
      };
      this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
      })
        .then((response) => {
          let data = response.data;

          console.log(data);

          if (data !== undefined) {
            view.eldaEmployerName = data.name;
            view.eldaEmployerPhoneNumber = data.phone_number;
            view.eldaEmployerEmail = data.email;
            view.eldaEmployerInsurer = JSON.parse(JSON.stringify(data.insurer));

            view.inputEldaEmployerName = data.name;
            view.inputEldaEmployerPhoneNumber = data.phone_number;
            view.inputEldaEmployerEmail = data.email;
            view.inputEldaEmployerInsurer = JSON.parse(JSON.stringify(data.insurer));
          } else {
            view.eldaEmployerName = "";
            view.eldaEmployerPhoneNumber = "";
            view.eldaEmployerEmail = "";
            view.eldaEmployerInsurer = [];

            view.inputEldaEmployerName = "";
            view.inputEldaEmployerPhoneNumber = "";
            view.inputEldaEmployerEmail = "";
            view.inputEldaEmployerInsurer = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadEldaCreatorSettings: function () {
      let view = this;
      let url = "/api/sec/elda/settings/creator";
      const params = {
        companyId: this.$store.state.companyId,
      };
      this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
      })
        .then((response) => {
          let data = response.data;

          console.log(data);

          if (data !== undefined) {
            view.eldaCreator = JSON.parse(JSON.stringify(data));
            view.inputEldaCreator = JSON.parse(JSON.stringify(data));
          } else {
            view.eldaCreator = [];
            view.inputEldaCreator = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadEldaCreatorList: function () {
      let view = this;
      let url = "/api/sec/elda/settings/creator/list";
      const params = {
        companyId: this.$store.state.companyId,
      };
      this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
      })
        .then((response) => {
          let data = response.data;

          console.log(data);

          if (data !== undefined) {
            view.eldaCreatorList = JSON.parse(JSON.stringify(data));
          } else {
            view.eldaCreatorList = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadCountryEnum: function () {
      let view = this;
      let url = "/api/enum/country";

      this.axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          view.enumCountry = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addInsurer() {
      let view = this;

      let newItem = { insurer: "", contribution_accountnumber: "" };
      // add random negative id to distinguish between old and new entries.
      // new entries will be deleted without backend call, while old entries need to be deleted in the backend as well.
      newItem.id = Math.floor(Math.random() * 9999) - 10000;
      view.inputEldaEmployerInsurer.push(newItem);
    },
    saveInsurer(item) {
      let view = this;
      let url = "/api/sec/elda/settings/insurer";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/elda/settings/insurer"
      );

      if (item.insurer === "" || item.contribution_accountnumber === "") {
        view.inputEldaEmployerInsurer = view.inputEldaEmployerInsurer.filter(
          (insurer) => {
            return insurer.id !== item.id;
          }
        );
        view.$helpers.info(
          "Information",
          "Unvollständig hinzugefügter Versicherungsträger wurde vor dem Speichern entfernt."
        );
        return;
      }

      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        data: item,
      })
        .then((response) => {
          view.eldaEmployerInsurer = view.eldaEmployerInsurer.filter((insurer) => {
            return insurer.id !== item.id;
          });
          view.eldaEmployerInsurer.push({ ...response.data });
          view.inputEldaEmployerInsurer = view.inputEldaEmployerInsurer.filter(
            (insurer) => {
              return insurer.id !== item.id;
            }
          );
          view.inputEldaEmployerInsurer.push({ ...response.data });
          view.$helpers.success("Information", "Versicherungsträger wurde hinzugefügt.");
        })
        .catch(function (error) {
          console.log(error);
          view.$helpers.error(
            "Information",
            "Fehler beim Hinzufügen des Versicherungsträgers."
          );
        });
    },
    deleteInsurer(id) {
      let view = this;

      if (id < 0) {
        view.inputEldaEmployerInsurer = view.inputEldaEmployerInsurer.filter(
          (insurer) => {
            return insurer.id !== id;
          }
        );
        return;
      }

      let url = "/api/sec/elda/settings/insurer";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/elda/settings/insurer"
      );
      url = this.$helpers.appendParam("id", id, url, "/api/sec/elda/settings/insurer");

      this.axios({
        method: "delete",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      })
        .then((response) => {
          view.eldaEmployerInsurer = view.eldaEmployerInsurer.filter((insurer) => {
            return insurer.id !== id;
          });
          view.inputEldaEmployerInsurer = view.inputEldaEmployerInsurer.filter(
            (insurer) => {
              return insurer.id !== id;
            }
          );
          view.$helpers.success("Information", "Versicherungsträger wurde gelöscht.");
        })
        .catch(function (error) {
          console.log(error);
          view.$helpers.error(
            "Information",
            "Fehler beim Löschen des Versicherungsträgers."
          );
        });
    },

    addCreator() {
      let view = this;

      let newItem = {
        elda_serial_number: "",
        elda_password_sha512: "",
        periodic_clearing_check: false,
        name: "",
        country: "",
        postal: "",
        city: "",
        street: "",
        phone: "",
        email: "",
      };
      // add random negative id to distinguish between old and new entries.
      // new entries will be deleted without backend call, while old entries need to be deleted in the backend as well.
      newItem.id = Math.floor(Math.random() * 9999) - 10000;
      view.inputEldaCreator.push(newItem);
    },
    saveNewCreator(item) {
      let view = this;
      let url = "/api/sec/elda/settings/creator";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/elda/settings/creator"
      );

      let exists = false;
      view.eldaCreatorList.forEach((creator) => {
        if (creator.elda_serial_number === item.elda_serial_number) {
          view.eldaCreatorTempId = creator.id;
          view.$refs["modal-eldanumber-exists"].show();
          view.resetEldaInputs();
          exists = true;
        }
      });
      if (exists) {
        return;
      }

      if (
        item.elda_serial_number === "" ||
        item.elda_password === "" ||
        item.name === "" ||
        item.country === "" ||
        item.postal === "" ||
        item.city === "" ||
        item.street === ""
      ) {
        view.inputEldaCreator = view.inputEldaCreator.filter((creator) => {
          return creator.id !== item.id;
        });
        view.$helpers.info(
          "Information",
          "Unvollständig ausgefüllter Hersteller wurde vor dem Speichern entfernt."
        );
        return;
      }

      view.sha512(item.elda_password).then((result) => {
        item.elda_password_sha512 = result;
        item.elda_password = "";

        this.axios({
          method: "post",
          headers: {
            AUTHORIZATION: "Bearer " + this.$store.state.jwt,
          },
          url: url,
          data: item,
        })
          .then((response) => {
            view.eldaCreator = view.eldaCreator.filter((creator) => {
              return creator.id !== item.id;
            });
            view.eldaCreator.push(response.data.data);
            view.inputEldaCreator = view.inputEldaCreator.filter((creator) => {
              return creator.id !== item.id;
            });
            view.inputEldaCreator.push(response.data.data);
            view.$helpers.success("Information", "Hersteller wurde hinzugefügt.");
          })
          .catch(function (error) {
            console.log(error);
            view.$helpers.error("Information", "Fehler beim Hinzufügen des Herstellers.");
          });
      });
    },
    updateCreator(item) {
      let view = this;
      let url = "/api/sec/elda/settings/creator";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/elda/settings/creator"
      );

      view
        .sha512(item.elda_password)
        .then((result) => {
          item.elda_password_sha512 = result;
          item.elda_password = "";

          let original = view.eldaCreator.find((org) => item.id === org.id);
          original.elda_password = "";
          if (JSON.stringify(item) !== JSON.stringify(original)) {
            this.axios({
              method: "put",
              headers: {
                AUTHORIZATION: "Bearer " + this.$store.state.jwt,
              },
              url: url,
              data: item,
            })
              .then((response) => {
                view.eldaCreator = view.eldaCreator.filter((creator) => {
                  return creator.id !== item.id;
                });
                view.eldaCreator.push({ ...response.data });
                view.inputEldaCreator = view.inputEldaCreator.filter((creator) => {
                  return creator.id !== item.id;
                });
                view.inputEldaCreator.push({ ...response.data });
                view.$helpers.success("Information", "Hersteller wurde aktalisiert.");
              })
              .catch(function (error) {
                console.log(error);
                view.$helpers.error(
                  "Information",
                  "Fehler beim aktualiseren des Herstellers."
                );
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteCreator(id) {
      let view = this;

      if (id < 0) {
        view.inputEldaCreator = view.inputEldaCreator.filter((creator) => {
          return creator.id !== id;
        });
        return;
      }

      let url = "/api/sec/elda/settings/creator";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/elda/settings/creator"
      );
      url = this.$helpers.appendParam("id", id, url, "/api/sec/elda/settings/creator");

      this.axios({
        method: "delete",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      })
        .then((response) => {
          view.eldaCreator = view.eldaCreator.filter((creator) => {
            return creator.id !== id;
          });
          view.inputEldaCreator = view.inputEldaCreator.filter((creator) => {
            return creator.id !== id;
          });
          view.$helpers.success("Information", "Hersteller wurde gelöscht.");
        })
        .catch(function (error) {
          console.log(error);
          view.$helpers.error("Information", "Fehler beim Löschen des Herstellers.");
        });
    },
    linkTempCreatorToStore(id) {
      let view = this;
      let url = "/api/sec/elda/settings/creator/link";

      let payload = {
        companyId: this.$store.state.companyId,
        creatorId: view.eldaCreatorTempId,
      };

      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          this.refresh();
        })
        .catch(function (error) {
          console.log(error);
          view.$helpers.error("Information", "Fehler beim Hinzufügen des Herstellers.");
        });
    },

    loadListForNewEmployeeNotification: function () {
      let view = this;
      let url = "/api/sec/notification/email/newemployeeList";
      const params = {
        companyId: this.$store.state.companyId,
      };
      this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
      })
        .then((response) => {
          view.emailsForNewEmployeeNotifications = response.data.data;
        })
        .catch(function (error) {});
    },
    saveEmailForNewEmployeeNotification: function () {
      let view = this;
      let url = "/api/sec/notification/email/newemployeeList";
      const params = {
        companyId: this.$store.state.companyId,
      };
      let payload = {
        email: view.newEmailForNotification,
      };
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
        data: payload,
      })
        .then((response) => {
          view.newEmailForNotification = "";
          view.$helpers.success("Information", "Email Adresse wurde hinzugefügt");
          view.loadListForNewEmployeeNotification();
        })
        .catch(function (error) {
          view.$helpers.error("Information", "Fehler beim Hinzufügen der Email Adresse");
        });
    },
    deleteEmailForNewEmployeeNotification: function (id) {
      let view = this;
      let url = "/api/sec/notification/email/newemployeeList";
      const params = {
        companyId: this.$store.state.companyId,
      };
      let payload = {
        id: id,
      };
      this.axios({
        method: "delete",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        params,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Information", "Email Adresse wurde gelöscht");
          view.loadListForNewEmployeeNotification();
        })
        .catch(function (error) {
          view.$helpers.error("Information", "Fehler beim Löschen der Email Adresse");
        });
    },

    updateCompanySettings: function (response) {
      let view = this;
      view.hasAccessRights = true;

      if (response.data.length == 1) {
        view.companyData = response.data[0];
        view.inputSoftwareId = view.companyData.softwareId;

        view.internalId = view.companyData.id;
        view.storeName = view.companyData.name;
        view.storeDescription = view.companyData.description;
        view.bmdId = view.companyData.bmdId;
        view.dpwId = view.companyData.dpwId;
        view.companyNumber = view.companyData.companyNumber;
        view.socialInsuranceRegistrationMode = view.companyData.socialInsuranceRegistrationMode;
        view.employeeChangedFromMDMode = view.companyData.employeeChangedFromMDMode;

        view.inputBmdId = view.bmdId;
        view.inputDpwId = view.dpwId;
        view.inputCompanyNumber = view.companyNumber;
        view.inputSocialInsuranceRegistrationMode = view.socialInsuranceRegistrationMode;
        view.inputEmployeeChangedFromMDMode = view.employeeChangedFromMDMode;
      }
    },

    save: function (key) {
      let view = this;
      view.saves = true;

      let payload = {
        companyId: this.$store.state.companyId,
        bmdId: this.inputBmdId,
        dpwId: this.inputDpwId,
        companyNumber: this.inputCompanyNumber,
        softwareId: this.inputSoftwareId,
        socialInsuranceRegistrationMode: this.inputSocialInsuranceRegistrationMode,
        employeeChangedFromMDMode: this.inputEmployeeChangedFromMDMode,
      };
      payload["stores"] = [];
      for (let i = 0; i < view.storesData.length; i++) {
        payload["stores"].push({
          storeId: view.storesData[i].id,
          bmdId: view.storesData[i].inputBmdId,
          dpwId: view.storesData[i].inputDpwId,
          costCenter: view.storesData[i].inputCostCenter,
          storeNumber: view.storesData[i].inputStoreNumber,
          isWebcockpitExportEnabled: view.storesData[i].isWebcockpitExportEnabled,
        });
      }

      let url = "/api/company";

      this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.bmdId = view.inputBmdId;
          view.dpwId = view.inputDpwId;
          view.companyNumber = view.inputCompanyNumber;
          view.companyData.softwareId = view.inputSoftwareId;
          view.socialInsuranceRegistrationMode = view.inputSocialInsuranceRegistrationMode;
          view.employeeChangedFromMDMode = view.inputEmployeeChangedFromMDMode;

          for (let i = 0; i < view.storesData.length; i++) {
            view.storesData[i].bmdId = view.storesData[i].inputBmdId;
            view.storesData[i].dpwId = view.storesData[i].inputDpwId;
            view.storesData[i].costCenter = view.storesData[i].inputCostCenter;
            view.storesData[i].storeNumber = view.storesData[i].inputStoreNumber;
          }
        })
        .then((response) => {
          view.saveEldaCreatorSettings();
          if (view.inputEldaEmployerName != null && view.inputEldaEmployerName !== "") {
            view.saveEldaEmployerSettings();
          }
        }).then((response) => {
          view.editieren = false;
          view.saves = false;
          view.$helpers.success(
            "Speichern erfolgreich",
            "Daten wurde erfolgreich gespeichert"
          );
        })
        .catch(function (error) {
          console.log(error);
          view.saves = false;
          view.$helpers.error(
            "Speichern fehlgeschlagen",
            "Fehler beim Speichern der Daten"
          );
          /*
          view.deletingPref = false;
          view.$helpers.error('Löschen fehlgeschlagen', 'Fehler beim Löschen der Abwesenheit');
        */
        });
    },
    saveEldaEmployerSettings: function () {
      let view = this;
      let payload = {
        companyId: this.$store.state.companyId,
        name: view.inputEldaEmployerName,
        phone_number: view.inputEldaEmployerPhoneNumber,
        email: view.inputEldaEmployerEmail,
        insurer: view.inputEldaEmployerInsurer,
      };

      // update elda settings
      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: "/api/sec/elda/settings/employer",
        data: payload,
      }).then((response) => {
        let newInsurer = view.inputEldaEmployerInsurer.filter((insurer) => {
          return insurer.id < 0;
        });
        newInsurer.forEach((insurer) => {
          view.saveInsurer(insurer);
        });

        view.eldaEmployerName = view.inputEldaEmployerName;
        view.eldaEmployerPhoneNumber = view.inputEldaEmployerPhoneNumber;
        view.eldaEmployerEmail = view.inputEldaEmployerEmail;
        view.eldaEmployerInsurer = JSON.parse(
          JSON.stringify(view.inputEldaEmployerInsurer)
        );

        view.editieren = false;
      });
    },
    saveEldaCreatorSettings: function () {
      let view = this;

      let existingCreators = view.inputEldaCreator.filter((creator) => {
        return creator.id > 0;
      });
      existingCreators.forEach((creator) => {
        view.updateCreator(creator);
      });

      let newCreators = view.inputEldaCreator.filter((creator) => {
        return creator.id < 0;
      });
      newCreators.forEach((creator) => {
        view.saveNewCreator(creator);
      });
    },

    closeEldaNumberExistsModal() {
      this.$refs["modal-eldanumber-exists"].hide();
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.xColumn3 {
  width: 31%;
  text-align: left;
  float: left;
  margin-left: 1%;
  margin-right: 1%;
  overflow-y: auto;
}

.xColumn3 > .header {
  display: inline-block;
  width: 100%;
  color: #e51550;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  text-align: left;
  padding: 7px;
}

.entryBox {
  font-size: 13px;
  text-align: left;
  padding: 3px 7px;
  display: flex;
  align-items: center;
}

.entryBox > .entryHeader {
  display: inline-block;
  width: 50%;
  float: left;
  color: #555;
  font-weight: 700;
}

.entryBox > .entryHeaderLight{
  display: inline-block;
  width: 50%;
  float: left;
  color: #aaa;
  font-weight: 200;
}

.entryBox > .entryData {
  display: inline-block;
  width: 50%;
  float: right;
  color: #aaa;
  font-weight: 200;
}

.btn-ml {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}

.btn-ml:hover {
  color: #fff;
  background-color: #cc1347;
  border-color: #cc1347;
}
</style>
