<template>
  <div class="app has-header">

    <!-- main view -->
    <template v-if="!loading">
      <div class="header main">
        <div class="align-left">
          <strong v-if="title">{{ title }}</strong>
          <slot name="header-left"></slot>
        </div>
        <div class="align-middle">
          <headerbuttonstack :state="headerButtons || []"/>
          <slot name="header-middle"></slot>
        </div>
        <div class="align-right">
          <div class="small-layout">
            <headerbuttonstack :state="headerButtons || []"/>
            <slot name="header-middle"></slot>
          </div>
          <slot name="header-right"></slot>
        </div>
      </div>

      <div class="report-page-container">
        <div class="report-page-grid">
          <div class="section employees-container" v-if="$slots['employee-list']">
            <slot name="employee-list"></slot>
          </div>
          <div class="section settings-container" v-if="$slots['report-settings']">
            <slot name="report-settings"></slot>
          </div>
          <div class="section reports-container" v-if="$slots['report-list']">
            <slot name="report-list"></slot>
          </div>
          <slot></slot>
        </div>
        <div class="section button-container" :class="[!$slots['button-bar'] ? 'button-bar-hidden' : '']">
          <div class="button-bar">
            <slot name="button-bar"></slot>
          </div>
        </div>
      </div>
    </template>

    <div>
      <div v-if="loading" class="loading-md bg">
          <i class="fas fa-spinner"></i>
          <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">
                  {{ loadingText }}
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'md-report-layout',
  components: {
  },
  props: {
    title: String,
    headerButtons: Object,
    loading: {
      type: Boolean,
      default: () => false,
    },
    loadingText: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style scoped>
.report-page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - var(--header-height) - 48px);
  /*noinspection CssOverwrittenProperties*/
  /* padding-top: 60px; */
  /*noinspection CssOverwrittenProperties*/
  /* padding-top: var(--header-height); */
  overflow-y: auto;
}

.report-page-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
  width: 100%;
  max-width: 2048px;
  height: 100%;
  padding: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.employees-container {
  grid-area: 1 / 1 / 3 / 5; /* spans from 1st column to 4th (5th line) and from row 1 to 2 (line 1 to 3) */
}

.settings-container {
  grid-area: 1 / 5 / 2 / 13;
  max-height: 100%;
  min-height: 15rem;
  overflow-y: auto;
}

.reports-container {
  grid-area: 2 / 5 / 3 / 13;
}

.button-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100px;
  transition: max-height 0.125s ease-in-out;
  background-color: var(--contrast-1);
}

.button-bar-hidden {
  max-height: 0;
}

.button-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  max-width: 2048px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
}

.small-layout {
  display: none;
}

@media screen and (max-width: 512px) {
  .report-page-container {
    overflow-x: hidden;
  }

  .report-page-grid {
    grid-template-columns: repeat(4, 1fr);
    height: unset;
  }

  .employees-container {
    grid-area: 1 / 1 / 2 / 5;
  }

  .settings-container {
    grid-area: 2 / 1 / 3 / 5;
    height: 100%;
  }

  .reports-container {
    grid-area: 3 / 1 / 4 / 5;
  }

  .align-middle {
    display: none;
  }

  .small-layout {
    display: block;
  }
}
</style>
