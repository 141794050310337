<template><div></div></template>

<script>
export default {
  name: 'accountPreferences',
  data () {
    return {
      preference: {
          dynamicFilterEnabled: true,
          },
    }
  },
  created: function () {
  },
  computed: {
        loggedIn() {
            return this.$store.state.loggedIn;
        }
  },
  watch: {
        loggedIn(newVal, oldVal){
            if(newVal){
                //this.loadAccountPreferences();
            }
        }
  },
  methods: {
      isSet:function (val){
        if(val != undefined && val != null && val != "") return true;
        return false;
      },
    loadAccountPreferences : function() {
        console.log("called loadAccountPreferences");
        let view = this;
        let url = '/api/sec/accountpreference?storeId='+this.$store.state.storeId;
                return this.axios({
                    method: 'get',
                    headers: {
                        'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                    },
                    url: url
                }).then(response => {
                    return new Promise((resolve, reject) => {
                        if(response.status == 200 && response.data.length > 0){
                            //view.$store.commit('setStoreId', response.data);
                            let remotePreference = response.data[0].preference;

                            // Dynamic Filter Enabled ?
                            if(view.isSet(remotePreference.dynamicFilterEnabled)){
                                view.preference.dynamicFilterEnabled = remotePreference.dynamicFilterEnabled;
                            }




                            //******************************* */
                            // ADD ADDITIONAL PARAMETERS ABOVE !
                            //******************************* */
                            this.$forceUpdate();
                            view.$store.commit('setAccountPreference', view.preference);
                        }
                        resolve("SUCCESS");
                    })   
                }).catch(function (error) {
                    return new Promise((resolve, reject) => {
                         reject(error)
                    })
                })
    },
    updateAccountPreference : function() {
        console.log("DO UPDATE! STILL TODO");
    }
  },
  components: {
  },
  
}
</script>

<style scoped>
</style>