<template>
  <div class="c-dropdown c-dropdown-container" v-bind:style="[{top: (posy + offsety) + 'px', left: (posx + offsetx) + 'px', display: toggled || staticElement ? 'inline-block' : 'none', opacity: show || staticElement ? 1 : 0, width: width, maxWidth: maxWidth, height: height}, styles]" v-bind:class="{'static-container': staticElement, maxw50: maxw50, tooltip: tooltip, 'centered': centered, 'list' : type === 'list', 'list-thick': listStyle, 'expanded' : expanded, scrolling: scroll, 'no-scrolling': noScroll, 'index-override': indexOverride, 'drag-container': draggable, locked: GetLockedStatus(), 'allow-overflow': allowOverflow, 'button-container': buttonContainer}">
    <div v-if="!staticElement" class="background" v-on:scroll="PreventScroll($event)" v-on:click="Toggle($event)"></div>
    <div class="container-body" v-bind:style="containerstyle">
      <div class="fa-stack cancel" v-on:click="Toggle(true)" v-if="!staticElement && (type !== 'list' && !disableCloseIcon)">
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fas fa-times fa-stack-2x"></i>
      </div>
      <div class="header" v-if="header && !(tabs.length > 0 && smallheader)">
        <div class="label" v-html="header"></div>
      </div>
      <div class="header tab-header" v-if="tabs.length > 0">
        <div class="nameheader" v-if="smallheader">
          <div class="label smallheader" >{{header}}</div>
        </div>
        <div class="label" v-for="(tab, indexT) in tabs" :key="'t' + indexT" v-bind:class="{selected: tabCurrent === tab}" v-on:click="ChangeTab(tab)">{{tab.tabHeader}}</div>
      </div>

      <div class="tab-body">
        <div v-if="componentState && type === 'list'" class="component items" v-bind:class="{'icons-left': iconsLeft}">
          <div class="item" v-for="(item, indexI) in componentState.entries" :key="indexI" v-bind:class="{'selected': item.selected, header: item.isHeader}" v-on:click="SelectItem($event, item)" v-bind:style="item.styles">
            <i v-if="item.toggled !== undefined && item.toggled" class="fas fa-check-circle checkbox true" v-bind:style="{color: item.color}"></i>
            <i v-if="item.toggled !== undefined && !item.toggled" class="fas fa-circle checkbox false" v-bind:style="{color: item.color}"></i>
            <div class="label" v-bind:style="{fontWeight: item.bold ? 'bold' : 'normal'}">{{item.label}}</div>
          </div>
        </div>
        <component class="component" v-if="components && components.length > 0 && component.type !== 'list'" v-for="(component, indexC) in components" :key="component.type + indexC" v-bind:is="component.type"
                   :state="component.state" v-bind="component.propContainer" ref="component"
                   v-on="component.customEvents"
                   @click.native="ClickComponent(component)"
                   @hook:mounted="ComponentMounted()"></component>
      </div>
    </div>
    <div class="pointer up" v-bind:style="{left: pointerOffset + 'px', display: !pointerHide && pointerDirection === 0 ? 'inline-block' : 'none'}"></div>
    <div class="pointer right" v-bind:style="{top: pointerOffset + 'px', display: !pointerHide && pointerDirection === 1 ? 'inline-block' : 'none'}"></div>
    <div class="pointer down" v-bind:style="{left: pointerOffset + 'px', display: !pointerHide && pointerDirection === 2 ? 'inline-block' : 'none'}"></div>
    <div class="pointer left" v-bind:style="{top: pointerOffset + 'px', display: !pointerHide && pointerDirection === 3 ? 'inline-block' : 'none'}"></div>
  </div>
</template>

<script>
import dropdownsearch from './dropdownsearch.vue'
import imagecrop from './imagecrop.vue'
import timebar from '../testroster/TimeBar.vue'
import datafill from './datafill.vue'
import datatable from './datatable.vue'
export default {
  name: 'dropdowncontainer',
  components: {
    dropdownsearch,
    imagecrop,
    datafill,
    timebar,
    datatable
  },
  data() {
    return {
      searchbarItems: {
        items: [],
        input: ''
      },
      el: null,
      elRelative: null,
      defaultSettings: {
        pointerDirection: 0,
        pointerOffset: 15,
      },
      selectedItem: {},
      items: [],
      toggled: false,
      posx: 0,
      posy: 0,
      offsetx: 0,
      offsety: 0,
      maxw50: false,
      remainAfterSelect: false,
      pointerDirection: -1,
      pointerOffset: 15,
      selectCallback: function ($event, item) {},
      parentCallback: function () {},
      currentComponent: '',
      mode: '',
      iconsLeft: false,
      expanded: false,
      eventProp: {},
      header: null,
      show: false,
      componentState: {},
      type: '',
      centered: false,
      width: '',
      maxWidth: '',
      height: '',
      scroll: false,
      noScroll: false,
      tabs: [],
      tabCurrent: null,
      customEventName: null,
      customEventHandler: null,
      tooltip: false,
      listStyle: false,
      pointerHide: false,
      indexOverride: false,
      draggable: false,
      callbackDrag: null,
      toggleCallback: null,
      propContainer: {},
      components: [],
      //components can either be like this
      //{type: 'datafill', state: {...}}
      //or
      //{type: 'labelc', propContainer: {text: '', icon-left: '', ...}}
      //propContainer passes each key as a prop to the component to avoid having to name everything state or have to specify props like eventProp
      //customEvents go in a customEvents object in the component where the key is the name and the value is the handler
      //{type: 'emotlist', customEvents: {'append' : this.AppendUnicode}}
      //click event has to go separate because it won't work if it's not click.native

      //These are used to pin the container to an element
      pinTopRight: false,
      pinTopLeft: false,
      pinBottomRight: false,
      pinBottomLeft: false,
      pinBottom: false,
      locked: false,
      allowOverflow: false,
      disableCloseIcon: false,
      staticElement: false,
      tooltipVisibilityWatch: false,
      smallheader: false,
      containerstyle: "",
      buttonContainer: false,
    }
  },
  created() {
  },
  mounted() {
    if(!this.staticElement) {
      if (!this.state) this.toggled = false;
    } else {
      this.toggled = true;
    }
    if(this.draggable){
      window.removeEventListener('mousemove', this.DragTrack);
      window.addEventListener('mousemove', this.DragTrack);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.Reposition);
    window.removeEventListener('mousemove', this.DragTrack);
    window.removeEventListener('mouseup', this.CallbackDrag);
  },
  watch: {
    state: {
      immediate: true,
      handler(newVal, oldVal) {
        //this.show = false;
        let view = this;
        if(!oldVal && newVal){
          window.addEventListener("resize", this.Reposition);
          if(!newVal.staticElement) {
            requestAnimationFrame(function () {
              if(newVal.staticElement) return;
              $(view.$el).appendTo('body');
            });
          } else {
            requestAnimationFrame(function () {
              view.toggled = true;
              view.show = true;
            });
          }
        }
        if(!newVal) {
          this.toggled = false;
          this.tooltipVisibilityWatch = false;
          return;
        }
        for (var x in newVal) this[x] = newVal[x];
        this.toggled = newVal.hasOwnProperty('toggled') ? newVal.toggled : true;
        if(!this.toggled) return;
        if(this.draggable){
          window.removeEventListener('mousemove', this.DragTrack);
          window.removeEventListener('mouseup', this.CallbackDrag);
          window.addEventListener('mousemove', this.DragTrack);
          window.addEventListener('mouseup', this.CallbackDrag);
          if(!this.state.offsetx) this.offsetx = 15;
          if(!this.state.offsety) this.offsety = 15;
        }
        if(this.state && this.state.tabs){
          let tabSelected = this.tabs[0];
          let tabFound = null;
          if(this.tabCurrent) tabFound = this.state.tabs.find(t => t.tabHeader === view.tabCurrent.tabHeader);
          this.ChangeTab(tabFound ? tabFound : tabSelected);
        } else {

          if (this.eventProp) {
            if (this.propContainer && !this.propContainer.eventProp) this.propContainer.eventProp = this.eventProp;
            if (!this.propContainer) this.propContainer = {eventProp: this.eventProp};
          }

          if (!newVal.components) {
            this.components = [];
            if (this.componentState) {
              let component = {
                type: this.type,
                state: this.componentState,
                propContainer: this.propContainer,
                customEvents: {},
              };
              if (this.customEventName) {
                component.customEvents[this.customEventName] = this.HandleCustomEvent;
              }
              this.components.push(component);
            }
          }
        }
        if(view.tooltip && !view.tooltipVisibilityWatch && view.elRelative) view.CheckTooltipRelativeElementState(view.elRelative);

        if(this.type === 'list') {
          requestAnimationFrame(function () {
            view.Reposition();
          });
        }
      }
    }
  },
  props: {
    dropdown: Object,
    state: Object,
    styles: Object,
  },
  methods: {
    CheckChildTooltip(container, element){
      if(element === null || element === document) return false;
      if(element === container) return true;
      return this.CheckChildTooltip(container, element.parentNode);
    },
    CheckTooltipRelativeElementState(element){
      let view = this;
      view.tooltipVisibilityWatch = true;
      if(!element) {
        this.toggled = false;
        this.header = '';
        this.type = '';
        this.componentState = null;
        this.tooltipVisibilityWatch = false;
        return;
      }
      setTimeout(function(){
        view.CheckTooltipParentVisiblity(view.elRelative);
        if(view.tooltipVisibilityWatch) view.CheckTooltipRelativeElementState(view.elRelative);
      }, 50);
    },
    CheckTooltipParentVisiblity(element){
      if(!element) {
        this.toggled = false;
        this.header = '';
        this.type = '';
        this.componentState = null;
        this.tooltipVisibilityWatch = false;
        return;
      }
      if(element.parentNode === document) return;
      if(!this.elRelative || element.style.display === 'none' || element.style.opacity === 0) {
        this.toggled = false;
        this.header = '';
        this.type = '';
        this.componentState = null;
        this.tooltipVisibilityWatch = false;
        return;
      }
      this.CheckTooltipParentVisiblity(element.parentNode);
    },
    GetLockedStatus(){
      if(Object.prototype.toString.call(this.locked) == '[object Function]') return this.locked(this);
      return this.locked;
    },
    Close(){
      if(this.staticElement) return;
      this.toggled = false;
      if(window.app.tooltipState && window.app.tooltipState.toggled && window.app.tooltipState.elRelative && this.CheckChildTooltip(this.$el, window.app.tooltipState.elRelative)) {
        window.app.tooltipState.toggled = false;
        window.app.tooltipState.header = '';
        window.app.tooltipState.type = '';
        window.app.tooltipState.componentState = null;
      }
      window.removeEventListener('mousemove', this.DragTrack);
      window.removeEventListener('mouseup', this.CallbackDrag);
      //if(this.toggleCallback) this.toggleCallback(this);
      this.$emit('on-close', this);
    },
    ClickComponent(component){
      if(component.click) {
        component.click(this, this.$children[this.components.indexOf(component)]);
      }
    },
    DragTrack($event){
      this.posx = window.mouse.x;
      this.posy = window.mouse.y;
    },
    CallbackDrag(){
      this.$emit('CallbackDrag', window.mouse.element, this);
      if(this.callbackDrag) this.callbackDrag(window.mouse.element, this);
      if(!this.remainAfterSelect) {
        this.$emit('on-close', this);
        this.toggled = false;
      } else {
        window.removeEventListener('mousemove', this.DragTrack);
        window.removeEventListener('mouseup', this.CallbackDrag);
        this.draggable = false;
      }
    },
    ChangeTab(tab){
      this.tabCurrent = tab;
      if(!tab.components) {
        this.components = [];
        if (tab.componentState)
        {
          let component = {
            type: tab.type,
            state: tab.componentState,
            propContainer: tab.propContainer,
            customEvents: {},
          };
          if (tab.customEventName) {
            component.customEvents[tab.customEventName] = tab.HandleCustomEvent;
          }
          this.components.push(component);
        }
      } else {
        this.components = tab.components;
      }
    },
    PreventScroll($event) {
      $event.preventDefault();
    },
    ComponentMounted(){
      this.Reposition();
      this.FocusInput();
    },
    FocusInput(selectAll){
      let view = this;
      requestAnimationFrame(function(){
        requestAnimationFrame(function(){
          if(view.$children.length === 0) return;
          let list = view.$children[0].$el.querySelectorAll('.data:not(.disabled)');
          list = Array.apply(null, list);
          if(list.length === 0) return;

          let input = list.find(l => !l.closest('.hidden') && !l.closest('disabled'));
          if(input) {
            if(input.querySelector('input')) input = input.querySelector('input');
            if(input.querySelector('button')) input = input.querySelector('button');
            input.focus();
            if(input.select) {
              if(selectAll) input.select();
              else
                requestAnimationFrame(function(){
                  requestAnimationFrame(function(){
                    requestAnimationFrame(function(){
                      input.selectionStart = input.selectionEnd = input.value.length;
                    });
                  });
                });
            }
          }
        });
      });
    },
    CheckWindowOverflow(){
      let view = this;
      requestAnimationFrame(() => {
        let box = this.$el.getBoundingClientRect();
        if(box.bottom > window.innerHeight){
          view.offsety = -5;
          view.posy = window.innerHeight - box.height;
        }
        if(box.top < 0){
          view.offsety = 5;
          view.posy = 0;
        }
        if(box.right > window.innerWidth){
          view.offsetx = -5;
          view.posx = window.innerWidth - box.width;
        }
        if(box.left < 0){
          view.offsetx = 5;
          view.posx = 0;
        }
      });
    },
    Reposition() {
      if(!this.$el || this.staticElement) return;
      var boxContainer = this.$el.getBoundingClientRect();
      var view = this;
      if (this.elRelative) {
        if(this.pinTopRight) {
          requestAnimationFrame(function () {
            let boxRelative = view.elRelative.getBoundingClientRect();
            view.posx = boxRelative.right + 1; // + 1 to account for border
            view.posy = boxRelative.top;
            view.CheckWindowOverflow();
            view.$forceUpdate();
          })
        } else if(this.pinTopLeft) {
          requestAnimationFrame(function () {
            let boxRelative = view.elRelative.getBoundingClientRect();
            boxContainer = view.$el.getBoundingClientRect();
            view.posx = boxRelative.left - 1 - boxContainer.width; // + 1 to account for border
            view.posy = boxRelative.top;
            view.CheckWindowOverflow();
            view.$forceUpdate();
          })
        } else if(this.pinBottomRight) {
          requestAnimationFrame(function () {
            let boxRelative = view.elRelative.getBoundingClientRect();
            boxContainer = view.$el.getBoundingClientRect();;
            view.posx = boxRelative.right + 1; // + 1 to account for border
            view.posy = boxRelative.bottom + boxContainer.height;
            view.CheckWindowOverflow();
            view.$forceUpdate();
          })
        } else if(this.pinBottomLeft) {
          requestAnimationFrame(function () {
            let boxRelative = view.elRelative.getBoundingClientRect();
            boxContainer = view.$el.getBoundingClientRect();
            view.posx = boxRelative.left - 1 - boxContainer.width; // + 1 to account for border
            view.posy = boxRelative.bottom + boxContainer.height;
            view.CheckWindowOverflow();
            view.$forceUpdate();
          })
        } else if(this.pinBottom) {
          requestAnimationFrame(function () {
            if(!view.elRelative) return;
            let boxRelative = view.elRelative.getBoundingClientRect();
            boxContainer = view.$el.getBoundingClientRect();
            view.posx = boxRelative.left - 1 + (boxRelative.width / 2) - (boxContainer.width / 2); // + 1 to account for border
            view.posy = boxRelative.bottom + 5;
            view.pointerDirection = 0;
            view.pointerOffset = (boxContainer.width / 2) - 7;
            view.offsetx = 0;
            view.pointerHide = false ;
            view.CheckWindowOverflow();
            view.$forceUpdate();
          })
        } else if(view.pointerDirection === 0 || view.pointerDirection === 1 || view.pointerDirection === 2 || view.pointerDirection === 3){
          requestAnimationFrame(function () {
            var boxRelative = view.elRelative.getBoundingClientRect();
            var boxPointer = null;
            boxContainer = view.$el.getBoundingClientRect();

            var widthTotal = window.innerWidth;
            var heightTotal = window.innerHeight;
            var posx = 0;
            var posy = 0;
            var widthContainer = boxContainer.width;
            var heightContainer = boxContainer.height;
            var offsetFromPointer = (boxContainer.width * .5); //15;
            view.pointerHide = false;
            if (view.pointerDirection === 0) {
              boxPointer = $(view.$el).find('.pointer.up')[0].getBoundingClientRect();
              view.pointerOffset = (boxContainer.width * .5) - (boxPointer.width * .5);
              posx = (boxRelative.left + (boxRelative.width * .5)) - (boxContainer.width * .5);
              var pointerPositionRelative = boxPointer.top - boxContainer.top;
              posy = boxRelative.bottom + (5);
            }
            if (view.pointerDirection === 1) {
              boxPointer = $(view.$el).find('.pointer.right')[0].getBoundingClientRect();
              posx = boxRelative.left - offsetFromPointer - boxContainer.width;
              var pointerPositionRelative = boxPointer.top - boxContainer.top;
              posy = boxRelative.top + (boxRelative.height / 2) - pointerPositionRelative;
            }
            if (view.pointerDirection === 2) {
              boxPointer = $(view.$el).find('.pointer.down')[0].getBoundingClientRect();
              posx = boxRelative.right + offsetFromPointer;
              var pointerPositionRelative = boxPointer.top - boxContainer.top;
              posy = boxRelative.top + (boxRelative.height / 2) - pointerPositionRelative;
            }
            if (view.pointerDirection === 3) {
              boxPointer = $(view.$el).find('.pointer.left')[0].getBoundingClientRect();
              posx = boxRelative.right + 15;
              if (posx + widthContainer > widthTotal) posx = widthTotal - widthContainer;
              var pointerPositionRelative = boxPointer.top - boxContainer.top;
              posy = (boxRelative.top + (boxRelative.height * .5)) - (boxContainer.height * 0.5) - ((boxPointer.top + (boxPointer.height * 1.5)) - (boxPointer.top + (boxContainer.height * 0.5)));
            }
            if (boxContainer.left + boxContainer.width + posx > widthTotal) {
              //console.log('too far right');
              posx = widthTotal - boxContainer.width - 5;
              view.pointerHide = true;
            }
            if (boxContainer.left + posx < 0) {

              //console.log('too far left');
              posx = 5;
              view.pointerHide = true;
            }
            if (boxContainer.top + boxContainer.height + posy > heightTotal) {
              //console.log('too far down');
              posy = heightTotal - boxContainer.height - 5;
              view.pointerHide = true;
            }
            if (boxContainer.left + posy < 0) {
              //console.log('too far up');
              posy = 5;
              view.pointerHide = true;
            }
            view.posx = posx;
            view.posy = posy;
            //view.pointerDirection = pointerDirection;
            view.$forceUpdate();
          });
        } else {
          if(!this.offsetx) this.offsetx = 0;
          if(!this.offsety) this.offsety = 0;
          this.posx = window.mouse.x;
          this.posx += this.pointerDirection === 3 ? -this.offsetx : this.offsetx;
          this.posy = window.mouse.y;
          this.posy += this.pointerDirection === 2 ? -this.offsety : this.offsety;

          if(this.pointerDirection === 3) this.posx -= this.$el.getBoundingClientRect().width;
          if(this.pointerDirection === 2) this.posy -= this.$el.getBoundingClientRect().height;
        }
      } else {
        if(!this.offsetx) this.offsetx = 10;
        if(!this.offsety) this.offsety = -10;
        this.posx = window.mouse.x;
        this.posx += this.pointerDirection === 3 ? -this.offsetx : this.offsetx;
        this.posy = window.mouse.y;
        this.posy += this.pointerDirection === 2 ? -this.offsety : this.offsety;

        if(this.pointerDirection === 3) this.posx -= this.$el.getBoundingClientRect().width;
        if(this.pointerDirection === 2) this.posy -= this.$el.getBoundingClientRect().height;
      }
      if (isNaN(this.posx) || isNaN(this.posy)) {
        this.posx = window.mouse.x + this.offsetx;
        this.posy = window.mouse.y + this.offsety;
      }
      if(this.$el.getBoundingClientRect().width + this.posx > window.innerWidth) this.posx = window.innerWidth - this.$el.getBoundingClientRect().width - 5;
      if(this.$el.getBoundingClientRect().height + this.posy > window.innerHeight) this.posy = window.innerHeight - this.$el.getBoundingClientRect().height - 5;
      if(this.posx < 5) this.posx = 5;
      if(this.posy < 5) this.posy = 5;
      requestAnimationFrame(function () {
        requestAnimationFrame(function () {
          view.show = true;
        });
      });
    },
    Init(config) {
      this.header = '';
      this.expanded = false;
      this.show = false;
      for (var x in this.defaultSettings) {
        this[x] = this.defaultSettings[x];
      }

      for (var y in config) {
        if (Object.keys(this).indexOf(y) > -1) this[y] = config[y];
      }
      var view = this;
      requestAnimationFrame(function () {
        if (config.currentComponent === 'datafill') view.$refs.component.Init(config.header, config.data, config.onceSelected, view);
        //view.Reposition();
      });

      this.items = config.items;
      this.Toggle();
    },
    SelectItem($event, item) {
      var event = $event;
      var dropdown = this;
      this.selectedItem = item;
      this.toggled = this.remainAfterSelect;
      if (this.selectCallback) {
        this.selectCallback($event, item, dropdown);
      }
      if (item.selectCallback) {
        item.selectCallback($event, item, dropdown);
      }
      this.$forceUpdate();
    },
    loadItems(itemsNew) {
      this.items = itemsNew;
    },
    HandleCustomEvent($event){

      if(this.customEventHandler){
        this.customEventHandler($event);
      }
      this.toggled = this.remainAfterSelect;
    },
    Toggle($event) {
      if(this.GetLockedStatus()) return;
      if ($event && $event.currentTarget) {
        if (window.mouse.x > this.$el.getBoundingClientRect().left && window.mouse.x < this.$el.getBoundingClientRect().right &&
          window.mouse.y > this.$el.getBoundingClientRect().top && window.mouse.y < this.$el.getBoundingClientRect().bottom) return;
      }
      var view = this;
      view.toggled = (!$event && view.remainAfterSelect) || !view.toggled;
      requestAnimationFrame(function () {
        if (view.elRelative) return;
        var box = view.$el.getBoundingClientRect();
        if (view.pointerDirection == 1) {
          if (view.pointerOffset > box.height - 20) view.pointerOffset = box.height - 20;
          if (view.pointerOffset < 20) view.pointerOffset = 20;
        } else if (view.pointerDirection == 3) {
          if (view.pointerOffset > box.height - 20) view.pointerOffset = box.height - 20;
          if (view.pointerOffset < 20) view.pointerOffset = 20;
        } else if (view.pointerDirection == 2) {
          if (view.pointerOffset > box.width - 20) view.pointerOffset = box.width - 20;
          if (view.pointerOffset < 20) view.pointerOffset = 20;
        } else {
          if (view.pointerOffset > box.width - 20) view.pointerOffset = box.width - 20;
          if (view.pointerOffset < 20) view.pointerOffset = 20;
        }
      });
      requestAnimationFrame(function () {
        if (view.componentState && view.componentState.type === 'timebar') {
          if (view.toggled) view.$refs.component.Init();
          else view.$refs.component.$refs.overlay.toggled = false;

        }
        if (view.componentState && view.componentState.type === 'datafill') {
          if (!view.toggled) view.$refs.component.entries = [];
        }
        if (!view.toggled) {
          if(view.toggleCallback) view.toggleCallback(view);
          view.$emit('on-close', view);
        }
      });
      if (this.parentCallback) this.parentCallback();
    },
  }
}
</script>

<style scoped>
.c-dropdown-container {
  z-index: 1000;
  max-height: 96%;
  box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.2), 0 0 0 1px var(--contrast-3);
  border-radius: 0;
  padding:0;
  max-height: calc(100vh - 30px);
  max-width: calc(100vh - 30px);
}

.c-dropdown-container.maxw50 {
  max-width: 50%;
}

.container-body{
  background: var(--bg);
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 20px);
}

.c-dropdown-container .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.c-dropdown-container>.header {
  width: 100%;
  height: 40px;
  display: block;
}

.c-dropdown-container.list {
  background: var(--bg);
}

.nightmode .dropdown-container {
  background: var(--sub-menu);
}

.c-dropdown .pointer {
  position: absolute;
  top: -7px;
  left: 225px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #959595;
}

.c-dropdown:not(.list) .pointer:before {
  content: '';
  position: absolute;
  top: 2px;
  left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ebebeb;
}

.c-dropdown .pointer.up {
  top: -7px;
  left: 15px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid var(--contrast-2);
}

.c-dropdown:not(.list) .pointer.up:before {
  top: 2px;
  left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
}

.c-dropdown .pointer.right {
  left: initial;
  right: -7px;
  right: -19px;
  top: 15px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--contrast-2);
}

.c-dropdown:not(.list) .pointer.right:before {
  left: initial;
  top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid white;
}

.c-dropdown .pointer.down {
  top: initial;
  border-bottom: none;
  bottom: -7px;
  left: 15px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid var(--contrast-2);
}

.c-dropdown:not(.list) .pointer.down:before {
  top: initial;
  border-bottom: none;
  bottom: 2px;
  left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}

.c-dropdown .pointer.left {
  left: -14px;
  top: 15px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid var(--contrast-2);
}

.c-dropdown:not(.list) .pointer.left:before {
  left: -4px;
  top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid white;
}

.item {
  position: relative;
  display: block;
  height: 20px;
  text-align: left;
  font-size: 11px;
  padding: 0 10px;
  cursor: pointer;
  -webkit-transition: .2s;
  transition: .2s;
  background: var(--bg);
  overflow: hidden;
  color: var(--contrast-4);
  align-items: center;
}

.container {
  overflow: hidden;
}

.item * {
  pointer-events: none;
  float: left;
  min-height: 15px;
  margin-top: -3px;
  overflow: hidden;
  line-height: 200%;
}

.item .fas {
  margin-right: 10px;
}

.item *:first-child {
  margin-top: 1px;
}

.item:hover,
.item.selected {
  background: var(--contrast-3);
}

.show {
  display: inline-block;
}

.checkbox {
  float: right;
  margin-left: 5px;
  top: 0;
  bottom: 0;
  margin-right: -5px !important;
  right: 5px;
  margin-top: 4px !important;
  min-height: initial;
  height: 12px;
  width: 12px;
  font-size: 12px;
  line-height: 100%;
  border-radius: 100px;
}

.icons-left .checkbox {
  float: left;
  margin-right: 5px !important;
  margin-left: -5px !important;
  left: 5px;
  right: initial;
}

.checkbox.true {
  color: #6d8d23;
}

.checkbox.false {
  color: rgba(0, 0, 0, 0);
  background: var(--contrast-2);
  box-shadow: none;
  border-radius: 100px;
}

.item:nth-child(even) .checkbox.false {
  background: var(--contrast-1);
}

.dropdown-search {
  width: 180px;
  margin: 0 10px;
}

.dropdown-search .items {
  width: 180px;
}

.c-dropdown-container.expanded .container-body {
  padding: 50px;
  background: var(--bg);
}

.nightmode .c-dropdown-container.expanded {
  background: var(--bg);
}

.expanded .item {
  margin-left: 35px;
  background-color: #6d8d23;
  padding: 4px 10px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  -webkit-transition: color 1000ms linear;
  transition: color 1000ms linear;
  height: 26px;
  margin-bottom: 5px;
  margin-left: 0;
  line-height: 100%;
}

.expanded .item .label {
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-left: 30px;
}

.expanded .item .checkbox {
  width: 18px;
  height: 18px;
  color: white;
  font-size: 18px;
  margin: 0 !important;
  /*background: rgb(255,255,255);
      background: radial-gradient(circle,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 60%, rgba(255,255,255,0) 100%);*/
}

.expanded .header {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  box-shadow: inset 0 -2px 0 -1px var(--contrast-3);
  width: 100%;
  /*padding-bottom: 10px;*/
}

.expanded .header.smallheader {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  box-shadow: inset 0 -2px 0 -1px var(--contrast-3);
  width: 100%;
  /*padding-bottom: 10px;*/
}

/*.header .label {*/
/*  padding-left: 30px;*/
/*}*/

.expanded .header .label {
  margin-right: 60px;
  font-family: DistrictProBold;
  padding: 0;
  font-size: 25px;
  margin-top: -3px;
}

.expanded .header .label.smallheader {
  font-size: 16px !important;
}

.item.header {
  background: none;
  text-align: left;
  pointer-events: none;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-weight: 600;
}

.item.header .label {
  margin-left: initial;
  line-height: 25px;
  font-weight: initial;

}

.fa-stack.cancel {
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 5px;
  font-size: 10px;
  transform: scale(1);
}

.fa-stack.cancel .fa-circle {
  color: transparent;
  font-size: 22px;
  transform: translateY(2px);
}

.fa-stack.cancel .fa-times {
  font-size: 16px;
  transform: translateY(5px);
  color: var(--contrast-3);
}

.fa-stack.cancel:hover .fa-circle {
  color: var(--contrast-3);
}

.fa-stack.cancel:hover .fa-times {
  color: white;
}

.c-dropdown.centered {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto;
  height: fit-content;
}

.c-dropdown.scrolling .component {
  overflow: auto;
  height: calc(100% - 66px);
}

.c-dropdown.no-scrolling{
  overflow: initial;
}

.c-dropdown.no-scrolling .container-body{
  overflow: initial;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  padding: 4px 4px 4px 0px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--bg);
  border: 0px none var(--bg);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bg);
}

::-webkit-scrollbar-thumb:active {
  background: var(--bg);
}

::-webkit-scrollbar-track {
  background: var(--bg);
  border: 0px none var(--bg);
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: var(--bg);
}

::-webkit-scrollbar-track:active {
  background: var(--bg);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.tab-body{

}

.tab-header{

}

.c-dropdown .container-body > .tab-header .label.smallheader{
  text-align: left;
  width: 100%;
  color: var(--contrast-1);
  background: var(--contrast-5);
  font-size: 14px !important;
  font-family: DistrictProBold;
  border: none;
  border-bottom: var(--contrast-4) 4px solid;
  padding: 3px 16px 0px 10px;
  height: 26px;
  margin: 0px 1px 4px 0;
  transition: .2s;
  cursor: default;
}

.c-dropdown .container-body > .tab-header .label{
  text-align: center;
  color: var(--contrast-4);
  font-size: 12px !important;
  font-family: DistrictProBook;
  border: var(--contrast-2) solid 1px;
  border-bottom: none;
  padding: 3px 10px;
  height: 25px;
  margin: 0 -1px 0 0;
  transition: .2s;
  cursor: pointer;
}

.c-dropdown .container-body > .tab-header .label:hover{
  background: var(--contrast-4);
  color: var(--contrast-1);
}

.c-dropdown .container-body > .tab-header .label.selected{
  background: #6c8d25;
  color: white;
}

.c-dropdown .container-body > .tab-header .label.smallheader:hover{
  /* background: #6c8d25;
  color: white; */
  background: var(--bg);
  color: var(--md);
  cursor: default;
}

.c-dropdown.index-override{
  z-index: 100000000;
}
.c-dropdown.tooltip{
  z-index: 100000001;
  border-radius: 0;
  pointer-events: none;
  padding: 5px 10px;
  overflow: hidden;
  overflow: visible;
}
.c-dropdown.tooltip .container-body {
  overflow: hidden;
  overflow: visible;
}
.c-dropdown.tooltip .container-body > .cancel{
  display: none;
}
.c-dropdown.tooltip .container-body > .header {
  height: initial;
  width: initial;
  padding: 0;
}
.c-dropdown.tooltip .container-body > .header > .label{
  font-size: 12px;
  font-weight: 700;
  color: var(--contrast-4);
  padding: 0;
}

.c-dropdown.list-thick{
  background: var(--bg);
  padding: 0;
  border-radius: 0;
}


.c-dropdown.list-thick .container-body > .cancel{
  display: none;
}


.c-dropdown.list .container-body{
  overflow-y: hidden;
}

.c-dropdown.drag-container{
  pointer-events: none;
}

.c-dropdown.drag-container .container-body{
  overflow: hidden;
}

.c-dropdown.locked{

}

.c-dropdown.locked .background{
  background: rgba(0,0,0,.5);
}

.c-dropdown.locked .fa-stack.cancel{
  display: none;
}

.c-dropdown.allow-overflow, .c-dropdown.allow-overflow .container-body{
  overflow: visible;
}

.c-dropdown.static-container{
  position: relative;
  display: inline-block;
  box-shadow: none;
  border: none;
  background: var(--contrast-2);
}
.c-dropdown.static-container .container-body{
  background: var(--contrast-2);
  overflow: visible;
  padding: 15px;
}

.c-dropdown.static-container .tab-header{
  position: absolute;
  top: -25px;
  left: 0;
}

.c-dropdown.static-container .tab-header .label{
  background: var(--contrast-6);
  height: 25px;
  font-size: 12px !important;
  display: inline-flex;
  align-items: center;
  margin: 0 2.5px;
}
.c-dropdown.static-container .tab-header .label:first-child{
  margin-left: 0;
}

.c-dropdown.static-container .tab-header .label.selected{
  background: var(--contrast-2);
  color: var(--contrast-4);
}

.c-dropdown.static-container .tab-header .label.selected{
  background: var(--contrast-2);
  color: var(--contrast-4);
}

.c-dropdown.button-container {
  padding: 10px;
}

.c-dropdown.button-container .tab-body {
  text-align: center;
}

.c-dropdown.button-container .container-body > .header > .label {
  width: calc(100% - 20px);
  padding-left: 20px;
  margin-left: 10px;
  box-shadow: 0 2px 0 -1px var(--contrast-2);
}


</style>
