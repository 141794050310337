<template>
  <div
    class="button"
    v-bind:class="[
      type,
      classes,
      {
        'loading-button': loading,
        selected: selected,
        disabled: IsDisabled,
        active: active,
        'focus-highlight': hover,
      },
    ]"
    v-bind:style="[styleButton, { display: InputHidden ? 'none' : '' }]"
    @click="Click($event)"
    v-tooltip="tooltip"
    @mousedown="MouseDown($event)"
  >
    <div
      class="icon fas"
      v-if="icon"
      v-bind:class="[icon]"
      v-bind:style="styleIcon"
    ></div>
    <div class="label" v-bind:style="[styleLabel]" v-if="label">{{ label }}</div>
    <!--<router-link class="router-link" v-if="link && !disabled" :to="link"></router-link>-->
    <div v-if="loading" class="fas fa-spinner loading-icon"></div>
    <button
      v-if="!InputHidden"
      v-bind:tabindex="IsDisabled ? -1 : ''"
      v-on:change="Click($event)"
      v-on:focus="Focus"
      v-on:blur="Blur"
    />
  </div>
</template>

<script>
export default {
  name: "buttonc",
  data() {
    return {
      hover: false,
    };
  },
  props: {
    label: [String, Number],
    icon: String,
    styleIcon: [String, Object],
    type: String,
    classes: String,
    link: Object,
    loading: Boolean,
    selected: Boolean,
    disabled: [Boolean, Function],
    active: Boolean,
    styleButton: Object,
    styleLabel: Object,
    hideInput: [Boolean, Function],
    tooltip: [Object, String, Function],
  },
  computed: {
    IsDisabled() {
      if (!this.$helpers.isFunction(this.disabled)) return this.disabled;
      return this.disabled(this);
    },
    InputHidden() {
      if (!this.$helpers.isFunction(this.hideInput)) return this.hideInput;
      return this.hideInput(this);
    },
  },
  methods: {
    Focus($event) {
      this.hover = true;
    },
    Blur($event) {
      this.hover = false;
    },
    Update($event) {
      if (this.link) this.$router.push(this.link);
      this.$emit("Update", $event);
    },
    Click($event) {
      let view = this;
      this.hover = false;
      requestAnimationFrame(() => {
        view.hover = false;
      });
      let input = this.$el.querySelector("input");
      if (input) input.focus();
      input = this.$el.querySelector("button");
      if (input) input.focus();
      if (this.link) this.$router.push(this.link);
      this.$emit("click", $event);
    },
    MouseDown($event) {
      this.$emit("mousedown", $event);
    },
  },
};
</script>

<style scoped>
.button {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  padding: 0 9px;
  margin: 0 5px;
  cursor: pointer;
}

.button.focus-highlight {
  filter: brightness(100%);
}
.button.block.focus-highlight {
  filter: brightness(120%);
}

.button.hover {
  filter: brightness(120%);
}

.button > *:not(.router-link) {
  pointer-events: none;
}
.button:not(.no-hover):hover {
  background: var(--contrast-4);
}
.button:not(.no-hover):hover .fas {
  color: var(--bg);
}

.button.green {
  background: #6c8d25;
}
.button.ml {
  background: var(--ml);
}

.button.selected {
  background: var(--ml);
}

.button.selected:hover {
  background: #fad0dc !important;
}

.button.green > *,
.button.selected > * {
  color: white;
}

.button.green:not(.no-hover):hover {
  background: #404040;
}

.button.selected:not(.no-hover):hover {
  background: #404040;
}

.button.active {
  background: var(--ml);
  color: white;
}

.button.active .label {
  color: white;
}

.button > * {
  align-self: center;
}

.button .label {
  margin: 0;
}

.button .label:nth-child(2) {
  margin-left: 4px;
}

.button .fas {
  position: relative;
  /*font-size: 17px;*/
  padding: 0;
  margin: 0;
  float: none;
  height: initial;
}

.button.square {
  width: 25px;
  height: 25px;
}

.button.rectangle {
  width: 50px;
}

.button.round {
  width: 25px;
  height: 25px;
  border-radius: 100px;
}

.button.round.small {
  width: 18px;
  height: 18px;
}

.button.no-border {
  border: none !important;
  box-shadow: none !important;
}

.button.round.small .fas {
  font-size: 12px;
}

.button.big {
  height: 30px;
  width: 30px;
}

.button.menu-shadow {
  box-shadow: 0 0 0 1.4px var(--contrast-5);
}

.button.big .fas {
  color: var(--contrast-5);
}

.button.big:not(.no-hover):hover .fas {
  color: white;
}

.button.big:not(.no-hover):hover {
  background: #e5154f;
}

.button .fa-refresh {
  font-size: 14px;
}
.button.big .fa-refresh {
  font-size: 18px;
}

.fa-refresh:before {
  content: "\f021";
}

.button.loading-button > .fas:not(.loading-icon),
.button.loading-button .label {
  display: none;
}

.loading-button .fa-spinner {
  font-size: 14px;
  color: #cccccc;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button.thin-label .label {
  font-family: DistrictProBook;
  font-weight: 200;
  font-size: 15px;
}

.button .fa-robot {
  font-size: 14px;
}

.button .fa-calendar-plus {
  font-size: 14px;
}

.fas.color-bg {
  color: var(--bg);
}

.button .fa-angle-down,
.button .fa-angle-up,
.button .fa-angle-left,
.button .fa-angle-right {
  font-size: 22px;
}

.button.small .fa-angle-down,
.button.small .fa-angle-up,
.button.small .fa-angle-left,
.button.small .fa-angle-right {
  font-size: 18px;
}

.button.block {
  height: 55px;
  width: 55px;
  border: none;
  box-shadow: none;
  background: var(--contrast-3);
  border-radius: 0;
  margin: 0;
}

.button.block:not(.no-hover):hover {
  background: var(--contrast-2);
}

.button.block.border-r::after {
  content: "";
  width: 1px;
  background: var(--contrast-1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 30px;
}

/* border-l type for buttons. same style as border-r, but on the next element. designed for dynamic buttons */
.button.block.border-l::after {
  content: "";
  width: 1px;
  background: var(--contrast-1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  margin: auto;
  height: 30px;
}

.button.block .fas {
  font-size: 20px;
}

.button.block .fas,
.button.block .label {
  color: var(--contrast-4);
  transform: scale(1.25);
}

.button.block.disabled {
  background: var(--contrast-3) !important;
  opacity: 1 !important;
}
.button.block.disabled .fas,
.button.block.disabled .label {
  color: #dbdbdb !important;
  opacity: 1 !important;
}

.button.block:not(.no-hover):hover .fas,
.button.block:not(.no-hover):hover .label {
  color: var(--contrast-5);
}

.button.red .fas {
  color: #e5154f;
}

.button.block.red {
  background: #e5154f;
}

.button.block.red:not(.no-hover):hover {
  background: #f15985;
}

.button.block.red .fas,
.button.block.red .label {
  color: #f15985;
  transform: scale(1.25);
}

.button.block.red:not(.no-hover):hover .fas,
.button.block.red:not(.no-hover):hover .label {
  color: #ffb3cb;
}

.button.blue .fas {
  color: #2f61ac;
}

.button.block.blue {
  background: #2f61ac;
}

.button.block.blue:not(.no-hover):hover {
  background: #4076c6;
}

.button.block.blue .fas,
.button.block.blue .label {
  color: #4076c6;
  transform: scale(1.25);
}

.button.block.blue:not(.no-hover):hover .fas,
.button.block.blue:not(.no-hover):hover .label {
  color: #5388d8;
}

.button.block.green {
  background: #6c8d25;
}

.button.block.green:not(.no-hover):hover {
  background: #99af65;
}

.button.block.green .fas,
.button.block.green .label {
  color: #99af65;
  transform: scale(1.25);
}

.button.block.green:not(.no-hover):hover .fas,
.button.block.green:not(.no-hover):hover .label {
  color: #ebffb3;
}
.button.block.orange {
  background: #e76e13;
}

.button.block.orange:not(.no-hover):hover {
  background: #ec9a5c;
}

.button.block.orange .fas,
.button.block.orange .label {
  color: #ec9a5c;
  transform: scale(1.25);
}

.button.block.orange:not(.no-hover):hover .fas,
.button.block.orange:not(.no-hover):hover .label {
  color: #ffe2cc;
}

.button.block.cyan {
  background: #1e9e9a;
}

.button.block.cyan:not(.no-hover):hover {
  background: #25dbd5;
}

.button.block.cyan .fas,
.button.block.cyan .label {
  color: #25dbd5;
  transform: scale(1.25);
}

.button.block.cyan:not(.no-hover):hover .fas,
.button.block.cyan:not(.no-hover):hover .label {
  color: #ebffb3;
}

.button.block.lightred {
  background: #d47b7b;
}

.button.block.lightred:not(.no-hover):hover {
  background: #f5a2a2;
}

.button.block.lightred .fas,
.button.block.lightred .label {
  color: #da9a9a;
  transform: scale(1.25);
}

.button.block.lightred:not(.no-hover):hover .fas,
.button.block.lightred:not(.no-hover):hover .label {
  color: #f7cccc;
}

.button.block.yellow {
  background: #e5c41d;
}

.button.block.yellow:not(.no-hover):hover {
  background: #e5c93b;
}

.button.block.yellow .fas,
.button.block.yellow .label {
  color: #ecd559;
  transform: scale(1.25);
}

.button.block.yellow:not(.no-hover):hover .fas,
.button.block.yellow:not(.no-hover):hover .label {
  color: #ecdb7d;
}

.button .fa-undo-alt {
  transform: scaleX(-1.35) scaleY(1.35) !important;
}

.label-center .label,
.label-corner .label {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
  height: fit-content;
  width: fit-content;
}

.label-center .label {
  font-size: 10px;
  top: -1px;
}

.button.block.label-center .label {
  top: -1px;
  font-family: DistrictProBold;
  font-size: 11px;
}

.label-corner .label {
  right: -40px;
  bottom: -35px;
  font-size: 9px;
  font-family: DistrictProThin;
}

.button.menu {
  background: transparent;
  border-radius: 0;
}

.button.menu:not(.no-hover):hover,
.button.menu:not(.no-hover).focus-highlight {
  background: var(--contrast-4);
}

.button.menu.active {
  background: var(--ml);
}

.button.no-background {
  background: transparent;
  border-radius: 0;
}

.button.no-background:not(.no-hover):hover {
  background: var(--contrast-4);
}

.button.no-background.active {
  background: var(--ml);
}

.button.square {
  border-radius: 0;
}

.label.space-content .button {
  margin: 0;
  padding: 0;
}

.button.smaller {
  font-size: 12px;
  width: 14px;
  height: 14px;
}

.label .button .label {
  transform: none; /* this is to prevent the offset applied to the button text to be applied to the buttons in label component icons */
  color: inherit; /* only applied to label buttons because otherwise breaks header */
}

.button.error .icon {
  color: var(--col-error) !important;
}
.button.warning .icon {
  color: var(--col-warning) !important;
}
.button.okay .icon {
  color: var(--col-ok) !important;
}

.button.input-error {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  background: none;
  height: fit-content;
  pointer-events: none;
}
.button.input-error {
  top: 1px;
  pointer-events: initial;
  cursor: help;
}
.button.input-error:hover {
  background: none;
}

.button.input-error .icon {
  font-size: 15px;
}

.button.no-background {
  background: none;
  border: none;
  box-shadow: none;
}

.button > button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.button.label-overlay,
.button.icon-overlay {
  background: none;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0;
}

.button.label-overlay:hover,
.button.icon-overlay:hover {
  background: none;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0;
}

.button.icon-overlay.allow-hover:hover .fas {
  color: var(--bg);
}

.button.icon-overlay.focus-highlight .fas,
.button.icon-overlay:hover .fas {
  color: var(--contrast-4);
}

.button.label-overlay .label {
  margin: 0;
}
.button.label-overlay.absolute,
.button.icon-overlay.absolute {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.button.checkbox {
}

.button.checkbox:hover,
.button.highlight-hover:hover {
  background: none;
  filter: brightness(120%);
}

.button.checkbox:hover .icon,
.button.highlight-hover:hover .icon {
  color: inherit;
}

.button.checkbox .fa-check-circle {
  color: #e51550;
}

.button.checkbox .fa-circle {
  color: var(--contrast-8);
}

.button.checkbox:hover .fa-circle,
.button.checkbox.focus-highlight .fa-circle {
  color: var(--contrast-4);
}
.button.accept .label,
.button.delete .label {
  font-size: 12px;
  font-family: DistrictProLight;
  color: white;
  font-weight: 400;
  transform: none;
  margin: 0 10px;
  font-size: 12px;
}

.button.accept {
  color: white;
}

.data-table .click-container.highlight-focus .fas,
.data-table .click-container:hover .fas {
  color: var(--contrast-4);
}

.button.landing-button {
  height: 80px;
  color: white;
  width: 130px;
  margin: 0px !important;
  background: #46535e;
  border: solid 1px #b2b2b2;
  box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.2);
}
.button.landing-button:hover {
  transform: scale(0.98);
  color: #46535e;
  background: #323a45;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
}

.button.landing-button .label {
  position: absolute;
  bottom: 6px;
  color: white;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 12px;
  font-family: DistrictProBook;
}

.button.landing-button .icon {
  position: absolute;
  bottom: 28px;
  left: 0;
  right: 0;
  margin: auto;
  top: initial;
  font-size: 40px;
  color: white !important;
}

.button.input-dummy {
  box-shadow: inset 0 0 0px 1px var(--contrast-5);
  border: none;
  padding: 0 5px;
  cursor: text;
  background: var(--contrast-3);
  color: var(--contrast-4);
  border-radius: 5px;
  font-size: 12px;
}

.button.input-dummy:hover {
  background: none;
}

.button.input-dummy:hover .label {
  color: inherit;
}

.button.input-dummy .label {
  font-size: inherit;
  font-family: DistrictProLight;
  text-align: inherit;
  font-weight: 200;
  display: inline-block;
  margin: initial;
  /*height: calc(100% - 10px);*/
  width: calc(100% - 10px);
}

.input-component .button.input-dummy {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.input-component .button.input-dummy .label {
  font-size: 13px;
  transform: inherit;
  white-space: nowrap;
  max-width: calc(100% - 5px);
  overflow: hidden;
}

.button.input-dummy.focus-highlight {
  background: green;
}

.input-component .button.input-dummy {
  box-shadow: none;
  border: none;
  background: none;
}

.input-component .button.focus-highlight.input-dummy {
  box-shadow: inset 0 -2px 0px -1px var(--contrast-5);
}
.input-component .button.focus-highlight.input-dummy .label {
  color: inherit;
}

.menu-bar .input-component .button {
  background: var(--contrast-3);
  padding: 4px 8px;
}
.menu-bar .input-component .button .label {
  font-family: DistrictProBook;
  font-size: 11px;
  font-weight: 600;
}

.button.datatable-icon {
  border-radius: 100px;
  height: 25px;
  width: 25px;
  background: var(--bg);
  justify-content: center;
  font-size: 12px;
}

.button.datatable-icon:hover {
  background: var(--contrast-4);
  color: var(--bg);
}

.button.placeholder .label {
  opacity: 0.3;
  font-family: DistrictProThin;
  font-size: 11px !important;
  padding: 2px;
  margin-top: 2px;
}

.checkbox-label .checkbox-component .icon {
  font-size: 15px;
}

.checkbox-component .fas {
  font-size: inherit;
}

.button.occupy-container {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
</style>
