import { mapGetters } from 'vuex';
import moment from 'moment';

/**
 * @typedef { import('md-components/dist/types/src/lib-components/reports/md-report-config.vue').ConfigOption } ConfigOption
 */

export const reportMixin = {
  data() {
    return {
      // loading states
      loading: true,
      loadingReports: true,
      loadingEmployees: true,
      creatingReport: false,

      // date range states
      rangeStart: this.$helpers.getCurrentMonthMomentTZ().startOf('month'),
      rangeEnd: this.$helpers.getCurrentMonthMomentTZ().endOf('month').startOf('day'),
      rangeMode: 'monthlyRange',

      // employee states
      employeeList: [],
      employeeMap: {},
      showOnlyActiveEmployees: false, // if true, loads employees based on rangeStart/rangeEnd

      // report state
      reports: [],

      // config states
      reportConfig: {},
      reportConfigOpts: {},
    };
  },
  created() {
    // console.log('Report Mixin on', this.$options.name);
    this.reportConfig = this.loadReportConfig();
  },
  computed: {
    ...mapGetters([ 'userInfo', 'exportSettingsMap', 'isSuperUser', 'permissions']),
    CheckEnableConfigEdit(){
      if(this.isSuperUser){
          return true;
      }
      // always return true for now
      return true;
      // let res = false;
      // Object.values(this.permissions).forEach(perms => {
      //     if(perms.map(perm => perm.id).includes(39) && perms.map(perm => perm.id).includes(40)) { //can edit report data
      //       res = true;
      //     }
      // })

      // return res;
    },
    CheckEnableConfigSpecialEdit(){
      if(this.isSuperUser){
          return true;
      }
      // always return true for now
      return true;
      // let res = false;
      // Object.values(this.permissions).forEach(perms => {
      //     if(perms.map(perm => perm.id).includes(7)) { //can edit storesetting data

      //       res = true;
      //     }
      // })

      // return res;
    },

    employees() {
      return ( this.employeeList || [] )
          .sort(this.compareEmployees)
          .map((employee) => {
            const emp = {
              ...employee,
              imageUrl: this.getImageUrl(employee),
            };
            if (employee.selected !== undefined)
              emp.isSelected = employee.selected;
            return emp;
          });
    },

    visibleEmployees() {
      return this.employees.filter(({ visible }) => visible);
    },

    selectedEmployees() {
      return this.employees.filter(({ selected }) => selected);
    },

    countEmployees() {
      return this.employees.length;
    },

    countSelectedEmployees() {
      return this.selectedEmployees.length;
    },

    selectedEmployeeIds() {
      return this.selectedEmployees.map(({ id }) => id);
    },

    /**
     * computes an array of
     * @returns ConfigOption[]
     */
    reportConfigData() {
      if (typeof this.reportConfig !== 'object') {
        this.reportConfig = JSON.parse(this.reportConfig);
      }
      return Object.values(this.reportConfigOpts).map((section) => ( {
        ...section,
        options: section.options.map((option) => ( {
          ...option,
          currentValue: option.key in this.reportConfig
              ? this.reportConfig[option.key]
              : this._getDefaultOptionValue(option),
        } )),
      } ));
    },

  },
  methods: {

    //
    // date selector helpers

    dateRangeChanged({ from, to, mode }) {
      this.rangeStart = moment(from);
      this.rangeEnd = moment(to).startOf('day');
      this.rangeMode = mode;
      if (this.getReportName !== undefined)
        this.updateReportConfig('reportname', typeof this.getReportName === 'function' ? this.getReportName() : this.getReportName());
      this.loadEmployees().catch(() => {
      });
    },

    //
    // employee helpers

    async loadEmployees(initAllSelected = true, from = null, to = null) {
      this.loadingEmployees = true;
      let response = {};
      if (this.showOnlyActiveEmployees) {
        response = await this.$helpers.GetActiveEmployeeMap(from || this.rangeStart, to || this.rangeEnd).catch((error) => {
          console.error('Error loading active employees', error);
          this.$helpers.error('Fehler', 'Fehler beim Laden der Mitarbeiter');
        });
      } else {
        response = await this.$helpers.GetEmployeeMap().catch((error) => {
          console.error('Error loading employees', error);
          this.$helpers.error('Fehler', 'Fehler beim Laden der Mitarbeiter');
        });
      }

      console.log("done loading employees");

      this.employeeList = Object.values(response)
        .filter((employee) => !employee.isHidden)
        .map((employee) => (
          initAllSelected
              ? { ...employee, selected: true, visible: true }
              : { ...employee, visible: true } ));
      this.employeeMap = {};
      this.employeeList.forEach(e => this.employeeMap[e.id] = e);

      this.loadingEmployees = false;
    },

    toggleShowOnlyActiveEmployees() {
      this.showOnlyActiveEmployees = !this.showOnlyActiveEmployees;
      this.loadEmployees().catch(() => {
      });
    },

    onEmployeeSelected(employee) {
      const index = this.employeeList.findIndex(({ id }) => id === employee.id);
      this.$set(this.employeeList, index, { ...employee, selected: !employee.selected });
    },

    selectAllEmployees(selected = true) {
      this.employeeList = this.employeeList.map((employee) => ( { ...employee, selected } ));
    },

    deselectAllEmployees() {
      this.selectAllEmployees(false);
    },

    getImageUrl(employee) {
      if (!employee.employeePicture_fileId) return undefined;
      return `${employee.employeePicture_server}/api/file?id=${employee.employeePicture_fileId}&accessToken=${employee.employeePicture_accessToken}`;
    },

    compareEmployees(a, b) {
      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
      return 0;
    },

    //
    // config helpers

    loadReportConfig(key = undefined) {
      if (!key)
        key = this.$options.name + 'ReportConfig';

      console.log(key);
      let es = Object.values(this.exportSettingsMap).find(s => s.type == 2 && s.name == key);
      if(es != null && es.setting != null){
        return es.setting;
      } else {
        const storedConfig = localStorage.getItem(key);
        if (!storedConfig) return {};
        try {
          return JSON.parse(storedConfig);
        } catch (e) {
          console.error('Could not load report config in ' + this.$options.name, e);
          return {};
        }
      }

    },

    storeReportConfig(config) {
      if(this.CheckEnableConfigEdit){
        this.reportConfig = config;

        const ignoredKeys = [ 'reportname', 'reportcomment' ];
        const reportConfig = Object.fromEntries(
          Object.entries(this.reportConfig).filter(([ key ]) => !ignoredKeys.includes(key)),
        );
        let key = this.$options.name + 'ReportConfig';
        let es = Object.values(this.exportSettingsMap).find(s => s.type == 2 && s.name == key);

        if(es != null){
          let fixedSetting = Object.fromEntries(
            Object.entries(es.setting).filter(([ key ]) => !ignoredKeys.includes(key)),
          );
          if(!this.deepEqual(fixedSetting, reportConfig)){

            es.setting = reportConfig;
            this.$helpers.UpdateExportSetting(es);
          }

        } else {

          let es = {name: key, type: 2, setting: reportConfig}
          this.$helpers.CreateExportSetting(es, 2);
        }
        //const jsonString = JSON.stringify(reportConfig);
        //localStorage.setItem(key, jsonString);
        localStorage.removeItem(key);

      }

    },

    deepEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {

        return false;
      }
      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
          areObjects && !this.deepEqual(val1, val2) ||
          !areObjects && val1 !== val2
        ) {
          console.log(key)
          return false;
        }
      }
      return true;
    },
    isObject(object) {
      return object != null && typeof object === 'object';
    },

    getReportConfigValue(keys, defaultVal = undefined) {
      let path = this.reportConfig;
      let key = keys;
      if (Array.isArray(keys)) {
        for (let i = 0; i < keys.length - 1; i++) {
          key = keys[i];
          path = path[key];
          if (path === undefined)
            return defaultVal;
        }
        key = keys.pop();
      }
      return path[key];
    },

    updateReportConfig(keys, value) {

        let path = this.reportConfig;
        if (typeof path !== 'object') {
          path = JSON.parse(path);
        }
        let key = keys;
        if (Array.isArray(keys)) {
          key = keys.pop(); // last element is the key to be updated
          for (const k of keys) {
            if (path[k] === undefined)
              this.$set(path, k, {});
            path = path[k];
          }
        }
        this.$set(path, key, value);


    },

    onReportConfigChange(config) {
      if (typeof config !== 'object') config = JSON.parse(config);
      this.storeReportConfig(config);

      this.$forceUpdate()
    },

    //
    // report config component helpers

    createReportConfigSection(title, expanded = false, disabled = false, key = undefined) {
      // the section key can be any random string (as long as it is unique), since it will not be saved in localstorage
      if (key === undefined)
        key = btoa(title);
      this.$set(this.reportConfigOpts, key, { title, key, expanded, disabled, options: [] });
      return key;
    },

    addReportConfigOption(sectionKey, optionKey, optionType, defaultVal = undefined, disabled = false, attributes = {}) {
      if (!this.reportConfigOpts[sectionKey]) return;

      const option = { key: optionKey, type: optionType, disabled, ...attributes, defaultVal };

      const options = this.reportConfigOpts[sectionKey].options;
      this.$set(options, options.length, option);
    },

    addTextConfigOption(sectionKey, optionKey, placeholder = 'Text eingeben', defaultVal = undefined, required = false, disabled = false) {
      this.addReportConfigOption(sectionKey, optionKey, 'text', defaultVal, disabled, { placeholder, required });
    },

    addSelectConfigOption(sectionKey, optionKey, values = [], multiSelect = false, defaultVal = undefined, disabled = false) {
      this.addReportConfigOption(sectionKey, optionKey, multiSelect ? 'multiple' : 'select', defaultVal, disabled, { values });
    },

    addSelectableValue(title, value, defaultVal = undefined, key = undefined) {
      if (key === undefined) key = value;
      return { title, key, value, default: defaultVal };
    },

    /**
     * gets a default value for the option
     * @param option
     * @returns {{[p: string]: any}|undefined|*|undefined}
     * @private
     */
    _getDefaultOptionValue(option = {}) {
      if (option.defaultVal !== undefined || !Array.isArray(option.values))
        return option.defaultVal;

      if (option.type === 'select') {
        // TODO: default to first element when not found ?
        const firstDefault = option.values.find(({ default: defVal }) => !!defVal);
        return firstDefault ? firstDefault.value : undefined;
      } else if (option.type === 'multiple') {
        return Object.fromEntries(option.values.map(({ key, default: defVal }) => [ key, defVal ]));
      }

      return undefined;
    },

    //
    // report helpers

    async loadReports(reportType = undefined) {
      this.loadingReports = true;
      const url = '/api/sec/report';

      const params = {
        // Replace rosterFromTo with a pagination solution (?)
        //from: this.rosterFromTo.from,
        //to: this.rosterFromTo.to
      }
      if (reportType !== undefined) {
        params.companyId = this.$store.state.companyId;
        params.reportType = reportType.toUpperCase();
      }

      try {
        const response = await this.axios({
          method: 'GET',
          url,
          params,
          headers: {
            'AUTHORIZATION': 'Bearer ' + this.$store.state.jwt,
          },
        });

        const reports = response.data.data
            .filter((report) => reportType === undefined || report.reportType.toUpperCase() === reportType.toUpperCase())
            .map((report) => ( {
              id: report.id,
              name: report.name,
              comment: ( report.description == null ) ? '' : report.description.substring(0, 32),
              format: report.config ? (report.config.fileformat ?? 'pdf') : 'pdf',
              creationDate: report.jobEnded ? this.$helpers.getMomentFromStringUTC(report.jobEnded, "YYYY-MM-DD HH:mm:ss").local().format('DD.MM.YYYY  HH:mm') : "",
              url: report.fileserverURL ? `${report.fileserverURL}/api/file?id=${report.fileserverID}&accessToken=${report.fileserverAccessToken}` : null,
              reportType: report.reportType,
              status: report.jobEnded ? 'Fertig' : report.jobFailed ? 'Fehlgeschlagen' : 'In Bearbeitung',
            } ));

        this.reports = reports;

        return reports;
      } catch (error) {
        console.error('Error loading reports', error);
        this.$helpers.error('Fehler', 'Fehler beim Laden der Reports');
      } finally {
        this.loadingReports = false;
      }

      return [];
    },

    async createReport(reportType, name, comment = null, config = {}, sensitive = false, additionalPayload = {}) {
      this.creatingReport = true;
      const url = '/api/sec/report/create?companyId=' + this.$store.state.companyId;

      const username = this.userInfo.firstname && this.userInfo.lastname
          ? this.userInfo.firstname + ' ' + this.userInfo.lastname
          : 'Anonymer Benutzer';

      const payload = {
        name: name,
        description: comment,
        reportType: reportType.toUpperCase(),
        sensitive,
        config: {
          companyId: parseInt(this.$store.state.companyId),
          reportType: reportType.toLowerCase(),
          username,
          ...config,
        },
        ...additionalPayload,
      };

      try {
        await this.axios({
          method: 'POST',
          headers: {
            'AUTHORIZATION': 'Bearer ' + this.$store.state.jwt,
          },
          url,
          data: payload,
        });
        this.$helpers.success('Erfolg', 'Der Report wurde erfolgreich erstellt');
      } catch (error) {
        console.error('Error creating report', error);
        this.$helpers.error('Fehler', 'Fehler beim Erstellen des Reports');
      } finally {
        this.creatingReport = false;
        await this.loadReports(reportType.toUpperCase());
      }
    },

    storeStartedReport(reportType, name, comment = null, config = {}, sensitive = false, additionalPayload = {}) {
      this.creatingReport = true;
      const url = '/api/sec/report/manual/create?companyId=' + this.$store.state.companyId;

      const username = this.userInfo.firstname && this.userInfo.lastname
          ? this.userInfo.firstname + ' ' + this.userInfo.lastname
          : 'Anonymer Benutzer';

      const payload = {
        name: name,
        description: comment,
        reportType: reportType.toUpperCase(),
        sensitive,
        config: {
          companyId: parseInt(this.$store.state.companyId),
          reportType: reportType.toLowerCase(),
          username,
          ...config,
        },
        ...additionalPayload,
      };

      return this.axios({
        method: 'POST',
        headers: {
          'AUTHORIZATION': 'Bearer ' + this.$store.state.jwt,
        },
        url,
        data: payload,
      })
      .catch((error) => {
        console.error('Error creating report', error);
        this.$helpers.error('Fehler', 'Fehler beim Erstellen des Reports');
      })
      .finally(() => {
        this.loadReports(reportType.toUpperCase());
      });
    },

    storeFinishedReport(reportType, reportId, file) {
      this.creatingReport = true;
      const url = '/api/sec/report/manual/finish?companyId=' + this.$store.state.companyId;

      let formData = new FormData();
      formData.append("reportId", reportId);
      formData.append("file", file);

      return this.axios({
        method: 'POST',
        headers: {
          'AUTHORIZATION': 'Bearer ' + this.$store.state.jwt,
        },
        url,
        data: formData,
      }).then(() => {
        this.$helpers.success('Erfolg', 'Der Report wurde erfolgreich erstellt');
      }).catch((error) => {
        console.error('Error creating report', error);
        this.$helpers.error('Fehler', 'Fehler beim Erstellen des Reports');
      }).finally(() => {
        this.creatingReport = false;
        this.loadReports(reportType.toUpperCase());
      });
    },

    async deleteReport(id) {
      const url = '/api/sec/report';
      const params = {
        companyId: this.$store.state.companyId, id: id,
      }

      try {
        await this.axios({
          method: 'DELETE', headers: {
            'AUTHORIZATION': 'Bearer ' + this.$store.state.jwt,
          }, url, params,
        });

        this.reports = this.reports.filter(r => r.id !== id);
        this.$helpers.success('Erfolg', 'Report wurde erfolgreich gelöscht');
      } catch {
        this.$helpers.error('Fehler', 'Fehler beim Löschen des Reports');
      }
    },

    openReport(report) {
      if (!report.url) {
        if (report.jobEnded) {
          this.$helpers.error('Fehler', 'Der Report konnte nicht korrekt erstellt werden. Bitte wenden Sie sich an den Support.');
        } else if (report.jobFailed) {
          this.$helpers.error('Fehler', 'Die Erstellung des Reports ist fehlgeschlagen. Bitte wenden Sie sich an den Support.');
        } else {
          this.$helpers.info('Info', 'Der Report wird noch erstellt. Bitte warten Sie einen Moment.');
        }
        return;
      }
      window.open(report.url, report.url);
    },

    downloadReport(report) {
      this.$helpers.DownloadFileFromFileServer(report.url, report.name, report.format);
    },

    openDeleteReportPopup(report) {
      window.app.popupalerts.push({
        header: 'Report löschen?',
        description: 'Soll der Report "' + report.name + '" wirklich gelöscht werden?',
        hidden: false,
        callbackAccept: (popup) => {
          popup.hidden = true;
          this.deleteReport(report.id).catch(console.error);
        },
        callbackCancel: (popup) => {
          popup.hidden = true;
        },
      });
    },

  },
};
