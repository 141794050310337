<template>
  <div class="has-header">
    <div class="header main">
      <div class="align-left">
        <dateselectionheader
          :label="dateDisplay"
          @PrevDate="modDate('sub')"
          @NextDate="modDate('add')"
        />
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right">
        <!--<headerbuttonstack :state="sideButtons" />-->
      </div>
    </div>
    <div class="scrollbar-container" style="overflow-x: hidden; overflox-y: auto">
      <div v-if="externalSoftware == 0">
        <br />

        <div
          style="width: 50%; margin: 0 auto"
          v-if="state == 'loadingEmployees'"
          class="alert alert-info"
          role="alert"
        >
          <i class="fas fa-circle-notch fa-spin"></i>
          <strong>Mitarbeiter werden geladen</strong>
        </div>

        <div
          style="width: 50%; margin: 0 auto"
          v-if="state == 'employeeLoadingError'"
          class="alert alert-danger"
          role="alert"
        >
          <strong>Error</strong><br />
          <strong>Mitarbeiterdaten konnten nicht geladen werden</strong><br /><br />
          <button v-on:click="refresh()" type="button" class="btn btn-secondary">
            Neu laden
          </button>
        </div>

        <div v-if="state == 'waitingForCSV'">
          <br />
          <h4>BMD Urlaubsstand, CSV-Datei hochladen</h4>
          <br />

          <div
            @click="showFilePicker"
            id="dropzone"
            style="text-align:center; background: var(--contrast-1; padding: 100px 0px;  border-radius: 25px; cursor: pointer;"
          >
            <i class="fal fa-4x fa-file-download" style="color: var(--contrast-5)"></i>
            <br /><br />
            <small style="color: var(--contrast-5); font-size: 11px"
              >Hier klicken oder CSV-Datei per Drag &amp; Drop ablegen</small
            >
            <input
              id="fileImportInput"
              type="file"
              multiple
              @change="filesSelected($event.target.files)"
              class="input-file"
            />
          </div>
        </div>

        <div
          style="width: 50%; margin: 0 auto"
          v-if="state == 'loadMonthlyStatements'"
          class="alert alert-info"
          role="alert"
        >
          <i class="fas fa-circle-notch fa-spin"></i>
          <strong>Monatsabschlüsse werden geladen</strong>
        </div>

        <div
          style="width: 50%; margin: 0 auto"
          v-if="state == 'errorMonthlyLoading'"
          class="alert alert-danger"
          role="alert"
        >
          <strong>Error</strong><br />
          <strong>Monatsabschlüsse konnten nicht geladen werden</strong><br /><br />
          <button v-on:click="refresh()" type="button" class="btn btn-secondary">
            Neu beginnen
          </button>
        </div>

        <div
          style="width: 50%; margin: 0 auto"
          v-if="state == 'computingData'"
          class="alert alert-info"
          role="alert"
        >
          <i class="fas fa-circle-notch fa-spin"></i>
          <strong>Daten werden verarbeitet.<br />Bitte etwas Geduld.</strong>
        </div>

        <div style="width: 100%; margin: 0 auto" v-if="state == 'complete'">
          <!-- ************** -->
          <!-- CONTENT BEGINS -->
          <!-- ************** -->

          <div class="row">
            <!-- Falsche Firmennummer -->
            <div class="col-6" v-if="malformedLines.length > 0">
              <div
                v-if="malformedLines.length > 0"
                style="width: 50%; margin-left: 10%"
                class="alert alert-danger"
                role="alert"
              >
                <strong>Zeilen konnten nicht verarbeitet werden</strong>

                <span
                  style="float: right; cursor: pointer"
                  v-on:click="toggleMalformedLines = !toggleMalformedLines"
                  ><i
                    v-if="toggleMalformedLines"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleMalformedLines"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ malformedLines.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 80%; margin: 0 auto"
                v-if="toggleMalformedLines && malformedLines.length > 0"
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">Zeile</th>
                    <th class="documentsTh">Zeilen Inhalt</th>
                  </tr>
                  <tr v-for="line in malformedLines" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">{{ line.line }}</td>
                  </tr>
                </table>
              </div>
              <hr v-if="toggleMalformedLines && malformedLines.length > 0" />
            </div>

            <!-- Falsche Firmennummer -->
            <div class="col-6" v-if="wrongCompanyIdLines.length > 0">
              <br v-if="wrongCompanyIdLines.length > 0" />
              <div
                v-if="wrongCompanyIdLines.length > 0"
                style="width: 90%; margin-left: 5%"
                class="alert alert-danger"
                role="alert"
              >
                <strong>Falsche Firmennummer</strong>
                <span
                  style="float: right; cursor: pointer"
                  v-on:click="toggleWrongCompanyLine = !toggleWrongCompanyLine"
                  ><i
                    v-if="toggleWrongCompanyLine"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleWrongCompanyLine"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ wrongCompanyIdLines.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 90%; margin: 0 auto"
                v-if="toggleWrongCompanyLine && wrongCompanyIdLines.length > 0"
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">Zeile</th>
                    <th class="documentsTh">Firmennummer</th>
                    <th class="documentsTh">Zeilen Inhalt</th>
                  </tr>
                  <tr v-for="line in wrongCompanyIdLines" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.companyId }}</strong>
                    </td>
                    <td class="documentsTd">{{ line.line }}</td>
                  </tr>
                </table>
              </div>
              <hr v-if="toggleWrongCompanyLine && wrongCompanyIdLines.length > 0" />
            </div>

            <!-- Mitarbeiter nicht gefunden -->
            <div class="col-6" v-if="employeeNotFoundLines.length > 0">
              <br v-if="employeeNotFoundLines.length > 0" />
              <div
                v-if="employeeNotFoundLines.length > 0"
                style="width: 90%; margin-left: 5%"
                class="alert alert-danger"
                role="alert"
              >
                <strong>Mitarbeiter nicht gefunden</strong>
                <span
                  style="float: right; cursor: pointer"
                  v-on:click="toggleNoEmployeeFound = !toggleNoEmployeeFound"
                  ><i
                    v-if="toggleNoEmployeeFound"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleNoEmployeeFound"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ employeeNotFoundLines.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 90%; margin: 0 auto"
                v-if="toggleNoEmployeeFound && employeeNotFoundLines.length > 0"
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">Zeile</th>
                    <th class="documentsTh">Mitarbeiter Id</th>
                    <th class="documentsTh">Mitarbeiter Name</th>
                    <th class="documentsTh">Zeilen Inhalt</th>
                  </tr>
                  <tr v-for="line in employeeNotFoundLines" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeId }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeName }}</strong>
                    </td>
                    <td class="documentsTd">{{ line.line }}</td>
                  </tr>
                </table>
              </div>
              <hr v-if="toggleNoEmployeeFound && employeeNotFoundLines.length > 0" />
            </div>

            <!-- Monatsabschluss nicht gefunden -->
            <div class="col-6" v-if="statementsEmptyLines.length > 0">
              <br v-if="statementsEmptyLines.length > 0" />
              <div
                v-if="statementsEmptyLines.length > 0"
                style="width: 90%; margin-left: 5%"
                class="alert alert-danger"
                role="alert"
              >
                <strong>Monatsabschluss nicht gefunden</strong
                ><span
                  style="float: right; cursor: pointer"
                  v-on:click="
                    toggleNoMonthlyFinancialStatementFound = !toggleNoMonthlyFinancialStatementFound
                  "
                  ><i
                    v-if="toggleNoMonthlyFinancialStatementFound"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleNoMonthlyFinancialStatementFound"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ statementsEmptyLines.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 90%; margin: 0 auto"
                v-if="
                  toggleNoMonthlyFinancialStatementFound &&
                  statementsEmptyLines.length > 0
                "
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">Zeile</th>
                    <th class="documentsTh">Mitarbeiter Id</th>
                    <th class="documentsTh">Mitarbeiter Name</th>
                    <th class="documentsTh">Zeilen Inhalt</th>
                  </tr>
                  <tr v-for="line in statementsEmptyLines" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeId }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeName }}</strong>
                    </td>
                    <td class="documentsTd">{{ line.line }}</td>
                  </tr>
                </table>
              </div>
              <hr
                v-if="
                  toggleNoMonthlyFinancialStatementFound &&
                  statementsEmptyLines.length > 0
                "
              />
            </div>

            <!-- Mehrere Monatsabschlüsse gefunden -->
            <div class="col-6" v-if="statementsMultipleLines.length > 0">
              <br v-if="statementsMultipleLines.length > 0" />
              <div
                v-if="statementsMultipleLines.length > 0"
                style="width: 90%; margin-left: 5%"
                class="alert alert-danger"
                role="alert"
              >
                <strong>Mehrere Monatsabschlüsse gefunden</strong
                ><span
                  style="float: right; cursor: pointer"
                  v-on:click="
                    toggleMultipleMonthlyFinancialStatementFound = !toggleMultipleMonthlyFinancialStatementFound
                  "
                  ><i
                    v-if="toggleMultipleMonthlyFinancialStatementFound"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleMultipleMonthlyFinancialStatementFound"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ statementsMultipleLines.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 90%; margin: 0 auto"
                v-if="
                  toggleMultipleMonthlyFinancialStatementFound &&
                  statementsMultipleLines.length > 0
                "
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">Zeile</th>
                    <th class="documentsTh">Mitarbeiter Id</th>
                    <th class="documentsTh">Mitarbeiter Name</th>
                    <th class="documentsTh">Zeilen Inhalt</th>
                  </tr>
                  <tr v-for="line in statementsMultipleLines" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeId }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeName }}</strong>
                    </td>
                    <td class="documentsTd">{{ line.line }}</td>
                  </tr>
                </table>
              </div>
              <hr
                v-if="
                  toggleMultipleMonthlyFinancialStatementFound &&
                  statementsMultipleLines.length > 0
                "
              />
            </div>
            <!-- Mitarbeiter im CSV nicht gefunden -->
            <div class="col-6" v-if="employeeNotFoundMD.length > 0">
              <br v-if="employeeNotFoundMD.length > 0" />
              <div
                v-if="employeeNotFoundMD.length > 0"
                style="width: 90%; margin-left: 5%"
                class="alert alert-danger"
                role="alert"
              >
                <strong>Mitarbeiter fehlen in der CSV Datei</strong
                ><span
                  style="float: right; cursor: pointer"
                  v-on:click="toggleEmployeeNotInCSV = !toggleEmployeeNotInCSV"
                  ><i
                    v-if="toggleEmployeeNotInCSV"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleEmployeeNotInCSV"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ employeeNotFoundMD.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 90%; margin: 0 auto"
                v-if="toggleEmployeeNotInCSV && employeeNotFoundMD.length > 0"
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">MD-ID</th>
                    <th class="documentsTh">Personalnummer</th>
                    <th class="documentsTh">Mitarbeiter Name</th>
                  </tr>
                    <tr v-for="line in employeeNotFoundMD" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.mdPersonnelId }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.lastName }}, {{ line.firstName }}</strong>
                    </td>
                  </tr>
                </table>
              </div>
              <hr v-if="toggleEmployeeNotInCSV && employeeNotFoundMD.length > 0" />
            </div>
          </div>
          <!-- row end -->
          <div class="row">
            <!-- Urlaubs Abweichung -->
            <div class="col-6" v-if="badLines.length > 0">
              <br v-if="badLines.length > 0" />
              <div
                v-if="badLines.length > 0"
                style="width: 90%; margin-left: 5%"
                class="alert alert-danger"
                role="alert"
              >
                <strong>Urlaubs Abweichung</strong
                ><span
                  style="float: right; cursor: pointer"
                  v-on:click="toggleBadHolidayLines = !toggleBadHolidayLines"
                  ><i
                    v-if="toggleBadHolidayLines"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleBadHolidayLines"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ badLines.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 90%; margin: 0 auto"
                v-if="toggleBadHolidayLines && badLines.length > 0"
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">Zeile</th>
                    <th class="documentsTh">Kostenstelle</th>
                    <th class="documentsTh">Mitarbeiter Id</th>
                    <th class="documentsTh">Mitarbeiter Name</th>
                    <th class="documentsTh">CSV-Urlaubsstand</th>
                    <th class="documentsTh">MD-Urlaubsstand</th>
                    <th class="documentsTh">Abweichung</th>
                  </tr>
                  <tr v-for="line in badLines" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.storeName }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeId }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeName }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.csvValue }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.mdValue }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong class="colorError">{{
                        Math.round(
                          (Math.abs(line.csvValue - line.mdValue) + Number.EPSILON) * 100
                        ) / 100
                      }}</strong>
                    </td>
                  </tr>
                </table>
              </div>
              <hr v-if="toggleBadHolidayLines && badLines.length > 0" />
            </div>
            <!-- Urlaubs MATCH -->
            <div class="col-6" v-if="goodLines.length > 0">
              <br v-if="goodLines.length > 0" />
              <div
                v-if="goodLines.length > 0"
                style="width: 90%; margin-left: 5%"
                class="alert alert-success"
                role="alert"
              >
                <strong>Urlaubs Übereinstimmung</strong
                ><span
                  style="float: right; cursor: pointer"
                  v-on:click="toggleGoodHolidayLines = !toggleGoodHolidayLines"
                  ><i
                    v-if="toggleGoodHolidayLines"
                    class="fa fa-caret-down"
                    style="cursor: pointer"
                  ></i
                  ><i
                    v-if="!toggleGoodHolidayLines"
                    class="fa fa-caret-up"
                    style="cursor: pointer"
                  ></i></span
                ><br />
                <small
                  ><em>{{ goodLines.length }} Einträge</em></small
                >
              </div>
              <div
                style="width: 90%; margin: 0 auto"
                v-if="toggleGoodHolidayLines && goodLines.length > 0"
              >
                <table class="table table-striped table-borderless">
                  <tr>
                    <th class="documentsTh">Zeile</th>
                    <th class="documentsTh">Kostenstelle</th>
                    <th class="documentsTh">Mitarbeiter Id</th>
                    <th class="documentsTh">Mitarbeiter Name</th>
                    <th class="documentsTh">CSV-Urlaubsstand</th>
                    <th class="documentsTh">MD-Urlaubsstand</th>
                    <th class="documentsTh">Abweichung</th>
                  </tr>
                  <tr v-for="line in goodLines" :key="line.id">
                    <td class="documentsTd">
                      <strong>{{ line.id }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.storeName }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeId }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.employeeName }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.csvValue }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong>{{ line.mdValue }}</strong>
                    </td>
                    <td class="documentsTd">
                      <strong class="colorOK">{{
                        Math.round(
                          (Math.abs(line.csvValue - line.mdValue) + Number.EPSILON) * 100
                        ) / 100
                      }}</strong>
                    </td>
                  </tr>
                </table>
              </div>
              <hr v-if="toggleGoodHolidayLines && goodLines.length > 0" />
            </div>
          </div>
          <!-- ROW END -->
        </div>

        <!-- complete div -->
      </div>
      <div
        v-if="externalSoftware == 1"
        style="font-size: 22px; font-weight: bold; padding: 60px"
      >
        Ein Urlaubsabgleich kann für die Software DPW/Sage nicht durchgeführt werden.
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { mapState, mapGetters } from "vuex";
export default {
  components: {},
  name: "ComparisonHoliday",
  data() {
    return {
      monthNames: {
        0: "Jänner",
        1: "Februar",
        2: "März",
        3: "April",
        4: "Mai",
        5: "Juni",
        6: "Juli",
        7: "August",
        8: "September",
        9: "Oktober",
        10: "November",
        11: "Dezember",
      },
      selectedDate: this.$helpers.getMomentTZ().clone().subtract(1, "months"),
      timeframeStart: this.$helpers
        .getMomentTZ()
        .clone()
        .subtract(1, "months")
        .startOf("month"),
      state: "loadingEmployees",
      importFilePickerOpening: false,
      importState: {},
      content: [],
      stores: [],
      storesMap: {},
      monthlyStatements: [],
      statementResponses: [],
      employeeMap: {},
      csvContent: [],
      malformedLines: [],
      wrongCompanyIdLines: [],
      employeeNotFoundLines: [],
      statementsEmptyLines: [],
      statementsMultipleLines: [],

      matchingLines: [],

      employeeNotFoundMD: [],

      visibleWrongCompanyIdLines: false,
      treshold: 0.1,
      filterDropdownOptions: [],

      toggleMalformedLines: false,
      toggleWrongCompanyLine: false,
      toggleNoEmployeeFound: false,
      toggleNoMonthlyFinancialStatementFound: false,
      toggleMultipleMonthlyFinancialStatementFound: false,
      toggleEmployeeNotInCSV: false,

      toggleBadHolidayLines: true,
      toggleGoodHolidayLines: false,
    };
  },
  computed: {
    ...mapGetters(["externalSoftware", "companySettings"]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        click: () => {
          view.refresh();
        },
        tooltip: "Neuer Upload",
      });
      state.buttons.push({
        type: "block green",
        icon: "fa-slider",
        click: () => {
          view.openTresholdSlider();
        },
        tooltip: "Abweichungs Grenze einstellen",
      });
      return state;
    },
    sideButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block border-r",
        icon: "fa-eye",
        tooltip: "Anzeige Filter",
        click: ($event) => {
          view.OpenFilterDropdown($event);
        },
      });
      return state;
    },
    dateDisplay: function () {
      return (
        this.monthNames[this.timeframeStart.months()] +
        " " +
        this.timeframeStart.format("YYYY")
      );
    },

    goodLines: function () {
      let array = [];
      for (let i in this.matchingLines) {
        if (
          Math.abs(this.matchingLines[i].csvValue - this.matchingLines[i].mdValue) <=
          this.treshold
        ) {
          array.push(this.matchingLines[i]);
        }
      }
      return array;
    },
    badLines: function () {
      let array = [];
      for (let i in this.matchingLines) {
        if (
          Math.abs(this.matchingLines[i].csvValue - this.matchingLines[i].mdValue) >
          this.treshold
        ) {
          array.push(this.matchingLines[i]);
        }
      }
      return array;
    },
  },
  watch: {},
  mounted() {
    //this.LoadFilterDropdown();
    this.refresh();
  },
  methods: {
    refresh: function () {
      let view = this;
      let params = {};
      view.$helpers.GetCall("/api/sec/stores", params).then((response) => {
        view.stores = response.data.data;

        for (let k = 0; k < view.stores.length; k++) {
          view.storesMap[view.stores[k].id] = view.stores[k];
        }

        view.loadEmployees();
      });
    },
    loadEmployees: function (force = false, employeeIdToSelect = null) {
      let view = this;
      this.state = "loadingEmployees";
      this.api
        .get("/sec/employee/coredata")
        .then((response) => {
          let empls = response.data.data;
          for (let k = 0; k < empls.length; k++) {
            view.employeeMap[empls[k].id] = empls[k];
          }
          this.state = "waitingForCSV";
        })
        .catch(function (error) {
          console.log(error);
          this.state = "employeeLoadingError";
        });
    },

    checkImportFiles(selectedFiles) {
      this.csvContent = [];
      this.importState.files = selectedFiles;
      this.importState.count = selectedFiles.length;
      if (selectedFiles.length > 1) {
        this.$helpers.error("Fehler beim Hochladen", "Bitte nur eine Datei hochladen!");
        return;
      }
      for (var i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContent = event.target.result;
          const allLines = fileContent.split(/\r\n|\n/);
          let firstLine = true;
          for (let j = 0; j < allLines.length; j++) {
            let line = allLines[j];
            this.csvContent.push(line);
            let parts = line.split(";");
            if (firstLine && parts.length != 6 && firstLine && parts.length != 7) {
              this.$helpers.error(
                "Fehler beim Verarbeiten",
                "Bitte ein gültiges CSV Format hochladen!"
              );
              return;
            }
            firstLine = false;
          }
          this.state = "loadMonthlyStatements";
          this.loadAllMonthlyStatements();
        };
        reader.onerror = (event) => {
          alert(event.target.error.name);
        };
        reader.readAsText(file);
      }
    },
    async loadAllMonthlyStatements() {
      let view = this;
      view.statementResponses = [];
      for (let j = 0; j < view.stores.length; j++) {
        let tmp = view.stores[j];
        view.monthlyStatements[tmp.id] = {
          confirmed: false,
          loading: true,
        };
        await this.loadMonthlyStatementFromMD(view.stores[j].id);
      }
      if (view.state != "errorMonthlyLoading") {
        view.state = "computingData";
        view.computeAll();
      }
      view.$forceUpdate();
    },
    loadMonthlyStatementFromMD: function (storeId) {
      let view = this;
      let promise = new Promise(function (resolve, reject) {
        let params = {
          storeId: storeId,
          date: view.selectedDate.format("YYYY-MM-DD"),
        };
        view.$helpers
          .GetCall("/api/sec/proxy/md/monthlyfinancialstatements", params)
          .then((response) => {
            let tmpObjectForSet = view.monthlyStatements[storeId];
            view.statementResponses.push(response.data);
            let statement = response.data;
            tmpObjectForSet.loading = false;
            tmpObjectForSet.confirmed = statement.length > 0 ? true : false;
            for (let k = 0; k < statement.length; k++) {
              if (!statement[k].confirmed) {
                tmpObjectForSet.confirmed = false;
              }
            }
            view.$set(view.monthlyStatements, storeId, tmpObjectForSet);
            resolve("");
          })
          .catch((e) => {
            view.state = "errorMonthlyLoading";
            view.$helpers.error(
              "Fehler beim Monatsabschluss",
              "Monatsabschluss konnte nicht geladen werden"
            );
            console.log(e);
            reject(e);
          });
      });
      return promise;
    },

    computeAll: function () {
      let bmdId = this.companySettings.data[0].bmdId;
      this.malformedLines = [];
      this.wrongCompanyIdLines = [];
      this.employeeNotFoundLines = [];
      this.statementsEmptyLines = [];
      this.statementsMultipleLines = [];
      this.matchingLines = [];
      this.employeeNotFoundMD = [];

      for (let k in this.employeeMap) {
        this.employeeMap[k].foundInCSV = false;
      }

      for (let i = 0; i < this.csvContent.length; i++) {
        let line = this.csvContent[i];
        let parts = line.split(";");
        if (parts.length != 6 && parts.length != 7) {
          if (parts.length > 1) {
            this.malformedLines.push({ line: line, id: i });
          }
        } else {
          //Line parts are ok, we can continue
          if (parts[0] != bmdId) {
            this.wrongCompanyIdLines.push({
              line: line,
              id: i,
              companyId: parts[0],
            });
          } else {
            let employeeFound = false;
            let employeeId = false;
            let isHidden = false;
            for (let k in this.employeeMap) {
              let empl = this.employeeMap[k];
              if (parts[2] == empl.mdPersonnelId) {
                employeeFound = true;
                isHidden = this.employeeMap[k].isHidden;
                employeeId = this.employeeMap[k].id;
                this.employeeMap[k].foundInCSV = true;
              }
            }
            if (!employeeFound) {
              this.employeeNotFoundLines.push({
                line: line,
                id: i,
                employeeId: parts[2],
                employeeName: parts[4],
              });
            } else if(!isHidden){
              let statements = 0;
              let statement = null;
              for (let k in this.statementResponses) {
                for (let l in this.statementResponses[k]) {
                  let monatsabschluss = this.statementResponses[k][l];
                  if (monatsabschluss.remoteId == employeeId) {
                    statements++;
                    statement = monatsabschluss;
                  }
                }
              }

              if (statements != 1) {
                statement = null;
                if (statements == 0) {
                  this.statementsEmptyLines.push({
                    line: line,
                    id: i,
                    employeeId: parts[2],
                    employeeName: parts[4],
                  });
                } else if (statements > 1) {
                  this.statementsMultipleLines.push({
                    line: line,
                    id: i,
                    employeeId: parts[2],
                    employeeName: parts[4],
                  });
                }
              } else {
                let holidayTimeMD =
                  Math.round(
                    (statement.vacationTimeBalance / 60 / 24 + Number.EPSILON) * 100
                  ) / 100;
                let tmp = parseFloat(parts[5].replace(",", "."));

                let storeName = "";
                if (this.storesMap[statement.storeId]) {
                  storeName = this.storesMap[statement.storeId].name;
                }

                this.matchingLines.push({
                  line: line,
                  id: i,
                  storeName: storeName,
                  employeeId: parts[2],
                  employeeName: parts[4],
                  csvValue: tmp,
                  mdValue: holidayTimeMD,
                });
              }

              /*
                      lineObj.diff = Math.abs(tmp - lineObj.urlaubsstandMD);
                      lineObj.diffRounded =
                        Math.round((lineObj.diff + Number.EPSILON) * 100) / 100;*/
            }
          }
        }
      }

      for (let k in this.employeeMap) {
        if (!this.employeeMap[k].foundInCSV && !this.employeeMap[k].isHidden) {
          this.employeeNotFoundMD.push(this.employeeMap[k]);
        }
      }

      this.state = "complete";
    },
    modDate: function (method) {
      let view = this;

      if (method == "add") {
        this.selectedDate.add(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      } else if (method == "sub") {
        this.selectedDate.subtract(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      }
      this.refresh();
    },
    showFilePicker() {
      if (!this.importFilePickerOpening) {
        console.log("Pick files with filepicker");
        this.importFilePickerOpening = true;
        setTimeout(() => {
          // debounce multiple clicks to avoid multiple subsequent file input dialogs
          this.importFilePickerOpening = false;
        }, 1000);
        document.getElementById("fileImportInput").click();
      }
    },
    filesSelected(files) {
      this.importFilePickerOpening = false;
      this.checkImportFiles(files);
    },

    openTresholdSlider() {
      let view = this;
      let description =
        "Ab welchem Grenzwert soll der Urlaubsstand als Unterschiedlich angezeigt werden?";
      let dataEntries = [];
      dataEntries.push({
        label: "Grenzwert",
        key: "fieldTreshold",
        hidden: false,
        type: "slider",
        value: view.treshold,
        min: 0,
        max: 10,
        slider: {
          hideMin: true,
          hideMax: true,
          min: 0,
          max: 10,
          value: view.treshold,
          step: 0.05,
          DisplayLabel: (val) => {
            return Math.round((val + Number.EPSILON) * 100) / 100;
          },
        },
      });

      view.$helpers.OpenPopup({
        type: "datafill",
        componentState: {
          entries: dataEntries,
          description: description,
          selectCallback: function (data) {
            view.treshold = data.fieldTreshold;
          },
        },
        header: "Abweichungs Grenze einstellen",
        expanded: true,
        centered: true,
      });
    },
    OpenFilterDropdown($event) {
      var view = this;
      var target = $event.currentTarget;

      this.$helpers.OpenPopup({
        elRelative: target,
        type: "list",
        componentState: {
          entries: view.filterDropdownOptions,
        },
        remainAfterSelect: true,
        pointerDirection: 0,
        iconsLeft: true,
        selectCallback: function ($event, item, dropdown) {
          item.toggled = !item.toggled;
          if (item.state) item.state.show = item.toggled;
          if (item.type) item.type.show = item.toggled;
          if (item.all) {
            view.filterDropdownOptions.forEach((option) => {
              if (option.state || option.type) {
                option.toggled = item.toggled;
                if (option.state) option.state.show = item.toggled;
                if (option.type) option.type.show = item.toggled;
              }
            });
          }
        },
      });
    },
    /*
    LoadFilterDropdown() {
      var view = this;
      this.filterDropdownOptions = [];
      view.filterDropdownOptions.push({
        label: "Fehlerhafte CSV-Zeilen",
        toggled: true,
        type: "1",
        color: "#000",
      });
      view.filterDropdownOptions.push({
        label: "Falsche Firmennummer",
        toggled: true,
        type: "2",
        color: "#000",
      });
      view.filterDropdownOptions.push({
        label: "Mitarbeiter nicht gefunden",
        toggled: true,
        type: "3",
        color: "#000",
      });
      view.filterDropdownOptions.push({
        label: "Monatsabschluss nicht gefunden",
        toggled: true,
        type: "4",
        color: "#000",
      });
      view.filterDropdownOptions.push({
        label: "Mehrere Monatsabschlüsse gefunden",
        toggled: true,
        type: "5",
        color: "#000",
      });
      view.filterDropdownOptions.push({
        label: "Urlaubs Abweichung",
        toggled: true,
        type: "6",
        color: "#000",
      });
      view.filterDropdownOptions.push({
        label: "Urlaub Korrekt",
        toggled: true,
        type: "7",
        color: "#000",
      });
      view.filterDropdownOptions.push({
        label: "Mitarbeiter nicht im CSV",
        toggled: true,
        type: "8",
        color: "#000",
      });

      this.filterDropdownOptions.push({
        label: "Alle",
        toggled: true,
        all: true,
        color: "#eeeeee",
      });
    },*/
  },
};
</script>
<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.redBackground {
  background-color: #bb8d9a;
}
.greenBackground {
  background-color: #889e53;
}
.export-builder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.export-builder > .header {
  box-shadow: 0 2px 0px -1px var(--contrast-2);
  margin-bottom: 20px;
}

.colorError {
  color: var(--col-error);
}
.colorOK {
  color: var(--col-ok);
}
</style>
