<template>
    <div class="filter" v-on:click="OpenTableFilter($event)">
        <div class="fas fa-filter"></div>
    </div>
</template>

<script>
    export default {
        name: "datatablefilter",
        data(){
            return {
            }
        },
        props:{
            filterOptionsState: [Object, Array],
        },
        mounted(){
        },
        methods: {
            OpenTableFilter($event){
                let view = this;
                view.$helpers.OpenPopup({
                    elRelative: $event.currentTarget,
                    type: 'datafill',
                    componentState: {
                        entries: this.filterOptionsState,
                        selectCallback: (filterNew) => {
                            console.log(filterNew);
                            view.$emit('update', filterNew)
                        },
                    },
                    expanded: true,
                    header: 'Filters',
                    pointerDirection: 1,
                });
            },
        }
    }
</script>

<style scoped>
    .filter{
        display: inline-flex;
        align-items: center;
        position: absolute;
        z-index: 10;
        background: none;
        right: 10px;
        width: initial;
        padding: 0 20px;
        top: 0;
        bottom: 0;
    }

    .filter:hover .fa-filter{
        color: var(--bg) !important;
    }
</style>