<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      Benutzerrollen
    </div>


    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br/>
       Die Benutzerrollen werden gerade geladen<br/>



      <div style="width: 100%; clear:both; border-bottom:1px solid #ccc; margin-top: 25px;">
      </div>
    </div>

    <div v-if="false">
       Die Benutzerrollen wurden noch nicht geladen
      <br style="clear:both;" />


      <div style="width: 100%; clear:both; border-bottom:1px solid #ccc; margin-top: 25px;">
      </div>
    </div>



    <div v-if="!loading">
      <div style="max-width: 1300px; margin:0 auto;">
        <b-row style="margin-top: 50px;">


          <!-- ROLE SECTION START -->
          <b-col md="auto" class="half-width">
            <!-- ROLE FILTER -->
            <div class="name-filter">
              <i class="fas fa-search"></i>
              <input class="filter-input" v-model="roleFilter" placeholder="Rollen filtern" v-on:input="roleFilter = SanitizeInput(roleFilter);"/>
              <!-- <div class="button add fix-button-width" @click="openNewRoleWizard();">
                <div class="label">Neue Rolle anlegen</div>
              </div> -->
            </div>
            <!-- ROLE LIST -->
            <div class="list">
              <div class="scroll-content">
                <div class="list-entry" :key="index" v-for="(role, index) in rolesFilteredList" v-on:click="ToggleRole($event, null, role)" v-bind:class="{'selected': selectedRole === role}">
                  <div class="picture" v-bind:style="{'background-color': role.color}">
                    <strong>{{role.letter}}</strong>
                  </div>
                  <div class="label">{{role.name}}</div>

                  <div class="position-right">
                    <div class="picture text-center cursor-not-allowed" v-if="role.immutable">
                      <i class="fas fa-lock fa-sm cursor-not-allowed"></i>
                    </div>
                    <div class="picture text-center" v-if="!role.immutable" v-on:click="openDeleteModal($event, null, role)">
                      <i class="fas fa-trash fa-sm"></i>
                    </div>
                  </div>
                  <div class="position-right">
                    <div class="picture text-center" v-if="!role.immutable" v-on:click="openEditModal($event, null, role)">
                      <i class="fas fa-edit fa-sm"></i>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </b-col>
          <!-- ROLE SECTION END -->

          <!-- USER SECTION START -->
          <b-col md="auto" class="half-width">
            <!-- USER FILTER -->
            <div class="name-filter">
              <i class="fas fa-search"></i>
              <input class="filter-input" v-model="userFilter" placeholder="Benutzer filtern" />
              <!-- <div class="button add fix-button-width" @click="openAddUserWizard();">
                <div class="label">Neuen Benutzer anlegen</div>
              </div> -->
            </div>
            <!-- USER LIST -->
            <div class="list">
              <div class="scroll-content">
                <div class="list-entry" :key="index" v-for="(user, index) in usersFilteredList" v-on:click="ToggleUser($event, null, user)" v-bind:class="{'selected': user.highlighted}">
                  <div v-if="!IsExternalUser(user)" class="picture" v-bind:style="{background: GetProfilePictureCSS(user)}"></div>
                  <div v-if="IsExternalUser(user)" class="picture text-center" style="background-color:#ccc;">
                    <strong>E</strong>
                  </div>
                  <div class="label">{{user.name}} &nbsp; <small class="label-small">{{user.email}}</small></div>
                  <div class="position-right">
                    <div class="picture text-center" v-bind:style="{'background-color': user.role.color}" v-if="user.role">
                      <strong>{{user.role.letter}}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <!-- USER SECTION END -->

        </b-row>
      </div>


    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'userroles',
  data () {
    return {
      // FILTER VARIABLES
      roleFilter: "",
      userFilter: "",
      //LIST ARRAYS
      rolesList: [],
      usersList: [],

      rolesFilteredList: [],
      usersFilteredList: [],

      //MAPS FOR LINKING STUFF
      roleMap: {},
      userMap: {},
      userRoleMap: {},


      //HELPERS
      selectedRole: null,

      loading: true,





      saves: false,
    }
  },
  created: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  mounted: function () {
  },
  computed: {
        ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings','employeeMap']),
        dateDisplay: function() {
          return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
        },
          headerButtons() {
              let view = this;

              let state= {
                  buttons: []
              };
              state.buttons.push({
                  type: "block",
                  icon: "fa-refresh",
                  click: () => {
                     view.refresh();
                  },
                  tooltip: 'Daten neu laden'
              });
              state.buttons.push({
                  type: "block",
                  icon: "fa-cloud-download-alt",
                  click: () => {
                     view.loadExternalUsersFromKundencenter();
                  },
                  tooltip: 'Externe Benutzer vom Kundencenter laden'
              });
              return state;
          },
  },
  watch: {
    roleFilter: function(val, oldVal) {
      this.filterRoles();
    },
    userFilter: function(val, oldVal) {
      this.filterUsers();
    },
    newRolename: function(val, oldVal) {
      this.checkRoleExisting();
    },
    companyId: function(val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
      let view = this;
      view.loading = true;
      view.roleFilter = "";
      view.userFilter = "";
      view.rolesList = [];
      view.usersList = [];
      view.rolesFilteredList = [];
      view.usersFilteredList = [];
      view.roleMap = {};
      view.userMap = {};
      view.userRoleMap = {};
      view.selectedRole = null;
      view.loadRoles();
    },

    loadExternalUsersFromKundencenter: function(){
      let view =this;
      view.loading = true;
      view.$helpers.GetCall('/api/loadExternalUsersFromMCCORE').then((response) => {
        view.refresh();
      }).catch((e) => {
        view.refresh();
        console.log(e);
      });
    },
    
    loadRoles: function(){
      let view = this;
      let params = { store_id: view.$store.state.storeId };
      view.$helpers.GetCall('/api/roles', params).then((response) => {
        view.rolesList = response.data.data;
        
        
        let data = response.data.data;
        
        let overviewA = [];
        for (let i = 0; i < data.length; i++) {
          let roleItem = {
            id: data[i].id,
            name: data[i].name,
            immutable: data[i].immutable,
            //active: data[i].active,
            //type: data[i].type,
            letter: data[i].name.charAt(0).toUpperCase(),
            //color: "#"+roles.intToRGB(roles.hashCode(data[i].name)),
            color: "var(--contrast-1)",
          };
          this.roleMap[data[i].id] = roleItem;
          overviewA.push(roleItem);
        }
        view.rolesList = overviewA;
        
        this.rolesList.sort( this.sortByName );
        view.rolesFilteredList = view.rolesList.slice();

        view.loadEmployees();
      }).catch((e) => {
          view.loading = false;
          console.log(e);
      });
    },
    
    loadEmployees: function () {
      let view = this;
      view.$helpers.GetEmployeeMap(true).then(response => {
        let overviewUsers = [];
        Object.values(view.employeeMap).forEach(e => {
          e.name = e.lastName + " " + e.firstName;
          if(e.accountId != null){
            if(!view.userMap[e.accountId]){
              view.userMap[e.accountId] = e;
              e.external = false;
            }
            overviewUsers.push(e);
          }else{
            //overviewEmployees.push(user);
          }
        });
        //view.absenceEmployeeMap = map;
          
        view.usersList = overviewUsers;
        view.usersList.sort( view.sortByName );
        view.usersFilteredList = view.usersList.slice();

        view.loadExternaluser();

      }).catch(function (error) {
        console.log(error.response);
      });
    },



    loadExternaluser: function () {
      let view = this;

      let params = { store_id: view.$store.state.storeId };
      view.$helpers.GetCall('/api/externaluser', params).then((response) => {

        let data = response.data.data;


        let overviewUsers = [];
        Object.values(data).forEach(e => {
          e.name = e.lastName + " " + e.firstName;
          if(e.accountId != null){
            if(!view.userMap[e.accountId]){
              view.userMap[e.accountId] = e;
              e.external = true;
              view.usersList.push(e);
            }
          }else{
            //overviewEmployees.push(user);
          }
        });
          
        view.usersList.sort( view.sortByName );
        view.usersFilteredList = view.usersList.slice();

        view.loadModelHasRole();

      }).catch(function (error) {
        console.log(error.response);
      });


    },


    

    loadModelHasRole: function() {
      let view = this;
      let url = '/api/modelHasRole';
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/modelHasRole');
      
      this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url,
      }).then(response => {
        view.rolesList = response.data.data;
        let data = response.data.data;
        
        let overviewA = [];
        for (let i = 0; i < data.length; i++) {
            if(this.userMap[data[i].uuidText] != undefined && data[i].uuidText != null){
                let userRoleItem = {
                    id: data[i].id,
                    accountId: data[i].uuidText,
                    //immutable: data[i].immutable,
                    role: this.roleMap[data[i].roleId],
                }
                this.userRoleMap[data[i].id] = userRoleItem; 
                overviewA.push(userRoleItem);
            }
        }        

        for (let i = 0; i < this.usersList.length; i++) {
          for (let j = 0; j < overviewA.length; j++) {
            if(overviewA[j].accountId == this.usersList[i].accountId){
              this.usersList[i].userRoleID = overviewA[j].roleId;
              //this.usersList[i].immutable = overviewA[j].immutable;
              this.usersList[i].role = overviewA[j].role;
            }
          }
        }
        this.usersFilteredList = this.usersList.slice();
        
        view.loading = false;
      }).catch((e) => {
          view.loading = false;
          console.log(e);
      });

    },




    filterRoles(){
      let view = this;
      if (view.roleFilter === "") {
        view.rolesFilteredList = view.rolesList.slice();
        return;
      }
      view.rolesFilteredList = view.rolesList.filter(e => e.name.toUpperCase().indexOf(view.roleFilter.toUpperCase()) > -1 || (view.selectedRole != null && e.id == view.selectedRole.id));
    },
    filterUsers(){
      if (this.userFilter === "") {
        this.usersFilteredList = this.usersList.slice();
        return;
      }
      this.usersFilteredList = this.usersList.filter(e => e.name.toUpperCase().indexOf(this.userFilter.toUpperCase()) > -1 || e.email.toUpperCase().indexOf(this.userFilter.toUpperCase()) > -1);
    },
    filterFilteredUsers(){
      if (this.userFilter === "") {
        this.usersFilteredList = this.usersFilteredList.slice();
        return;
      }
      this.usersFilteredList = this.usersFilteredList.filter(e => e.name.toUpperCase().indexOf(this.userFilter.toUpperCase()) > -1 || e.email.toUpperCase().indexOf(this.userFilter.toUpperCase()) > -1);
    },

    ToggleRole($event, component, role) {
      if (this.selectedRole === role) {
        this.selectedRole = null;
      } else {
        this.selectedRole = role;
      }

      for (let i = 0; i < this.usersList.length; i++) {
        if(this.selectedRole != null && this.usersList[i].role && this.usersList[i].role.id != null && this.selectedRole.id == this.usersList[i].role.id){
          this.usersList[i].highlighted = true;
        }else{
          this.usersList[i].highlighted = false;
        }
      }
      this.OrderUsersBySelectedRole(0);
    },


    ToggleUser($event, component, user) {
      if(this.selectedRole == null){
        this.$helpers.warning('Eingabe fehlerhaft', 'Bitte zuerst eine Benutzerrolle auswählen!');
      }else if(this.selectedRole.id == 1){
        this.$helpers.warning('Eingabe fehlerhaft', 'Der Besitzer kann nicht verändert werden!');
      }else if(user.role && user.role.id == 1){
        this.$helpers.warning('Eingabe fehlerhaft', 'Der Besitzer kann nicht verändert werden!');
      }else if(user.role && user.immutable){
        this.$helpers.warning('Eingabe fehlerhaft', 'Dieser Benutzer kann nicht verändert werden!');
        //ToDo We need to get the userInfo somehow to perform the next check
      /*}else if(user.accountId == this.userInfo.id && this.selectedRole.id == user.role.id){
        this.$helpers.warning('Eingabe fehlerhaft', 'Die eigene Benutzerrolle kann nur geändert aber nicht entfernt werden!');*/
      }else if((!user.role || this.selectedRole.id != user.role.id) && this.selectedRole.name.toLowerCase() != 'user'){
        this.updateUserRole(user.accountId,this.selectedRole.id);
      }else if(this.selectedRole.id == user.role.id){
        if (confirm('Soll von diesem Benutzer wirklich die Benutzerrolle gelöscht werden? Dieser Benutzer hat anschließend keinen Zugriff mehr auf diesen Store.')) {
          this.updateUserRole(user.accountId,0);
        }
      }
    },
    
    updateUserRole: function(accountId,roleId){
        let view = this;
        let url = '/api/modelHasRole';
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/modelHasRole');
        let payload = { companyId: this.$store.state.companyId , accountId: accountId , roleId: roleId }

        this.axios({
            method: 'put',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
        }).then(response => {
          if(roleId == 0){
            view.userRoleMap[accountId] = null; 

            for (let i = 0; i < view.usersList.length; i++) {
              if(accountId == view.usersList[i].accountId){
                view.usersList[i].userRoleID = null;
                //view.usersList[i].immutable = data.immutable;
                view.usersList[i].role = null;
                view.usersList[i].highlighted = !view.usersList[i].highlighted;
              }
            }
            view.$forceUpdate();
            view.OrderUsersBySelectedRole(1);
            view.$helpers.success('Erfolg', 'Benutzerrolle wurde gelöscht');
          }else{
            let data = response.data;
            
            let userRoleItem = {
              id: data.id,
              accountId: data.uuidText,
              //immutable: data[i].immutable,
              role: view.roleMap[data.roleId],
            }
            view.userRoleMap[data.id] = userRoleItem; 

            for (let i = 0; i < view.usersList.length; i++) {
              if(data.uuidText == view.usersList[i].accountId){
                view.usersList[i].userRoleID = data.id;
                //view.usersList[i].immutable = data.immutable;
                view.usersList[i].role = view.roleMap[data.roleId];
                view.usersList[i].highlighted = !view.usersList[i].highlighted;
              }
            }
            view.$forceUpdate();
            view.OrderUsersBySelectedRole(1);
            view.$helpers.success('Erfolg', 'Benutzerrolle wurde zugewiesen');
          }
        }).catch(function (error) {
          /*
            view.error=true;
            console.log(error)
            */
        })

    },

    OrderUsersBySelectedRole(filtermethod){
      this.usersList.sort( this.sortByName ); // Maybe not needed, if it's already sorted
      let helperSelected = [];
      let helperNotSelected = [];
      let newArray = this.usersList.slice();
      let j = 0;
      let jj = 0;
      if(this.selectedRole != null){
        newArray = [];
        for (let i = 0; i < this.usersList.length; i++) {
          if (this.usersList[i].role && this.usersList[i].role.id == this.selectedRole.id) {
            helperSelected[j++] = this.usersList[i];
          } else {
            helperNotSelected[jj++] = this.usersList[i];
          }
        }
        newArray = helperSelected.concat(helperNotSelected);
        this.usersList = newArray.slice();
        this.usersFilteredList = newArray.slice();
      }
      if (filtermethod == 0 ? this.filterUsers() : this.filterFilteredUsers());
      this.$forceUpdate();
    },

    sortByName: function(a,b){
      if ( a.name.toUpperCase() < b.name.toUpperCase() ) return -1;
      if ( a.name.toUpperCase() > b.name.toUpperCase() ) return 1;
      return 0;
    },
    SanitizeInput(input) {
      input = input.replace(/[^A-Za-z0-9 \-]/g, '');
      if (input.length > 32) {
        input = input.substring(0, 32);
      }
      return input;
    },
    
    IsExternalUser(user){
      return (user.external);
    },
    GetProfilePictureCSS(user){
      if(!user.profileImage) return 'var(--contrast-1) no-repeat center/cover  url(https://fileserver1.meindienstplan.de:8443/api/file?id=d55fd03c-9999-4a48-8515-906585d0bc0e&accessToken=ALPW6DGLB92U2U0S)';
      return 'var(--contrast-1) no-repeat center/cover  url(' + user.profileImage + ')';
      },
  },


}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>


.name-filter .fa-search {
    color: var(--contrast-4);
    position: absolute;
    top: 5px;
    left: 5px;
}

.half-width {
  width: 50%;
}

.fix-button-width {
  width: 150px;
}

.filter-input {
  margin-left: -45px;
  padding-left: 40px;
  width: calc(100% - 25px);
}

.list {
  width: calc(100% - 78px);
  margin: auto;
  height: 265px;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
}
.list .scroll-content {
  padding: 5px;
  overflow-y: auto;
  height: 265px;
}

.list-entry {
  height: 35px;
  margin-bottom: 2px;
  border-radius: 100px;
  cursor: pointer;
}


.list-entry:hover {
  background: var(--sub-menu);
  cursor: pointer;
}
.list-entry.selected {
  background: #e51550;
  cursor: pointer;
}
.list-entry.selected > .label,
.list-entry:hover > .label{
  color: var(--bg);
  cursor: pointer;
}
.list-entry.selected > .label > .label-small,
.list-entry.hover > .label > .label-small {
  color: var(--bg) !important;
  cursor: pointer;
}


.list-entry > .label {
  float: left;
  font-size: 14px;
  font-family: DistrictProBook;
  color: var(--contrast-4);
  padding-top: 6px;
  cursor: pointer;
}
.list-entry > .label > .label-small {
  cursor: pointer;
  color:var(--contrast-5);
}


.picture {
  float: left;
  border-radius: 100px;
  background: var(--contrast-1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.position-right{
  float: right;
  margin: 0;
  margin-right: -5px;
}
.pictureImg {
  border-radius: 100px;
  height: 25px;
  width: 25px;
}





/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--contrast-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--contrast-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}
.has-header > .has-header > .scrollbar-container{
    height: calc(100% - var(--header-height) - var(--tab-menu-height)) !important;
    overflow-x: hidden;
    top: var(--header-height);
    padding: 0px 14px;
}
</style>
