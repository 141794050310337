<template>
  <div class="tab-menu">
    <div class="header">
      <menutabs :state="{tabs: tabs}" :tab-selected="tabSelected" @tab-select="UpdateSelectedView"></menutabs>
    </div>
    <div class="component-container">
      <component ref="component" :is="tabSelected.view"/>
    </div>
  </div>
</template>

<script>
  import AbsenceExport from './AbsenceExport'
  import ISTExport from './ISTExport'
  export default {
    name: 'AbsenceMain',
    components: {
      AbsenceExport,
      ISTExport,
    },
    data() {
      return {
          viewMode: 1,
          loading: true,
          loadingText: '',
          tabs:[
            {label: 'Abwesenheiten', view: 'AbsenceExport', selected: true},
            {label: 'IST Stunden', view: 'ISTExport', selected: false},
          ],
          tabSelected: null,
      }
    },
    props: {
      view: String,
    },
    created() {
      let tab = this.tabs.find(t => t.selected);
      if(tab) this.tabSelected = tab;
      else this.tabSelected = {view: this.view ? this.view : 'storesettings'};
    },
    mounted(){

    },
    updated() {},
    destroyed() {},
    methods: {
      UpdateSelectedView(tab){
        this.tabs.forEach(t => t.selected = false);
        tab.selected = true;
        this.tabSelected = tab;
      }
    }
  }
</script>
<style scoped>
</style>
