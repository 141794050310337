<template>
    <div class="drag" v-bind:style="{top: posy + 'px', left: posx + 'px', display: toggled ? 'inline-block' : 'none'}" v-bind:class="GetColor()">
        <div class="info">
            <div class="entry" v-for="(entry, index) in info" :key="index" v-bind:class="{half: entry.half}">
                <div class="label">{{entry.label}}</div>
                <div class="data">{{entry.data}}</div>
            </div>
        </div>
        <div class="extra"></div>
    </div>
</template>

<script>
    export default {
        name: 'drag',
        data(){
            return{
                selectedItem: {},
                items: [],
                toggled: false,
                posxstart: 0,
                posystart: 0,
                posx: 0,
                posy: 0,
                appendedData: {},
                info: [],
                callbackEnd: function(){},
                htmlContent: '',
                shadowAdded: [],
            }
        },
        created () {
            var view = this;
            requestAnimationFrame(function(){
                $(view.$el).appendTo('body');
            });
        },
        methods: {
            GetColor(){
                if(this.appendedData && this.appendedData.date && this.appendedData.date.status) return this.appendedData.date.status.label;
            },
            DragStart(posx, posy, appendedData){
                console.log("Drag Start");
                document.addEventListener('mousemove', this.DragUpdate);
                document.addEventListener('mouseup', this.DragEnd);
                this.appendedData = appendedData;
                this.posx = posx - 10;
                this.posy = posy - 10;
                this.toggled = true;
                $(this.$el).find('.extra').html('');
                if(this.htmlContent)
                {
                    $(this.htmlContent).appendTo($(this.$el).find('.extra'));
                }
            },
            DragUpdate($event){
                var view = this;
                requestAnimationFrame(function(){
                    view.posx = $event.pageX - 10;
                    view.posy = $event.pageY - 10;
                });






                /*
                if(!this.toggled)
                {
                    for(var x in this.shadowAdded)
                    {
                        this.shadowAdded[x].find('.background').css('box-shadow', '');
                    }
                    this.shadowAdded = [];
                    return;
                }
                */
               



                

                let seconds = (performance.now()-this.$parent.dragSollSnappedTime);
                if(seconds > 50){
                    this.$parent.dragSollSnappedTime = performance.now();
                    console.log("drag update");
                    var timebar = $(window.mouse.element).closest('.time-bar-new');
                    if(timebar && timebar.length > 0){
                        var timebarComponent = window.timebars.find(t => t.$el === timebar[0]);
                        if(timebarComponent){
                            this.$parent.dragSollSnappedTime = performance.now();
                            this.$parent.dragShiftTargetPrevious = this.$parent.dragShiftTarget;
                            this.$parent.dragShiftTarget = timebarComponent.employeeId;
                            this.$parent.RefreshEmployeeSollIst();

                        }
                    }else{
                        var day = $(window.mouse.element).closest('.day');
                        if(day && day.length > 0){
                            console.log(day.attr('employeeId'));
                            this.$parent.dragShiftTargetPrevious = this.$parent.dragShiftTarget;
                            this.$parent.dragShiftTarget = day.attr('employeeId');
                            this.$parent.RefreshEmployeeSollIst();
                        }
                    }
                }


                /*
                for(var x in this.shadowAdded)
                {
                    this.shadowAdded[x].find('.background').css('box-shadow', '');
                }
                this.shadowAdded = [];
                if(timebar.length && timebar.length > 0)
                {
                    timebar.find('.background').css('box-shadow', '0 0 0 3px rgba(0,120,0, .3)');
                    this.shadowAdded.push(timebar);
                }
                */
            },
            DragEnd(){
                this.toggled = false;
                for(var x in this.shadowAdded)
                {
                    this.shadowAdded[x].find('.background').css('box-shadow', '');
                }
                console.log("Remove event listener");
                document.removeEventListener('mousemove', this.DragUpdate);
                document.removeEventListener('mouseup', this.DragEnd);
                if(this.callbackEnd)
                {
                    this.info = [];
                    this.callbackEnd(this.appendedData);
                    this.callbackEnd = null;
                }
            },
            DragStop(){
                this.toggled = false;
                for(var x in this.shadowAdded)
                {
                    this.shadowAdded[x].find('.background').css('box-shadow', '');
                }
                document.removeEventListener('mousemove', this.DragUpdate);
                document.removeEventListener('mouseup', this.DragEnd);
            },
            Toggle(){
                this.toggled = !this.toggled;
            },
        },
        mounted() {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .drag{
        position:fixed;
        display:flex;
        min-height: 50px;
        min-width: 50px;
        border-radius: 5px;
        display:none;
        z-index: 1000;
        overflow: hidden;
        pointer-events:none !important;
        background: var(--bg);
        box-shadow: 4px 4px 5px 3px rgba(0,0,0,0.2);
    }
    .drag .item{
        position: relative;
        display: block;
        height: 30px;
        text-align: center;

        padding: 0 10px;
        padding-top: 5px;
        color: var(--contrast-4);
        cursor: pointer;
        transtion:.2s;
        background:gray;
        overflow:hidden;
    }
    .drag .item:hover{
        filter:brightness(140%);
    }
    .drag .show{
        display:inline-block;
    }
    .info{
        width: 100%;
        position: relative;
        padding: 10px;
    }

    .entry {
        width: 100%;
        float: left;
        height: 23px;
        display: flex;
    }
    .entry.half {
        width: 50%;
    }
    .entry .label{
        width: initial;
        height: 20px;
        position: relative;
        margin-right: 5px;
        font-size: 14px;
        margin: 0;
        text-align: right;
        color: var(--contrast-4);
    }
    .shift .info{
        top: -6px;
    }

    .shift .entry .label{
        width: 60px;
    }
    .entry .data{
        flex: auto;
        height: 20px;
        position: relative;
        font-size: 14px;
        margin-left: 5px;
        color: var(--contrast-4);
        box-shadow: 0 2px 0 -1px var(--contrast-1);
    }

    .info .entry:last-child{
        margin-bottom: 10px;
    }
</style>
