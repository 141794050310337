
<template>
    <div class="datepicker" v-if="toggled" v-bind:style="{display: (toggled ? 'inline-block' : 'none'), top: posy + 'px', left: posx + 'px', zIndex: 10000}" v-bind:class="{'no-edit': noEdit, calendar: yearView, 'month-view': monthView}">
        <div class="background" v-on:click="Toggle()"></div>
        <div class="options" v-if="showOptions">
            <div class="label" v-on:click="monthView = true, yearView = false, weekView = false" v-bind:class="{'selected': monthView && !weekView}"><div class="text">Y</div></div>
            <div class="label" v-if="dayViewAllowed" v-on:click="monthView = false, yearView = false, weekView = false" v-bind:class="{'selected': !monthView && !yearView && !weekView}"><div class="text">M</div></div>
            <div class="label" v-if="yearViewAllowed" v-on:click="monthView = false, yearView = true, weekView = false, Reposition()" v-bind:class="{'selected': yearView && !weekView}"><div class="text">C</div></div>
            <div class="label" v-if="weekViewAllowed" v-on:click="monthView = false, yearView = false, weekView = true, Reposition()" v-bind:class="{'selected': weekView}"><div class="text">W</div></div>
        </div>
        <div class="fa-stack cancel" v-on:click="Toggle()">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-stack-2x fa-times"></i>
        </div>
        <div class="header main">
            <div class="button light fa-stack arrow left" v-on:click="PrevYear()">
                <i class="fas fa-stack-2x fa-angle-left"></i>
            </div>
            <div class="year">{{selectedDate.format('YYYY')}}</div>
            <div class="button light fa-stack arrow right" v-on:click="NextYear()">
                <i class="fas fa-stack-2x fa-angle-right"></i>
            </div>
        </div>
        <div class="header main" v-if="!yearView && !yearSelectOnly && !weekView">
            <div class="button light fa-stack arrow left" v-on:click="PrevMonth()">
                <i class="fas fa-stack-2x fa-angle-left"></i>
            </div>
            <div class="month">{{selectedDate.format('MMMM')}}</div>
            <div class="button light fa-stack arrow right" v-on:click="NextMonth()">
                <i class="fas fa-stack-2x fa-angle-right"></i>
            </div>
        </div>
        <div v-if="!monthView && !yearView && !yearSelectOnly && !weekView" class="month-container">
            <div class="day"  v-bind:class="GetDayName(index - 1)" v-for="index in parseInt(selectedDate.daysInMonth())" :key="index"
                 v-on:click="PickDay(index)">
                <div class="name">{{index}}</div>
            </div>
        </div>
        <div v-if="yearView && !yearSelectOnly && !weekView" class="year-container">
            <div v-for="monthIndex in 12" :key="monthIndex" class="month-container">
                <div class="month">{{selectedDate.clone().month(monthIndex - 1).date(1).format('MMMM')}}</div>
                <div>
                    <div class="day"  v-bind:class="GetDayMonthName(index - 1, monthIndex - 1)" v-for="index in parseInt(selectedDate.clone().month(monthIndex - 1).date(1).daysInMonth())" :key="index"
                         v-on:click="PickDayMonth(index, monthIndex - 1)">
                        <div class="name">{{index}}</div>
                        <div v-if="CountMap(index, monthIndex) > 0" class="count">{{CountMap(index, monthIndex)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="weekView" class="weeks-container">
            <div v-for="monthIndex in 12" :key="monthIndex" class="week-container">
                <div class="month">{{selectedDate.clone().month(monthIndex - 1).date(1).format('MMMM')}}</div>
                <div class="day" v-for="week in GetWeeksInMonth(monthIndex - 1)" :key="week" v-bind:class="{selected: weekSelected === week}"
                     v-on:click="PickWeek(week)">
                    <div class="name">{{week}}</div>
                </div>
            </div>
        </div>
        
        <div class="separator"></div>
        <div class="button" v-on:click="PassDate()">
            <div class="label">Ok</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'datepicker',
        data(){
            return{
                test: '',
                toggled: false,
                selectedDate: undefined, 
                date: undefined, 
                posx: 0,
                posy: 0,
                monthView: false,
                yearViewAllowed: true,
                dayViewAllowed: true,
                items: [],
                posxstart: 0,
                posystart: 0,
                appendedData: {},
                yearView: false,
                noEdit: false,
                elRelative: null,
                showOptions: true,
                yearSelectOnly: false,
                weekView: false,
                weekViewAllowed: false,
                weekSelected: 1,
                CountMap: function(index, month){ return 0}
            }
        },
        created(){
        },
        props: {
            state: Object
        },
        watch: { 
            state: {
                deep: true,
                immediate: true,
                handler(newVal, oldVal){
                    this.state = newVal;
                    this.toggled = newVal.toggled;
                    if(newVal.toggled){ //this is to make sure the picker is inbounds
                        var view = this;
                        requestAnimationFrame(function(){
                            view.Reposition();
                        });
                    }
                    this.posx = newVal.posx;
                    this.posy = newVal.posy;
                    if(this.elRelative){
                        var boxEl = this.elRelative.getBoundingClientRect();
                        var box = this.$el.getBoundingClientRect();
                        this.posx = boxEl.right + 15;
                        this.posy = ((boxEl.top + boxEl.bottom) * .5) + (box.height * .5);
                    }
                    if(newVal.date){
                        this.date = newVal.date.clone();
                    }
                    if(newVal.selectedDate){
                        this.selectedDate = newVal.selectedDate.clone();
                    }
                    
                    this.monthView = newVal.monthView;
                    this.yearView = newVal.yearView;
                    if(newVal.yearViewAllowed != null){
                        this.yearViewAllowed = newVal.yearViewAllowed;
                    }
                    if(newVal.dayViewAllowed != null){
                        this.dayViewAllowed = newVal.dayViewAllowed;
                    }
                    this.noEdit = newVal.noEdit;
                    if(newVal.showOptions !== undefined) this.showOptions = newVal.showOptions;
                    if(newVal.yearSelectOnly !== undefined) this.yearSelectOnly = newVal.yearSelectOnly;
                    this.CountMap = newVal.CountMap ? newVal.CountMap : function(){return 0;};
                    this.weekView = newVal.weekView;
                    this.weekViewAllowed = newVal.weekViewAllowed;
                    if(newVal.weekSelected) this.weekSelected = newVal.weekSelected;
                    else this.weekSelected = 1;

                }
               
            }
        },
        methods: {
            PickWeek(week){
                this.weekSelected = week;
                this.selectedDate.isoWeek(week);
            },
            GetWeeksInMonth(month){
                let start = this.selectedDate.clone().month(month).date(1);
                let end = start.clone().date(start.daysInMonth());
                let startWeeks = start.isoWeek();
                let endWeeks = end.isoWeek();
                if(endWeeks === 1) endWeeks = start.weeksInYear();
                if(startWeeks > endWeeks) startWeeks = 1;
                let weeks = (endWeeks - startWeeks) + 1;
                if(end.isoWeekday() === 7) weeks++;
                if(start.isoWeekday() === 7) weeks--;
                console.log(end.isoWeek());
                console.log(start.isoWeek());
                console.log(month);
                let array = Array(weeks).fill(0);
                let index = 0;
                for(let x in array){
                    array[x] = startWeeks + index;
                    index++;
                }
                return array;
            },
            PassDate: function(){
                var picker = this;
                requestAnimationFrame(function(){
                    picker.toggled = false;
                });
                this.date.year(this.selectedDate.year());
                this.date.month(this.selectedDate.month());
                this.date.date(this.selectedDate.date());

                this.$emit('datepicker-date-picked', this.date);

            },
            Reposition(){
                var view = this;
                requestAnimationFrame(function() {
                    var box = view.$el.getBoundingClientRect();
                    var widthTotal = window.innerWidth;
                    var heightTotal = window.innerHeight;
                    if (box.bottom > heightTotal) view.posy = heightTotal - box.height - 5;
                    if (box.right > widthTotal) view.posx = widthTotal - box.width - 5;
                    view.$forceUpdate();
                });
            },
            PickDay:function(index){
                this.selectedDate.date(index);
                this.$forceUpdate();
            },
            PickDayMonth:function(index, month){
                this.selectedDate.date(index).month(month);
                this.$forceUpdate();
            },
            NextYear: function(){
                
                var daynum = parseInt(this.selectedDate.format('DD'));
                this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) + 1);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);

                this.$forceUpdate();
            },
            PrevYear: function(){

                

                var daynum = parseInt(this.selectedDate.format('DD'));
                this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) - 1);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);

                this.$forceUpdate();
            },
            NextMonth: function(){
                var daynum = parseInt(this.selectedDate.format('DD'));
                var monthnum = parseInt(this.selectedDate.format('MM'));
                if(monthnum === 12) {
                    monthnum = 0;
                    this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) + 1);
                }
                this.selectedDate.date(1);
                this.selectedDate.month(monthnum);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);

                this.$forceUpdate();
            },
            PrevMonth: function(){

                var daynum = parseInt(this.selectedDate.format('DD'));
                var monthnum = parseInt(this.selectedDate.format('MM'));
                if(monthnum === 0) {
                    monthnum = 11;
                    this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) - 1);
                }
                this.selectedDate.date(1);
                this.selectedDate.month(monthnum - 2);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);

                this.$forceUpdate();
            },

            GetDayName(index){
                var temp = this.selectedDate.clone();
                var dayName = temp.date(index + 1).format('dddd');
                var selected = (parseInt(this.selectedDate.format('DD')) === index + 1 ? 'selected' : '');
                return  dayName + ' ' + selected;
            },
            GetDayMonthName(index, month){
                var temp = this.selectedDate.clone();
                var dayDate = temp.clone();
                dayDate.date(index + 1).month(month);
                var dayName = dayDate.format('dddd');
                var selected = (parseInt(this.selectedDate.format('DD')) === index + 1 && this.selectedDate.month() === month ? 'selected' : '');
                var special = '';
                if(this.state.SpecialCount) {
                    var count = this.state.SpecialCount(dayDate);
                    if(count > 0) special = 'special';
                }
                return  dayName + ' ' + selected + ' ' + special;
            },
            GetMonth(){
                return this.selectedDate.format('MMMM');
            },
            DragStart(posx, posy, appendedData){
                this.appendedData = appendedData;
                this.posy = posy - 10,
                this.posy = posy - 10;
                this.toggled = true;
            },
            DragUpdate($event){
                this.posy = $event.pageX - 10;
                this.posy = $event.pageY - 10;
            },
            DragEnd(){
                this.toggled = false;
                
            },
            Toggle(){
                var picker = this;
                requestAnimationFrame(function(){
                    picker.toggled = false;
                });
            },
        },
        mounted() {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .datepicker{
        position: fixed;
        display: inline-block;
        background: var(--bg);
        box-shadow: 0 0 0 1px var(--contrast-1);
        padding: 10px;
        padding: 30px;
        border-radius: 5px;
        display: none;
        z-index: 1000;
        overflow: hidden;
        text-align: center;
        min-width: 283px;
    }
    .datepicker.calendar{
        width: 952px;
    }
    .datepicker.month-view{
        width: 283px;
    }
    .datepicker .background{
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        right: 0;
        z-index: -1;
        top: 0;
    }
    .datepicker .item{
        position: relative;
        display: block;
        height: 30px;
        text-align: center;

        padding: 0 1px;
        padding-top: 5px;
        color: black;
        cursor: pointer;
        transtion:.2s;
        background:gray;
        overflow:hidden;
    }
    .datepicker .item:hover{
        filter:brightness(140%);
    }
    .datepicker .show{
        display:inline-block;
    }
    .datepicker .month,.datepicker .year{
        color: var(--ml);
        padding: 0px;
        font-size: 20px;
        font-weight: 800;
        font-family: DistrictProBold;
        justify-content: center;
        width: 100%;
        pointer-events: none;
    }

    .datepicker .day{
        float: left;
        height: 25px;
        box-shadow: inset 0 0 0 1px var(--bg);
        width: 25px;
        background: #e5e5e5;
        color: white;
        font-size: 14px;
        font-width: 600;
        margin: 0 -1px -1px 0;
        cursor:pointer;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .datepicker .day .name{
        color: #404040;
        pointer-events: none;
        justify-items: center;
        display: flex;
        margin: 0;
        padding: 0;
        height: initial;
        align-self: center;
        position: relative;
        align-items: flex-start;
    }
    .datepicker .day:hover{
        background: #404040;
    }
    .datepicker .day:hover .name{
        color: white;
    }

    .datepicker .day.selected{

        background: var(--ml);
    }

    .datepicker .day.selected .name{

        color: white;
    }

    .datepicker .day.Monday:nth-child(1){
        margin-left: 29px
    }
    .datepicker .day.Tuesday:nth-child(1){
        margin-left: 58px;
    }
    .datepicker .day.Wednesday:nth-child(1){
        margin-left: 87px;
    }
    .datepicker .day.Thursday:nth-child(1){
        margin-left: 116px;
    }
    .datepicker .day.Friday:nth-child(1){
        margin-left: 145px;
    }
    .datepicker .day.Saturday:nth-child(1){
        margin-left: 174px;
    }
    .datepicker .day.Sunday{

        clear: right;
    }
    .datepicker .day.Montag:nth-child(1){
        margin-left: 0px
    }
    .datepicker .day.Dienstag:nth-child(1){
        margin-left: 24px;
    }
    .datepicker .day.Mittwoch:nth-child(1){
        margin-left: 48px;
    }
    .datepicker .day.Donnerstag:nth-child(1){
        margin-left: 72px;
    }
    .datepicker .day.Freitag:nth-child(1){
        margin-left: 96px;
    }
    .datepicker .day.Samstag:nth-child(1){
        margin-left: 120px;
    }
    .datepicker .day.Sonntag:nth-child(1){
        margin-left: 144px;
    }

    .datepicker .header .arrow{
        position:absolute;
        float:none;
        height:30px;
        width: 30px;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: 10px;
        cursor:pointer;
        box-shadow: none;
        background: var(--contrast-2);
        transform:scale(.8);
    }

    .datepicker .arrow.left{
        left: -150px;
    }
    .datepicker .arrow.right{
        right: -150px;
    }
    .datepicker .accept{
        clear:left;
    }


    .datepicker .separator{
        display: block;
        width: 100%;
        position: relative;
        height: 1px;
        clear: both;
    }

    .datepicker .button{
        width: 60px;
        margin: 5px;
        margin-bottom: 0;
        text-align: center;
        display: inline-block;
        float: right;
        clear: left;
    }

    .year-container{
        display: inline-block;
    }

    .month-container{
        width: 223px;
        float: left;
        padding: 0 27px;
    }

    .month-container:nth-child(4n + 1){
        clear: both;
    }

    .month-container .month{
        margin: 5px 0;
    }

    .options{
        float: left;
        margin-top: 0;
    }

    .header{
        position: relative;
        clear: both;
        display: flex;
    }

    .no-edit .day{
        pointer-events: none;
    }

    .count{
        border-radius: 100px;
        position: absolute;
        background: #e86e13;
        right: -3px;
        bottom: -3px;
        z-index: 20;
        width: 13px;
        height: 13px;
        font-size: 10px;
        line-height: 11px;
    }

    .day.special{
        background: var(--contrast-3);
    }

    .fa-stack.cancel{
        cursor: pointer;
        position: absolute;
        top: 6px;
        right: 5px;
        font-size: 10px;
        transform: scale(.8);
    }
    .fa-stack.cancel .fa-circle{
    }
    .fa-stack.cancel .fa-times{
        font-size: 13px;
        transform: translateY(3px) scale(.9);
    }

    .arrow:hover{
        background: var(--contrast-4) !important;
    }


    .weeks-container{
        width: 300px;
        float: left;
        padding: 0 27px;
        text-align: justify;
    }

    .weeks-container .month{
        width: 100px;
        float: none;
        margin-right: 10px;
        display: inline-flex;
        justify-content: flex-end;
    }

    .weeks-container .day{
        float: none;
        display: inline-flex;
    }


    .week-container{
        position: relative;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
    }


</style>
