<template>
    <div class="c-dropdown" v-bind:style="{top: posy + 'px', left: posx + 'px', display: toggled ? 'inline-block' : 'none'}">
        <div class="background" v-on:click="toggle()"></div>
        <div class="line username">
            <div class="label">Benutzername</div>
            <input placeholder="Benutzername"/>
        </div>
        <div class="line password">
            <div class="label">Passwort</div>
            <input/>
        </div>
        <div class="line signin">
            <div class="label">Passwort vergessen?</div>
            <div class="button">Login</div>
        </div>
        <div class="pointer"></div>
    </div>
</template>

<script>
    export default {
        name: 'dropdown',
        data(){
            return{
                selectedItem: {},
                items: [],
                toggled: false,
                posx: 0,
                posy: 0,
                onceSelected: {},
            }
        },
        methods: {
            selectItem(item){
                this.selectedItem = item;
                this.toggled = false;
                this.onceSelected(item);
            },
            loadItems(itemsNew){
                this.items = itemsNew;
                this.toggled = true;
            },
            toggle(){
                this.toggled = !this.toggled;
            },
        }
    }
</script>