
<template>
    <div class="datepicker" v-if="toggled" v-bind:style="{display: (toggled ? 'inline-block' : 'none'), top: posy + 'px', left: posx + 'px', zIndex: 10000}" v-bind:class="{'no-edit': noEdit, calendar: yearView}">
        <div class="background" v-on:click="Toggle()"></div>
        <div class="options">
            <div class="label" v-on:click="monthView = true, yearView = false" v-bind:class="{'selected': monthView}">Y</div>
            <div class="label" v-on:click="monthView = false, yearView = false" v-bind:class="{'selected': !monthView && !yearView}">M</div>
            <div class="label" v-on:click="monthView = false, yearView = true, Reposition()" v-bind:class="{'selected': yearView}">C</div>
        </div>
        <div class="header">
            <i class="fas fa-angle-left" v-on:click="PrevYear()"></i>
            <div class="year">{{selectedDate.format('YYYY')}}</div>
            <i class="fas fa-angle-right" v-on:click="NextYear()"></i>
        </div>
        <div class="header">
            <i v-if="!yearView" class="fas fa-angle-left" v-on:click="PrevMonth()"></i>
            <div v-if="!yearView" class="month">{{selectedDate.format('MMMM')}}</div>
            <i v-if="!yearView" class="fas fa-angle-right" v-on:click="NextMonth()"></i>
        </div>
        <div v-if="!monthView && !yearView" class="month-container">
            <div class="day"  v-bind:class="GetDayName(index - 1)" v-for="index in parseInt(selectedDate.daysInMonth())" :key="index"
                 v-on:click="PickDay(index)">
                <div class="name">{{index}}</div>
            </div>
        </div>
        <div v-if="yearView" class="year-container">
            <div v-for="monthIndex in 12" :key="monthIndex" class="month-container">
                <div class="month">{{selectedDate.clone().month(monthIndex - 1).date(1).format('MMMM')}}</div>
                <div>
                    <div class="day"  v-bind:class="GetDayMonthName(index - 1, monthIndex - 1)" v-for="index in parseInt(selectedDate.clone().month(monthIndex - 1).date(1).daysInMonth())" :key="index"
                         v-on:click="PickDayMonth(index - 1, monthIndex - 1)">
                        <div class="name">{{index}}</div>
                        <div v-if="CountMap(index, monthIndex) > 0" class="count">{{CountMap(index, monthIndex)}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="separator"></div>
        <div class="button" v-on:click="Toggle()">
            <div class="label">Cancel</div>
        </div>
        <div class="button" v-on:click="PassDate()">
            <div class="label">Accept</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'datepickerarray',
        data(){
            return{
                test: '',
                toggled: false,
                selectedDate: undefined,
                date: undefined,
                posx: 0,
                posy: 0,
                monthView: false,
                items: [],
                posxstart: 0,
                posystart: 0,
                appendedData: {},
                dateArray: [],
                yearView: false,
                noEdit: false,
                CountMap: function(index, month){ return 0}
            }
        },
        created(){
        },
        props: {
            state: Object
        },
        watch: {
            state: {
                deep: true,
                immediate: true,
                handler(newVal, oldVal){ //newVal === oldVal aaaaah
                    this.state = newVal;
                    this.toggled = newVal.toggled;
                    if(newVal.toggled){ //this is to make sure the picker is inbounds
                        var view = this;
                        requestAnimationFrame(function(){
                            view.Reposition();
                        });
                    }
                    this.posx = newVal.posx;
                    this.posy = newVal.posy;
                    this.date = newVal.date;
                    this.selectedDate = newVal.selectedDate;
                    this.monthView = newVal.monthView;
                    this.dateArray = newVal.dateArray;
                    this.yearView = newVal.yearView;
                    this.noEdit = newVal.noEdit;
                    this.CountMap = newVal.CountMap ? newVal.CountMap : function(){return 0;};

                }

            }
        },
        methods: {
            Reposition(){
                var view = this;
                requestAnimationFrame(function() {
                    var box = view.$el.getBoundingClientRect();
                    var widthTotal = window.innerWidth;
                    var heightTotal = window.innerHeight;
                    if (box.bottom > heightTotal) view.posy = heightTotal - box.height - 5;
                    if (box.right > widthTotal) view.posx = widthTotal - box.width - 5;
                    view.$forceUpdate();
                });
            },
            PassDate: function(){
                var picker = this;
                requestAnimationFrame(function(){
                    picker.toggled = false;
                });
                this.$emit('datepickerarray-dates-picked', this.dateArray);

            },
            PickDay:function(index){
                var temp = this.selectedDate.clone().date(index);
                if(this.dateArray.indexOf(temp.dayOfYear()) === -1) this.dateArray.push(temp.dayOfYear());
                else this.dateArray = this.dateArray.filter(d => d !== temp.dayOfYear());
                this.$forceUpdate();
            },
            PickDayMonth:function(index, month){
                var temp = this.selectedDate.clone();
                temp.date(index + 1).month(month);
                if(this.dateArray.indexOf(temp.dayOfYear()) === -1) this.dateArray.push(temp.dayOfYear());
                else this.dateArray = this.dateArray.filter(d => d !== temp.dayOfYear());
                this.$forceUpdate();
            },
            NextYear: function(){
                
                var daynum = parseInt(this.selectedDate.format('DD'));
                this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) + 1);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);

                this.$forceUpdate();
            },
            PrevYear: function(){

               

                var daynum = parseInt(this.selectedDate.format('DD'));
                this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) - 1);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);

                this.$forceUpdate();
            },
            NextMonth: function(){
                var year = this.selectedDate.year();
                var daynum = parseInt(this.selectedDate.format('DD'));
                var monthnum = parseInt(this.selectedDate.format('MM'));
                if(monthnum === 12) {
                    monthnum = 0;
                    this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) + 1);
                }
                this.selectedDate.date(1);
                this.selectedDate.month(monthnum);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);
                this.selectedDate.year(year);
                this.$forceUpdate();
            },
            PrevMonth: function(){

                var year = this.selectedDate.year();
                var daynum = parseInt(this.selectedDate.format('DD'));
                var monthnum = parseInt(this.selectedDate.format('MM'));
                if(monthnum === 0) {
                    monthnum = 11;
                    this.selectedDate.year(parseInt(this.selectedDate.format('YYYY')) - 1);
                }
                this.selectedDate.date(1);
                this.selectedDate.month(monthnum - 2);
                if(parseInt(this.selectedDate.daysInMonth()) < daynum) daynum = parseInt(this.selectedDate.daysInMonth());
                this.selectedDate.date(daynum);
                this.selectedDate.year(year);
                this.$forceUpdate();
            },
            GetDayName(index){
                var temp = this.selectedDate.clone().date(index + 1);
                return this.dateArray.indexOf(temp.dayOfYear()) !== -1 ? 'selected' : '';
            },
            GetDayMonthName(index, month){
                var temp = this.selectedDate.clone().date(index + 1).month(month);
                var selected = (this.dateArray.find(d => d === temp.dayOfYear()) ? 'selected' : '');
                var classes = temp.format('dddd');
                return classes + ' ' + selected;
            },
            GetMonth(){
                return this.selectedDate.format('MMMM');
            },
            DragStart(posx, posy, appendedData){
                this.appendedData = appendedData;
                this.posy = posy - 10,
                    this.posy = posy - 10;
                this.toggled = true;
            },
            DragUpdate($event){
                this.posy = $event.pageX - 10;
                this.posy = $event.pageY - 10;
            },
            DragEnd(){
                this.toggled = false;

            },
            Toggle(){
                var picker = this;
                requestAnimationFrame(function(){
                    picker.toggled = false;
                });
            },
        },
        mounted() {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .datepicker{
        position: fixed;
        display: inline-block;
        background: white;
        box-shadow: 0 0 0 1px #cccccc;
        padding: 10px;
        border-radius: 5px;
        display: none;
        z-index: 1000;
        overflow: hidden;
        text-align: center;
    }
    .datepicker.calendar{
        width: 912px;
    }
    .datepicker .background{
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        right: 0;
        z-index: -1;
        top: 0;
    }
    .datepicker .item{
        position: relative;
        display: block;
        height: 30px;
        text-align: center;

        padding: 0 1px;
        padding-top: 5px;
        color: black;
        cursor: pointer;
        transtion:.2s;
        background:gray;
        overflow:hidden;
    }
    .datepicker .item:hover{
        filter:brightness(140%);
    }
    .datepicker .show{
        display:inline-block;
    }
    .datepicker .month,.datepicker .year{
        color: #e86e13;
        padding: 0px;
        font-size: 20px;
        font-weight: 600;
        box-shadow: inset 0 -2px 0 -1px #cccccc;
    }
    .datepicker .month{

        margin: 5px 0 10px;
    }

    .datepicker .day{
        float: left;
        height: 30px;
        box-shadow: inset 0 0 0 1px black;
        width: 30px;
        background: #e5e5e5;
        color: white;
        padding-top: 4px;
        font-size: 16px;
        font-width: 600;
        margin: 0 -1px -1px 0;
        cursor:pointer;
        position: relative;
    }

    .datepicker .day .name{
        color: #404040;
        pointer-events: none;
    }
    .datepicker .day:hover{
        background: #404040;
    }
    .datepicker .day:hover .name{
        color: white;
    }

    .datepicker .day.selected{

        background: #e86e13;
    }

    .datepicker .day.selected .name{

        color: white;
    }

    .datepicker .day.Monday:nth-child(1){
        margin-left: 29px
    }
    .datepicker .day.Tuesday:nth-child(1){
        margin-left: 58px;
    }
    .datepicker .day.Wednesday:nth-child(1){
        margin-left: 87px;
    }
    .datepicker .day.Thursday:nth-child(1){
        margin-left: 116px;
    }
    .datepicker .day.Friday:nth-child(1){
        margin-left: 145px;
    }
    .datepicker .day.Saturday:nth-child(1){
        margin-left: 174px;
    }
    .datepicker .day.Sunday{

        clear: right;
    }
    .datepicker .day.Montag:nth-child(1){
        margin-left: 29px
    }
    .datepicker .day.Dienstag:nth-child(1){
        margin-left: 58px;
    }
    .datepicker .day.Mittwoch:nth-child(1){
        margin-left: 87px;
    }
    .datepicker .day.Donnerstag:nth-child(1){
        margin-left: 116px;
    }
    .datepicker .day.Freitag:nth-child(1){
        margin-left: 145px;
    }
    .datepicker .day.Samstag:nth-child(1){
        margin-left: 174px;
    }

    .datepicker .day.Sonntag{
        clear: right;
    }

    .datepicker .fas{
        position:absolute;
        float:none;
        height:30px;
        width: 30px;
        top: 0;
        padding-top: 10px;
        cursor:pointer;
    }
    .datepicker .fa-angle-left{
        left: 5px;
    }
    .datepicker .fa-angle-right{
        right: 5px;
    }
    .datepicker .accept{
        clear:left;
    }

    .datepicker .separator{
        display: block;
        width: 100%;
        position: relative;
        height: 1px;
        clear: both;
    }

    .datepicker .button{
        width: 60px;
        margin: 5px;
        margin-bottom: 0;
        text-align: center;
        display: inline-block;
        float: right;
        clear: left;
    }

    .year-container{
        display: inline-block;
    }

    .month-container{
        width: 223px;
        float: left;
    }

    .month-container:nth-child(4n + 1){
        clear: both;
    }

    .month-container .month{

    }

    .options{
        float: left;
        margin-top: 0;
    }

    .header{
        position: relative;
        clear: both;
    }

    .no-edit .day{
        pointer-events: none;
    }

    .count{
        border-radius: 100px;
        position: absolute;
        background: #e86e13;
        right: -3px;
        bottom: -3px;
        z-index: 20;
        width: 13px;
        height: 13px;
        font-size: 10px;
        line-height: 11px;
    }
</style>
