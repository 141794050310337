<template>
    <div class="popupalert " v-if="!hidden" v-bind:class="[message.type]">
        <div class="background"></div>
        <div class="container">
            <div class="fa-stack cancel" v-on:click="Cancel()">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-stack-2x fa-times"></i>
            </div>
            <div class="header">
                <div class="label" v-bind:style="{width: !message.number || message.number === '' ? '100%' : ''}">{{message.header}}</div>
                <div class="label number">{{message.number}}</div>
            </div>
            <div class="description">
                <p v-html="message.description"></p>
            </div>
            <div class="button accept" v-bind:class="{disabled: loading}" v-on:click="Accept()" v-if="!message.disableAccept">
                <div class="label" v-if="!loading">{{message.acceptLabel ? message.acceptLabel : 'OK'}}</div>
                <div v-if="loading" class="loading">
                    <i class="fas fa-spinner"></i>
                </div>
            </div>
            <div class="button altaccept" v-bind:class="{disabled: loading}" v-on:click="AltAccept()" v-if="message.enableAltAccept">
                <div class="label" v-if="!loading">{{message.altAcceptLabel ? message.altAcceptLabel : 'OK'}}</div>
                <div v-if="loading" class="loading">
                    <i class="fas fa-spinner"></i>
                </div>
            </div>
            <div class="button decline" v-bind:class="{disabled: loading}" v-on:click="Cancel()" v-if="!message.disableCancel">
                <div class="label" v-if="!loading">{{message.cancelLabel ? message.cancelLabel : 'Abbrechen'}}</div>
                <div v-if="loading" class="loading">
                    <i class="fas fa-spinner"></i>
                </div>
            </div>
            <div class="button more" v-bind:class="{disabled: loading}" v-on:click="openExternal()" v-if="message.enableExternal">
                <div class="label" v-if="!loading">{{message.externalLabel ? message.externalLabel : 'Mehr erfahren'}}</div>
                <div v-if="loading" class="loading">
                    <i class="fas fa-spinner"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "popupalert",

        created(){
        },
        destroyed () {
        },
        data(){
            return{
                header: '',
                description: '',
                type: '',
                type: 'html',
                timer: 0,
                hidden: false,
                loading: false,
            }

        },
        methods: {
            Accept(){
                if(this.loading) return;
                if(this.message.callbackAccept) this.message.callbackAccept(this);
                else this.hidden = true;
            },
            AltAccept(){
                if(this.loading) return;
                if(this.message.callbackAltAccept) this.message.callbackAltAccept(this);
                else this.hidden = true;
            },
            Cancel(){
                if(this.loading) return;
                if(this.message.callbackCancel) this.message.callbackCancel(this);
                else this.hidden = true;
            },
            openExternal(){
                window.open(this.message.externalUrl, "_blank");
                this.hidden = true;
            }
        },
        props:{
            message: Object
        }
    }
</script>

<style scoped>
    .popupalert{
        transition: 1s;
        /*display:none;*/
        z-index: 1050;
        position: fixed;
        overflow: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100vh;
        width: 100vw;
    }

    .popupalert .container{
        border-radius: 5px;
        background: var(--bg);
        position: absolute;
        box-shadow: 4px 4px 5px 3px rgba(0,0,0,0.2), 0 0 0 1px var(--contrast-3);
        height: fit-content;
        width: fit-content;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        min-width: 200px;
        max-width: 650px;
        text-align: center;
        padding: 40px;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: .5;
    }
    /*.popupalert.fade{
           opacity: 1;
        animation: fade forwards;
        animation-delay: 3000ms;
        animation-duration: 1000ms;
        animation-timing-function: ease-out;
    }
      @keyframes fade {
        from {
           opacity: 1;
        }
        to {

           opacity: 0;
        }
    }*/
    .popupalert.confirmed .background{
        background: var(--ml);
        background: #f1ffcc;
    }
    .popupalert.error .background{
        background: var(--ml);
        background: #ffe1cc;
    }

    .popupalert .header{

        /*background: rgba(0,0,0,.2);*/
        padding: 3px 0;
    }

    .popupalert .header .label{
        float: left;
        box-shadow: inset 0 -1px 0 0px var(--sub-menu);
        font-size: 23px;
        margin-left: 10px;
        margin-bottom: 5px;
        font-family: DistrictProBold;
        text-align: left;
    }

    .popupalert.confirmed .header .label{
        color: var(--ml);
    }
    .popupalert.error .header .label{
        color: #e5154f;
    }

    .popupalert .description{
        text-align: left;
        padding: 10px;
        color: var(--contrast-4);
        display: block;
        clear: both;
        width: 100%;
    }
    .popupalert .button{
        margin: 0 7.5px;
        margin-bottom: 10px;
        min-width: 55px;
    }

    .loading{
        position: relative;
        display: inline-block;
        width: 100%;
        height: initial;
        background: none;
    }

      .loading .fa-spinner{
        position:absolute;
        top: -9px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        text-align: center;
        padding: 0;
        font-size: 17px;
        color: #cccccc;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      @keyframes spin {
        from {
          transform:rotate(0deg);
        }
        to {
          transform:rotate(360deg);
        }
      }



    .fa-stack.cancel{
        cursor: pointer;
        position: absolute;
        top: 6px;
        right: 5px;
        font-size: 10px;
        transform: scale(.8);
    }
    .fa-stack.cancel .fa-circle{
    }
    .fa-stack.cancel .fa-times{
        font-size: 13px;
        transform: translateY(3px) scale(.9);
    }

    .header .label.number{
        color: var(--contrast-3);
        margin-left: -1px;
        padding-left: 20px;
        font-family: DistrictProBook;
    }

    .button.accept{
        float: left;
        background: var(--ml);
        box-shadow: inset 0 0 0px 1px var(--contrast-4);
    }

    .button.altaccept{
        float: right;
        background: var(--ml);
        box-shadow: inset 0 0 0px 1px var(--contrast-4);
    }

    .button.accept:hover{
        float: left;
        background: var(--contrast-4);;
    }

    .button.altaccept:hover{
        background: var(--contrast-4);;
    }

    .button.more{
        float: right;
        background: #1e9e9a;
        box-shadow: inset 0 0 0px 1px var(--contrast-4);
    }

    .button.more:hover{
        float: right;
        background: var(--contrast-4);;
    }

    .button .label{
        font-family: DistrictProLight;
        font-weight: 400;
    }

    .header .number, .description{
        font-family: DistrictProLight;
        font-weight: 400;
    }

    .button.accept .label{
        color: white;
    }

    .button.altaccept .label{
        color: white;
    }

    .button.more .label{
        color: white;
    }

    .button.decline{
        float: right;
    }

</style>