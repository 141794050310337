<template>
  <div class="login">
    <dropdowncontainer ref="container" :state="state"/>
  </div>
</template>

<script>
    import dropdowncontainer from '../components/uicomponents/dropdowncontainer.vue'
    import labelc from '../components/uicomponents/label.vue'
    import buttonc from '../components/uicomponents/button.vue'
    export default {
        components: {
            dropdowncontainer,
            labelc,
            buttonc
        },
        name: "Login",
        data() {
            return {
                state: {
                    components: [
                        {type: 'loginpanel'}
                    ],
                    centered: true,
                    show: true,
                    toggled: true,
                    expanded: true,
                    locked: true,
                }
            }
        },
        mounted(){
          this.$forceUpdate();
        },
        beforeDestroy() {
          this.state.locked = false;
            this.state.toggled = false;
            this.$el.appendChild(this.$refs.container.$el);
        }
    }
</script>

<style scoped>

</style>
