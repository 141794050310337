/* eslint-disable */
<template>

<div class="container">
	        <div class="card card-container loginwidth">
	            <img id="profile-img" class="profile-img-card" src="../assets/serviceimg/logo.png" />
	            <p id="profile-name" class="profile-name-card"></p>
	            <h2 style="text-align: center">{{message}}</h2>
				<h2>{{message2}}</h2>
	        </div><!-- /card-container -->
			<v-dialog/>
    	</div><!-- /container -->
</template>

<script>
import axios from 'axios'
export default {
  	name: 'Activation',
  	data () {
		return {
			email:"",
			token:"",
			message: "Bitte warten Sie, ihr Account wird aktiviert!",
			message2: ""
		}
  	},
  	created: function () {
        this.email = this.$route.params.email;
        this.token = this.$route.params.token;
        if(!this.isBlank(this.email) && !this.isBlank(this.token)){
        	this.submitActivation();
        } else {
			this.message = "Fehler beim aktivieren!";
			this.message2 = "";
			console.log("activation parameters missing");
		}
	},
	// methods that implement data logic.
	methods : {
		isBlank: function (str) {
		    return (!str || /^\s*$/.test(str));
		},
		getQueryStringValue: function (key) {
			  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
		},
		submitActivation : function() {

			var payload = {
					command: "activateUser",
					email: this.email,
					token: this.token
			}
			let view = this;
			axios({
				method: 'put',
				url: '/api/user',
				data: payload
			}).then(response => {
				let token = response.data.data;
                localStorage.setItem('md_token', token);
				view.message = "Erfolgreich aktiviert!";
				view.message2 = "";

				window.setTimeout(view.returnToIndex, 3000);
			}).catch(function (error) {
				view.message = "Fehler beim Aktivieren!";
				view.message2 = "Bitte versuchen sie es erneut.";
				if(error.response != undefined && error.response.data.message != undefined){
					console.log(error.response.data.message);
					view.toggleModal('Fehler', 'Ihr Account konnte nicht aktiviert werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
				}
			})

		},
		returnToIndex: function(){
			this.$router.replace({name: 'Login'});
		},
      toggleModal(title, text, text2) {
          this.$helpers.OpenInfoPopup(title, [text, text2]);
      },
	},
	watch: {
		'$route' (to, from) {
			this.email = to.params.email;
        	this.token = to.params.token;
        	if(!this.isBlank(this.email) && !this.isBlank(this.token)){
        		this.submitActivation();
        	} else {
				this.message = "Fehler beim aktivieren!";
				this.message2 = "";
				console.log("activation parameters missing");
			}
		}
  	}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginwidth {
    max-width: 600px !important;
}
</style>
