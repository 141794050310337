<template>
    <div class="popupmessage" v-bind:class="{popupmessagegreen: ['confirmed', 'success'].includes(message.type), popupmessageblue: message.type == 'info', popupmessageyellow: message.type == 'warning', popupmessagered: message.type == 'error'}">
        <div class="popupmessage__icon">
            <img :src="['confirmed', 'success'].includes(message.type)? '../img/check.png' : message.type == 'error'? '../img/error.png' : message.type == 'warning'? '../img/warning.png' : '../img/info.png'"/>
        </div>
        <div class="popupmessage__content">
            <p class="popupmessage__type">{{ message.header }}</p>
            <p class="popupmessage__message">{{ message.description }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "popupmessage",

        created(){
            var message = this;
            requestAnimationFrame(function(){
                var el = message.$el;
                if((message.message.html && message.message.html != ''))
                {
                    var frame = $('<iframe></iframe>')
                    frame.parent($(el));
                    frame[0].document = message.message.html;
                    //$(el).html(message.message.html);
                }
                setTimeout(function(){
                    el.style.opacity = 0;
                    el.style.marginTop = (-1 * (el.getBoundingClientRect().height + 40)).toString() + 'px';
                    el.style.marginBottom = (40).toString() + 'px';

                    setTimeout(function(){
                        message.hidden = true;
                        window.app.popupmessages = window.app.popupmessages.filter(p => new Date().getTime() - p.startTime < p.timer ? p.timer : 2000)
                    }, 1000);
                }, message.message.timer ? message.message.timer : 2000);
                return;
            });

        },
        destroyed () {
        },
        data(){
            return{
                header: '',
                description: '',
                type: '',
                type: 'html',
                timer: 0,
                hidden: false,
            }

        },
        methods: {
        },
        props:{
            message: Object
        }
    }
</script>

<style scoped>
    .add-margin{
    margin-top:20px;
    }

    .popupmessage__svg{
        fill:#fff;
    }

    .popupmessage {
        width: 350px;
        margin-bottom: 20px;
        transition: 1s;
        z-index: 1000;
        overflow: hidden;
        text-align:left;
        padding: 21px 0;
        background-color:#fff;
        border-radius:4px;
        max-width: 500px;
        top: 0px;
        left: 4px;
        position:relative;
        box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);
    }

    .popupmessage:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        border-top-left-radius:4px;
        border-bottom-left-radius: 4px;
    }

    .popupmessage__icon{
        position:absolute;
        top:50%;
        left:20px;
        transform:translateY(-50%);
        width:36px;
        height:36px;
        border-radius:50%;
        display:inline-block;
    }

    .popupmessage__type {
        color: #3e3e3e;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 8px;
    }

    .popupmessage__message {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0;
        color: #878787;
    }

    .popupmessage__content{
        padding-left:70px;
        padding-right:60px;
    }

    .popupmessagegreen:before{
        background:#41AD49;
    }

    .popupmessageblue:before{
        background:#4169E1;
    }
    
    .popupmessageyellow:before{
        background:#FEA500;
    }

    .popupmessagered:before{
        background:#ED1B2E
    }
</style>