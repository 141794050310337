<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      Finanzbuchhaltungs Kreise
    </div>

    <div>
      <table class="table">
        <tr>
          <th>Nummer</th>
          <th>Name</th>
          <th>Farbe</th>
          <th>Standard FIBU</th>
          <th>Bearbeiten</th>
          <th>Löschen</th>
        </tr>
        <tr v-for="x in financialAccountingGroups" v-bind:key="x.id">
          <td>{{x.number}}</td>
          <td>{{x.name}}</td>
          <td style="display: flex; align-items: center; align-content: center;">
            <div style="width:20px; height:20px; background-color:#f00; text-align:center; cursor:pointer; margin:0 auto;" v-bind:style="{'background-color':x.color}" v-on:click="toggleColorPicker(x)"></div>
          </td>
          <td><i class="fas fa-check-circle checkbox" v-bind:class="{false: !x.isDefault}" v-on:click="toggleDefaultFinancialAccountingGroup(x)"></i></td>
          <td><i class="fas fa-edit" v-on:click="openEditFinancialAccountingGroup(x)"></i></td>
          <td><i class="fas fa-trash" v-on:click="openDeleteFinancialAccountingGroup(x)"></i></td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'financialaccountinggroups',
  data () {
    return {
      loading: true,
      saves: false,
      financialAccountingGroups: [],
    }
  },
  created: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  mounted: function () {
  },
  computed: {
        ...mapGetters(['companyId']),
          headerButtons() {
              let view = this;

              let state= {
                  buttons: []
              };
              state.buttons.push({
                  type: "block",
                  icon: "fa-refresh",
                  click: () => {
                     view.refresh();
                  },
                  tooltip: 'Daten neu laden'
              });
              state.buttons.push({
                  type: "block",
                  icon: "fa-plus",
                  click: ($event) => {
                     view.openNewFinancialAccountingGroup($event);
                  },
                  tooltip: 'Einen neuen FIBU Kreis anlegen'
              });
              return state;
          },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
      let view = this;
      view.loading = true;
      view.loadFinancialAccountingGroups();
    },
    loadFinancialAccountingGroups: function(){
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers.GetCall('/api/financialaccountinggroups', params).then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            number: data[i].number,
            name: data[i].name,
            sorting: data[i].sorting,
            color: data[i].color,
            isDefault: data[i].isDefault,
          };
          tmp.push(item);
        }
        view.financialAccountingGroups = tmp;
      }).catch((e) => {
          view.loading = false;
          console.log(e);
      });
    },



    openNewFinancialAccountingGroup(event){
        let view = this;
        let dataEntries = [
            {label: 'Nummer: ', key: 'number', hidden: false, type: 'numeric', value: 0, min: 0, max: 65535, styles: {'width': '350px'}},
            {label: 'Name: ', key: 'name', hidden: false, type: 'text', value: '', length: 128, styles: {'width': '350px'}},
            //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: 1, slider: {value: 1, min: 1, max: 100}},
            {flexDivider: true, styles: {'margin-bottom': '23px'}},
        ];
        this.$helpers.OpenPopup({
            elRelative: event.currentTarget,
            type: 'datafill',
            pointerDirection: 0,
            componentState: {
                entries: dataEntries,
                selectCallback: function (newFIBU) {
                    view.createFinancialAccountingGroup(newFIBU);
                },
                errorCheck: {
                  'number': (entries) => {
                    if (entries.hasOwnProperty('number') && view.financialAccountingGroups.find(f => parseInt(f.number) === parseInt(entries.number)))
                      return {
                        key: 'number',
                        type: 'error',
                        text: 'Wird bereits verwendet'
                      }
                    else return null
                  },
                  'name': (entries) => {
                    if (entries.hasOwnProperty('name') && view.financialAccountingGroups.find(f => f.name === entries.name))
                      return {
                        key: 'name',
                        type: 'error',
                        text: 'Wird bereits verwendet'
                      }
                    else return null
                  }
                }
            },
            header: 'Neuer Finanzbuchhaltungskreis',
            remainAfterSelect: false,
            expanded: true,
        });
    },
    openEditFinancialAccountingGroup(fibu){
        let view = this;

        let dataEntries = [
            {label: 'id: ', key: 'id', hidden: true, type: 'text', value: fibu.id, length: 128, styles: {'width': '350px'}},
            {label: 'Nummer: ', key: 'number', hidden: false, type: 'numeric', value: 0, min: 0, max: 65535, styles: {'width': '350px'}},
            {label: 'Name: ', key: 'name', hidden: false, type: 'text', value: fibu.name, length: 128, styles: {'width': '350px'}},
            //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: fibu.sorting, slider: {value: fibu.sorting, min: 1, max: 100}},
            {flexDivider: true, styles: {'margin-bottom': '23px'}},
        ];
        this.$helpers.OpenPopup({
            elRelative: null,
            type: 'datafill',
            pointerDirection: 0,
            componentState: {
                entries: dataEntries,
                selectCallback: function (eFibu) {
                    view.editFinancialAccountingGroup(eFibu);
                },
                errorCheck: {
                  'number': (entries) => {
                    if (entries.hasOwnProperty('number') && view.financialAccountingGroups.find(f => f.number !== fibu.number && f.number === entries.number))
                      return {
                        key: 'number',
                        type: 'error',
                        text: 'Wird bereits verwendet'
                      }
                    else return null
                  },
                  'name': (entries) => {
                    if (entries.hasOwnProperty('name') && view.financialAccountingGroups.find(f => f.name !== fibu.name && f.name === entries.name))
                      return {
                        key: 'name',
                        type: 'error',
                        text: 'Wird bereits verwendet'
                      }
                    else return null
                  }
                }
            },
            header: 'Finanzbuchhaltungskreis bearbeiten',
            remainAfterSelect: false,
            centered: true,
            expanded: true,
        });
    },
    openDeleteFinancialAccountingGroup(fibu){
        let view = this;

        let dataEntries = [
            {label: 'id: ', key: 'id', hidden: true, type: 'text', value: fibu.id, length: 128, styles: {'width': '350px'}},
            {label: '', key: 'number', hidden: false, type: 'output', value: "Soll das Element wirklich gelöscht werden?"},
            {label: 'Name: ', key: 'name', hidden: false, type: 'output', value: fibu.name, length: 128, styles: {'width': '350px'}},
            {flexDivider: true, styles: {'margin-bottom': '23px'}},
        ];
        this.$helpers.OpenPopup({
            elRelative: null,
            type: 'datafill',
            pointerDirection: 0,
            componentState: {
              positiveButtonLabel: "Löschen",
                entries: dataEntries,
                selectCallback: function (dFibu) {
                    view.deleteFinancialAccountingGroup(dFibu);
                }
            },
            header: 'Finanzbuchhaltungskreis löschen',
            remainAfterSelect: false,
            centered: true,
            expanded: true,
        });
    },



    createFinancialAccountingGroup(newFIBU){
        let view = this;
        let url = '/api/financialaccountinggroups';
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/financialaccountinggroups');
        let payload = {
          companyId: this.$store.state.companyId,
          number: newFIBU.number,
          name: newFIBU.name,
          priority: 0,//newFIBU.priority,
        }
        this.axios({
            method: 'post',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
        }).then(response => {
            view.$helpers.success('Finanzbuchhaltungskreis erstellt', 'Finanzbuchhaltungskreis erstellt');
            view.refresh(true);
        }).catch(function (error) {
            console.log(error)
        })
    },
    editFinancialAccountingGroup(editFIBU){
        let view = this;
        let url = '/api/financialaccountinggroups/'+editFIBU.id;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/financialaccountinggroups/'+editFIBU.id);
        let payload = {
          companyId: this.$store.state.companyId,
          number: editFIBU.number,
          name: editFIBU.name,
          priority: 0,//editFIBU.priority,
        }
        this.axios({
            method: 'put',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
        }).then(response => {
            view.$helpers.success('Finanzbuchhaltungskreis geändert', 'Finanzbuchhaltungskreis geändert');
            view.refresh(true);
        }).catch(function (error) {
            console.log(error)
        })
    },
    toggleDefaultFinancialAccountingGroup(editFIBU){
        let view = this;

        if (editFIBU.isDefault) {
          let url = '/api/financialaccountinggroups/'+editFIBU.id;
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/financialaccountinggroups/'+editFIBU.id);
          let payload = {
            companyId: this.$store.state.companyId,
            isDefault: false,
          }
          this.axios({
            method: 'put',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
          }).then(response => {
              view.$helpers.success('Finanzbuchhaltungskreis geändert', 'Finanzbuchhaltungskreis geändert');
              view.refresh(true);
          }).catch(function (error) {
              console.log(error)
          });
        } else {
          let promises = [];

          let url = '/api/financialaccountinggroups/'+editFIBU.id;
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/financialaccountinggroups/'+editFIBU.id);
          let payload = {
            companyId: this.$store.state.companyId,
            isDefault: true,
          }
          promises.push(
            this.axios({
              method: 'put',
              headers: {
                  'AUTHORIZATION': "Bearer "+view.$store.state.jwt
              },
              url: url,
              data: payload
            })
          );
          view.financialAccountingGroups.forEach(fibu => {
            if (fibu.id !== editFIBU.id && fibu.isDefault) {
              let url = '/api/financialaccountinggroups/'+fibu.id;
              url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/financialaccountinggroups/'+fibu.id);
              let payload = {
                companyId: this.$store.state.companyId,
                isDefault: false,
              }
              promises.push(
                this.axios({
                  method: 'put',
                  headers: {
                      'AUTHORIZATION': "Bearer "+view.$store.state.jwt
                  },
                  url: url,
                  data: payload
                })
              );
            }
          });
          Promise.all(promises).then(response => {
                view.$helpers.success('Finanzbuchhaltungskreise geändert', 'Finanzbuchhaltungskreise geändert');
                view.refresh(true);
            }).catch(function (error) {
                console.log(error)
            });
        }
    },
    deleteFinancialAccountingGroup(deleteFIBU){
        let view = this;
        console.log(deleteFIBU);
        let url = '/api/financialaccountinggroups/'+deleteFIBU.id;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/financialaccountinggroups/'+deleteFIBU.id);
        let payload = {
          companyId: this.$store.state.companyId,
        }
        this.axios({
            method: 'delete',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
        }).then(response => {
            view.$helpers.success('Finanzbuchhaltungskreis gelöscht', 'Finanzbuchhaltungskreis gelöscht');
            view.refresh(true);
        }).catch(function (error) {
            console.log(error)
        })
    },


    toggleColorPicker(fibu, val = null){
        let view = this;
        this.$helpers.OpenColorSelection(
            (color) => {
                fibu.color = color;
                view.saveColor(fibu);
            },
            {
                colorSelected: fibu.color,
                colorBlacklist: this.financialAccountingGroups.map(s => {
                    return {color: s.color, label: s.name}
                })
            }
        );
    },
    saveColor(editFIBU){
        let view = this;
        let url = '/api/financialaccountinggroups/'+editFIBU.id;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/financialaccountinggroups/'+editFIBU.id);
        let payload = {
          companyId: this.$store.state.companyId,
          color: editFIBU.color,
        }
        this.axios({
            method: 'put',
            headers: {
                'AUTHORIZATION': "Bearer "+view.$store.state.jwt
            },
            url: url,
            data: payload
        }).then(response => {
            view.$helpers.success('Farbe geändert', 'Farbe geändert');
            view.refresh(true);
        }).catch(function (error) {
            console.log(error)
        })
    },


  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>


.name-filter .fa-search {
    color: var(--contrast-4);
    position: absolute;
    top: 5px;
    left: 5px;
}

.half-width {
  width: 50%;
}

.fix-button-width {
  width: 150px;
}

.filter-input {
  margin-left: -45px;
  padding-left: 40px;
  width: calc(100% - 25px);
}

.list {
  width: calc(100% - 78px);
  margin: auto;
  height: 265px;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
}
.list .scroll-content {
  padding: 5px;
  overflow-y: auto;
  height: 265px;
}

.list-entry {
  height: 35px;
  margin-bottom: 2px;
  border-radius: 100px;
  cursor: pointer;
}


.list-entry:hover {
  background: var(--sub-menu);
  cursor: pointer;
}
.list-entry.selected {
  background: #e51550;
  cursor: pointer;
}
.list-entry.selected > .label,
.list-entry:hover > .label{
  color: var(--bg);
  cursor: pointer;
}
.list-entry.selected > .label > .label-small,
.list-entry.hover > .label > .label-small {
  color: var(--bg) !important;
  cursor: pointer;
}


.list-entry > .label {
  float: left;
  font-size: 14px;
  font-family: DistrictProBook;
  color: var(--contrast-4);
  padding-top: 6px;
  cursor: pointer;
}
.list-entry > .label > .label-small {
  cursor: pointer;
  color:var(--contrast-5);
}


.picture {
  float: left;
  border-radius: 100px;
  background: var(--contrast-1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.position-right{
  float: right;
  margin: 0;
  margin-right: -5px;
}
.pictureImg {
  border-radius: 100px;
  height: 25px;
  width: 25px;
}





/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--contrast-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--contrast-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}
.has-header > .has-header > .scrollbar-container{
    height: calc(100% - var(--header-height) - var(--tab-menu-height)) !important;
    overflow-x: hidden;
    top: var(--header-height);
    padding: 0px 14px;
}
</style>
