<template>
<div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
        <div class="align-left">
        </div>
        <div class="align-middle">
            <headerbuttonstack :state="headerButtons" />
        </div>
        <div class="align-right">
        </div>
    </div>
    <div class="scrollbar-container">
        <b-container fluid>
            <!-- <div class="full m20-top">
                <div class="mainheader">
                    <div class="half">
                        Dokumente Vorlagen
                    </div>
                </div>
            </div> -->

            <div class="label left" v-if="loading">
                <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
                Vorlagen werden geladen<br />
            </div>

            <div class="label left" v-if="!loading && !hasAccessRights">
                <strong>Sie haben nicht die Berechtigung um die Vorlagen in diesem Store anzusehen.</strong>
                <br />
                Bitte wenden Sie sich an Ihren Store Administrator, er kann Ihnen die erforderlichen Rechte zuweisen.
            </div>

            <div class="label left" v-if="!loading && hasAccessRights && otherError">
                <strong>Bei der Datenabfrage ist ein Fehler aufgetreten.</strong>
                <br />
                Bitte versuchen Sie die Seite neu zu laden.<br />
                <br />
                Sollte der Fehler erneut auftreten wenden Sie sich bitte an den Support.<br />
                <small class="text-muted">&lt; {{ otherErrorMessage }} &gt;</small>
            </div>

            <div class="label left" v-if="!loading && hasAccessRights && !otherError && allTemplates.length == 0">
                Es wurden noch keine Vorlagen hochgeladen, klicken Sie auf das "+" in der Bedienleiste um eine Vorlage hochzuladen.
            </div>

            <div v-if="!loading && hasAccessRights && !otherError && allTemplates.length > 0">
                <div class="full">
                <div class="header">
                    <div class="label">Firma oder Betrieb auswählen</div>
                </div>
            </div>
                <div style="width: 100%; text-align: left;">
                    <select v-model="templatesSelect">
                        <option value="-1" key="-1" >Firmenweite Vorlagen</option>
                        <option
                            v-for="store in stores" v-bind:key="store.id"
                            :value="store.id">
                            {{ store.name }}
                        </option>
                    </select>
                </div>
                <div class="full m20-top split-bottom">
                
                    <!-- <div class="mainheader">
                            <div>{{ templatesSelect == -1? 'Firmenweite Vorlagen' : ('Vorlagen ' + stores.find((s) => s.id == templatesSelect).name) }}</div>
                    </div> -->
                    <div class="full" v-for="type in templateTypes" v-bind:key="type.id">
                        <div v-if="templatesForType(type.id, templatesSelect) != undefined && templatesForType(type.id, templatesSelect).length > 0" class="header">
                            <div class="label">{{ type.label_de }}</div>
                        </div>
                        <div v-if="templatesForType(type.id, templatesSelect) != undefined && templatesForType(type.id, templatesSelect).length > 0" class="full">
                            <b-table class="documents" striped hover fixed :borderless="true" :outlined="false" :items="templatesForType(type.id, templatesSelect)" :fields="fields">
                                <template v-slot:cell(link)="row">
                                    <a :href="row.item.url+'/api/file?id='+row.item.fileId+'&accessToken='+row.item.accessToken" target="_blank"><i class="fas fa-eye"></i></a>
                                </template>
                                <template v-slot:cell(actions)="row">
                                    <div class="button small mright8" v-on:click="openFile(row)">
                                        <div class="label">Herunterladen</div>
                                    </div>
                                    <div class="button small mright8" v-on:click="openUploadTemplatePopup(row.item)">
                                        <div class="label">Aktualisieren</div>
                                    </div>
                                    <div disabled class="button small" v-on:click="deleteDocumentTemplate(row.item)">
                                        <div class="label">Löschen</div>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div v-else>
                            <div class="label left">
                                Es gibt noch keine Vorlagen vom Typ "{{ type.label_de }}" "{{ templatesSelect == -1? ' die firmenweit verfügbar sind.' : ' für den Betrieb ' + stores.find((s) => s.id == templatesSelect).name }}" hochgeladen, klicken Sie auf das "+" in der Bedienleiste um eine Vorlage hochzuladen.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</div>
</template>

<script>
import {
    mapState,
    mapGetters
} from 'vuex';
export default {
    name: 'documenttemplates',
    data() {
        return {
            loading: true,
            longLoading: false,
            templateTypes: [],
            allTemplates: [],
            templatesSelect: -1,
            templatesMap: {},
            companyTemplates: [],
            stores: [],
            hasAccessRights: true,
            otherError: false,
            otherErrorMessage: '',
            fields: [{
                key: "name",
                sortable: false,
                thClass: 'documentsTh',
                tdClass: 'documentsTd'
            }, {
                key: "created_at",
                label: "Datum",
                formatter: "creationDate",
                sortable: false,
                thClass: 'documentsTh',
                tdClass: 'documentsTd'
            }, {
                key: "updated_at",
                label: "Aktualisiert",
                formatter: "creationDate",
                sortable: false,
                thClass: 'documentsTh',
                tdClass: 'documentsTd'
            }, {
                key: "actions",
                label: "",
                sortable: false,
                thClass: 'documentsTh',
                tdClass: 'documentsTd'
            }],
        }
    },
    created: function () {
        //console.log("CREATED");
    },
    mounted: function () {
        //console.log("MOUNTED");
        if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
            this.refresh();
        }
    },
    computed: {
        ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings', 'selectedStores']),
        dateDisplay: function () {
            return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
        },
        headerButtons() {
            let view = this;

            let state = {
                buttons: [{
                    type: "block",
                    icon: "fa-plus",
                    click: () => {
                        view.openUploadTemplatePopup();
                    },
                    tooltip: 'Vorlage hinzufügen',
                    helperText: 'Sie können eine neue Vorlage im DOCX (Microsoft Word) Format hochladen um diese anschließend in MEINDIENSTPLAN automatisch befüllen zu lassen.',
                    externalUrl: 'https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/article/1665040385?src=1537747213'
                }]
            };

            return state;
        },
        timeZoneList() {
            let result = [];
            /*
            this.timeZones.forEach(i => {
                let item = {label: i, value: i};
                result.push(item);
            })
            */
            return result;
        },
    },
    watch: {
        companyId: function (val, oldVal) {
            this.refresh();
        },
    },
    // methods that implement data logic.
    methods: {
        refresh: function (force = false) {
            let view = this;
            this.$helpers.GetStores(false, true).then((response) => {
                console.log(response);
                this.stores = response;
                this.loadTemplateTypes().then((response) => {
                    console.log(response);
                    view.templateTypes = response.data.data;
                    view.loadDocumentTemplates().then((response) => {
                        console.log(response);
                        this.templatesMap = {};
                        this.companyTemplates = [];
                        /* this.stores.forEach((s) => {
                            this.templatesMap[s.id] = [];
                        }); */
                        view.allTemplates = response.data.data[0];
                        view.allTemplates.forEach((t) => {
                            if(t.storeId != null){
                                if(!(t.storeId in this.templatesMap)){
                                    this.templatesMap[t.storeId] = [];
                                }
                                this.templatesMap[t.storeId].push(t);
                            }else{
                                this.companyTemplates.push(t);
                            }
                        });
                        this.loading = false;
                    });
                });
            });
        },
        templatesForType(typeId, storeId) {
            let temps = [];
            if(storeId == -1){
                console.log(this.companyTemplates);
                // check for company templates
                this.companyTemplates.forEach((t) => {
                    if(t.template_type == typeId){
                        temps.push(t);
                    }
                });
            }else if(storeId in this.templatesMap){
                for (var i = 0; i < this.templatesMap[storeId].length; i++) {
                    if (this.templatesMap[storeId][i].template_type == typeId) {
                        temps.push(this.templatesMap[storeId][i]);
                    }
                }
            }
            return temps;
        },
        loadTemplateTypes() {
            let url = '/api/templatetypes';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/templatetypes');
            return this.axios.get(url);
        },
        loadDocumentTemplates() {
            let url = '/api/documenttemplates';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/documenttemplates');
            return this.axios.get(url);
        },
        loadDocumentTemplatesForAllStores() {
            this.stores.forEach((s) => {
                let url = '/api/documenttemplates';
                url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/documenttemplates');
                this.axios.get(url).then((response) => {
                    this.templatesMap[s.id] = response.data.data[0];
                    console.log(this.templatesMap);
                });
            });
        },
        openUploadTemplatePopup(docTemplate) {
            let view = this;
            let typeItems = [];
            let selectedItem = {};
            let selectedItems = [];
            this.templateTypes.forEach((t) => {
                t.label = t.label_de;
                t.value = t.id;
                typeItems.push(t);
                if (docTemplate != null && docTemplate.template_type == t.id) {
                    selectedItem = t;
                    selectedItems.push(selectedItem);
                }
            });

            let selStoreItem = {};
            let selStoreItems = [];
            let storeItems = [];
            this.stores.forEach((s) => {
                let item = {
                    label: s.name,
                    value: s.id,
                    obj: s
                };
                storeItems.push(item);
                if(docTemplate != null && docTemplate.storeId == s.id){
                    selStoreItem = item;
                    selStoreItems.push(selStoreItem);
                }
            });
            let dataEntries = [{
                    label: 'DOCX Datei',
                    accept: ".docx",
                    key: 'file',
                    hidden: false,
                    type: 'file',
                    value: null
                },
                {
                    label: 'File ID',
                    key: 'fileId',
                    hidden: true,
                    type: 'text',
                    value: docTemplate != null ? docTemplate.fileId : null
                },
                {
                    label: 'Typ',
                    key: 'template_type',
                    hidden: false, //docTemplate != null,
                    type: 'dropdownsearch',
                    value: docTemplate != null ? docTemplate.template_type : null,
                    selectMultiple: false,
                    flexGroup: false,
                    dropdownSearchState: {
                        toggled: false,
                        displayedItems: [],
                        input: '',
                        items: typeItems,
                        selectMultiple: false,
                        selectedItems: selectedItems,
                        selectedItem: selectedItem,
                        onceSelected: function ($event, item, searchbar) {},
                        styleLight: true,
                        placeholder: "Typ auswählen"
                    }
                },
                {
                    label: 'Firmenweit verfügbar',
                    key: 'isCompanyTemplate',
                    type: 'bool',
                    value: docTemplate != null ? docTemplate.storeId == null : true,
                },
                {
                    label: 'Store',
                    key: 'storeId',
                    type: 'dropdownsearch',
                    value: docTemplate != null ? docTemplate.storeId : null,
                    selectMultiple: false,
                    hidden: docTemplate == null || docTemplate.storeId == null,
                    flexGroup: false,
                    disabled: false, //docTemplate != null,
                    dropdownSearchState: {
                        toggled: false,
                        displayedItems: [],
                        input: '',
                        items: storeItems,
                        selectMultiple: false,
                        selectedItems: selStoreItems,
                        selectedItem: selStoreItem,
                        onceSelected: function ($event, item, searchbar) {},
                        styleLight: true,
                        placeholder: "Store auswählen"
                    },
                    computed: (datafill, lastVal, entryBeforeUpdate) => {
                        let companyTemp = datafill.entries.find((i) => i.key === "isCompanyTemplate").value;

                        entryBeforeUpdate.hidden = companyTemp;
                        return entryBeforeUpdate;
                    },
                }
            ];

            this.$helpers.OpenPopup({
                elRelative: null,
                type: 'datafill',
                componentState: {
                    description: docTemplate == null? 'Wählen Sie Ihre DOCX Vorlage sowie deren Typ und Zuordnung aus.<br/>Firmenweit verfügbare Vorlagen können von allen Stores der Firma<br/>genutzt werden, wird ein Store ausgewählt so kann nur dieser die<br/>Vorlage in MD verwenden.' : 'Wenn Sie eine neue DOCX Datei auswählen wird die derzeitige Vorlage<br/>durch die neue ersetzt.Es ist aber auch möglich nur den Typ oder die<br/>Zuordnung (Firmenweit verfügbar oder einzelner Store) zu aktualisieren.',
                    entries: dataEntries,
                    selectCallback: function (templatef) {
                        console.log(templatef);
                        if(docTemplate != null && templatef.file == null && templatef.template_type != null && (templatef.isCompanyTemplate || templatef.storeId != null)){
                            // only update config of template
                            view.updateTemplateStoreAssignment(docTemplate, templatef.template_type, templatef.storeId, templatef.isCompanyTemplate);
                        }else{
                            if (templatef.file != null && templatef.template_type != null && (templatef.isCompanyTemplate || templatef.storeId != null)) {
                                if (templatef.file.length > 0) {
                                    view.uploadTemplate(templatef.file[0], templatef.template_type, docTemplate, templatef.storeId, templatef.isCompanyTemplate);
                                } else {
                                    view.$helpers.error("Fehler", "Es wurde keine Import Datei ausgewählt");
                                }
                            } else {
                                alert('Vorlagendatei, Typ und Store auswählen!');
                            }
                        }
                    },
                },
                header: 'DOCX Vorlage hochladen',
                remainAfterSelect: false,
                expanded: true,
                centered: true
            });

        },
        deleteDocumentTemplate(docTemplate) {
            let view = this;
            window.app.popupalerts.push({
                header: 'Vorlage löschen',
                description: 'Sind Sie sicher dass Sie die Vorlage löschen möchten? Der Vorgang kann nicht rückgängig gemacht werden!',
                hidden: false,
                callbackAccept: function (popup) {
                    popup.loading = true;

                    let url = '/api/documenttemplates/' + docTemplate.id;

                    let params = {
                        companyId: view.$store.state.companyId,
                    }

                    view.axios({
                        method: 'delete',
                        params: params,
                        headers: {
                            'AUTHORIZATION': "Bearer " + view.$store.state.jwt
                        },
                        url: url,
                    }).then(response => {
                        popup.loading = false;
                        popup.hidden = true;
                        view.refresh();
                        view.$helpers.success('Erfolg', 'Vorlage wurde gelöscht');
                    }).catch(error => {
                        popup.loading = false;
                        popup.hidden = true;
                        view.refresh();
                        view.$helpers.error('Fehler', 'Fehler beim Löschen der Vorlage');
                    });
                },
                callbackCancel: function (popup) {
                    popup.loading = false;
                    popup.hidden = true;

                }
            });
        },
        updateTemplateStoreAssignment(docTemplate, templateType, storeId, isCompanyTemplate){
            let view = this;
            let payload = {
                companyId: view.$store.state.companyId,
                storeId: isCompanyTemplate? null : storeId,
                template_type: templateType
            };

            view.loading = true;
            
            // update existing template
            let url = '/api/documenttemplates/' + docTemplate.id;
            console.log('Udatiing template config...');
            console.log(url);
            view.axios({
                method: 'put',
                headers: {
                    'AUTHORIZATION': "Bearer " + view.$store.state.jwt
                },
                url: url,
                data: payload
            }).then(response => {
                console.log(response.data);
                view.refresh();
            }).catch(function (error) {
                console.log(error);
            })
        },
        uploadTemplate(file, templateType, docTemplate, storeId, isCompanyTemplate) {
            let view = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                let b64 = e.target.result;

                let payload = {
                    companyId: view.$store.state.companyId,
                    storeId: isCompanyTemplate? null : storeId,
                    base64: b64,
                    name: file.name,
                    template_type: templateType
                };

                view.loading = true;
                if (docTemplate == null) {
                    // create new template
                    let url = '/api/documenttemplates';
                    console.log('Uploading template...');
                    view.axios({
                        method: 'post',
                        headers: {
                            'AUTHORIZATION': "Bearer " + view.$store.state.jwt
                        },
                        url: url,
                        data: payload
                    }).then(response => {
                        console.log(response.data);
                        view.refresh();
                    }).catch(function (error) {
                        console.log(error);
                    })
                } else {
                    // update existing template
                    payload['fileId'] = docTemplate.fileId;
                    let url = '/api/documenttemplates/' + docTemplate.id;
                    console.log('Uploading template...');
                    console.log(url);
                    view.axios({
                        method: 'put',
                        headers: {
                            'AUTHORIZATION': "Bearer " + view.$store.state.jwt
                        },
                        url: url,
                        data: payload
                    }).then(response => {
                        console.log(response.data);
                        view.refresh();
                    }).catch(function (error) {
                        console.log(error);
                    })
                }

            };
            reader.readAsDataURL(file);
        },
        openFile(row) {
            let url = row.item.url + '/api/file?id=' + row.item.fileId + '&accessToken=' + row.item.accessToken;
            window.open(url, '_blank');
        },
        creationDate(str) {
            return this.$helpers.getMomentFromStringTZ(str).format("DD.MM.YYYY | HH:mm");
        },

    },

}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->

<style scoped>
    
</style>
