/* eslint-disable */
<template>

<div class="admin-overview">
        <buttonc type="footer rectangle" icon="fa-refresh" label="Benutzer laden" @click="getUsers()" style="position: absolute; margin-top: -30px"/>
        <buttonc type="footer rectangle" :disabled="uuids.length === 0"  :label="'UUIDs laden' + (uuids.length > 0 ? ' (' + uuids.length + ')' : '')" @click="getMultiUserInfo()" style="position: absolute; margin-left: 150px; margin-top: -30px"/>
		<div class="row">
            <div class="entry" style="width: calc(100% - 30px); margin-left: 15px;">
                <buttonc type="footer" label="Seite" style="width: 150px; height: 30px; margin-right: 0px"/>
                <input type="number" autocomplete="off" v-model="currentPage"/>
            </div>
            <div class="entry" style="width: calc(100% - 30px); margin-left: 15px;">
                <buttonc type="footer" label="UUID hinzufügen" style="width: 150px; height: 30px; margin-right: 0px"/>
                <input @keydown="addUUID($event)" type="text" autocomplete="off" v-model="currentUUID"/>
            </div>
			<div class="entry uuid" v-for="(uuid, index) in uuids" :key="uuid" v-bind:class="{last: index === uuids.length - 1}">
                <labelc :text="uuid" style="width: 100%"/>
                <buttonc type="cancel round small" icon="fa-times" @click="uuids.splice(index, 1)"/>
			</div>
		</div>

        <datatablepaging :pageLength="limit" :maxItems="DataTableFilteredItems().length" @update="(options) => { pageOptions = options }" />
        <datatabletextfilter @update="SetFilterText"></datatabletextfilter>
        <datatable :state="dataTableState" :filterState="filters" :filterCheckState="dataTableFilterOptions" :pageState="pageOptions"/>
        
    	</div>
</template>

<script>
import axios from 'axios';
import datatable from "../components/uicomponents/datatable";
import datatablefilter from "../components/uicomponents/datatablefilter";
import datatabletextfilter from "../components/uicomponents/datatabletextfilter";
import datatablepaging from "../components/uicomponents/datatablepaging";

export default {
  	name: 'AdminOverview',
    components: {
        datatable,
        datatablefilter,
        datatabletextfilter,
        datatablepaging
    },
  	data () {
		return {
			items: [],
     		fields: [
				{ key: 'email', label: 'Email', sortable: true, sortDirection: 'desc' },
				{ key: 'lastname', label: 'Vorname', sortable: true, sortDirection: 'desc' },
				{ key: 'firstname', label: 'Nachname', sortable: true, sortDirection: 'desc' },
				{ key: 'active', label: 'Aktiv' },
       			{ key: 'lastlogin', label: 'Letzter Login', sortable: true, sortDirection: 'desc' },
        		{ key: 'actions', label: 'Actions' }
			  ],
			newUser: {
				command: "registerUser",
				email:"",
				password:"",
				firstname:"",
				lastname:""
			},
			password: "",
			newpassword: "",
			selectedId: "",
			currentUUID:"",
			uuids: [],
			multiuseritems: [],
			multiuserfields: [
				{ key: 'email', label: 'Email', sortable: true, sortDirection: 'desc' },
				{ key: 'lastname', label: 'Vorname', sortable: true, sortDirection: 'desc' },
				{ key: 'firstname', label: 'Nachname', sortable: true, sortDirection: 'desc' }
			  ],
      		totalRows: 0,
      		sortBy: null,
      		sortDesc: false,
      		sortDirection: 'asc',
      		filter: null,
			currentPage: 1,
			limit: 10,
        pageOptions:{
            start: 1,
            end: 10
        },
        filters:{
			textsearch: '',
            employeeId: null,
            email: '',
            firstname: '',
            lastname: '',
            active: [true, false],
        },
    }
	},
    computed: {
        sortOptions () {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => { return { text: f.label, value: f.key } })
        },
        buttonStyle () {
            return {
                flex: `1 1 ${100 / 2}%`
            }
        },
        dataTableFilterOptions() {
            let view = this;
            let state = {};
            state.textsearch = {
                hidden: true,
                filterCheck: (data) => {
                    return (data.email.indexOf(view.filters.textsearch) > -1 || data.firstname.indexOf(view.filters.textsearch) > -1 || data.lastname.indexOf(view.filters.textsearch) > -1)
                }
            };
            return state;
        },
        dataTableState(){
            let view = this;
            let state = {};
            let items = Object.values(this.items);
            
            state = this.$helpers.GetDataTableStateFromKeyLabelObject({
                id: 'Id',
                email: 'E-Mail',
                firstname: 'Vorname',
                lastname: 'Nachname',
                active: 'Aktiv',
                lastlogin: 'Letzter Login',
            }, items);
            state.data = items;

            state.headerMap.id.hidden = true;
            state.headerMap.lastlogin.DisplayLabel = (i) => i.lastlogin ? view.$helpers.getMomentFromStringTZ(i.lastlogin).format('DD.MM.YY HH:mm') : '-';

            state.headerMap.active.width = '80px';
            state.headerMap.active.clickOptions = {action: ($event, entry) => {
                    if(entry.data.active){
                        view.deactivateUser(entry.data);
                    } else {
                        view.activateUser(entry.data);
                    }
                }};

            state.headerMap['actions'] = {
                width: '230px',
                button:
                    {icon: 'fas fa-user-plus', action: ($event, header) => {
                            view.CreateUserPopup($event);
                        }},
                buttons:[
                    {icon: 'fas fa-eye', tooltip: 'Profil anzeigen', action: ($event, data) => view.rowClickHandler(data)},
                    {icon: 'fas fa-user-plus', tooltip: 'Registrieren', action: ($event, data) => view.registerUser(data)},
                    {icon: 'fas fa-key', tooltip: 'Passwort ändern', action: ($event, data) => view.OpenChangePasswordPopup(data)},
                    {icon: 'fas fa-calendar-times', tooltip: 'Token Invalidieren', action: ($event, data) => view.invalidateToken(data)},
                    {icon: 'fas fa-mobile', tooltip: '2F Deaktivieren', action: ($event, data) => view.deactivateTwoFactor(data)},
                    {icon: 'fas fa-user-times', tooltip: 'Profilbild löschen', action: ($event, data) => view.deleteProfileImage(data)},
                    {icon: 'fas fa-trash', tooltip: 'Benutzer löschen', action: ($event, data) => view.deleteUser(data)},
                ]};

            Object.values(state.headerMap).forEach(h => {
                h.sort = true;
                h.align = 'center';
            });

            state.height = '40px';
            return state;
        },
    },
  	created: function () {
        let token = localStorage.getItem('md_token')
        if(this.isBlank(token)){
        	this.returnToIndex();
        } else {
			let claims = this.extractJWTInfo(token);
			if(claims.role === null || claims.role != 'ADMIN'){
				console.log(claims);
				this.returnToIndex();
			} else {
				this.getUsers();
			}

    }
	},
    beforeDestroy() {
  	    let view = this;
  	    requestAnimationFrame(function(){
          document.body.append(view.$parent.$el);
          document.body.removeChild(view.$parent.$el);
          view.$parent.$destroy();
        });

    },
    mounted(){
        this.$helpers.MountViewOnDropdownContainer(this, {locked: true, header: 'Benutzer Übersicht', height: 'calc(100vh - 50px)'});
    },
	// methods that implement data logic.
	methods : {
        OpenChangePasswordPopup(user){
            let view = this;
            this.$helpers.OpenPopup({
                header: 'Passwort ändern',
                width: '600px',
                components: [{
                    type: 'datafill',
                    state: {
                        entries: [
                            {key: 'id', value: user.id},
                            {key: 'password', type: 'password', value: '', label:'Neues Passwort'},
                            {key: 'newpassword', type: 'password', value: '', label:'Neues Passwort wiederholen'},
                        ],
                        selectCallback: (data, datafill) => {
                            datafill.loading = true;
                            view.changePasswordAdmin(data.id, data.password, data.newpassword).then(response => {
                                datafill.Toggle();
                                view.toggleModal('Erfolg', '<p>Passwort wurde erfolgreich geändert!.</p>');

                            }).catch(function (error) {
                                datafill.loading = false;
                                if(error.response != undefined && error.response.data.message != undefined){
                                    view.toggleModal('Fehler', '<p>Änderung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                                }
                            })
                        },
                    },
                }],
                centered: true,
            });
        },
  	    CreateUserPopup(){
              let view = this;
              this.$helpers.OpenPopup({
                  header: 'Benutzer anlegen',
                  width: '600px',
                  components: [{
                      type: 'datafill',
                      state: {
                          entries: [
                              {key: 'email', type: 'text', value: '', label:'E-Mail'},
                              {key: 'firstname', type: 'text', value: '', label:'Vorname'},
                              {key: 'lastname', type: 'text', value: '', label:'Nachname'},
                              {key: 'password', type: 'password', value: '', label:'Passwort'},
                          ],
                          selectCallback: (data, datafill) => {
                              datafill.loading = true;
                              view.createUser(data).then(response => {
                                  view.toggleModal('Erfolg', 'Benutzer wurde erfolgreich erstellt', '');
                                  view.getUsers();
                              }).catch(function (error) {
                                  datafill.loading = false;
                                  if(error.response != undefined && error.response.data.message != undefined){
                                      view.toggleModal('Fehler', 'Benutzer konnte nicht erstellt werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
                                  }
                              })
                          },
                      },
                  }],
                  centered: true,
              });
        },
        DataTableFilteredItems(){
            let view = this;
                let filteredItems = this.dataTableState.data.filter(d => {
                let passed = true;
                for(let x in view.dataTableFilterOptions){
                    let filter = view.dataTableFilterOptions[x];
                    if(!filter.filterCheck(d)) passed = false;
                }
                return passed;
            });
                return filteredItems;
        },
        SetFilterText(text){
            this.filters.textsearch = text;
        },
		isBlank: function (str) {
		    return (!str || /^\s*$/.test(str));
		},
		getQueryStringValue: function (key) {
			  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
		},
		extractJWTInfo: function(token){
      		if (token) return JSON.parse(atob(token.split('.')[1]));
      		return {};

		},
		rowClickHandler(record, index) {
            this.$router.replace({ name: 'Profile', params: { id: record.id } })
			
		},
		addUUID: function($event) {
          if(!$event) {
              this.uuids.push(this.currentUUID);
              this.currentUUID = '';
          }
          else {
              if($event.key === 'Enter' && this.currentUUID !== '') {
                  this.uuids.push(this.currentUUID);
                  this.currentUUID = '';
              }
          }

		},
		getMultiUserInfo: function() {
			var payload = {
				command: "getMultiUserInfo",
				ids: this.uuids
			};
			let token = localStorage.getItem('md_token')
			let view = this;
			axios({
				method: 'post',
				url: '/api/user',
				headers: {'Authorization': 'Bearer ' + token},
				data: payload
			}).then(response => {
				view.multiuseritems = response.data;
			}).catch(function (error) {
				if(error.response != undefined && error.response.data.message != undefined){
					view.toggleModal('Fehler', 'Benutzer Info konnte nicht geladen werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
				}
			})
		},
		createUser: function (newUser) {
			this.hideCustomModal('createNewUserModal');
      		var payload = newUser;
			let token = localStorage.getItem('md_token')
			let view = this;
			return axios({
				method: 'post',
				url: '/api/user',
				headers: {'Authorization': 'Bearer ' + token},
				data: payload
			})
		},
		getUsers: function () {
			let url = '/api/user?page='+this.currentPage+'&limit='+50;

			let view = this;
			let token = localStorage.getItem('md_token')
			axios({
				ethod: 'get',
				url: url,
				headers: {'Authorization': 'Bearer ' + token}
			}).then(response => {
				view.items = response.data;
				view.totalRows = view.items.length;
			}).catch(function (error) {
				view.toggleModal('Fehler', 'Laden der Benutzerübersicht fehlgeschlagen.', '');
			})

		},
		activateUser: function(item) {
			this.$modal.show('dialog', {
				title: "Benutzer aktivieren",
				text: '<p>Wollen Sie wirklich eine Aktivierungs-Email an '+item.email+' senden?</p><p> Dieser Vorgang kann nicht rückgängig gemacht werden</p>',
				buttons: [
					{
						title: 'Abbrechen',
						handler: () => {
							this.$modal.hide('dialog')
						}
					},
					{
						title: 'OK',
						handler: () => {
							this.$modal.hide('dialog');
							var payload = {
								command: "requestActivation",
								email: item.email
							}
							let token = localStorage.getItem('md_token')
							let view = this;
							axios({
								method: 'put',
								url: '/api/user',
								headers: {'Authorization': 'Bearer ' + token},
								data: payload
							}).then(response => {
								view.toggleModal('Erfolg', 'Aktivierungs Email wurder erfolgreich versandt!', '');
							}).catch(function (error) {
								if(error.response != undefined && error.response.data.message != undefined){
									view.toggleModal('Fehler', 'Email konnte nicht verschickt werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
								}
							});

						}
					}
				]
			})
		},
		registerUser: function(item) {

            let view = this;
            this.$helpers.OpenPopup({
                header: 'Benutzer registrieren',
                width: '650px',
                components: [{
                    type: 'datafill',
                    state: {
                        entries: [
                            {key: 'info', type: 'output', value: 'Wollen Sie wirklich eine Registrierungs-Email an '+item.email+' senden?'},
                            {key: 'info2', type: 'output', value: 'Dieser Vorgang kann nicht rückgängig gemacht werden'},
                        ],
                        selectCallback: (data, datafill) => {
                            datafill.loading = true;
                            let token = localStorage.getItem('md_token');
                            let payload = {
                                command: "requestRegistration",
                                email: item.email,
                                firstname: item.firstname,
                                lastname: item.lastname,
                                service: "mdauth",
                                reactivate: true,
                            };
                            axios({
                                method: 'put',
                                url: '/api/user',
                                headers: {'Authorization': 'Bearer ' + token},
                                data: payload
                            }).then(response => {
                                datafill.Toggle();
                                view.toggleModal('Erfolg', 'Registrierungs Email wurde erfolgreich versandt!', '');
                            }).catch(function (error) {
                                datafill.loading = false
                                if(error.response != undefined && error.response.data.message != undefined){
                                    view.toggleModal('Fehler', 'Email konnte nicht verschickt werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
                                }
                            });

                        },
                        positiveButtonLabel: 'OK',
                        deleteCallback: () => {},
                        negativeButtonLabel: 'Abbrechen',
                    },
                }],
                centered: true,
            });
		},
		openPasswordChangeModal: function(item){
			this.selectedId = item.id;
			this.showCustomModal('changePasswordModal');
		},
		changePasswordAdmin: function(id, password, newpassword){
			let view = this;
			var payload = {
				command: "updatePasswordAdmin",
				id: id,
				newpassword: newpassword,
				password: password
			}
			let token = localStorage.getItem('md_token')
			return axios({
				method: 'put',
				url: '/api/user',
				headers: {'Authorization': 'Bearer ' + token},
				data: payload
			})
		},
		deactivateUser: function (item) {
      		var payload = {
				command: "deactivateUser",
				id: item.id
			}
			let token = localStorage.getItem('md_token')
			let view = this;
			axios({
				method: 'put',
				url: '/api/user',
				headers: {'Authorization': 'Bearer ' + token},
				data: payload
			}).then(response => {
				view.toggleModal('Erfolg', 'Benutzer wurde erfolgreich deaktiviert', '');
				item.active = false;

			}).catch(function (error) {
				if(error.response != undefined && error.response.data.message != undefined){
					view.toggleModal('Fehler', 'Deaktivierung konnte nicht durchgeführt werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
				}
			})
		},
		resetLoginCount: function (item) {
      		var payload = {
				command: "resetLoginAttempts",
				id: item.id
			}
			let token = localStorage.getItem('md_token')
			let view = this;
			axios({
				method: 'put',
				url: '/api/user',
				headers: {'Authorization': 'Bearer ' + token},
				data: payload
			}).then(response => {
				view.toggleModal('Erfolg', 'Login Versuche wurden erfolgreich zurückgesetzt', '');

			}).catch(function (error) {
				if(error.response != undefined && error.response.data.message != undefined){
					view.toggleModal('Fehler', 'Reset konnte nicht durchgeführt werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
				}
			})
		},
		deactivateTwoFactor: function (item) {
      		var payload = {
				command: "deactivateTwoFactor",
				id: item.id
			}
			let token = localStorage.getItem('md_token')
			let view = this;
			axios({
				method: 'put',
				url: '/api/user',
				headers: {'Authorization': 'Bearer ' + token},
				data: payload
			}).then(response => {
				view.toggleModal('Erfolg', '2F Auth des Benutzers wurde erfolgreich deaktiviert', '');
				item.twofactor = false;

			}).catch(function (error) {
				if(error.response != undefined && error.response.data.message != undefined){
					view.toggleModal('Fehler', 'Deaktivierung konnte nicht durchgeführt werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
				}
			})
		},
		invalidateToken: function (item) {
      		var payload = {
				command: "updateValidity",
				id: item.id
			}
			let token = localStorage.getItem('md_token')
			let view = this;
			axios({
				method: 'put',
				url: '/api/user',
				headers: {'Authorization': 'Bearer ' + token},
				data: payload
			}).then(response => {
				item.active = false;
				view.toggleModal('Erfolg', 'Login-Token wurde erfolgreich invalidiert!', '');
			}).catch(function (error) {
				if(error.response != undefined && error.response.data.message != undefined){
					view.toggleModal('Fehler', 'Login-Token Invalidierung konnte nicht durchgeführt werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
				}
			})
		},
		deleteUser: function(item) {
            let view = this;
            this.$helpers.OpenPopup({
                header: 'Benutzer löschen',
                width: '650px',
                components: [{
                    type: 'datafill',
                    state: {
                        entries: [
                            {key: 'info', type: 'output', value: 'Wollen Sie wirklich den Benutzer '+ item.email +' löschen?'},
                            {key: 'info2', type: 'output', value: 'Dieser Vorgang kann nicht rückgängig gemacht werden'},
                        ],
                        selectCallback: (data, datafill) => {
                            datafill.loading = true;
                            let token = localStorage.getItem('md_token')
                            axios({
                                method: 'delete',
                                url: '/api/user?id='+item.id,
                                headers: {'Authorization': 'Bearer ' + token}
                            }).then(response => {
                                view.toggleModal('Erfolg', 'Benutzer wurde erfolgreich gelöscht', '');
                                view.items = view.items.filter(i => i !== item);
                            }).catch(function (error) {

                                datafill.loading = false;
                                if(error.response != undefined && error.response.data.message != undefined){
                                    view.toggleModal('Fehler', 'Benutzer konnte nicht gelöscht werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
                                }
                            });
                        },
                        positiveButtonLabel: 'OK',
                        deleteCallback: () => {},
                        negativeButtonLabel: 'Abbrechen',
                    },
                }],
                centered: true,
            });
		},
		deleteProfileImage: function(item, index) {
  	        let view = this;
            this.$helpers.OpenPopup({
                header: 'Profilbild löschen',
                width: '700px',
                components: [{
                    type: 'datafill',
                    state: {
                        entries: [
                            {key: 'info', type: 'output', value: 'Wollen Sie wirklich das Profilbild des Benutzers '+item.email+' löschen?'},
                            {key: 'info2', type: 'output', value: 'Dieser Vorgang kann nicht rückgängig gemacht werden'},
                        ],
                        selectCallback: (data, datafill) => {
                            datafill.loading = true;
                            let token = localStorage.getItem('md_token')
                            axios({
                                method: 'delete',
                                url: '/api/profileimage?id='+item.id,
                                headers: {'Authorization': 'Bearer ' + token}
                            }).then(response => {
                                view.toggleModal('Erfolg', 'Profilbild wurde erfolgreich gelöscht', '');

                            }).catch(function (error) {
                                datafill.loading = false;
                                if(error.response != undefined && error.response.data.message != undefined){
                                    view.toggleModal('Fehler', 'Profilbild konnte nicht gelöscht werden.', 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.');
                                }
                            });
                        },
                        positiveButtonLabel: 'OK',
                        deleteCallback: () => {},
                        negativeButtonLabel: 'Abbrechen',
                    },
                }],
                centered: true,
            });
		},
		returnToIndex: function(){
			this.$router.replace('/');
		},

		onFiltered (filteredItems) {
			this.totalRows = filteredItems.length
		},
		showCustomModal: function(modal){
			this.$modal.show(modal);
		},
		hideCustomModal: function(modal){
			this.$modal.hide(modal);
		},
		toggleModal(title, text, text2) {
        this.$helpers.OpenInfoPopup(title, [text, text2]);
		},
	},
	watch: {

  	}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin-overview {
    padding: 40px 40px;
    width: calc(100vw - 200px);
}

  .paging, .text-filter, .filter{
    top: 110px;
  }

.text-filter{
  right: 120px;
  width: 300px;
}
.filter{
  right: 95px;
}

  .uuid{
    box-shadow: inset 0 0 1px 1px var(--contrast-3);
    height: 30px;
    width: calc(100% - 100px) !important;
    margin: 0 !important;
    margin-left: 50px !important;
    margin-bottom: -1px !important;
    padding: 6px 5px;
  }

  .uuid.last{
    margin-bottom: 10px !important;
  }
</style>
