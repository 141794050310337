/* eslint-disable */
<template>

<div class="container">
	        <div class="card card-container loginwidth">
	            <img v-if="showimg" id="profile-img" class="profile-img-card" :src="require(`../assets/serviceimg/${serviceimg}.png`)" />
	            <p id="profile-name" class="profile-name-card"></p>
	            <h4 class="text-center">{{message}}</h4>
                <div>
                    <form ref="redirectForm" id="redirectForm" style="display:none" action="" method="POST">

                    </form>
            	</div>
	        </div><!-- /card-container -->
    	</div><!-- /container -->
</template>

<script>
import axios from 'axios'
export default {
  	name: 'Logout',
  	data () {
		return {
			showimg: false,
			redirect:"",
			serviceimg: "md",
			message: "Bitte warten Sie, Sie werden ausgeloggt!"
		}
  	},
  	created: function () {
		let view = this;
		this.redirect = this.$route.query.redirect;
		this.setServiceImg();
		window.setTimeout(function () { view.logout(); }, 500);

	},
	// methods that implement data logic.
	methods : {
		setServiceImg: function(){
			if(!this.isBlank(this.redirect)){
				if(this.redirect.includes("mz")){
					this.serviceimg = "mz";
				} else if(this.redirect.includes("mp")) {
				this.serviceimg = "mp";
				} else if(this.redirect.includes("mdcore")) {
				this.serviceimg = "md";
				} else {
				this.serviceimg = "md";
				}
			} else {
				this.serviceimg = "md";
			}

			this.showimg=true;
		},
		isBlank: function (str) {
		    return (!str || /^\s*$/.test(str));
		},
		getQueryStringValue: function (key) {
			  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        },
        logout: function(){
            localStorage.removeItem("md_token");
            if(!this.isBlank(this.redirect)){
        	    this.checkRedirect();
            } else {
                console.log("redirect parameter missing");
                this.returnToIndex();
            }

		},
		checkRedirect : function() {

			let url = '/api/whitelist'
            if (!this.isBlank(this.redirect)) {
                url = url + '?redirect=' + this.redirect + '&source=frontend'
            }
			let view = this;
			axios({
				method: 'get',
				url: url
			}).then(response => {
				view.message = "Logout erfolgreich!";
				 if (response.data.redirect != null) {
					window.setTimeout(function () { view.redirectTo(response.data.redirect) }, 1000)
				}

			}).catch(function (error) {
                view.message = "Umleitung zu "+view.redirect+" nicht erlaubt.";
                window.setTimeout(view.returnToIndex, 3000);
			})

        },
       	redirectTo: function (url) {
			if(this.redirect.includes("mdpl")){
				window.location = url;
			} else {
				document.getElementById('redirectForm').setAttribute("action", url);
				this.$refs.redirectForm.submit();
			}
		},
		returnToIndex: function(){
			this.$router.replace('/');
		},
    },
    computed: {
        computedFormAction: function () {
            return this.redirect
        }
    },
	watch: {
		'$route' (to, from) {
			this.redirect = this.getQueryStringValue('redirect')
			this.setServiceImg();
            this.logout();
		}
  	}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginwidth {
    max-width: 600px !important;
}
</style>
