<template>
    <div class="text-filter">
        <input class="input" v-model="filterText" v-on:input="UpdateText()"/>
        <buttonc class="cancel" icon="fa-times color-bg" type="round small no-border" @click="UpdateState('')"/>
    </div>
</template>

<script>
    export default {
        name: "datatabletextfilter",
        data(){
            return {
                pageCurrent: 0,
                filterText: '',
            }
        },
        props:{
        },
        mounted(){
            this.UpdateState();
        },
        methods: {
            UpdateText(text){
                if(typeof text === 'string') this.filterText = text;
                this.UpdateState(this.filterText);
            },
            UpdateState(text){
                if(typeof text === 'string') this.filterText = text;
                this.$emit('update', this.filterText);
            }
        }
    }
</script>

<style scoped>


    .text-filter{
        display: inline-flex;
        align-items: center;
        position: absolute;
        z-index: 10;
        background: none;
        right: 10px;
        width: initial;
        padding: 0 20px;
        top: 10px;
    }

    .text-filter input{
        background: var(--bg);
        border-radius: 0;
        padding-left: 10px;
        box-shadow: inset 0 0 0 1px  var(--contrast-3);

        width: 100%;
    }

    .text-filter .label{
        color: var(--contrast-4);
        font-family: DistrictProLight;
        font-weight: 200;
    }

    .text-filter .label.today{
        font-size: 15px;
        cursor:pointer;
    }

    .text-filter .fa-angle-right,.text-filter .fa-angle-left{
        color: var(--contrast-4);
        font-size: 20px;
        padding: 5px;
        cursor:pointer;
    }

    .text-filter .fa-angle-right:hover,.text-filter .fa-angle-left:hover, .text-filter .fa-calendar:hover, .text-filter .today:hover{
        color: var(--bg) !important;
    }

    .text-filter .label.bold{
        font-family: DistrictProBold;
        font-weight: 800;
        margin: -3px 5px 0;
    }

    .text-filter .cancel{
        border-radius: 100px;
        background: var(--contrast-2);
        position: absolute;
        right: 20px;
        transform: scale(.8);
    }

    .text-filter .cancel:hover{
        background: var(--contrast-4);
    }

</style>