<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
                <dateselectionheader :label="dateDisplay" @PrevDate="modDate('sub')" @NextDate="modDate('add')"/>
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
            <buttonc v-if="storeData != null" type="square" icon="fa-info" v-tooltip="'Gewählte Software anzeigen'" @click="showChosenSoftwarePopup"/>
          </div>
      </div>
      <div v-if="selectedStore != null" class="scrollbar-container">

        <div class="full split-bottom">
            <div class="header">
                <div class="label">Betrieb auswählen</div>
            </div>
            <div style="float: left !important; margin: 4px 0px 4px -5px;">
                <buttonc :disabled="loading" v-for="store in storeArray" v-bind:key="store.id" :label="store.name" :active="selectedStore != null && selectedStore == store.id" @click="selectedStore = store.id"/>
            </div>

        </div>
        <br /><br />
        <div v-if="loading" class="loading-md bg">
            <i class="fas fa-spinner"></i>
            <br/>
            <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">
                {{ loadingText }}
            </div>
        </div>
        <div v-else>
          <div class="full" style="margin-top: -16px; margin-bottom: 16px;">
            <div class="header">
              <div v-if="storeData != null && softwareId == 0" class="label">
                Bitte stattdessen den "Tages Export" für die IST-Stunden verwenden.
              </div>
            </div>
          </div>


          <div v-if="storeData != null && softwareId == 1">
            <table class="table table-striped table-borderless">
              <!-- <thead class="thead-dark"> -->
                <tr>
                  <th class="documentsTh" scope="col">ID</th>
                  <th class="documentsTh" scope="col" v-if="softwareId == 0">Store BMD ID</th>
                  <th class="documentsTh" scope="col" v-if="softwareId == 1">Id Kostenstelle</th>
                  <th class="documentsTh" scope="col" style="width: 200px;" v-if="softwareId == 0">Mitarbeiter BMD ID</th>
                  <th class="documentsTh" scope="col" style="width: 200px;" v-if="softwareId == 1">Mitarbeiter DPW ID</th>
                  <th class="documentsTh" scope="col">Name</th>
                  <th class="documentsTh" scope="col">SV-Nummer</th>
                  <th class="documentsTh" scope="col">Datum</th>
                  <th class="documentsTh" scope="col">von</th>
                  <th class="documentsTh" scope="col">bis</th>
                </tr>
              <!-- </thead> -->
              <tbody>
                <tr v-for="actualShift in exportableTableActualShifts">
                    <td class="documentsTd" scope="col">{{ actualShift.id }}</td>
                    <td class="documentsTd" scope="col" v-if="softwareId == 0">
                      <div v-if="actualShift.storeExternalId == null">BMD ID fehlt
                        <div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                      </div>
                      <div v-if="actualShift.storeExternalId != null">{{ actualShift.storeExternalId }}</div>
                    </td>
                    <td class="documentsTd" scope="col" v-if="softwareId == 1">
                      <div v-if="actualShift.storeExternalId == null">DPW ID fehlt
                        <div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                      </div>
                      <div v-if="actualShift.storeExternalId != null">{{ actualShift.storeExternalId }}</div>
                    </td>


                    <td class="documentsTd" scope="col" v-if="softwareId == 0">
                      <div v-if="!actualShift.editing">
                        <div v-if="actualShift.bmdId == null">
                          <div class="button small right mright8" v-on:click="toggleEmployeeBmd(actualShift)">
                              <div class="label">BMD ID eingeben</div>
                          </div>
                        </div>
                        <div v-if="actualShift.bmdId != null">{{ actualShift.bmdId }}
                          <div class="button small right mright8" v-on:click="toggleEmployeeBmd(actualShift)">
                              <div class="label">BMD ID bearbeiten</div>
                          </div>
                        </div>
                      </div>
                      <div v-if="actualShift.editing">
                        <input style="width: 50%;" v-model="actualShift.bmdId" />
                        <div class="button small right mright8" v-on:click="saveEmployeeBmd(actualShift)">
                          <div class="label">Speichern</div>
                        </div>
                      </div>
                    </td>
                    <td class="documentsTd" scope="col" v-if="softwareId == 1">
                      <div v-if="!actualShift.editing">
                        <div v-if="actualShift.dpwId != null">{{ actualShift.dpwId }}
                        </div>
                      </div>
                    </td>


                    <td class="documentsTd" scope="col">{{ actualShift.lastName }}  {{ actualShift.firstName }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.socialsecurity }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.actualShift.fromMoment.format('DD.MM.YYYY') }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.actualShift.fromMoment.format('HH:mm') }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.actualShift.toMoment.format('HH:mm') }}</td>
                </tr>
              </tbody>
            </table>


            <hr />
            <br />
            <strong>Die nachfolgenden Werte werden nicht exportiert</strong>
            <br />
            <br />



            <table class="table table-striped table-borderless">
              <!-- <thead class="thead-dark"> -->
                <tr>
                  <th class="documentsTh" scope="col">ID</th>
                  <th class="documentsTh" scope="col" v-if="softwareId == 0">Store BMD ID</th>
                  <th class="documentsTh" scope="col" v-if="softwareId == 1">Id Kostenstelle</th>
                  <th class="documentsTh" scope="col" style="width: 200px;" v-if="softwareId == 0">Mitarbeiter BMD ID</th>
                  <th class="documentsTh" scope="col" style="width: 200px;" v-if="softwareId == 1">Mitarbeiter DPW ID</th>
                  <th class="documentsTh" scope="col">Name</th>
                  <th class="documentsTh" scope="col">SV-Nummer</th>
                  <th class="documentsTh" scope="col">Datum</th>
                  <th class="documentsTh" scope="col">von</th>
                  <th class="documentsTh" scope="col">bis</th>
                    <th class="documentsTh" scope="col">Status</th>
                </tr>
              <!-- </thead> -->
              <tbody>
                <tr v-for="actualShift in hiddenTableActualShifts">
                    <td class="documentsTd" scope="col">{{ actualShift.id }}</td>
                    <td class="documentsTd" scope="col" v-if="softwareId == 0">
                      <div v-if="actualShift.storeExternalId == null">BMD ID fehlt
                        <div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                      </div>
                      <div v-if="actualShift.storeExternalId != null">{{ actualShift.storeExternalId }}</div>
                    </td>
                    <td class="documentsTd" scope="col" v-if="softwareId == 1">
                      <div v-if="actualShift.storeExternalId == null">DPW ID fehlt
                        <div class="fas fa-exclamation-circle" style="margin: 1px 0px 0px 4px; display:inline;"></div>
                      </div>
                      <div v-if="actualShift.storeExternalId != null">{{ actualShift.storeExternalId }}</div>
                    </td>


                    <td class="documentsTd" scope="col" v-if="softwareId == 0">
                      <div v-if="!actualShift.editing">
                        <div v-if="actualShift.bmdId == null">
                          <div class="button small right mright8" v-on:click="toggleEmployeeBmd(actualShift)">
                              <div class="label">BMD ID eingeben</div>
                          </div>
                        </div>
                        <div v-if="actualShift.bmdId != null">{{ actualShift.bmdId }}
                          <div class="button small right mright8" v-on:click="toggleEmployeeBmd(actualShift)">
                              <div class="label">BMD ID bearbeiten</div>
                          </div>
                        </div>
                      </div>
                      <div v-if="actualShift.editing">
                        <input style="width: 50%;" v-model="actualShift.bmdId" />
                        <div class="button small right mright8" v-on:click="saveEmployeeBmd(actualShift)">
                          <div class="label">Speichern</div>
                        </div>
                      </div>
                    </td>
                    <td class="documentsTd" scope="col" v-if="softwareId == 1">
                      <div v-if="!actualShift.editing">
                        <div v-if="actualShift.dpwId == null">
                        </div>
                        <div v-if="actualShift.dpwId != null">{{ actualShift.dpwId }}
                        </div>
                      </div>
                    </td>


                    <td class="documentsTd" scope="col">{{ actualShift.lastName }}  {{ actualShift.firstName }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.socialsecurity }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.actualShift.fromMoment.format('DD.MM.YYYY') }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.actualShift.fromMoment.format('HH:mm') }}</td>
                    <td class="documentsTd" scope="col">{{ actualShift.actualShift.toMoment.format('HH:mm') }}</td>
                    <td class="documentsTd" scope="col">
                      <span v-if="actualShift.actualShift.mdConfirmed"></span>
                      <span v-if="!actualShift.actualShift.mdConfirmed">nicht genehmigt</span>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>



      </div>

  </div>
</template>

<script>
  // @ is an alias to /src
  import { mapState, mapGetters } from 'vuex';
  export default {
    name: 'ISTExport',
    components: {
    },
    data() {
      return {
            selectedStore: null,
            stores: [],


        loading: true,
        loadingText: 'Daten werden geladen...',
        selectedDate: this.$helpers.getMomentTZ().clone(),

        companyData: null,
        storeData: null,
        isBalanceConfirmationRequired: true,
        storeExternalId: '',

        timeframeStart: this.$helpers.getMomentTZ().clone().startOf('month'),
        timeframeEnd: this.$helpers.getMomentTZ().clone().endOf('month'),

        actualShiftExports: [],
        actualShifts: [],
        actualPauses: [],

        actualShiftEmployeeMap: {},

        exportableTableActualShifts: [],
        hiddenTableActualShifts: [],

            softwareId: 0,

        monthNames: {0:'Jänner',1:'Februar',2:'März',3:'April',4:'Mai',5:'Juni',6:'Juli',7:'August',8:'September',9:'Oktober',10:'November',11:'Dezember',},

      }
    },
    mounted(){
      let view = this;
      view.loading = true;
      this.refresh(true);
    },
    watch: {
        companyId: function (val, oldVal) {
            this.selectedStore = null;
            this.stores = [];
            this.refresh(true);
        },
        selectedStore: function (val, oldVal) {
            if(val != null)
            this.refresh(true);
        },
    },
    computed: {
      ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings','employeeMap', 'storeArray', 'selectedStores']),
        dateDisplay: function() {
          let view = this;
          if(view.storeData != null){
            if(view.softwareId == 0){
              return view.monthNames[view.timeframeStart.months()] + " " + view.timeframeStart.format('YYYY');
            }else if(view.softwareId == 1){
              return view.timeframeStart.format("DD") + '. - ' +view.timeframeEnd.format("DD") + '. ' + view.monthNames[view.timeframeStart.months()] + " " + view.timeframeStart.format('YYYY');
            }
          }
          return "-";
        },
          headerButtons() {
              let view = this;

              let state= {
                  buttons: []
              };
              state.buttons.push({
                  type: "block",
                  icon: "fa-refresh",
                  click: () => {
                     view.refresh();
                  },
                  tooltip: 'Refresh'
              });
              if(view.storeData != null && (view.softwareId == 0)){
                /*
                state.buttons.push({
                    type: "block",
                    icon: "fa-file-download",
                    click: () => {
                      view.export();
                    },
                    tooltip: 'Datei herunterladen'
                });*/
              }
              if(view.storeData != null && view.softwareId == 1){
                state.buttons.push({
                    type: "block",
                    icon: "fa-server",
                    click: () => {
                      view.saveOnSFTP();
                    },
                    tooltip: 'Datei am Server ablegen'
                });
              }
              return state;
          },
    },
    methods: {
      reset: function(){
        let view = this;
            view.companyData = null;
        view.actualShifts = [];
        view.exportableTableActualShifts = [];
        view.hiddenTableActualShifts = [];
      },
      showChosenSoftwarePopup($event){
          let info = 'Für den Betrieb werden Daten verarbeitet für: ' + ((this.storeData != null && this.softwareId == 0)? 'BMD' : (this.storeData != null && this.softwareId == 1)? 'DPW' : 'nicht ausgewählt!');
          let dataEntries = [];
          dataEntries.push({key: 'info', hidden: false, type: 'output', value: info});
          this.$helpers.OpenPopup({
              elRelative: $event.currentTarget,
              type: 'datafill',
              componentState: {
                  entries: dataEntries,
                  selectCallback: function (){
                      console.log('Bye bye...');
                  },
                  positiveButtonLabel: 'Schließen'
              },
              header: 'Gewählte Software',
              remainAfterSelect: false,
              expanded: true,
              locked: false
          });
      },
      refresh: function(changeTimeframe = false,force = false){
        let view = this;
            view.reset();


            view.$helpers.GetCompanySettings(force).then(response => {
                view.$helpers.success('Betriebseinstellungen', 'Betriebseinstellungen wurden geladen');
                view.updateCompanySettings(response);
                view.$helpers.GetStoresArray().then((stores) => {
                  view.$helpers.success('Standorte', 'Standorte wurden geladen');
                    view.storesData = stores;
                    console.log(stores);
                    if(view.selectedStore == null){
                        if(stores.length > 0){
                            view.selectedStore = stores[0].id;
                            console.log('Auto selected first store:');
                            console.log(view.selectedStore);
                            view.$forceUpdate();
                        }
                    }
                    view.loading = true;
                    if (view.storesData.length > 0) {
                        view.storesData = view.storeArray;
                        let selectedStoreIndex = 0;
                        for (let i = 0; i < view.storesData.length; i++) {
                            if(view.storesData[i].id == view.selectedStore){
                                selectedStoreIndex = i;
                            }
                        }

                        view.storeData = view.storesData[selectedStoreIndex];
                        //view.isBalanceConfirmationRequired = (view.storeData == null || view.storeData.isBalanceConfirmationRequired == null || view.storeData.isBalanceConfirmationRequired == undefined || view.storeData.isBalanceConfirmationRequired);
                        if(view.companyData != null){
                            if(view.companyData.individualStoreIds == 1){
                                if (view.storeData != null) {
                                    if (view.softwareId == 0) {
                                        view.storeExternalId = view.storeData.bmdId;
                                    } else if (view.softwareId == 1) {
                                        view.storeExternalId = view.storeData.dpwId;
                                    }
                                }
                            }else{
                                if (view.softwareId == 0) {
                                    view.storeExternalId = view.companyData.bmdId;
                                } else if (view.softwareId == 1) {
                                    view.storeExternalId = view.companyData.dpwId;
                                }
                            }
                                if (changeTimeframe) {
                                    if (view.companyData != null && view.softwareId == 0) {
                                        //BMD PFAD MONATLICH
                                        view.selectedDate.subtract(1, 'months');
                                        view.timeframeStart = view.selectedDate.clone().startOf('month');
                                        view.timeframeEnd = view.selectedDate.clone().endOf('month');
                                        view.$forceUpdate();
                                    } else if (view.companyData != null && view.softwareId == 1) {
                                        //DPW PFAD ZWEI WÖCHENTLICH
                                        if (view.selectedDate.date() > 16) {
                                            view.timeframeStart = view.selectedDate.clone().startOf('month');
                                            view.timeframeEnd = view.timeframeStart.clone().add('days', 14);
                                            view.$forceUpdate();
                                        } else {
                                        this.selectedDate.startOf('month');
                                        view.timeframeStart = view.selectedDate.clone().subtract(1, 'months').startOf('month').add('days',15);
                                        view.timeframeEnd = view.timeframeStart.clone().endOf('month');
                                        view.$forceUpdate();
                                        }
                                    }
                                  view.$helpers.success('Zeitraum', 'Zeitraum wurde geändert');
                                }
                        }
                        if(view.softwareId == 1){
                          view.createActualShiftExport();
                        }else{
                          view.loading = false;
                        }
                    }
                })
            }).catch(error => {
                console.log("REFRESH ERROR: "+error)
            });
      },
        updateCompanySettings: function(response){
            let view = this;      
            if(response.data.length == 1){
                view.companyData = response.data[0];
                view.softwareId = view.companyData.softwareId;
            }
        },
      createActualShiftExport: function(){
        //If the export for the timeframe is not existing it gets created and returned immediately
        //Otherwise if it already exists, it just gets returned with no side effects
        let view = this;
        let url = '/api/actualshiftexport';

        let payload = {
          storeId: view.selectedStore,
          from: view.timeframeStart.format('DD.MM.YYYY'),
          to: view.timeframeEnd.format('DD.MM.YYYY'),
        };

        this.axios({
          method: 'post',
          headers: {
            'AUTHORIZATION': "Bearer "+this.$store.state.jwt
          },
          url: url,
          data: payload
        }).then(response => {
          view.actualShiftExports = response.data.data;
            view.loadEmployees();
        }).catch(function (error) {
          console.log(error);
        })
      },




      loadEmployees: function(){
        let view = this;
        view.$helpers.GetEmployeeMapOLD(true,false,view.selectedStore)
        .then(response => {
          view.$helpers.success('Mitarbeiter', 'Mitarbeiter wurden geladen');
          let map = {};
          Object.values(view.employeeMap).forEach(e => {
            map[e.id] = {mdId: e.mdId, actualShifts: []};
          });
          view.actualShiftEmployeeMap = map;
          view.loadActualShifts();
        }).catch(function (error) {
          console.log(error.response);
        });
      },




      loadActualShifts: function(){
        let view = this;
        let url = '/api/actualshift';

        url = this.$helpers.appendParam("storeId", view.selectedStore, url, '/api/actualshift');
        url = this.$helpers.appendParam("from", view.timeframeStart.format('DD.MM.YYYY'), url, '/api/actualshift');
        url = this.$helpers.appendParam("to", view.timeframeEnd.format('DD.MM.YYYY'), url, '/api/actualshift');

        this.axios({
          method: 'get',
          headers: {
            'AUTHORIZATION': "Bearer "+this.$store.state.jwt
          },
          url: url,
        }).then(response => {
          view.$helpers.success('IST Zeiten', 'IST Zeiten wurden geladen');

          view.actualShifts = response.data.data;


          Object.values(view.actualShifts).forEach(actualShift => {
            actualShift.skip = false;

            
            let fromMoment = view.$helpers.getMomentFromStringUTC(actualShift.from, 'YYYY-MM-DD HH:mm:ss').tz("Europe/Vienna");
            let toMoment = view.$helpers.getMomentFromStringUTC(actualShift.to, 'YYYY-MM-DD HH:mm:ss').tz("Europe/Vienna");
            
            if(fromMoment < view.timeframeStart){
              fromMoment = view.timeframeStart.clone();
              actualShift.skip = true;
            }
/*

            let midnightStart = fromMoment.clone().startOf('day');
            if(midnightStart.format('x') != fromMoment.format('x')){
              fromMoment = fromMoment.clone().add(1, 'days').startOf('day');
            }

            let midnightEnd = toMoment.clone().startOf('day');
            if(midnightEnd.format('x') != toMoment.format('x')){
              toMoment = toMoment.clone().startOf('day');
            }
            toMoment.subtract(1,'days');
            if(toMoment > view.timeframeEnd){
              toMoment = view.timeframeEnd.clone().startOf('day');
            }

*/
            actualShift.fromTimestamp= fromMoment.clone().format('x');
            actualShift.fromMoment= fromMoment.clone();
            actualShift.toMoment= toMoment.clone();

            if(view.actualShiftEmployeeMap[actualShift.employeeId]){
              //view.actualShiftEmployeeMap[actualShift.employeeId].actualShifts.push(actualShift);
            }else{
              view.$helpers.error('FEHLER', 'Schicht konnte nicht einem Mitarbeiter zugewiesen werden');
              console.log("Employee with the ID: " + actualShift.employeeId + " is missing in shift: " + actualShift.id);
            }
              view.actualShiftEmployeeMap[actualShift.employeeId].actualShifts.push(actualShift);// Let it happen to throw an error, cause the fast popup message can be overlooked. And then we do "currently" dont have a possiblility to detect if something went wrong. And if the company decides to auto import the files, then we don't know what happens if data is missing.
            

          });

          view.loadActualPauses();

          

        }).catch(function (error) {
          view.saves = false;
        })
      },



      loadActualPauses: function(){
        let view = this;
        let url = '/api/actualpause';

        url = this.$helpers.appendParam("storeId", view.selectedStore, url, '/api/actualpause');
        url = this.$helpers.appendParam("from", view.timeframeStart.format('DD.MM.YYYY'), url, '/api/actualpause');
        url = this.$helpers.appendParam("to", view.timeframeEnd.format('DD.MM.YYYY'), url, '/api/actualpause');

        this.axios({
          method: 'get',
          headers: {
            'AUTHORIZATION': "Bearer "+this.$store.state.jwt
          },
          url: url,
        }).then(response => {
          view.$helpers.success('Pausen', 'Pausen wurden geladen');

          view.actualPauses = response.data.data;

          view.refreshVisibleMaps();
        }).catch(function (error) {
          view.saves = false;
        })
      },




      refreshVisibleMaps: function(){
        let view = this;
        
        
        Object.values(view.actualShifts).forEach(actualShift => {
            let add = false;
            let addHidden = true;
            //if(actualShift.mdConfirmed == 1){
              if(!actualShift.skip){
                add = true;
                addHidden = false;
              }
            //}

            if(add){
              view.exportableTableActualShifts.push(
                {
                  id: actualShift.id,
                  editing: false,
                  storeExternalId: view.storeExternalId,
                  bmdId: view.employeeMap[actualShift.employeeId].bmdId, 
                  dpwId: view.employeeMap[actualShift.employeeId].dpwId, 
                  socialsecurity: view.employeeMap[actualShift.employeeId].socialsecurity, 
                  firstName: view.employeeMap[actualShift.employeeId].firstName, 
                  lastName: view.employeeMap[actualShift.employeeId].lastName, 
                  employeeId: actualShift.employeeId,
                  actualShift:actualShift
                });
            }else if(addHidden){
              view.hiddenTableActualShifts.push(
                {
                  id: actualShift.id,
                  editing: false,
                  storeExternalId: view.storeExternalId,
                  bmdId: view.employeeMap[actualShift.employeeId].bmdId, 
                  dpwId: view.employeeMap[actualShift.employeeId].dpwId, 
                  socialsecurity: view.employeeMap[actualShift.employeeId].socialsecurity, 
                  firstName: view.employeeMap[actualShift.employeeId].firstName, 
                  lastName: view.employeeMap[actualShift.employeeId].lastName, 
                  employeeId: actualShift.employeeId,
                  actualShift:actualShift
                });
            }
        });

        view.exportableTableActualShifts.sort(view.sortActualShiftArray);
        view.hiddenTableActualShifts.sort(view.sortActualShiftArray);
        view.loading = false;
      },
      
      
      sortActualShiftArray: function(a,b){
        let x = a.lastName + " " + a.firstName;
        let y = b.lastName + " " + b.firstName;
        if ( x.toUpperCase() < y.toUpperCase() ) return -1;
        if ( x.toUpperCase() > y.toUpperCase() ) return 1;

        if ( a.actualShift.fromTimestamp < b.actualShift.fromTimestamp ) return -1;
        if ( a.actualShift.fromTimestamp > b.actualShift.fromTimestamp ) return 1;
        
        return 0;
      },





      modDate: function(method){
        let view = this;
        if(view.storeData != null && view.softwareId == 0){
          //BMD PFAD MONATLICH
          if(method == 'add'){
            this.selectedDate.add(1, 'months');
            this.timeframeStart = this.selectedDate.clone().startOf('month');
            this.timeframeEnd = this.selectedDate.clone().endOf('month');
            this.$forceUpdate();
          }else if(method == 'sub'){
            this.selectedDate.subtract(1, 'months');
            this.timeframeStart = this.selectedDate.clone().startOf('month');
            this.timeframeEnd = this.selectedDate.clone().endOf('month');
            this.$forceUpdate();
          }
        }else if(view.storeData != null && view.softwareId == 1){
          //DPW PFAD ZWEI WÖCHENTLICH
          if(method == 'add'){
            if(view.selectedDate.date() > 16){
              this.selectedDate.add(1, 'months').startOf('month');
              view.timeframeStart = view.selectedDate.clone().subtract(1, 'months').startOf('month').add('days',15);
              view.timeframeEnd = view.timeframeStart.clone().endOf('month');
              view.$forceUpdate();
            }else{
              this.selectedDate.endOf('month');
              view.timeframeStart = view.selectedDate.clone().startOf('month');
              view.timeframeEnd = view.timeframeStart.clone().add('days',14);
              view.$forceUpdate();
            }
          }else if(method == 'sub'){
            if(view.selectedDate.date() > 16){
              this.selectedDate.startOf('month');
              view.timeframeStart = view.selectedDate.clone().subtract(1, 'months').startOf('month').add('days',15);
              view.timeframeEnd = view.timeframeStart.clone().endOf('month');
              view.$forceUpdate();
            }else{
              this.selectedDate.subtract(1, 'months').endOf('month');
              view.timeframeStart = view.selectedDate.clone().startOf('month');
              view.timeframeEnd = view.timeframeStart.clone().add('days',14);
              view.$forceUpdate();
            }
          }
        }
        this.refresh();
      },
      toggleEmployeeDpw: function(actualShift){
        let view = this;
        Object.values(view.exportableTableActualShifts).forEach(actualShift => {
          actualShift.editing = false;
        });
        actualShift.editing = true;
        view.$forceUpdate();
      },
      saveEmployeeDpw: function(actualShift){
        let view = this;

        if(actualShift.dpwId.length > 8){
          view.$helpers.error('Speichern fehlgeschlagen', 'Die Nummer darf höchstens 8 stellig sein');
          return;
        }

        view.saves = true;

        let payload = {
          storeId: view.selectedStore,
          dpwId: actualShift.dpwId,
          employeeId: actualShift.employeeId,
        };
        let url = '/api/employee';
        
        this.axios({
          method: 'put',
          headers: {
            'AUTHORIZATION': "Bearer "+this.$store.state.jwt
          },
          url: url,
          data: payload
        }).then(response => {
           view.saves = false;
           view.$helpers.success('Speichern erfolgreich', 'Daten wurde erfolgreich gespeichert');

          view.employeeMap[actualShift.employeeId].dpwId = actualShift.dpwId;
          Object.values(view.exportableTableActualShifts).forEach(actualShift => {
            actualShift.editing = false;
            actualShift.dpwId = view.employeeMap[actualShift.employeeId].dpwId;
          });
          Object.values(view.hiddenTableActualShifts).forEach(actualShift => {
            actualShift.editing = false;
            actualShift.dpwId = view.employeeMap[actualShift.employeeId].dpwId;
          });

        }).catch(function (error) {
          view.saves = false;
          view.$helpers.error('Speichern fehlgeschlagen', 'Fehler beim Speichern der Daten');
        })



        view.$forceUpdate();
      },

      
      saveOnSFTP: function(){
        let view = this;
        let canExport = true;
        Object.values(view.exportableTableActualShifts).forEach(actualShift => {
          if(actualShift.dpwId == null){
            canExport = false;
          }
        });
        if(canExport){
          let view = this;
          let url = '/api/actualshiftexport/saveonsftp';

          url = this.$helpers.appendParam("storeId", view.selectedStore, url, '/api/actualshiftexport/saveonsftp');
          url = this.$helpers.appendParam("from", view.timeframeStart.format('DD.MM.YYYY'), url, '/api/actualshiftexport/saveonsftp');
          url = this.$helpers.appendParam("to", view.timeframeEnd.format('DD.MM.YYYY'), url, '/api/actualshiftexport/saveonsftp');

          this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url,
          }).then(response => {
            view.$helpers.success('Export erfolgreich', 'Die Datei wurde erstellt und gespeichert');
          }).catch(function (error) {
            console.log(error);
          })
        }else{
          view.$helpers.error('Export fehlgeschlagen', 'DPW ID fehlt bei einem Mitarbeiter');
        }
      },

      // TODO: use $helpers function
      forceFileDownload(response, title) {
        console.log(title)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
      },


    },
  }
</script>
<style scoped>
  .employee{
    height: 40px;
    width: 150px;
    margin: 5px;

    position: relative;
    box-shadow: 0 0 1px 1px var(--contrast-3);
    padding: 5px;
    float: left;
  }

</style>
