<template>
<div>
    <div class="full">
        <div class="full">
            <div class="mainheader">
                <div class="half">
                    Dokumente
                </div>
            </div>
        </div>
        <b-container fluid>
            <div class="full split-bottom">
                <div class="label left" v-if="loading">
                    <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
                    Dokumente werden geladen<br />
                </div>

                <div class="label left" v-if="!loading">
                    <div v-if="isEmpty">
                        <h4 style="margin-top:10px;margin-bottom:10px;">
                            Es wurden noch keine Dokumente hochgeladen
                        </h4>
                    </div>
                    <div v-for="(store, key) in storesComputed" v-bind:key="key">
                        <h4 style="margin-top:10px;margin-bottom:10px;">
                            {{storeNameAndDescription(store.documents[0].storeId)}}
                        </h4>
                        <table class="table table-striped table-borderless">
                            <tr>
                                <th class="documentsTh"></th>
                                <th class="documentsTh">Name</th>
                                <th class="documentsTh">Dokument Typ</th>
                                <th class="documentsTh" style="text-align:center;">Sensibles Dokument</th>
                                <th class="documentsTh" style="text-align:center;">Bestätigt</th>
                                <th class="documentsTh" style="text-align:center;">Datum</th>
                                <th class="documentsTh" style="text-align:center;">Ablaufdatum</th>
                                <th class="documentsTh" style="text-align:center;"></th>
                            </tr>
                            <tr v-for="doc in store.documents.sort((a,b) => (''+documentType(a.documentType)).localeCompare(documentType(b.documentType)))" v-bind:key="doc.id">
                              <!-- <p>{{ doc }}</p> -->
                                <td class="documentsTd"><a v-if="doc.fileId" style="cursor:pointer;" target="_blank" v-bind:href="doc.url + '/api/file?id=' + doc.fileId + '&accessToken='+doc.accessToken"><i style="cursor:pointer;" class="far" v-bind:class="fileIconClass(doc.fileType)"></i></a></td>
                                <td class="documentsTd">{{doc.name}}</td>
                                <td class="documentsTd">{{documentType(doc.documentType)}}</td>
                                <td class="documentsTd" style="text-align:center;">
                                    <i v-if="doc.sensitive" class="fas fa-check" style="cursor:auto;" />
                                    <i v-if="!doc.sensitive" class="fas fa-times" style="cursor:auto;" />
                                </td>
                                <td class="documentsTd" style="text-align:center;">
                                    <i v-if="doc.confirmed" class="fas fa-check" style="cursor:auto;" />
                                    <i v-if="!doc.confirmed" class="fas fa-times" style="cursor:auto;" />
                                </td>
                                <td class="documentsTd" style="text-align:center;">{{ $helpers.extractDateStringTZ(doc.created_at, 'DD.MM.YYYY') }}</td>
                                <td class="documentsTd" style="text-align:center;" v-if="doc.validUntil && !doc.validUntil.startsWith('Invalid')">{{ $helpers.extractDateStringTZ(doc.validUntil, 'DD.MM.YYYY') }}</td>
                                <td class="documentsTd" style="text-align:center;" v-if="!doc.validUntil || doc.validUntil.startsWith('Invalid')">-</td>
                                <td class="documentsTd" style="text-align:center;">
                                    <i v-if="doc.fileId" style="cursor:pointer;" v-bind:style="!doc.proxyDocument ? 'color: var(--ml);' : ''" class="far fa-download" v-tooltip="'Download'" v-on:click="downloadFile(doc)" />
                                    <i v-if="doc.fileId" style="cursor:pointer; margin-left: 5px;" v-bind:style="!doc.proxyDocument ? 'color: var(--ml);' : ''" class="far fa-envelope-open" v-tooltip="'Öffnen'" v-on:click="openFile(doc)" />
                                    <i v-show="!doc.proxyDocument" style="cursor:pointer; margin-left: 5px;" v-bind:style="!doc.proxyDocument ? 'color: var(--ml);' : ''" class="far fa-edit" v-tooltip="'Bearbeiten'" v-on:click="openEditModal(doc)"/>
                                    <i v-show="false && !doc.proxyDocument" style="cursor:pointer; margin-left: 5px;" v-bind:style="!doc.proxyDocument ? 'color: var(--ml);' : ''" class="far fa-file-check" v-tooltip="'Dokument bestätigen'"/>
                                    <i v-show="!doc.proxyDocument" style="cursor:pointer; margin-left: 5px;" v-bind:style="!doc.proxyDocument ? 'color: var(--ml);' : ''" class="far fa-trash" v-tooltip="'Dokument löschen'" v-on:click="deleteDocumentPopup(doc)" />

                                    <!-- <div v-tooltip="'Bestätigen'" class="iconbutton mright8" v-show="row.item.accessToken && !row.item.confirmed && ((!row.item.sensitive && canViewFiles) || (row.item.sensitive && canViewSensitiveFiles))" v-on:click="confirmEmployeeFile(row.item)" ><i class="fas fa-file-check" ></i></div>
                                    <div v-tooltip="'Bestätigung aufheben'" class="iconbutton mright8" v-show="row.item.accessToken && row.item.confirmed && ((!row.item.sensitive && canViewFiles) || (row.item.sensitive && canViewSensitiveFiles))" v-on:click="unconfirmEmployeeFile(row.item)" ><i class="fas fa-file-times" ></i></div>
                                    <div v-tooltip="'Sichtbarkeit ändern'" class="iconbutton mright8" v-show="row.item.accessToken && canViewSensitiveFiles" v-on:click="toggleFileSensitivity(row.item)" ><i class="fas fa-eye" ></i></div> -->

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </b-container>
    </div>

    <b-modal ref="modal-filldocuments" no-close-on-backdrop no-close-on-esc centered @cancel="hideModal('modal-filldocuments')" :hide-header="true" :hide-footer="true">
        <div class="upload docfillpopup">
            <b-row>
                <b-col>
                    <div class="header">
                        <div class="label">Dokumente ausfüllen</div>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="docTemplateLoading">
                <div style=" margin: 48px 0px; width: 100%; text-align: center; font-family: DistrictProThin;">
                    <div style="margin-bottom: 16px;"><i class="fas fa-rocket fa-7x" style="color: var(--md);"></i></div>
                    <div style="font-family: DistrictProThin; color: var(--contrast-4);">&nbsp;{{ docTemplateLoadingText }}</div>
                </div>
            </b-row>
            <b-row v-if="!docTemplateLoading && docfillpage == 1">
                <div class="full split-bottom">
                  <div class="subheader">
                      <div class="label">Storeauswahl</div>
                  </div>
                  <div class="full">
                    <div class="select-style" style="width: 75%; margin-left:50px">
                      <select v-model="docFillStoreId">
                          <option :value="-1">Aktuelle Mitarbeiter Kostenstelle</option>
                          <option v-for="store in storeArray" v-bind:key="store.id" :value="store.id">
                              {{ store.name }}
                          </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-for="type in templateTypes" v-bind:key="type.id" class="full split-bottom">
                    <div class="subheader">
                        <div class="label">{{ type.label_de }}</div>
                    </div>
                    <div v-for="t in templatesWithType(type, docFillStoreId)" v-bind:key="t.id">
                        <div class="full">
                            <div class="twothirds left">
                                <div class="data label">{{ t.name + " (" + (t.storeId && storeArray.find(s => s.id === t.storeId) ? storeArray.find(s => s.id === t.storeId).name : "Firmenweite Vorlage") + ")"}}</div>
                            </div>
                            <div class="third">
                                <div class="label data">
                                    <i class="fas fa-check-circle checkbox coredata" @click="t.checked = !t.checked; $forceUpdate();" v-bind:class="{'true' : t.checked, 'false' : !t.checked}"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="full">
                    <div class="twothirds left">
                        <div class="data label">Zukünftiger Arbeitsvertrag</div>
                    </div>
                    <div class="third">
                        <div class="label data">
                            <i class="fas fa-check-circle checkbox coredata" @click="toggleFillFutureContract()" v-bind:class="{'true' : fillFutureContract, 'false' : !fillFutureContract}"></i>
                        </div>
                    </div>
                </div>
                <div class="full split-bottom">
                    <div class="twothirds left">
                        <div class="data label">Vergangener Arbeitsvertrag</div>
                    </div>
                    <div class="third">
                        <div class="label data">
                            <i class="fas fa-check-circle checkbox coredata" @click="toggleFillPastContract()" v-bind:class="{'true' : fillPastContract, 'false' : !fillPastContract}"></i>
                        </div>
                    </div>
                </div>
            </b-row>
            <b-row v-if="!docTemplateLoading && docfillpage == 2">
                <div class="full split-bottom">
                    <div class="subheader">
                        <div class="label">{{ filledDocuments.length == 1? 'Ausgefülltes Dokument' : 'Ausgefüllte Dokumente' }}</div>
                    </div>
                    <div v-for="doc in filledDocuments" v-bind:key="doc.fileName">
                        <div class="full">
                            <div class="twothirds left">
                                <div class="data label">{{ docFilename(doc) }}</div>
                            </div>
                            <div class="third">
                                <div v-if="!docxDocuments" class="button small" @click="openFilledDocumentB64(doc)">
                                    <div class="label">
                                        Öffnen
                                    </div>
                                </div>
                                <div class="button small" @click="saveFilledDocumentB64(doc)">
                                    <div class="label">
                                        Download
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="filledDocuments.length > 1" class="full">
                    <div class="full">
                        <div class="twothirds left">
                            <div class="data label">&nbsp;</div>
                        </div>
                        <div class="third">
                            <div class="button small" @click="saveAllDocsB64()">
                                <div class="label">
                                    Alle herunterladen
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-row>
            <b-row v-if="!docTemplateLoading && docfillpage == -1">
                <div class="full">
                    <div class="subheader">
                        <div class="label">Vorgang fehlgeschlagen</div>
                    </div>
                    <div class="full">
                        <div class="data label">Beim Ausfüllen der Vorlage ist ein Fehler aufgetreten.</div>
                    </div>
                    <div class="full">
                        <div class="data label">Bitte prüfen Sie die Vorlage, wenn der Fehler weiter auftritt wenden Sie sich an den Support.</div>
                    </div>
                </div>
            </b-row>
            <hr/>
            <b-row style="margin-top:12px;">
                <b-col>
                    <div class="mright24 right">
                    <buttonc v-if="!docTemplateLoading && docfillpage == 1" type="no-border" label="Abbrechen" @click="hideModal('modal-filldocuments')"/>
                    <buttonc v-if="!docTemplateLoading" type="no-border ml" :label="docfillpage == 1? 'Dokumente ausfüllen' : 'Fertig'" @click="docfillpage == 1? fillSelectedDocuments() : endFillDocuments()"/>
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-modal>

    <b-modal ref="modal-createfile" no-close-on-backdrop no-close-on-esc centered @cancel="hideModal('modal-createfile')" :hide-header="true" :hide-footer="true">
        <div class="upload">
            <b-row>
                <b-col>
                    <div class="header">
                        <div class="label">Datei hochladen</div>
                    </div>
                    <div class="close" v-on:click="hideModal('modal-createfile'); resetFile()">
                        <i style="font-size: 14px; margin: -2px 0 0 -1.5px;" class="fas fa-times"></i>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <div v-if="previewPdf && pageCount > 1 && page > 1" @click="prevPage" class="previous fas fa-chevron-left" ></div>
                <b-col style="margin: 0px 35px;">
                    <div class="add">
                        <b-form-file @input="fileSelected()" style="display:none;" hidden id="file" accept="image/jpeg, image/png, application/pdf" v-model="employeeFile.upload" ref="fileInput" class="mb-2"></b-form-file>
                        <div v-if="uploading" class="loading">
                            <i class="fas fa-spinner"></i>
                        </div>
                        <div v-else-if="preview" style="width: 100%; height: 100%;">
                            <img id="imgPreview" class="preview" :src="previewSrc"/>
                            <div class="text-center" style="margin: 8px;">
                                <div class="button red" @click="resetFile">
                                    <div class="label">
                                        Foto löschen
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="previewPdf" style="width: 100%; height: 100%; overflow-y: hidden;">
                            <pdf
                                style="width: calc(100% - 16px); margin: 8px; height: 85%; overflow-y: hidden;"
                                :src="previewPdfSrc"
                                @numPages="pageCountLoaded"
                                @num-pages="pageCountLoaded"
                                :page="page"
                                ></pdf>
                            <div class="text-center" style="margin: 8px; height: 15%;">
                                <div class="button red" @click="resetFile">
                                    <div class="label">
                                        PDF löschen
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="fa-stack" v-on:click.stop="selectFileForUpload()">
                            <i class="fas fa-circle fa-stack-2x"></i>
                            <i class="fas fa-plus fa-stack-2x"></i>
                        </div>
                    </div>
                </b-col>
                <div v-if="previewPdf && pageCount > 1 && page < pageCount" @click="nextPage" class="next fas fa-chevron-right"></div>
            </b-row>
            <b-row>
                <b-col>
                    <b-row>
                        <b-col cols="2">
                            <div class="param">
                                Name:
                            </div>
                        </b-col>
                        <b-col cols="10">
                            <div style="margin-right:35px;">
                                <div class="cd-input" style="margin-top:0px;" v-bind:class="{bottomline: employeeFile.name == undefined || employeeFile.name == ''}">
                                    <input id="filename" v-model="employeeFile.name"/>
                                </div>
                            </div>
                        </b-col>

                    </b-row>
                    <b-row>

                        <b-col cols="2">
                            <div class="param">
                                Typ:
                            </div>
                        </b-col>
                        <b-col cols="10">
                            <div class="select-style" style="margin-right:35px;">
                                <select id="documenttype" v-model="employeeFile.documentType">
                                    <!--
                                    <option key="1" value="CONTRACT">
                                        Arbeitsvertrag
                                    </option>
                                    -->
                                    <option v-for="type in $helpers.documentTypes(true)" v-bind:key="type.key" :value="type.value">
                                        {{ type.label }}
                                    </option>
                                </select>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2">
                            <div class="param">
                                Store:
                            </div>
                        </b-col>
                        <b-col cols="10">
                            <div class="select-style" style="margin-right:35px;">
                                <select id="storeId" v-model="employeeFile.storeId">
                                    <option :value="null">Keinem Store zuordnen</option>
                                    <option :value="-1">Der aktuellen Kostenstelle zuordnen</option>
                                    <option v-for="store in storeArray" v-bind:key="store.id" :value="store.id">
                                        {{ store.name }}
                                    </option>
                                </select>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <div class="param">
                                Sensibles Dokument:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <b-form-checkbox id="sensitive" v-model="employeeFile.sensitive"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <div class="param">
                                Bestätigt:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <b-form-checkbox id="confirmed" v-model="employeeFile.confirmed"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <div class="param">
                                Hat Ablaufdatum:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <b-form-checkbox id="hasValidUntil" v-model="employeeFile.hasValidUntil"/>
                        </b-col>

                    </b-row>
                    <b-row v-if="employeeFile.hasValidUntil">
                        <b-col cols="6">
                            <div class="param">
                                Ablaufdatum:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <input type="date" id="validUntil" v-model="employeeFile.validUntil"/>
                        </b-col>
                    </b-row>
                    <hr/>
                    <b-row style="margin-top:12px;">
                        <b-col>
                            <div class="mright35">
                            <div class="button green right" @click="createFile">
                                <div class="label">
                                    Speichern
                                </div>
                            </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </b-modal>

    <b-modal ref="modal-editfile" no-close-on-backdrop no-close-on-esc centered :hide-header="true" :hide-footer="true">
        <div class="upload">
            <b-row>
                <b-col>
                    <div class="header">
                        <div class="label">Datei bearbeiten</div>
                    </div>
                    <div class="close" v-on:click="hideModal('modal-editfile'); resetFile()">
                        <i style="font-size: 14px; margin: -2px 0 0 -1.5px;" class="fas fa-times"></i>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <div v-if="previewPdf && pageCount > 1 && page > 1" @click="prevPage" class="previous fas fa-chevron-left" ></div>
                <b-col style="margin: 0px 35px;">
                    <div v-if="preview" class="add">
                        <div v-if="preview" style="width: 100%; height: 100%;">
                            <img id="imgPreview" class="preview" :src="employeeFile.url"/>
                        </div>
                    </div>
                    <div v-else-if="previewPdf" class="add">
                        <div style="width: 100%; height: 100%;">
                            <pdf
                                style="width: calc(100% - 16px); margin: 8px;"
                                :src="employeeFile.url"
                                @numPages="pageCountLoaded"
                                @num-pages="pageCountLoaded"
                                :page="page"
                                ></pdf>
                        </div>
                    </div>
                    <div v-else>
                        Keine Datei hinterlegt.
                        <hr/>
                    </div>
                </b-col>
                <div v-if="previewPdf && pageCount > 1 && page < pageCount" @click="nextPage" class="next fas fa-chevron-right"></div>
            </b-row>
            <b-row>
                <b-col>
                    <b-row>
                        <b-col cols="2">
                            <div class="param">
                                Name:
                            </div>
                        </b-col>
                        <b-col cols="10">
                            <div style="margin-right:35px;">
                                <input v-if="employeeFile.documentType != 'CONTRACT'" id="filename" v-model="employeeFile.name"/>
                                <div v-else class="cd-input" style="margin-top:0px;" v-bind:class="{bottomline: employeeFile.name == undefined || employeeFile.name == ''}">
                                    <input id="filename" v-model="employeeFile.name"/>
                                </div>
                            </div>
                        </b-col>

                    </b-row>
                    <b-row v-if="employeeFile.documentType != 'CONTRACT'">

                        <b-col cols="2">
                            <div class="param">
                                Typ:
                            </div>
                        </b-col>
                        <b-col cols="10">
                            <div class="select-style" style="margin-right:35px;">
                                <select id="documenttype" v-model="employeeFile.documentType">
                                    <option v-for="type in $helpers.documentTypes(true)" v-bind:key="type.key" :value="type.value">
                                        {{ type.label }}
                                    </option>
                                </select>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2">
                            <div class="param">
                                Store:
                            </div>
                        </b-col>
                        <b-col cols="10">
                            <div class="select-style" style="margin-right:35px;">
                                <select id="storeId" v-model="employeeFile.storeId">
                                    <option :value="null">Keinem Store zuordnen</option>
                                    <option :value="-1">Der aktuellen Kostenstelle zuordnen</option>
                                    <option v-for="store in storeArray" v-bind:key="store.id" :value="store.id">
                                        {{ store.name }}
                                    </option>
                                </select>
                            </div>
                        </b-col>
                    </b-row>

                    <hr/>

                    <b-row>
                        <b-col cols="6">
                            <div class="param">
                                Sensibles Dokument:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <b-form-checkbox id="sensitive" v-model="employeeFile.sensitive"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <div class="param">
                                Bestätigt:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <b-form-checkbox id="confirmed" v-model="employeeFile.confirmed"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <div class="param">
                                Hat Ablaufdatum:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <b-form-checkbox id="hasValidUntil" v-model="employeeFile.hasValidUntil"/>
                        </b-col>

                    </b-row>
                    <b-row v-if="employeeFile.hasValidUntil">
                        <b-col cols="6">
                            <div class="param">
                                Ablaufdatum:
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <input type="date" id="validUntil" v-model="employeeFile.validUntil"/>
                        </b-col>
                    </b-row>

                    <b-row style="margin-top:12px;">
                        <b-col>
                            <div class="mright35">
                            <div class="button green right" @click="editFile()">
                                <div class="label">
                                    Speichern
                                </div>
                            </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </b-modal>

    <b-modal ref="modal-deletefile" no-close-on-backdrop no-close-on-esc centered>
        <template slot="modal-header">
            <div class="error-modal">
                <div class="headerlabel">Datei löschen</div>
            </div>
        </template>
        <b-container fluid>
            <b-row class="my-1">
                Sind Sie sicher dass Sie die Datei löschen wollen?
            </b-row>
            <b-row v-if="fileerror" class="my-1">
                Fehler beim Löschen der Datei.
            </b-row>
        </b-container>
        <template slot="modal-footer">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="button red right" @click="hideModal('modal-deletefile')">
                <div class="label">
                    Abbrechen
                </div>
            </div>
            <div class="button green right" @click="deleteFile()">
                <div class="label">
                    OK
                </div>
            </div>
        </template>
    </b-modal>

    <b-modal ref="modal-upload-error" centered title="Fehler">
        <template slot="modal-header">
            <div class="error-modal">
                <div class="headerlabel">Fehler</div>
            </div>
        </template>
        <b-container fluid>
            <b-row class="my-1">
                Um den Datei Upload durchzuführen bitte {{ uploadErrorMessage }}!
            </b-row>
        </b-container>
        <template slot="modal-footer">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="button green right" @click="closeErrorModal()">
                <div class="label">
                    OK
                </div>
            </div>
        </template>
    </b-modal>

    <b-form-file @input="fileSelected(true)" style="display:none;" hidden id="file-outer" accept="image/jpeg, image/png, application/pdf" v-model="employeeFile.upload" ref="fileInput" class="mb-2"></b-form-file>
</div>
</template>

<script>
import pdf from 'vue-pdf'
import {
    mapState,
    mapGetters
} from 'vuex';
export default {
    name: 'employeedocuments',
    data() {
        return {
            loading: true,
            stores: {},
            isEmpty: true,

            fileTypeMapping: {
                "image/png" : "png",
                "image/jpeg": "jpg",
                "application/pdf" : "pdf",
                "text/plain": "txt"
            },

            employeeFile: {
                name: "",
                documentType: "UNDEFINED",
                hasValidUntil: false,
                validUntil: this.$helpers.getMomentTZ().add(7,'days').format("YYYY-MM-DD"),
                sensitive: false,
                confirmed: false,
                storeId: null,
                upload: null,
            },
            backupFile: {
                name: "",
                documentType: "UNDEFINED",
                hasValidUntil: false,
                validUntil: this.$helpers.getMomentTZ().add(7,'days').format("YYYY-MM-DD"),
                sensitive: false,
                confirmed: false,
                storeId: null,
                upload: null,
            },

            uploading: false,
            uploadErrorMessage: '',
            preview: false,
            previewSrc: '',
            previewPdf: false,
            previewPdfSrc: '',
            pageCount: 0,
            page: 1,
            fileerror: false,
            currentItem: {},

            docTemplateLoading: false,
            docTemplateLoadingText: '',
            documentTemplates: [],
            templateTypes: [],
            fillFutureContract: false,
            fillPastContract: false,
            docFillStoreId: -1,
            docfillpage: 1,
            filledDocuments: [],
            docxDocuments: false,
        }
    },
    components: {
        pdf
    },
    created: function () {
        //console.log("CREATED");
    },
    mounted: function () {
        //console.log("MOUNTED");
        if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
            this.refresh(true);
        }
    },
    computed: {
        ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings', 'storeArray', 'userInfo', 'employeeContractMap']),
        employeeFilenameAppendix(){
            let appendix = '';
            if(this.employee){
                appendix += '_' + this.employee.lastName + '_' + this.employee.firstName;
                let pId = this.employee.personnelId;
                if(pId != null && pId != undefined && pId != ""){
                    appendix += '_' + pId;
                }
            }
            return appendix;
        },
        storesComputed() {
            // console.log("storesComputed");
            return this.stores;
        }
    },
    watch: {
        companyId: function (val, oldVal) {
            this.refresh(true);
        },
        employee: function (val, oldVal){
            this.loading = true;
            this.refresh(true);
        }
    },
    props: [
        "employee"
    ],
    // methods that implement data logic.
    methods: {
        refresh: function (force = false) {
            this.loading = true;
            // console.log("loading documents. force? ", force);

            let view = this;
            let promises = [];

            promises.push(view.$helpers.GetEmployeeDocumentsMap(force).then((response) => {
                view.stores = {};
                view.isEmpty = true;
                Object.values(response).filter(x => x.employeeId == view.employee.id).forEach(doc => {
                    view.isEmpty = false;
                    if(view.stores[doc.storeId] == undefined){
                        view.stores[doc.storeId] = {};
                        view.stores[doc.storeId].documents = [];
                    }
                    view.stores[doc.storeId].documents.push(doc);
                });
            }).catch(function (error){
                console.log(error);
                view.$helpers.error('Fehler', 'Dokumente konnten nicht geladen werden');
            }));

            promises.push(view.$helpers.GetEmployeeContractMap(force));

            Promise.all(promises).finally(() => {
                view.loading = false;
            });
        },
        documentType(key){
            return this.$helpers.documentTypeLabel(key);
        },
        fileIconClass(key){
            return 'fa-' + this.$helpers.documentMimeIcon(key);
        },
        storeNameAndDescription(id){
            if (!id) {
              return "Keiner Kostenstelle zugeordnet";
            }
            for(let i in this.storeArray){
                let s = this.storeArray[i];
                if(s.id == id){
                    return s.name + " / " + s.description;
                }
            }
            return "Kostenstelle nicht gefunden";
        },

        deleteDocumentPopup(doc) {
            this.$helpers.OpenAlert({
                header: "Dokument löschen?",
                description: "Sind Sie sicher, dass Sie das Dokument löschen möchten? Dies kann NICHT rückgängig gemacht werden!",
                callbackAccept: (element)=>{
                    this.deleteDocument(doc);
                    element.hidden = true;
                },
                acceptLabel: "Dokument löschen"
            });
        },
        deleteDocument(doc) {
          let view = this;
          let successCallback = () => {
            view.stores[doc.storeId].documents = view.stores[doc.storeId].documents.filter(x => x.id != doc.id);
            if (view.stores[doc.storeId].documents.length == 0) {
              delete view.stores[doc.storeId];
            }
            view.$forceUpdate();
          };
          view.$helpers.DeleteCall('/api/sec/employee/documents/' + doc.id, doc, successCallback)
          .then((response) => {
            // handled in successCallback
          });
        },

        fileUploadDataError(){
            if(!this.employeeFile.upload) return "Datei auswählen";
            else if(!this.employeeFile.name) return "Namen wählen";
            else if(!this.employeeFile.documentType || this.employeeFile.documentType === 'UNDEFINED') return "Dokumenten Typ auswählen";
            return null;
        },
        selectFileForUpload(){
            let elem = document.getElementById('file');
            elem.click();
        },
        selectFileForUploadFromParent(){
            let elem = document.getElementById('file-outer');
            elem.click();
        },
        fileSelected(openModal = false){
            let view = this;
            view.page = 1;
            if(this.employeeFile.upload){
                if(this.employeeFile.name == null || this.employeeFile.name.length == 0){
                    let split = this.employeeFile.upload.name.split(".");

                    let name = split.length > 0 ? split[0].substring(0,20) : "";
                    this.employeeFile.name = name;
                }

                console.log('File selected, openModal: ' + openModal);
                if(openModal) this.showModal('modal-createfile');
                let reader = new FileReader();
                reader.onload = function (e){
                    if(view.employeeFile.upload.type == "application/pdf"){
                        view.preview = false;
                        view.previewPdf = true;
                        view.previewPdfSrc = e.target.result;
                    }else{
                        view.previewPdf = false;
                        view.preview = true;
                        view.previewSrc = e.target.result;
                    }
                };
                reader.readAsDataURL(this.employeeFile.upload);
            }
        },
        pageCountLoaded(event){
            this.pageCount = event;
            this.$forceUpdate();
        },
        prevPage(){
            if(this.page > 1) this.page = this.page - 1;
        },
        nextPage(){
            if(this.page < this.pageCount) this.page = this.page + 1;
        },
        resetFile(frontendOnly = false){
            this.previewSrc = '';
            this.preview = false;
            this.previewPdfSrc = '';
            this.previewPdf = false;
            this.uploading = false;
            if(!frontendOnly){
                this.employeeFile = {
                    upload: null,
                    name: "",
                    hasValidUntil: false,
                    validUntil: this.$helpers.getMomentTZ().add(7,'days').format("YYYY-MM-DD"),
                    sensitive: false,
                    confirmed: false,
                    storeId: null,
                    documentType: 'UNDEFINED'
                }
            }
        },
        openFile(item){
            let url = "";
            if(item.url){
                url+=item.url
            } else {
                url ="https://fileserver1.meindienstplan.de"
            }
            url+='/api/file?id=';
            if(item.fileId){
                url+= item.fileId
            } else if(item.id){
                url+= item.id
            }
            url+='&accessToken='+item.accessToken

            window.open(url, '_blank');
        },
        downloadFile(item){
            let url = "";
            if(item.url){
                url+=item.url
            } else {
                url ="https://fileserver1.meindienstplan.de"
            }
            url+='/api/file?id=';
            if(item.fileId){
                url+= item.fileId
            } else if(item.id){
                url+= item.id
            }
            url+='&accessToken='+item.accessToken;
            let fileEnding = this.fileTypeMapping[item.fileType] ? this.fileTypeMapping[item.fileType]: null;
            this.$helpers.DownloadFileFromFileServer(url, item.name, fileEnding)
        },
        createFile: function (evt) {
            evt.preventDefault();
            if(this.employeeFile.name == ""){
                this.employeeFile.name = this.documentTypeFormat(this.employeeFile.documentType);
            }
            this.uploadErrorMessage = this.fileUploadDataError();
            if(this.uploadErrorMessage == null){
                this.fileerror = false;
                let view = this;
                let url = '/api/sec/employee/documents';

                let formData = new FormData();
                formData.append("companyId", this.companyId);
                formData.append("storeId", this.employeeFile.storeId == -1 ? this.employee.storeId : this.employeeFile.storeId);
                formData.append("employeeId", this.employee.id);
                formData.append("name", this.employeeFile.name);
                formData.append("documentType", this.employeeFile.documentType);
                formData.append("sensitive", this.employeeFile.sensitive);
                formData.append("confirmed", this.employeeFile.confirmed);
                formData.append("file", this.employeeFile.upload);
                formData.append("fileType", this.employeeFile.upload.type);
                if(this.employeeFile.hasValidUntil){
                    formData.append("validUntil", this.$helpers.getMomentFromString(this.employeeFile.validUntil, "YYYY-MM-DD").utc().format());

                }
                this.uploading = true;
                this.axios({
                    method: 'post',
                    headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
                    url: url,
                    data: formData
                }).then(response => {
                    let file = response.data;

                    if(view.stores[file.storeId] == undefined){
                        view.stores[file.storeId] = {};
                        view.stores[file.storeId].documents = [];
                    }
                    view.stores[file.storeId].documents.push(file);

                    view.uploading = false;
                    view.resetFile(false);
                    view.hideModal('modal-createfile');
                    view.$helpers.success('Erstellen erfolgreich', 'Dokument wurde erfolgreich erstellt');
                    view.$emit('filesUpdated');
                }).catch(function (error) {
                    view.fileerror = true;
                    view.uploading = false;
                    view.$helpers.error('Fehler', 'Fehler beim Erstellen des Dokuments');
                    console.log(error)
                })
            }else{
                this.backupFile = this.employeeFile;
                this.showModal('modal-upload-error');
            }
        },
        closeErrorModal(){
            this.hideModal('modal-upload-error');
            this.employeeFile = this.backupFile;
        },
        openEditModal: function (item) {
            this.page = 1;
            this.currentItem = item;
            let url = item.fileId ? 'https://fileserver1.meindienstplan.de/api/file?id='+item.fileId+'&accessToken='+item.accessToken : "";
            let fileType = item.fileType;
            if (url == "") {
                this.previewPdf = false;
                this.preview = false;
            } else if(fileType == 'application/pdf'){
                this.previewPdf = true;
                this.preview = false;
            } else {
                this.previewPdf = false;
                this.preview = true;
            }

            console.log(item);

            this.employeeFile = {
                name: item.name,
                documentType: item.documentType,
                fileType: item.fileType,
                storeId: item.storeId,
                oldStoreId: item.storeId,
                sensitive: item.sensitive ? true : false,
                confirmed: item.confirmed ? true : false,
                hasValidUntil: item.validUntil != null,
                validUntil: item.validUntil == null ? (this.$helpers.getMomentTZ().add(7,'days').format("YYYY-MM-DD")) : (this.$helpers.getMomentFromStringUTC(item.validUntil).tz('Europe/Vienna').format("YYYY-MM-DD")),
                url: url
            };
            this.fileId = item.id;
            this.showModal('modal-editfile');
        },
        editFile: function () {
            this.fileerror = false;
            let view = this;
            let url = '/api/sec/employee/documents/' + this.fileId;
            this.employeeFile.storeId = this.employeeFile.storeId == -1 ? this.employee.storeId : this.employeeFile.storeId;

            let payload = {
                companyId: this.companyId,
                name: this.employeeFile.name,
                documentType: this.employeeFile.documentType,
                validUntil: null,
                confirmed: this.employeeFile.confirmed,
                sensitive: this.employeeFile.sensitive,
                storeId: this.employeeFile.storeId,
            }
            if(this.employeeFile.hasValidUntil){
               payload.validUntil = this.$helpers.getMomentFromString(this.employeeFile.validUntil, "YYYY-MM-DD").utc().format();
            }

            this.axios({
                method: 'put',
                headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
                url: url,
                data: payload
            }).then(response => {

                view.currentItem.name = view.employeeFile.name;
                view.currentItem.documentType = view.employeeFile.documentType;
                view.currentItem.validUntil = payload.validUntil;
                view.currentItem.confirmed = view.employeeFile.confirmed;
                view.currentItem.sensitive = view.employeeFile.sensitive;
                view.currentItem.storeId = view.employeeFile.storeId;

                if (view.employeeFile.oldStoreId != view.employeeFile.storeId) {
                    view.stores[view.employeeFile.oldStoreId].documents = view.stores[view.employeeFile.oldStoreId].documents.filter(x => x.id != view.fileId);
                    if (view.stores[view.employeeFile.oldStoreId].documents.length == 0) {
                        delete view.stores[view.employeeFile.oldStoreId];
                    }
                    if(view.stores[view.employeeFile.storeId] == undefined){
                        view.stores[view.employeeFile.storeId] = {};
                        view.stores[view.employeeFile.storeId].documents = [];
                    }
                    view.stores[view.employeeFile.storeId].documents.push(view.currentItem);
                }

                view.$helpers.success('Update erfolgreich', 'Update wurde erfolgreich durchgeführt');
                view.$emit('filesUpdated');
                view.hideModal('modal-editfile');
            }).catch(function (error) {
                view.fileerror = true;
                view.$helpers.error('Fehler', 'Fehler beim Update des Dokuments');
                console.log(error)
            })

        },
        documentTypeFormat(key){
            return this.$helpers.documentTypeLabel(key);
        },


        startFillDocuments(){
            this.showModal('modal-filldocuments');
            this.docFillStoreId = -1;
            this.docfillpage = 1;
            this.filledDocuments = [];
            this.fillMlTemplates = true;
            this.loadDocumentTemplates();
        },
        endFillDocuments(){
            this.hideModal('modal-filldocuments')
            this.docFillStoreId = -1;
            this.docfillpage = 1;
            this.filledDocuments = [];
            this.documentTemplates = [];
            this.templateTypes = [];
            this.docxDocuments = false;
        },
        loadDocumentTemplates: function(){
            this.docTemplateLoadingText = "Lade Daten...";
            this.docTemplateLoading = true;
            let view = this;

            let promises = [];

            let templateUrl = '/api/documenttemplates';
            templateUrl = this.$helpers.appendParam("companyId", this.$store.state.companyId, templateUrl, '/api/documenttemplates');
            promises.push(this.axios.get(templateUrl));

            let templateTypesUrl = '/api/templatetypes';
            templateTypesUrl = this.$helpers.appendParam("companyId", this.$store.state.companyId, templateTypesUrl, '/api/templatetypes');
            promises.push(this.axios.get(templateTypesUrl));

            Promise.all(promises).then((responses) => {
                view.docTemplateLoading = false;

                view.documentTemplates = responses[0].data.data[0];
                console.log(view.documentTemplates);
                view.templateTypes = responses[1].data.data;
                console.log(view.templateTypes);

            }).catch(error => {
                view.hideModal('modal-filldocuments');
                view.$helpers.error('Fehler', 'Fehler beim Laden der Vorlagen');

                view.openUploadTemplatePopup(true);
            });

        },
        templatesWithType(type, storeId = null){
            if (storeId == -1) storeId = this.employee.storeId;
            let result = [];
            this.documentTemplates.forEach((t) => {
              if(t.template_type == type.id) {
                if (t.storeId == null || storeId == null || t.storeId == storeId) {
                  result.push(t);
                }
              }
            });
            return result;
        },
        b64ToFile(b64){
            var byteCharacters = atob(b64);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new Blob([byteArray], { type: this.filledDocumentsType + ';base64' });
            return file;
        },
        openFilledDocumentB64(doc){
            if(this.fillMlTemplates){
                let pdfWindow = window.open("")
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(doc.data) + "'></iframe>"
                )
            }else{
            var file = this.b64ToFile(doc.data);
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            }
        },
        docFilename(doc){
            let filename = doc.fileName;
            if(this.docxDocuments){
                filename = filename.replace('.docx', this.employeeFilenameAppendix + '.docx');
            }else{
                filename = filename.replace('.pdf', this.employeeFilenameAppendix + '.pdf');
            }
            return filename;
        },
        saveFilledDocumentB64(doc){
            var file = this.b64ToFile(doc.data);
            this.$helpers.forceFileDownload(file, this.docFilename(doc));
        },
        saveAllDocsB64(){
            this.filledDocuments.forEach((doc) => {
                this.saveFilledDocumentB64(doc);
            });
        },
        toggleFillPastContract(){
            this.fillPastContract = !this.fillPastContract;
            this.$forceUpdate();
            if(this.fillPastContract){
                this.fillFutureContract = false;
            }
        },
        toggleFillFutureContract(){
            this.fillFutureContract = !this.fillFutureContract;
            this.$forceUpdate();
            if(this.fillFutureContract){
                this.fillPastContract = false;
            }
        },
        fillSelectedDocuments(){
            let view = this;
            this.fillMlTemplates = true;
            let docDate = this.$helpers.getMomentTZ().format('DD.MM.YYYY');
            let payload = {
                companyId: parseInt(this.$store.state.companyId),
                remoteId: this.employee.id,
                // employeeId: "01fd952f-fb3a-4899-ad4b-225aaedc98d8",
                documentDate: docDate,
                storeId: this.docFillStoreId == -1 ? this.employee.storeId : this.docFillStoreId,
            };

            let docContract = this.contract;
            if(this.fillFutureContract){
                //find the next contract in the future
                let id = null;
                let now = this.$helpers.getMomentTZ();
                let fcontracts = Object.values(this.employeeContractMap).filter(c => {
                    let ct = this.$helpers.getMomentFromStringTZ(c.from);
                    return c.remoteId == this.employee.id && ct >= now;
                }).sort((a,b) => {
                    let at = this.$helpers.getMomentFromStringTZ(a.from);
                    let bt = this.$helpers.getMomentFromStringTZ(b.from);
                    return at.unix() - bt.unix();
                })
                if(fcontracts.length > 0){
                    docContract = fcontracts[0];
                }
            }else{
                //find the last contract that ended in the past
                let id = null;
                let now = this.$helpers.getMomentTZ();
                let pcontracts = Object.values(this.employeeContractMap).filter(c => {
                    let ct = null;
                    if(c.to != null) {
                        ct = this.$helpers.getMomentFromStringTZ(c.to);
                    }
                    return c.remoteId == this.employee.id && ct != null && ct < now;
                }).sort((a,b) => {
                    let at = this.$helpers.getMomentFromStringTZ(a.from);
                    let bt = this.$helpers.getMomentFromStringTZ(b.from);
                    return bt.unix() - at.unix();
                })
                if(pcontracts.length > 0){
                    docContract = pcontracts[0];
                }
            }

            if(docContract != null && docContract != undefined){
                let id = docContract.id;
                if(id != null){
                    payload.contractId = id;
                }
                let preceedingContract = this.getPreceedingContract(docContract);
                if(preceedingContract != null){
                    let oId = preceedingContract.id;
                    if(oId != null){
                        payload.oldContractId = oId;
                    }
                }
            }
            let ids = [];
            this.documentTemplates.forEach((t) => {
                if(t.checked) ids.push(t.id);
            });
            payload.templates = ids.join(",");

            //console.log('Calling docfill endpoint');
            //console.log(payload);

            let url = '/api/sec/employee/documents/fill';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/documents/fill');
            this.loading = true;
            this.loadingText = "Dokumente werden erstellt...";
            this.axios({
                method: 'post',
                headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
                url: url,
                data: payload
            }).then(response => {
                view.loading = false;
                console.log(response);
                view.filledDocuments = response.data.data.data;
                view.docfillpage = 2;
            }).catch(function (error) {
                view.$helpers.error('Dokument füllen fehlgeschlagen', '');
                console.log(error);
                //view.endFillDocuments();
                view.docfillpage = -1;
                view.loading = false;
            });

        },
        getPreceedingContract(contract){
            let from = this.$helpers.getMomentFromStringTZ(contract.from);
            let prec = null;
            Object.values(this.employeeContractMap).forEach((pc) => {
                if(pc.remoteId == this.employee.id){
                    let to = this.$helpers.getMomentFromStringTZ(pc.to);
                    let diff = this.$helpers.TimeDiff(from, to);
                    if(diff == 0){
                        prec = pc;
                    }
                }
            });
            return prec;
        },


        hideModal(ref) {
            this.fileerror = false;
            this.fileId = "";
            this.employeeFile = {
                name: "",
                sensitive: false,
                confirmed: false,
                storeId: null,
                documentType: "UNDEFINED",
                upload: null,
            },
            this.currentItem = {};
            this.$refs[ref].hide()
        },
        showModal(ref) {
            this.$refs[ref].show()
        },
    },

}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->

<style scoped>
a {
    color: var(--ml);
    cursor: pointer !important;
}

a:hover {
    color: var(--contrast-4);
    cursor: pointer !important;
}

.docfillpopup .subheader{
    width: 100%;
    margin: 16px 0px 8px 0px;
    box-shadow: 0 2px 0 -1px var(--contrast-2);
}

.docfillpopup .subheader .label{
    font-family: DistrictProBold;
    font-size: 14px;
    color: var(--ml);
    margin: 0px 50px 0px 50px;
}

.docfillpopup .data.label{
    font-family: DistrictProBook;
    font-size: 11px;
    color: var(--contrast-4);
    margin: 0px 0px 0px 50px;
}
</style>
