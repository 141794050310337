<template>
    <div class="c-dropdown" v-bind:style="{top: dropdown.posy + 'px', left: dropdown.posx + 'px', display: dropdown.toggled ? 'inline-block' : 'none'}">
        <div class="background" v-on:click="toggle()"></div>
        <div class="container">
            <div class="item" v-for="(item, i) in dropdown.items" :key="i" v-on:click='selectItem($event, item)'
                 v-bind:style="{background: item.color !== '' ? item.color : lightgray}">
                <i class="fas text-center" style="width:13px;" v-bind:class="item.icon" v-bind:style="{display: (item.icon ? 'inline-block' : 'none')}"></i>
                <div class="label">{{(item.description && item.description !== '') ? item.description : item.label}}</div>
                <i v-if="item.toggle !== undefined && item.toggle" class="fas fa-check-circle checkbox true"></i>
                <i v-if="item.toggle !== undefined && !item.toggle" class="fas fa-circle checkbox false"></i>
                <router-link class="router-link" v-if="item.link" :to="item.link.name? item.link : { name: item.link }"></router-link>
            </div>
        </div>
        <div class="pointer up" v-bind:style="{left: dropdown.pointerOffset + 'px', display: !dropdown.pointerDirection || dropdown.pointerDirection === 0 ? 'inline-block' : 'none'}"></div>
        <div class="pointer right" v-bind:style="{top: dropdown.pointerOffset + 'px', display: dropdown.pointerDirection === 1 ? 'inline-block' : 'none'}"></div>
        <div class="pointer down" v-bind:style="{left: dropdown.pointerOffset + 'px', display: dropdown.pointerDirection === 2 ? 'inline-block' : 'none'}"></div>
        <div class="pointer left" v-bind:style="{top: dropdown.pointerOffset + 'px', display: dropdown.pointerDirection === 3 ? 'inline-block' : 'none'}"></div>
        <dropdownsearch v-bind:style="{display: searchbarItems.items.length > 0 ? '': 'none'}" :state="searchbarItems"/>
    </div>
</template>

<script>
    import dropdownsearch from './dropdownsearch.vue'
    export default {
        name: 'dropdown',
        components: {
            dropdownsearch,
        },
        data(){
            return{
                searchbarItems: {items: [], input: ''},
                el: null,
                defaultSettings: {
                    pointerDirection: 0,
                    pointerOffset: 15,
                },
                selectedItem: {},
                items: [],
                toggled: true,
                posx: 0,
                remainAfterSelect: false,
                posy: 0,
                pointerDirection: 0,
                pointerOffset: 0,
                onceSelected: function($event,item){},
                parentCallback: function(){},
            }
        },
        created () {
            var view = this;
            if(this.dropdown && this.dropdown.items && this.dropdown.items.length === 1 && this.dropdown.items[0].label === 'Betriebe')
            {
                this.searchbarItems = this.dropdown.items[0].searchbarItems;
                this.dropdown.items = [];
                var dropdown = this.dropdown;
                requestAnimationFrame(function(){
                    temp.$el.querySelector('.items').style.width = '180px';
                    temp.$el.querySelector('.items').style.position = 'absolute';
                    temp.$el.querySelector('.items').style.left = '0';
                });

            }
            requestAnimationFrame(function(){
                $(view.$el).appendTo('body');
            });

            window.addEventListener("resize", this.Reposition);
        },
        destroyed () {
            window.removeEventListener("resize", this.Reposition);
        },
        props:{
            dropdown: Object
        },
        methods: {
            Reposition(){
                var box = this.dropdown.el.getBoundingClientRect();
                this.dropdown.posx = box.left + this.dropdown.offsetx;
                this.dropdown.posy = box.bottom + this.dropdown.offsety;
            },
            Init(items, config){
                for(var x in this.defaultSettings)
                {
                    this[x] = this.defaultSettings[x];
                }
                for(var y in config)
                {
                    if(Object.keys(this).indexOf(y) > -1) this[y] = config[y];
                }
                this.items = items;
            },
            selectItem($event, item){
                var event = $event;
                var dropdown = this.dropdown;
                this.selectedItem = item;
                this.dropdown.toggled = (item.items && item.items.length > 0);
                
                if(item.externalLink)
                {
                    var win = window.open(item.externalLink, '_blank');
                    win.focus();
                }
                if(item.onceSelected){
                    item.onceSelected($event, item, dropdown);
                }
                else{
                    if(this.dropdown.onceSelected)this.dropdown.onceSelected($event, item, dropdown);
                }
                if(this.dropdown.parentCallback) this.dropdown.parentCallback();
                this.$emit("dropdown-selected", item);
                this.$forceUpdate();
            },
            loadItems(itemsNew){
                this.items = itemsNew;
            },
            toggle(){
                this.dropdown.toggled = !this.dropdown.toggled;
                if(this.dropdown.parentCallback) this.dropdown.parentCallback();
            },
        }
    }
</script>
