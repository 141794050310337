var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"button",class:[
    _vm.type,
    _vm.classes,
    {
      'loading-button': _vm.loading,
      selected: _vm.selected,
      disabled: _vm.IsDisabled,
      active: _vm.active,
      'focus-highlight': _vm.hover,
    } ],style:([_vm.styleButton, { display: _vm.InputHidden ? 'none' : '' }]),on:{"click":function($event){return _vm.Click($event)},"mousedown":function($event){return _vm.MouseDown($event)}}},[(_vm.icon)?_c('div',{staticClass:"icon fas",class:[_vm.icon],style:(_vm.styleIcon)}):_vm._e(),(_vm.label)?_c('div',{staticClass:"label",style:([_vm.styleLabel])},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"fas fa-spinner loading-icon"}):_vm._e(),(!_vm.InputHidden)?_c('button',{attrs:{"tabindex":_vm.IsDisabled ? -1 : ''},on:{"change":function($event){return _vm.Click($event)},"focus":_vm.Focus,"blur":_vm.Blur}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }