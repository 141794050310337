import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import LandingPage from '../views/LandingPage.vue'
import PwReset from '../views/PwReset.vue'
import Activation from '../views/Activation.vue'
import Registration from '../views/Registration.vue'
import Profile from '../views/Profile.vue'
import AdminOverview from '../views/AdminOverview.vue'
import Logout from '../views/Logout.vue'
import AbsenceExport from '../views/AbsenceExport.vue'
import AbsenceMain from '../views/AbsenceMain.vue'
import documentsmain from '../components/employeecontext/DocumentsMain.vue'

import CompanySettingsMain from '../components/companysettingcontext/CompanySettingsMain.vue'
import ReportAbsences from '../components/reportcontext/ReportAbsences';
import ComparisonHoliday from '../components/comparecontext/ComparisonHoliday';


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Landing',
    component: LandingPage
  },
  {
    path: '/employee/:tab',
    name: 'employee',
    props: true,
  },
  {
    path: '/superadmin/:tab',
    name: 'superadmin',
    props: true,
  },
  {
    path: '/company/:tab',
    name: 'company',
    component: CompanySettingsMain,
    props: true,
  },
  {
    path: '/absence/:tab',
    name: 'absence',
    component: AbsenceExport,
    props: true,
  },
  {
    path: '/import',
    name: 'Import',
    component: Activation
  },
  {
    path: '/documents/:tab',
    name: 'documents',
    props: true,
    component: documentsmain
  },
  {
    path: '/reports/:tab',
    name: 'reports',
    props: true,
    component: ReportAbsences
  },
  {
    path: '/comparison/:tab',
    name: 'comparison',
    props: true,
    component: ComparisonHoliday
  },
  {
    path: '/pwreset/:token/:email',
    name: 'PwReset',
    component: PwReset
  },
  {
    path: '/activation/:token/:email',
    name: 'Activation',
    component: Activation
  },
  {
    path: '/registration/:token/:email',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/overview',
    name: 'Overview',
    component: AdminOverview
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
