<template>
    <div class="slider" v-bind:class="{inactive: isDisabled}">
        <buttonc class="step left" :hideInput="false" v-bind:class="{disabled: decreaseButtonDisabled}" type="small round" icon="fa-minus" @click="value -= step, SliderEnd()" :styleIcon="{'font-size': '8px'}"/>
        <div class="bar">
            <div class="handle-container start" v-bind:style="{left: GetOffset()}" v-on:mousedown="SliderStart($event)" v-on:mouseup="FocusController('start')">
                <div class="handle" v-bind:class="{'focus-highlight': hover === 'start'}">
                    <div class="amount" v-bind:style="GetOffsetHandleLabel(false)" v-if="!hideValue">
                        {{labelValue}}
                    </div>
                    <input class="controller" type="number" v-on:keydown="ManageInput($event)" v-on:focus="Focus($event, 'start')" v-on:blur="Blur($event, 'start')"/>
                </div>
            </div>
            <div v-if="isRange" class="handle-container middle" v-bind:class="{'focus-highlight': hover === 'middle'}" v-bind:style="GetOffsetMiddle()" v-on:mousedown="SliderStartMiddle($event)" v-on:mouseup="FocusController('middle')">
                <input class="controller" type="number" v-on:keydown="ManageInputMiddle($event)" v-on:focus="Focus($event, 'middle')" v-on:blur="Blur($event, 'middle')"/>
            </div>
            <div v-if="isRange" class="handle-container end" v-bind:style="{left: GetOffset(true)}" v-on:mousedown="SliderStart($event, true)" v-on:mouseup="FocusController('end')">
                <div class="handle" v-bind:class="{'focus-highlight': hover === 'end'}">
                    <div class="amount" v-bind:style="GetOffsetHandleLabel(true)" v-if="!hideValue">
                        {{labelvalueEnd}}
                    </div>
                    <input class="controller" type="number" v-on:keydown="ManageInput($event, true)" v-on:focus="Focus($event, 'end')" v-on:blur="Blur($event, 'end')"/>
                </div>
            </div>
        </div>
        <buttonc class="step right" :hideInput="false" v-bind:class="{disabled: increaseButtonDisabled}" type="small round" icon="fa-plus" @click="value += step, SliderEnd()" :styleIcon="{'font-size': '8px', top: '-2px'}"/>
        <div class="amount min" v-if="!hideMin">
            {{labelMin}}
        </div>
        <div class="amount max" v-if="!hideMax">
            {{labelMax}}
        </div>
        <div class="label-right" v-if="showValueRight">
            {{labelValue}}
        </div>
        <div v-if="!isRange" class="bar left" v-bind:style="{width: GetOffset()}"></div>
    </div>
</template>

<script>
    import datepickerarray from './datepickerarray.vue'
    import datepicker from './datepicker.vue'
    import dropdownsearch from './dropdownsearch.vue'
    export default {
        name: "datafill",
        components: {
            datepickerarray,
            datepicker,
            dropdownsearch,
        },

        created(){
            var message = this;
            if(this.state) for(var x in this.state) this[x] = this.state[x];
        },
        destroyed () {
        },
        computed: {
            isDisabled(){
                return this.disabled || (this.DisabledCheck && this.DisabledCheck());
            },
            labelMin(){
                if(this.DisplayLabel) return this.DisplayLabel(this.min);
                return this.min;
            },
            labelMax(){
                if(this.DisplayLabel) return this.DisplayLabel(this.max);
                return this.max;
            },
            labelValue(){
                if(this.DisplayLabel) return this.DisplayLabel(this.value);
                return this.value;
            },
            labelvalueEnd(){
                if(this.DisplayLabel) return this.DisplayLabel(this.valueEnd);
                return this.valueEnd;
            },
            decreaseButtonDisabled(){
                return this.value <= this.min;
            },
            increaseButtonDisabled(){
                return this.value >= this.max;
            }
        },
        data(){
            return{
                startPos: {x: 0, y: 0},
                sliding: false,
                box: null,
                min: 0,
                max: 100,
                value: 40,
                isRange: false, //determines if slider uses range values
                valueEnd: 60, //initial value of end of range
                minRange: 0, //how close start and end of range can be
                maxRange: 20, //how large range value can be
                valueDifference: 1, //helper for dragging middle bar
                valueStartDrag: 1, //same
                DisabledCheck: null,
                disabled: false,
                step: 1,
                hover: '',
                hideMin: false,
                hideMax: false,
                hideValue: false,
                showValueRight: false
            }
        },
        /* example range state
            state = {
                isRange: true,
                min: 0,
                max: 100,
                value: 40,
                valueEnd: 60,
                minRange: 10,
                maxRange: 30,
                callback: (value) => {}
            }

            callback for one value slider is a float,
            callback for range slider is an object {start: float, end: float}
        */
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal){
                    let view = this;
                    for(var x in newVal) this[x] = newVal[x];
                    if(isNaN(parseInt(this.value)) && !isNaN(parseInt(this.min)) && isNaN(parseInt(this.max))) this.value = (this.min + this.max) * .5;
                    if(isNaN(parseInt(this.min)) || isNaN(parseInt(this.max)) || this.min === this.max) this.disabled = true;
                }
            }
        },
        props:{
            state: Object,
        },
        methods: {
            Focus($event, handleType){
                this.$el.querySelector('.' + handleType + ' input').value = '';
                this.hover = handleType;
            },
            Blur($event, handleType){
                this.$el.querySelector('.' + handleType + ' input').value = '';
                this.hover = '';
            },
            FocusController(handleType){
                this.$el.querySelector('.' + handleType + ' .controller').focus();
            },
            ManageInputMiddle($event){
                let pageStep = Math.ceil((this.max - this.min) / this.step);
                pageStep = (this.step * Math.ceil(pageStep / 10));
                let valueDifference = this.valueEnd - this.value;
                switch($event.key){
                    case 'ArrowLeft':
                        this.value -= this.step;
                        this.valueEnd -= this.step;
                        $event.preventDefault();
                    break;
                    case 'ArrowRight':
                        this.value += this.step;
                        this.valueEnd += this.step;
                        $event.preventDefault();
                    break;
                    case 'PageDown':
                        this.value = this.value - (this.value % pageStep);
                        this.value += pageStep;
                        this.valueEnd = this.valueEnd - (this.valueEnd % pageStep);
                        this.valueEnd += pageStep;
                        $event.preventDefault();
                    break;
                    case 'PageUp':
                        this.value = this.value - (this.value % pageStep);
                        this.value -= pageStep;
                        this.valueEnd = this.valueEnd - (this.valueEnd % pageStep);
                        this.valueEnd -= pageStep;
                        $event.preventDefault();
                    break;
                    case 'Home':
                        this.value = this.min;
                        this.valueEnd = this.min + valueDifference;
                        $event.preventDefault();
                    break;
                    case 'End':
                        this.value = this.max - valueDifference;
                        this.valueEnd = this.max;
                        $event.preventDefault();
                    break;
                }
                if(this.value + valueDifference > this.max) {
                    this.valueEnd = this.max;
                    this.value = this.valueEnd - valueDifference;
                }
                if(this.valueEnd - valueDifference < this.min) {
                    this.value = this.min;
                    this.valueEnd = this.value + valueDifference;
                }
                let view = this;
                requestAnimationFrame(() => {
                    view.$forceUpdate(); //fix handle label offset when they are fixed from clipping but don't reset when the next value isn't clipping
                });
                this.SliderEndMiddle();
            },
            ManageInput($event, isRange){
                let pageStep = Math.ceil((this.max - this.min) / this.step);
                pageStep = (this.step * Math.ceil(pageStep / 10));
                switch($event.key){
                    case '0':
                    case '1':
                    case '2':
                    case '3':
                    case '4':
                    case '5':
                    case '6':
                    case '7':
                    case '8':
                    case '9':
                        if(!isRange){
                            if($event.currentTarget.value === '') this.value = parseInt($event.key);
                            else this.value = parseInt(this.value + $event.key);
                        } else {
                            if($event.currentTarget.value === '') this.valueEnd = parseInt($event.key);
                            else this.valueEnd = parseInt(this.valueEnd + $event.key);
                        }
                    break;
                    case 'Backspace':
                        if(!isRange){
                            this.value = parseInt(this.value.toString().substr(0, this.value.toString().length - 1));
                        } else {
                            this.valueEnd = parseInt(this.valueEnd.toString().substr(0, this.valueEnd.toString().length - 1));
                        }
                    break;
                    case 'ArrowLeft':
                        if(!isRange){
                            this.value -= this.step;
                        } else {
                            this.valueEnd -= this.step;
                        }
                        $event.preventDefault();
                    break;
                    case 'ArrowRight':
                        if(!isRange){
                            this.value += this.step;
                        } else {
                            this.valueEnd += this.step;
                        }
                        $event.preventDefault();
                    break;
                    case 'PageDown':
                        if(!isRange){
                            this.value = this.value - (this.value % pageStep);
                            this.value += pageStep;
                        } else {
                            this.valueEnd = this.valueEnd - (this.valueEnd % pageStep);
                            this.valueEnd += pageStep;
                        }
                        $event.preventDefault();
                    break;
                    case 'PageUp':
                        if(!isRange){
                            this.value = this.value - (this.value % pageStep);
                            this.value -= pageStep;
                        } else {
                            this.valueEnd = this.valueEnd - (this.valueEnd % pageStep);
                            this.valueEnd -= pageStep;
                        }
                        $event.preventDefault();
                    break;
                    case 'Home':
                        if(!isRange){
                            this.value = this.min;
                        } else {
                            this.valueEnd = this.min;
                        }
                        $event.preventDefault();
                    break;
                    case 'End':
                        if(!isRange){
                            this.value = this.max;
                        } else {
                            this.valueEnd = this.max;
                        }
                        $event.preventDefault();
                    break;
                }
                if(isRange){
                    if(isNaN(this.valueEnd)) this.valueEnd = this.max;
                    if(this.valueEnd - this.value > this.maxRange) this.valueEnd = this.value + this.maxRange;
                    if(this.valueEnd - this.value < this.minRange) this.valueEnd = this.value + this.minRange;
                } else {
                    if(isNaN(this.value)) this.value = this.min;
                    if(this.isRange && this.valueEnd - this.value > this.maxRange) this.value = this.valueEnd - this.maxRange;
                    if(this.isRange && this.valueEnd - this.value < this.minRange) this.value = this.valueEnd - this.minRange;
                }
                let view = this;
                requestAnimationFrame(() => {
                    view.$forceUpdate(); //fix handle label offset when they are fixed from clipping but don't reset when the next value isn't clipping
                });
                this.SliderEnd(isRange);
            },
            SliderStart($event, isRange){
                if(this.IsDisabled) return;
                this.box = $event.currentTarget.parentNode.getBoundingClientRect();
                this.sliding = true;
                this.startPos = window.mouse;
                window.mouse.click = true;
                this.SliderWatch(isRange);
            },
            SliderWatch(isRange){
                let view = this;
                let factor = (window.mouse.x - this.box.left) / this.box.width;
                if(isRange){
                    this.valueEnd = (((this.max - this.min) * factor ) + this.min);

                    this.valueEnd = (this.valueEnd) - (this.valueEnd % this.step);

                    if(this.valueEnd < this.min) this.valueEnd = this.min;
                    if(this.valueEnd > this.max) this.valueEnd = this.max;
                    if(this.valueEnd < this.value) this.valueEnd = this.value;
                    if(Math.abs(this.value - this.valueEnd) < this.minRange) this.valueEnd = this.value + this.minRange;
                    if(Math.abs(this.value - this.valueEnd) > this.maxRange) this.valueEnd = this.value + this.maxRange;
                } else {
                    this.value = (((this.max - this.min) * factor ) + this.min);

                    this.value = (this.value) - (this.value % this.step);

                    if(this.value < this.min) this.value = this.min;
                    if(this.value > this.max) this.value = this.max;
                    if(this.isRange){
                        if(this.value > this.valueEnd) this.value = this.valueEnd;
                        if(Math.abs(this.value - this.valueEnd) < this.minRange) this.value = this.valueEnd - this.minRange;
                        if(Math.abs(this.value - this.valueEnd) > this.maxRange) this.value = this.valueEnd - this.maxRange;
                    }
                }

                if(window.mouse.click){
                    requestAnimationFrame(function(){view.SliderWatch(isRange)});
                } else {
                    this.SliderEnd(isRange);
                }

            },
            SliderEnd(isRange){
                this.$el.querySelector('.' + (isRange ? 'end' : 'start') + ' .controller').focus();
                this.sliding = false;
                this.box = null;
                if(this.isRange){
                    if(this.valueEnd < this.min) this.valueEnd = this.min;
                    if(this.valueEnd > this.max) this.valueEnd = this.max;
                    if(this.valueEnd < this.value) this.valueEnd = this.value;
                    if(this.callback) this.callback({start: this.value, end: this.valueEnd}, this.object);
                    this.$emit('callback', {start: this.value, end: this.valueEnd}, this.object);
                } else {
                    if(this.value < this.min) this.value = this.min;
                    if(this.value > this.max) this.value = this.max;
                    if(this.isRange && this.value > this.valueEnd) this.value = this.valueEnd;
                    if(this.callback) this.callback(this.value, this.object);
                    this.$emit('callback', this.value, this.object);
                }
            },
            SliderStartMiddle($event){
                if(this.IsDisabled) return;
                this.box = $event.currentTarget.parentNode.getBoundingClientRect();
                this.sliding = true;
                this.startPos = {x: window.mouse.x - ($event.currentTarget.getBoundingClientRect().left + 2)}; // 2 is from margins
                this.valueDifference = this.valueEnd - this.value;
                console.log(this.valueDifference);
                this.valueStartDrag = this.value;
                window.mouse.click = true;
                this.SliderWatchMiddle();
            },
            SliderWatchMiddle(){
                let view = this;
                let factor = ((window.mouse.x - this.startPos.x) - this.box.left) / this.box.width;
                //let factor = ((window.mouse.x - this.startPos.x) + ((this.valueStartDrag - this.min) / (this.max - this.min))) / this.box.width;

                this.value = (((this.max - this.min) * factor ) + this.min);

                this.value = (this.value) - (this.value % this.step);

                if(this.value < this.min) this.value = this.min;
                if(this.value + this.valueDifference > this.max) this.value = this.max - this.valueDifference;
                this.valueEnd = this.value + this.valueDifference;

                if(window.mouse.click){
                    requestAnimationFrame(function(){view.SliderWatchMiddle()});
                } else {
                    this.SliderEndMiddle();
                }

            },
            SliderEndMiddle(){
                this.$el.querySelector('.middle .controller').focus();
                this.sliding = false;
                this.box = null;
                if(this.valueEnd < this.min) this.valueEnd = this.min;
                if(this.valueEnd > this.max) this.valueEnd = this.max;
                if(this.valueEnd < this.value) this.valueEnd = this.value;
                if(this.callback) this.callback({start: this.value, end: this.valueEnd}, this.object);
                this.$emit('callback', {start: this.value, end: this.valueEnd}, this.object);
            },
            GetOffset(isRange){
                if(isNaN(parseInt(this.min)) || isNaN(parseInt(this.max))) return '50%';
                if(this.min === this.max) return '50%';
                return (100 * (((isRange ? this.valueEnd : this.value) - this.min) / (this.max - this.min))) + '%'
            },
            GetOffsetMiddle(){
                let style = {};
                if(isNaN(parseInt(this.min)) || isNaN(parseInt(this.max))) style.left = '50%';
                else if(this.min === this.max) style.left = '50%';
                else style.left = (100 * ((this.value - this.min) / (this.max - this.min))) + '%';
                style.width = (100 * ((this.valueEnd - this.value) / (this.max - this.min))) + '%';
                return style;
            },
            GetOffsetHandleLabel(isRange){
                if(!this.isRange || !this.$el) return {};
                let style = {};
                let query = this.$el.querySelector('.handle-container.start .amount');
                if(!query) return;
                let boxStart = query.getBoundingClientRect();
                query = this.$el.querySelector('.handle-container.end .amount');
                if(!query) return;
                let boxEnd = query.getBoundingClientRect();
                query = this.$el.querySelector('.handle-container.start .handle');
                if(!query) return;
                let handleStart = query.getBoundingClientRect();
                query = this.$el.querySelector('.handle-container.end .handle');
                if(!query) return;
                let handleEnd = query.getBoundingClientRect();
                let labelStartRightPosition = handleStart.left + (handleStart.width / 2) + (boxStart.width / 2);
                let labelEndLeftPosition = handleEnd.left + (handleEnd.width / 2) - (boxEnd.width / 2);

                if(labelStartRightPosition > labelEndLeftPosition) {
                    style.marginLeft = (Math.abs(labelEndLeftPosition - labelStartRightPosition) / (isRange ? 2 : -2)) + 'px';
                }
                console.log(style);
                return style;
            },
            GetOffsetLeftWidth(){
                if(isNaN(parseInt(this.min)) || isNaN(parseInt(this.max)))  return 'calc(50% - 10px)';
                if(this.min === this.max) return 'calc(50% - 10px)';
                return 'calc(' + (100 * ((this.value - this.min) / (this.max - this.min))) + '%' + ' - 10px)';
            },
        },
    }
</script>

<style scoped>
    .slider{
        border-radius: 100px;
        position: relative;
        width: 100%;
        height: 20px;
        width: calc(100% - 46px) !important;
        left: 30px;
    }

    .slider *{
       user-select: none;
    }

    .slider .bar{
        position: absolute;
        /*left: 10px;
        width: calc(100% - 20px);*/
        left: 0;
        width: 100%;
        height: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        background: var(--contrast-2);
    }

    .slider .bar.left{
        background: #e51550;
    }

    .slider .bar .handle-container{
        position: absolute;
        height: 35px;
        width: 22px;
        bottom: -5px;
        margin: auto;
        border-radius: 100px;
        cursor: pointer;
        margin-left: -10px;
        z-index: 2;
    }
    .slider .bar .handle-container.middle{
        background: #e51550;
        margin: auto;
        cursor: pointer;
        border-radius: 0;
        height: 100%;
        bottom: 0;
        z-index: 0;
    }

    .slider .bar .handle-container .handle{
        position: absolute;
        height: 20px;
        width: 2px;
        background: #404040;
        bottom: 2px;
        margin: auto;
        left: 0;
        right: 0;
        pointer-events: none;
        z-index: 2;
    }


    .slider.inactive .handle-container{
        /*background: #ccc;
        cursor: default;*/
    }

    .slider .amount{
        position: absolute;
        margin: auto;
        display: inline-block;
        pointer-events: none;
        width: fit-content;
        padding: 5px;
        border-radius: 5px;
        transform: translate(-50%, -90%);
        height: 25px;
        line-height: 13px;
        margin-left: 0px;
        color: #e51550;
        font-family: DistrictProBold;
        opacity: 0;
        opacity: 1;
        font-size: 13px;
    }


    .slider .amount.min{
        color: var(--contrast-2);
        left: 0;
        font-family: DistrictProBook;
    }


    .slider .amount.max{
        color: var(--contrast-2);
        right: 0;
        margin-left: initial;
        margin-right: 0;
        font-family: DistrictProBook;
        transform: translate(50%, -90%);
    }

    .slider:hover .amount{
        opacity: 1;
    }

    .step{
        position: absolute;
        top: 0;
        left: -30px;
        bottom: 0;
        margin: 2px 5px;
        height: 20px;
        width: 20px;
    }

    .step.right{
        left: initial;
        right: -14px;
    }
    .label-right{
        position: absolute;
        top: 0;
        left: initial;
        right: -55px;
        width: 20px;
        top: -4px;
        margin: 2px 5px;
    }

    input.controller{
        background: none;
        box-shadow: none;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        width: 1px;
        height: 1px;
    }

</style>
