<template>
  <div class="landing">
    <div class="background">
      <div class="landing-title">
        <div class="big">M</div>
        <div class="small">L</div>
      </div>
    </div>
    <div class="landing-container">
      <div class="iconcontainer">
        <div class="landing-icon">
          <img src="img/md-logo.png" />
        </div>
        <div class="landing-icon">
          <img src="img/mz-logo.png" />
        </div>
        <div class="landing-icon">
          <img src="img/mp-logo.png" />
        </div>
        <div class="landing-icon">
          <img src="img/ml-logo.png" />
        </div>
      </div>
      <div class="labelcontainer">
        <div class="labelmy">MEINE</div>
        <div class="labeldpl">LOHNABRECHNUNG</div>
      </div>
      <div class="button-container">
        <div
          class="landing-button-container"
          v-for="(route, index) in routes"
          :key="index"
        >
          <buttonc
            v-bind="route"
            @click="UpdateMenu(route)"
          />
          <!--<listlabel :state="{labels: route.links.filter(l => isSuperUser || !l.superUser).map(l => LabelFromLink(l))}"/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      routes: [
        {
          type: "landing-button",
          label: "Personalakt",
          icon: "far fa-users",
          links: [
            {
              label: "Personalakt",
              name: "documents",
              params: {
                //storeId: this.$store.state.storeId,
                tab: "employeedata",
              },
              default: true,
            },
          ],
        },
        {
          type: "landing-button",
          label: "Export",
          icon: "far fa-download",
          links: [
            {
              label: "Export",
              name: "absence",
              params: {
                //storeId: this.$store.state.storeId,
                tab: "ExportMonthly",
              },
              default: true,
            },
          ],
        },
        {
          type: "landing-button",
          label: "Abgleich",
          icon: "far fa-memo-circle-info",
          links: [
            {
              label: "Abgleich",
              name: "comparison",
              params: {
                //storeId: this.$store.state.storeId,
                tab: "ComparisonHoliday",
              },
              default: true,
            },
          ],
        },
        {
          type: "landing-button",
          label: "Reports",
          icon: "far fa-file-signature",
          links: [
            {
              label: "Reports",
              name: "reports",
              params: {
                //storeId: this.$store.state.storeId,
                tab: "ExportMonthly",
              },
              default: true,
            },
          ],
        },
        
        {
          type: "landing-button",
          label: "Einstellungen",
          icon: "far fa-cogs",
          links: [
            {
              label: "Einstellungen",
              name: "company",
              params: {
                //storeId: this.$store.state.storeId,
                tab: "CompanySettings",
              },
              default: true,
            },
          ],
        },
      ],
    };
  },
  computed: {},
  methods: {
    LabelFromLink(link) {
      let view = this;
      return {
        text: link.label,
        type: "block",
        click: () => {
          view.$router.push(link);
          if (window.menubar) window.menubar.$forceUpdate();
        },
      };
    },
    UpdateMenu(route) {
      this.$router.push(route.links.find((l) => l.default));
      if (window.menubar) window.menubar.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.landing {
  background: #323a45;
  background-image: url(/img/md_bg_overlay.svg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 110%;
  text-align: center;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.landing-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 800px;
  height: fit-content;
}

.button-container {
  width: 500px;
  margin: 0px 150px 0px 150px;
}

.iconcontainer {
  height: 68px;
}

.landing-icon {
  width: 68px !important;
  height: 68px !important;
  margin: 8px;
  display: inline;
}

.landing-icon img {
  width: 60px;
  height: 60px;
}

.labelcontainer {
  margin: 16px 0px 20px 0px;
}

.labelmy {
  display: inline;
  font-size: 70px;
  color: white;
  font-family: DistrictProThin;
}

.labeldpl {
  display: inline;
  font-size: 70px;
  color: white;
  font-family: DistrictProBold;
}

.landing-button-container {
  position: relative;
  display: inline-block;
  margin: 4px 8px;
}

.landing-button-container .list-label {
  position: absolute;
  z-index: 10;
  display: none;
  width: 100%;
  background: var(--bg);
  right: -100%;
  top: 0;
  box-shadow: 0 0 1px var(--contrast-3);
}

.landing-button-container:hover .list-label {
  display: block;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #323a45;
}

.landing-title {
  position: absolute;
  top: 55px;
  left: 110px;
  width: 100%;
  height: 100%;
  transform: rotate(-45deg);
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.background .big {
  font-family: DistrictProBold;
  color: #3a424c;
  display: inline-flex;
  font-size: 800px;
  align-items: flex-end;
  height: fit-content;
  line-height: 78%;
}
.background .small {
  align-items: flex-end;
  line-height: 87%;
  height: fit-content;
  font-family: DistrictProBold;
  display: inline-flex;
  color: #3a424c;
  font-size: 550px;
}
</style>
