<template>
    <div class="paging">
        <div class="fas fa-angle-left" v-on:click="ChangePage(-1)"></div>
        <div class="label bold">{{pageCounter}}</div>
        <div class="fas fa-angle-right" v-on:click="ChangePage(1)"></div>
    </div>
</template>

<script>
    export default {
        name: "datatablepaging",
        data(){
            return {
                pageCurrent: 0,
            }
        },
        props:{
            maxItems: Number,
            pageLength: Number,
        },
        mounted(){
            this.UpdateState();
        },
        computed: {
            pageCounter(){
                return (this.pageCurrent + 1) + '/' + Math.ceil(this.maxItems / this.pageLength);
            },
        },
        methods: {
            ChangePage(increase){
              this.pageCurrent += increase;
              if(this.pageCurrent >= Math.ceil(this.maxItems / this.pageLength)){
                  this.pageCurrent = 0;
              }
                if(this.pageCurrent < 0) this.pageCurrent = Math.ceil(this.maxItems / this.pageLength);
              this.UpdateState();
            },
            UpdateState(){
                this.$emit('update', {
                    pageCurrent: this.pageCurrent,
                    pageLength: this.pageLength,
                    maxItems: this.maxItems,
                })
            }
        }
    }
</script>

<style scoped>


    .paging{
        display: inline-flex;
        align-items: center;
        position: absolute;
        z-index: 10;
        background: none;
        right: 10px;
        width: initial;
        padding: 0 20px;
        top: 10px;
    }

    .paging .label{
        color: var(--contrast-4);
        font-family: DistrictProLight;
        font-weight: 200;
    }

    .paging .label.today{
        font-size: 15px;
        cursor:pointer;
    }

    .paging .fa-angle-right,.paging .fa-angle-left{
        color: var(--contrast-4);
        font-size: 20px;
        padding: 5px;
        cursor:pointer;
    }

    .paging .fa-angle-right:hover,.paging .fa-angle-left:hover, .paging .fa-calendar:hover, .paging .today:hover{
        color: var(--bg) !important;
    }

    .paging .label.bold{
        font-family: DistrictProBold;
        font-weight: 800;
        margin: -3px 5px 0;
    }
</style>