<template>
    <div class="user-select" v-bind:style="{top: posy + 'px', left: posx + 'px', display: toggled ? 'inline-block' : 'none'}">
        <div class="background" v-on:click="Toggle(false)"></div>
        <div class="pointer" v-bind:class="[pointerDirection]"></div>
        <div class="header">
            <div class="label">Verfugbares Personal</div>
        </div>
        <div class="fa-stack cancel" v-on:click="Toggle(false)">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-times fa-stack-2x"></i>
        </div>
        <dropdownsearch class="filters" :state="filters"/>
        <div class="scrollbar-container">
            <div class="entry" v-for="(entry, index) in itemsFiltered" v-bind:class="[entry.classes]" :key="index" v-on:click="selected = entry, SelectItem(entry)">
                <div class="label">{{displayMap(entry)}}</div>
            </div>
        </div>
        <div class="divider"></div>
        <div class="label">Wählen Sie eine Person aus und</div>
        <div class="label second">klicken Sie auf Zuweisen</div>
        <div class="button" v-bind:class="{'disabled': !selected}" v-on:click="ReturnSelection()">
            <div class="label">Zuweisen</div>
        </div>
        <div class="button remove" v-bind:class="{'disabled': !shift || shift.published}" v-on:click="RemoveShiftFromEmployee()">
            <div class="label">Kein Mitarbeiter</div>
        </div>
    </div>
</template>

<script>
    import dropdownsearch from '../uicomponents/dropdownsearch.vue'
    export default {
        name: 'timebaruserselect',
        components: {
            dropdownsearch,
        },
        data(){
            return{
                selectedItem: {},
                items: [],
                toggled: false,
                posxstart: 0,
                posystart: 0,
                posx: 0,
                posy: 0,
                width: 0,
                height: 0,
                appendedData: {},
                info: [],
                callbackEnd: function(){},
                htmlContent: '',
                shadowAdded: [],
                toggleUserSelect: false,
                selected: null,
                filters: {},
                itemsFiltered: [],
                displayMap: function(){},
                pointerDirection: 'top',
                elRelative: null,
                shift: null,
                overlay: null
            }
        },
        created () {
            var view = this;
            this.toggled = false;
            requestAnimationFrame(function(){
                $(view.$el).appendTo('body');
            });
            window.addEventListener('resize', this.Reposition);
        },
        destroyed(){
            window.removeEventListener('resize', this.Reposition);
        },
        methods: {
            Toggle(val){
                if(val !== undefined || val !== null) {
                    if(!val) this.selected = null;
                    this.toggled = val;
                    return;
                }
                this.toggled = !this.toggled;
                if(!this.toggled) this.selected = null;
            },
            ReturnSelection(){
                this.callbackEnd(this.selected);
                this.toggled = false;
            },
            RemoveShiftFromEmployee(){
                if(!this.shift.employeeId) return;
                var employee = window.roster.employeeMap[this.shift.employeeId];
                var shift = this.shift;

                if(employee){
                    employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')] = employee.shiftsDate[shift.fromDate.format('YYYY-MM-DD')].filter(s => s !== shift);
                    employee.shifts = employee.shifts.filter(s => s !== shift);
                    delete shift.employeeId;
                    window.roster.dayModeShifts = Object.values(window.roster.planShiftMap).filter(s => !s.employeeId && window.roster.dayModeDate.format('YYYY-MM-DD') === s.fromDate.format('YYYY-MM-DD'));
                    window.roster.$forceUpdate();
                    window.roster.UpdateShift(shift, {employeeId: null});
                    var timebar = window.timebars.find(t => t && t.timeFrames && t.timeFrames.find(f => f.shift && f.shift === shift));
                    if(timebar) timebar.$refs.overlay.toggled = false;
                    if(timebar) timebar.$refs.overlay.$forceUpdate();
                    if(timebar) timebar.Init(timebar.day);
                }
                this.toggled = false;
            },
            SelectItem(item){
                var view = this;
                for(var x in this.items){
                    (function(item){
                        if(!item.classes) item.classes = "";
                        item.classes = item.classes.split('selected').join(' ');
                        if(item === view.selected) item.classes += ' selected';
                    })(this.items[x]);
                }
            },
            ShiftPreference(){
                if(!this.shift) return;
                var view = this;
                for(var x in this.items){
                    (function(item, shift){
                        var shiftTemp = [];
                        shiftTemp.push(shift);
                        var shiftCheck = window.roster.GetDayAvailabilityRelativeToShifts(item, shift.fromDate, shiftTemp);
                        item.classes = shiftCheck;
                        if(item === view.selected) item.classes += ' selected';
                    })(this.items[x], this.shift);
                }
            },
            Reposition(){
                var view = this;
                requestAnimationFrame(function(){
                    if(!view.elRelative) return;
                    var box = view.elRelative.getBoundingClientRect();
                    var widthTotal = window.innerWidth;
                    var heightTotal = window.innerHeight;
                    var posx = 0;
                    var posy = 0;
                    var userselectwidth = 415;
                    var userselectheight = 560;
                    var offsety = 20;
                    var offsetyBottom = 70;
                    if(widthTotal - (box.left + (box.width / 2)) > userselectwidth) {
                        posx = (box.left + (box.width / 2)) - (userselectwidth / 2);
                    } else {
                        if(box.left < 150 || widthTotal < userselectwidth) {
                            posx = 5;
                        } else {
                            posx = widthTotal - userselectwidth - 5;
                        }
                    }
                    var pointerDirection = 'top';
                    if(heightTotal - (box.top + box.height + offsety) > userselectheight) {
                        posy = (box.top + box.height + offsety);
                    } else {
                        if(heightTotal < userselectheight || box.top - offsetyBottom - userselectheight < 0) {
                            posy = 5;
                            pointerDirection = 'hide';
                        } else {
                            posy = box.top - offsetyBottom - userselectheight;
                            pointerDirection = 'bottom';
                        }
                    }
                    view.posx = posx;
                    view.posy = posy;
                    view.pointerDirection = pointerDirection;
                });
            },
            SortPreference(item){
                if(item.classes.indexOf('border-red') > -1) return -1;
                if(item.classes.indexOf('border-green') > -1) return 1;
                return 0;
            },
            Init(options){
                var view = this;
                if(options.elRelative){
                    this.elRelative = options.elRelative
                    this.Reposition();
                }
                if(options.callbackEnd) this.callbackEnd = options.callbackEnd;
                if(options.filterItems) this.filterItems = options.filterItems;
                if(options.filterMap) this.filterMap = options.filterMap;
                if(options.items) this.items = options.items;
                if(options.displayMap) this.displayMap = options.displayMap;
                if(options.overlay) this.overlay = options.overlay;
                this.toggled = true;
                if(options.shift) {
                    this.shift = options.shift;
                    this.ShiftPreference();
                    this.items.sort(function(a, b){return view.SortPreference(b) - view.SortPreference(a)});
                }
                this.itemsFiltered = this.items.slice();
                this.filters = {
                    toggled: false,
                    displayedItems: [],
                    input: 'All',
                    items: Object.values(view.filterItems),
                    onceSelected: function ($event, item, searchbar){
                        view.itemsFiltered = view.filterMap(view.items, item);
                        view.$forceUpdate();
                    },
                    styleLight: true,
                }
            }
        },
        mounted() {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .user-select{
        position:fixed;
        display:inline-block;
        height: 560px;
        width: 415px;
        border-radius: 5px;
        display:none;
        z-index: 1000;
        background: white;
        box-shadow: 4px 4px 5px 3px rgba(0,0,0,0.2);
        padding: 50px;
    }

     .background {
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        right: 0;
        z-index: -1;
        top: 0;
    }

    .header{
    }
    .header .label{
        box-shadow: inset 0 -1px 0 0px #ebebeb;
        font-size: 30px;

    }

    .fa-stack.cancel{
        cursor: pointer;
        position: absolute;
        top: 9px;
        right: 5px;
        font-size: 10px;
    }
    .fa-stack.cancel .fa-circle{
    }
    .fa-stack.cancel .fa-times{
        font-size: 13px;
        transform: translateY(3px);
    }

    .dropdown-search{
        width: 100%;
        margin-top: 15px;
    }

    .scrollbar-container{
        position: relative;
        height: 300px;
        top: 0;
        margin-top: 15px;
    }

    .button{
        position: absolute;
        right: 50px;
        margin-top: -5px;
        padding: 2px 15px;
        height: 30px;
    }

    .button .label{

        font-weight: 400;
    }

    .user-select > .label{
        color: #cccccc;
        font-size: 11px;
        font-weight: 500;
        position: absolute;
        margin-top: -10px;
    }
    .user-select > .label.second{

        margin-top: 5px;
    }

    .entry{
        cursor: pointer;
        padding: 1px 5px;
        transition: .2s;
    }

    .entry .label{
    }

    .entry:nth-child(odd){
        background: #e5e5e5;
    }
    .entry.border-red{
        background: rgba(155, 0, 0, .3);
    }
    .entry.border-red:nth-child(odd){
        background: rgba(155, 0, 0, .5);
    }

    .entry.border-green{
        background: rgba(0, 180, 0, .3)
    }
    .entry.border-green:nth-child(odd){
        background: rgba(0, 180, 0, .5)
    }

    .entry:hover, .entry.selected{
        background: var(--ml);
    }
    .entry:hover .label, .entry.selected .label{
        color: white;
    }

    .divider{
        width: 100%;
        margin: 15px 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .pointer{
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        width: 0;
    }

    .pointer:before{
        content:'';
        position: absolute;
        top: 1px;
        left: -13px;
        z-index: -1;
        filter: blur(5px);
    }

    .pointer:after{
        content:'';
        position: absolute;
        left: -20px;
        background: white;
        width: 40px;
        height: 20px;
        z-index: -1;
    }

    .pointer.top{
        top: -15px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid white;
    }
    .pointer.top:before{
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid rgba(0,0,0,.2);
    }
    .pointer.top:after{
        top: 15px;
    }
    .pointer.bottom{
        bottom: -15px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid white;
    }
    .pointer.bottom:before{
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid rgba(0,0,0,.2);
        top: initial;
        bottom: 1px;
    }
    .pointer.bottom:after{
        bottom: 15px;
    }

    .pointer.hide{
        display: none;
    }

    .button.remove{
        margin-top: 30px;
        right: 58px;
    }
</style>
